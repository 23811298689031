import { getPinnedMembersBySeminar, getRemoteId, getScreenUserID, getSpeakerId } from '../base/members';
import { toState } from '../base/redux';
import { isRoomJoined } from '../room';
import { LAYOUT_TYPE, videoLayoutMode } from './constants';
import _ from 'lodash';
/**
 * 듀얼 모니터 여부
 * @param {Function} stateful
 * @returns
 */
export function getDualMonitor(stateful) {
    const state = toState(stateful);
    return state['features/video-layout'].isDualMonitor;
}
/**
 * 알림 문구 표시
 */
export function getCurrentDualWindow(stateful) {
    const state = toState(stateful);
    return state['features/video-layout'].isDualMonitor && state['features/base/settings'].dualSideOpen;
}
/**
 * 현재 모드 가져오기
 * @param {IStore['getState']} statful
 * @param {CheckModeData} data
 * @returns
 */
export function getCurrentMode(statful, data) {
    const state = toState(statful);
    const mode = data?.mode || state['features/video-layout'].mode;
    const view_mode = data?.viewVisible || state['features/video-layout'].viewVisible;
    if (!view_mode)
        return mode;
    let current = mode;
    const viewMode = Object.entries(view_mode).find(([name, value]) => {
        if (value)
            return name;
    });
    if (viewMode && viewMode[0])
        current = viewMode[0];
    return current;
}
/**
 * 현재 레이아웃 타입
 * @param {IStore['getState']} stateful
 * @returns
 */
export function getLayoutType(stateful) {
    const state = toState(stateful);
    return state['features/video-layout'].layoutType;
}
/**
 * 현재 모드에 따른 사용자 정보 가져오기
 * @param {IStore['getState']} stateful
 * @param {Object} layoutStyle
 */
export function getSeminarPinScreen(stateful, mode, layout_type) {
    let seminar = [];
    let pin = [];
    let screen = [];
    if (layout_type === LAYOUT_TYPE.desktop &&
        [videoLayoutMode.note, videoLayoutMode.white, videoLayoutMode.document, videoLayoutMode.vod].includes(mode)) {
        const seminarId = getSpeakerId(stateful);
        if (seminarId)
            seminar = [seminarId];
    }
    if (mode === videoLayoutMode.seminar) {
        const seminarId = getSpeakerId(stateful);
        const pinIds = getPinnedMembersBySeminar(stateful);
        if (seminarId)
            seminar = [seminarId];
        if (pinIds && pinIds.length > 0)
            pin = pinIds;
    }
    if (mode === videoLayoutMode.screen) {
        const screenId = getScreenUserID(stateful);
        if (screenId)
            screen = [screenId];
    }
    return { seminar, pin, screen };
}
export function getMembersByMode(stateful, props) {
    if (!isRoomJoined(stateful))
        return null;
    const { layout_type, mode } = props;
    const { seminar, pin, screen } = getSeminarPinScreen(stateful, mode, layout_type);
    return {
        pin,
        voice: [],
        seminar,
        screen,
    };
}
export function getDualMember(stateful, props) {
    if (!isRoomJoined(stateful))
        return null;
    const { layout_type, isDualMonitor, mode, page, grid_count } = props;
    const { seminar, pin, screen } = getSeminarPinScreen(stateful, mode, layout_type);
    if (isDualMonitor) {
        const sortMember = _.cloneDeep(props.sortMember);
        const member = sortMember && sortMember.length > 0 ? sortMember : getRemoteId(stateful, [...seminar, ...pin, ...screen]);
        return {
            page,
            maxPage: member && member.length > 0 ? Math.ceil(member.length / grid_count) : 1,
            remote: member.splice((page - 1) * grid_count, grid_count) || [],
        };
    }
    else {
        return {
            page: 1,
            maxPage: 1,
            remote: [],
        };
    }
}
/**
 * 방 설정 권한 확인 (레이아웃에서만 사용)
 * @param {IStore['getState']} stateful
 * @param {String} type
 * @returns Boolean
 */
export function checkLayoutPermission(stateful, type) {
    const state = toState(stateful);
    const permissions = state['features/room'].permissions;
    if (permissions.indexOf(type) !== -1)
        return true;
    return false;
}
/**
 * 공유 모드에서 화면이 펼쳐져있냐 안펼쳐져있냐
 * @param {IStore['getState']} stateful
 * @returns
 */
export function getExpand(stateful) {
    const state = toState(stateful);
    return state['features/video-layout'].expand;
}
/**
 * 현재 레이아웃 스타일 크기 가져오기
 * @param {IStore['getState']} stateful
 * @returns
 */
export function getLayoutWidthAndHeight(stateful) {
    const state = toState(stateful);
    return {
        width: state['features/video-layout'].layoutWidth,
        height: state['features/video-layout'].layoutHeight,
    };
}
/**
 * 모드에 표시 되는 숫자
 * @param {Function} stateful
 * @returns
 */
export function getPage(stateful) {
    const state = toState(stateful);
    const { page, maxPage } = state['features/video-layout'];
    return { page, maxPage };
}
/**
 * 현재 화면에 맞는 타입 사용자 가져오기
 * @param {IStore['getState']} stateful
 * @param {String} type
 * @returns
 */
export function getLayoutMemberByType(stateful, type) {
    const state = toState(stateful);
    const members = state['features/video-layout'][type];
    return members && members.length > 0 ? members : undefined;
}
/**
 * 현재 화면에 remote 사용자가 존ㅈㅐ 하는지에 대한 여부
 * @param {IStore['getState']} stateful
 * @param {String} type
 * @returns
 */
export function isShowByType(stateful, type) {
    const state = toState(stateful);
    const members = state['features/video-layout'][type];
    return members && members.length > 0 ? true : false;
}
/**
 * 사용자 크기 가져오기
 * @param {Function} stateful
 * @param {Number} memberCount
 * @param {Object} style
 * @param {Boolean} half
 * @returns
 */
export function getFilmStyle(stateful, memberCount, style, half = false) {
    const showCount = memberCount; // Math.min(state['features/video-layout'].showCount, memberCount);
    let { width, height } = style || getLayoutWidthAndHeight(stateful);
    if (!showCount || !width || !height)
        return { width: 0, height: 0 };
    height = half ? height / 2 : height;
    const columns = getGirdColumns(showCount);
    const rows = getGirdRows(showCount);
    let newWidth = width / columns;
    let newHeight = height / rows;
    let filmWidth = newWidth;
    let filmWHeight = (9 * filmWidth) / 16;
    if (Math.floor(newHeight / filmWHeight) < rows && newHeight < filmWHeight) {
        filmWHeight = newHeight;
        filmWidth = (16 * filmWHeight) / 9;
    }
    return { width: filmWidth, height: filmWHeight };
}
/**
 * 그리드 행 가져오기
 * @param {Number} count
 * @returns
 */
function getGirdColumns(count) {
    const rows = Math.round(Math.sqrt(count));
    const alpha = count - rows * rows;
    const plus = alpha > 0 ? 1 : 0;
    const columns = rows + plus;
    return columns;
}
/**
 * 그리드 열 가져오기
 * @param {Number} count
 * @returns
 */
function getGirdRows(count) {
    const rows = Math.round(Math.sqrt(count));
    return rows;
}
const VIDEO_QUALITY_LEVELS = {
    ULTRA: 2160,
    HIGH: 720,
    STANDARD: 360,
    LOW: 180,
    NONE: 0,
};
/**
 * 영상 화질 판단
 */
export const allocateUsersByVideoQuality = (state, data) => {
    const isDualMonitor = getDualMonitor(state);
    const mode = getCurrentMode(state);
    const { remote, pin, seminar, screen, layoutType } = data;
    // lastN 제한없음. 기본값
    let lastN = -1;
    // 기본 엔드포인트 제약. 수신 안함
    const defaultConstraints = {
        maxHeight: 0,
    };
    // 스테이지 또는 선택
    const selectedOrOnStage = new Map();
    // 엔드포인트 제약
    const constraints = {};
    switch (mode) {
        case videoLayoutMode.note:
        case videoLayoutMode.white:
        case videoLayoutMode.document:
        case videoLayoutMode.vod:
            // 태블릿 및 모바일엔 사용자 얼굴 안뜸
            if (LAYOUT_TYPE.mobile === layoutType || LAYOUT_TYPE.tablet === layoutType)
                break;
            for (let i = 0; i < seminar?.length; i++) {
                selectedOrOnStage.set(`${seminar[i]}-v0`, 'onStage');
                constraints[`${seminar[i]}-v0`] = {
                    maxHeight: layoutType === LAYOUT_TYPE.mobile ? VIDEO_QUALITY_LEVELS.STANDARD : VIDEO_QUALITY_LEVELS.HIGH,
                };
            }
            break;
        case videoLayoutMode.screen:
            // 화면공유 모드
            // 화면공유자 => high / selected
            // 공유된 화면 => high / onStage
            {
                if (screen) {
                    selectedOrOnStage.set(`${screen}-v0`, 'onStage');
                    constraints[`${screen}-v0`] = {
                        maxHeight: layoutType === LAYOUT_TYPE.mobile
                            ? VIDEO_QUALITY_LEVELS.STANDARD
                            : VIDEO_QUALITY_LEVELS.HIGH,
                    };
                }
            }
            break;
        case videoLayoutMode.seminar:
            {
                for (let i = 0; i < seminar?.length; i++) {
                    selectedOrOnStage.set(`${seminar[i]}-v0`, 'onStage');
                    constraints[`${seminar[i]}-v0`] = {
                        maxHeight: layoutType === LAYOUT_TYPE.mobile
                            ? VIDEO_QUALITY_LEVELS.STANDARD
                            : VIDEO_QUALITY_LEVELS.HIGH,
                    };
                }
                for (let i = 0; i < pin?.length; i++) {
                    selectedOrOnStage.set(`${pin[i]}-v0`, 'onStage');
                    constraints[`${pin[i]}-v0`] = {
                        maxHeight: layoutType === LAYOUT_TYPE.mobile
                            ? VIDEO_QUALITY_LEVELS.STANDARD
                            : VIDEO_QUALITY_LEVELS.HIGH,
                    };
                }
            }
            break;
    }
    const selectedSources = [];
    const onStageSources = [];
    selectedOrOnStage.forEach((value, key) => {
        if (value === 'selected') {
            selectedSources.push(key);
        }
        else if (value === 'onStage') {
            onStageSources.push(key);
        }
    });
    return {
        colibriClass: 'ReceiverVideoConstraints',
        lastN: lastN,
        selectedSources: selectedSources,
        onStageSources: onStageSources,
        defaultConstraints: defaultConstraints,
        constraints: constraints,
        assumedBandwidthBps: 1024 * 1024 * 5,
        //...(location.search.includes('assumedBandwidthBps') ? { assumedBandwidthBps: 1024 * 1024 * 5 } : {}),
    };
};
/**
 * 모의고사 모드 여부
 * @param {Function} stateful
 * @returns
 */
export function getIsTestMode(stateful) {
    const state = toState(stateful);
    return state['features/video-layout'].options?.isTestMode;
}
export function getTestLayoutOptions(stateful) {
    const state = toState(stateful);
    const options = state['features/video-layout'].options;
    return {
        backgroundUrl: options?.backgroundUrl,
        testId: options?.testId,
    };
}
export function getSortMember(stateful) {
    const state = toState(stateful);
    return state['features/video-layout'].sortMember;
}
