import styled, { css } from 'styled-components';
import { flexBox, layoutBase, paddingBox } from '../../../css/theme';
export const RoomStyled = styled.div `
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;

	.record_list {
		background: #d00000 !important;
		font-weight: 600;
		font-size: 15px;
		border-radius: 20px !important;
		padding: 1px 20px !important;
	}

	.count {
		border-left: 1px solid;
		${props => (props.individualLive ? `` : `border-right: 1px solid`)};
		padding: 0 8px;
		box-sizing: border-box;
	}

	.time {
		${props => (props.individualLive ? `display: none!important;` : ``)};
	}

	.layout {
		.sidebar {
			margin: 0;
		}

		.test_layout {
			max-width: 100%;
		}
	}

	${props => css `
		${layoutBase()}

		.room_label {
			padding: 8px;
		}

		.room_item {
			.center {
				left: 50%;
			}

			> * {
				width: inherit;
				flex: 1;
				overflow: hidden;
			}

			.click_button {
				${flexBox('column', 'center', 'center', '4px')};
				width: max-content;
			}

			&.row {
				.row_icon {
					flex-direction: row;
					gap: 12px;
				}
				.left {
					flex: ${props.isMobile ? 2 : 1};

					${flexBox('row', 'center', 'flex-start', '12px')};
				}

				.room_center {
					${flexBox('row', props.testEnter ? 'flex-end' : 'center', props.isMobile ? (props.isTestMode ? 'flex-end' : 'space-around') : 'center', '20px')}
				}

				.right {
					${flexBox('row', 'center', 'flex-end', '12px')}
				}
			}

			&.column {
				.left {
					${flexBox('column', 'center', 'flex-start', '12px')}
				}

				.room_center {
					${flexBox('column', 'center', 'space-around', '12px')}
				}

				.right {
					${flexBox('column', 'center', 'flex-end', '12px')}
				}
			}
		}

		.layout_mode {
			display: flex;
			flex-direction: column;
		}

		.header {
			width: 100%;
			height: 50px;
			min-height: 50px;
			position: relative;
			${paddingBox('0 15px')};
			${flexBox('row', 'center', 'flex-start', '12px')};
		}

		.body {
			margin: 0;
			display: flex;
			width: 100%;
			height: 100%;
			overflow: hidden;

			.layout {
				position: relative;
				display: flex;
				height: 100%;
				overflow: hidden;
			}

			&.row {
				flex-direction: column;

				.layout {
					flex: 1;
					width: 100%;
				}

				.footer {
					${paddingBox('0 15px')};
					${flexBox('row', 'center', 'flex-start', '12px')};
					width: 100%;
					height: 50px;
				}
			}

			&.column {
				flex-direction: row-reverse;

				.layout {
					flex: 1;
					width: calc(100% - 60px);
				}
				.footer {
					${paddingBox('15px 0')};
					${flexBox('column', 'center', 'flex-start', '12px')};
					width: 60px;
					height: 100%;
				}
			}
		}

		.floating {
			background: transparent;
			position: absolute;
			width: 50px;
			top: 70px;
			right: 10px;

			.left {
				box-sizing: border-box;
				display: flex;
				flex-direction: column;
				gap: 12px;
			}
		}
	`}
`;
