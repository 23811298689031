import React from 'react';
import * as ReactDOMClient from 'react-dom/client';
import { AppContainer } from './features/app';
import './features/app/middlewares.web';
import './features/app/reducers.web';
import { API } from './modules/API';
import { default as UI } from './modules/UI/UIHelper.web';
import EventManager from './modules/server/EventManager';
import { default as Management } from './modules/server/index.js';
// import './css/all.scss'; //개발 할때만 사용
import { setConfig } from './config/test';
const { config } = require('./config/' + setConfig() + 'Config');
const management = new Management();
const eventManager = new EventManager();
const window = global;
window.APP = {
    API,
    config,
    UI,
    management,
    canvasList: new Map(),
    vodList: [],
    eventManager,
    peerconnections: [],
};
const container = document.getElementById('root');
if (container) {
    const root = ReactDOMClient.createRoot(container);
    root.render(React.createElement(AppContainer, null));
}
