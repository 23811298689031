import React, { useMemo, forwardRef } from 'react';
import { ButtonOption } from '../option';
// @ts-ignore
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { Icon } from '../../ui';
export const Button = forwardRef(({ id, className, draggable, text, option, tabIndex, styles, onClick, onDoubleClick, handlerIcon }, ref) => {
    const { t } = useTranslation();
    const buttonOption = useMemo(() => _.merge({}, ButtonOption, option), [option]);
    return (React.createElement("button", { ref: ref, id: id, className: `button ${className} ${onClick ? 'cursor-pointer' : 'cursor-default'}`, onClick: onClick, onDoubleClick: onDoubleClick, tabIndex: tabIndex, draggable: draggable, style: styles?.buttonWrap },
        handlerIcon && React.createElement(Icon, { className: "handlerIcon", icon: handlerIcon, size: 24, color: "#fff" }),
        buttonOption.buttonBeforeIcon.show && (React.createElement(Icon, { className: buttonOption.buttonBeforeIcon.className, icon: buttonOption.buttonBeforeIcon.name, size: buttonOption.buttonBeforeIcon.size, width: buttonOption.buttonBeforeIcon.width, height: buttonOption.buttonBeforeIcon.height, fill: buttonOption.buttonBeforeIcon.color, stroke: buttonOption.buttonBeforeIcon.stroke, color: buttonOption.buttonBeforeIcon.color })),
        text && React.createElement("p", { className: "overText" }, t(text)),
        buttonOption.buttonAfterIcon.show && (React.createElement(Icon, { className: buttonOption.buttonAfterIcon.className, icon: buttonOption.buttonAfterIcon.name, size: buttonOption.buttonAfterIcon.size, width: buttonOption.buttonBeforeIcon.width, height: buttonOption.buttonBeforeIcon.height, fill: buttonOption.buttonAfterIcon.color, stroke: buttonOption.buttonBeforeIcon.stroke, color: buttonOption.buttonBeforeIcon.color }))));
});
