import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getLocalMember, setHandlerMember } from '../members';
import { ClickButton } from '../ui';
import { Colors } from '../common/Colors';
export const LocalHandsup = React.memo(({ mode, iconSize, iconStyles, label, className, }) => {
    const dispatch = useDispatch();
    const local_user = useSelector((state) => getLocalMember(state));
    const hands_up_policy = useSelector((state) => state['features/base/settings'].policy.hands_up);
    const { user_uuid, hands_up } = useMemo(() => {
        return {
            user_uuid: local_user?.user_uuid,
            hands_up: local_user?.hands_up,
        };
    }, [local_user]);
    const onClick = () => {
        if (local_user?.pinned) {
            return;
        }
        // @ts-ignore
        dispatch(setHandlerMember(user_uuid, !hands_up, false));
    };
    if (hands_up_policy === 'off')
        return null;
    return (React.createElement(ClickButton, { key: mode, className: `${className} hands_up ${hands_up ? 'hands_up_active' : ''} no-hover`, icon: mode, label: local_user?.pinned ? '손들기' : label, size: iconSize, color: local_user?.pinned ? Colors.tur : hands_up ? '#8bacff' : '#fff', styles: iconStyles, onClick: onClick }));
});
