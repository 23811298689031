import React from 'react';
// @ts-ignore
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { getLocalMember } from '../members';
import { ClickButton } from '../ui';
import PopupContainer from './PopupContainer';
import { addFileMessage } from './action';
import { EXT_STRING, FILE_MAX_SIZE } from './constants';
const ChatFilePopup = React.memo(({ status, data }) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const local = useSelector((state) => getLocalMember(state));
    const sendMessagee = () => {
        dispatch(addFileMessage(data, local, true));
        APP.eventManager.publish('UPDATE_OPEN_CHAT_POPUP');
    };
    return (React.createElement(PopupContainer, { title: t(`chat.${status}`), guid: t('chat.fileGuid', { volume: FILE_MAX_SIZE, file: EXT_STRING }) },
        React.createElement(ClickButton, { className: "no-hover", label: data.name }),
        React.createElement(ClickButton, { label: t('chat.send'), isText: true, onClick: sendMessagee })));
});
export default ChatFilePopup;
