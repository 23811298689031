import throttle from 'lodash/throttle';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useGlobalState } from '../../app/GlobalStateProvider';
import { InputElement, Table } from '../common';
import { Colors } from '../common/Colors';
import { Icon } from '../ui';
import { useScoreStatus, useStatistics } from './querys';
export const ErrataTable = React.memo(({ subject_name, isDetail, test_id, member_id, }) => {
    const { t } = useTranslation();
    const [page, setPage] = useState(0);
    const [testData, setTestData] = useState();
    const [totalCount, setTotalCount] = useState(0);
    const [notSubmit, setNotSubmit] = useState(false);
    const { isMobile } = useGlobalState();
    const { data: statisticData, refetch: statisticRefetch } = useStatistics({ test_id });
    //모바일 창에서 오답률을 보여주기 위해
    useEffect(() => {
        statisticRefetch();
    }, [test_id]);
    const { headTitleList, tableReadDataKeys } = React.useMemo(() => {
        // 기본적인 항목들
        const headTitleList = [
            { title: 'subject', space: 1, sort: false },
            { title: 'no', space: 1, sort: false },
            { title: 'answer', space: 1, sort: false },
            ...(isDetail ? [{ title: 'submitAnswer', space: 1, sort: false }] : []),
            ...(isDetail && !notSubmit ? [{ title: 'errata', space: 1, sort: false }] : []),
            { title: 'score', space: 1, sort: false },
        ];
        const tableReadDataKeys = [
            { title: 'subject', space: 1 },
            { title: 'number', space: 1 },
            { title: 'answer', space: 1 },
            ...(isDetail ? [{ title: 'value', space: 1 }] : []),
            ...(isDetail && !notSubmit ? [{ title: 'correction', space: 1 }] : []),
            { title: 'point', space: 1 },
        ];
        // useMemo에서 반환
        return { headTitleList, tableReadDataKeys };
    }, [notSubmit]);
    const { data, refetch } = useScoreStatus({
        id: test_id,
        member_id,
        valid_correction: member_id ? true : false,
    });
    const refetchList = throttle(refetch, 1000, { leading: true, trailing: false });
    useEffect(() => {
        if (test_id)
            refetchList();
    }, [member_id, test_id]);
    useEffect(() => {
        if (data && data.length > 0) {
            setTestData(data);
            setNotSubmit(member_id && data.every(i => i.correction === '' && i.value === ''));
            setTotalCount(data.length);
            return;
        }
        setTestData([]);
        setTotalCount(0);
        setNotSubmit(true);
    }, [data]);
    const handlerMoreData = () => {
        setPage(prevPage => prevPage + 1);
    };
    const renderBodyItem = (item, headItem, styles, isMobile, subject_name) => {
        const getContent = () => {
            switch (headItem.title) {
                case 'correction':
                    return React.createElement(Icon, { icon: item[headItem.title] === 'O' ? 'pass' : 'fail' });
                case 'answer':
                case 'submitAnswer':
                    return !item[headItem.title] ? ('-') : item.type === 'choice' ? (React.createElement("div", { className: `answer ${headItem.title === 'answer' ? 'pink' : ''}`, style: { ...styles?.bodyText } },
                        React.createElement("div", { className: "frame" },
                            React.createElement("p", null, item[headItem.title])))) : (React.createElement(InputElement, { className: `input_answer ${headItem.title === 'answer' ? 'pink' : ''}`, type: "text", isDisable: true, value: item[headItem.title] || '' }));
                case 'point':
                    return (React.createElement("div", { className: "input_answer_wrapper" },
                        React.createElement(InputElement, { type: "text", isDisable: true, defaultValue: item[headItem.title] }),
                        "\uC810"));
                case 'subject':
                    return (React.createElement("div", { className: "overText2 subject-text" }, ['history', 'character'].includes(subject_name)
                        ? t(`assignment.${subject_name}`)
                        : item[headItem.title]));
                default:
                    return React.createElement("p", { className: "overText5" }, item[headItem.title]);
            }
        };
        return (React.createElement("div", { key: headItem.title, className: "body-item", style: {
                flex: !isMobile && headItem.space,
                ...styles?.bodyItem,
                overflow: 'hidden',
            } }, getContent()));
    };
    const renderItem = (item, styles) => tableReadDataKeys.map(headItem => renderBodyItem(item, headItem, styles, false, subject_name));
    const renderEmpty = useMemo(() => {
        return (React.createElement("div", { className: "sel_eval_item", style: { color: Colors.light_grey, width: '100%', flex: 1 } }, t('assignment.noSubmitPersonGuid')));
    }, []);
    return (React.createElement("div", { className: `errata_table ${isMobile && 'mobile'}` },
        React.createElement(Table, { className: "popup-list-005", showIndex: false, isInifinite: true, page: page, data: testData, showCount: testData?.length, renderEmpty: notSubmit && renderEmpty, totalCount: totalCount, headTitleList: headTitleList, styles: {
                headWrapper: {
                    justifyContent: 'center',
                    alignItems: 'center',
                },
                headItem: {
                    alignItems: 'center',
                    justifyContent: 'center',
                },
            }, renderItem: renderItem, handlerMoreData: handlerMoreData })));
});
