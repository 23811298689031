// {
//     "media": {
//         "id": "c524508e-1ac5-4921-b0a5-b2bcb09b39f5",
//         "creation_time": "2023-07-31T02:31:08.078Z",
//         "status": "converted"
//     },
//     "pause_time": "0001-01-01T00:00:00Z",
//     "play_position": 0,
//     "play_time": "0001-01-01T00:00:00Z",
//     "status": "stopped",
//     "time": "2023-07-31T02:35:15.700204804Z"

import { getVodURLBase } from '../../../features/base/vod/functions';

// }
const getWebsocketURLBase = urlList => {
	if (urlList[0].startsWith('http:')) {
		return `ws:${urlList[1]}`;
	} else if (urlList[0].startsWith('https:')) {
		return `wss:${urlList[1]}`;
	}

	throw new Error('알 수 없는 프로토콜');
};

class Player {
	constructor(urlBase, handler) {
		this.urlBase = urlBase;
		this.handler = handler;

		this.nextId = 0;
		this.connection = null;
		this.onMessage = new Array();
		this.streaming_id = null;
		this.timeDiff = 0;

		this.handleMessage = this.handleMessage.bind(this);
	}

	handleMessage(message) {
		const result = message?.payload?.result;
		switch (message?.class) {
			case '@Streaming.StreamingPlayed':
				{
					const playTime = Date.parse(result.at);
					const position = Date.now() - playTime + result.position;

					this.handler.setPlaying(position / 1000);
				}
				return;

			case '@Streaming.StreamingPaused':
				{
					this.handler.setPause(result?.at);
				}
				return;
		}
	}

	async requestPlay(streaming_auth, position) {
		return await fetch(`${getVodURLBase(this.urlBase)}/api/media/v1/streamings/${this.streaming_id}/play`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				auth_token: streaming_auth,
				position: Math.round(position * 1000),
			}),
		});
	}

	async requestPause(streaming_auth) {
		return await fetch(`${getVodURLBase(this.urlBase)}/api/media/v1/streamings/${this.streaming_id}/pause`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				auth_token: streaming_auth,
			}),
		});
	}

	async getServerTimeDiff(t1) {
		const message = await this._request('GetServerTime', null, 5000);

		if (message?.status != 200) {
			throw new Error(message?.payload?.message);
		}
		const serverTime = Date.parse(message?.payload?.result?.time);
		const timeDiff = t1 + (Date.now() - t1) / 2 - serverTime;
		return timeDiff;
	}

	connectionAndJoin(streaming_id, container) {
		this.streaming_id = streaming_id;
		const connection = new WebSocket(`${getWebsocketURLBase(this.urlBase)}/api/media/v1/websocket`);

		return new Promise((resolve, reject) => {
			this.connection = connection;

			connection.onopen = async () => {
				try {
					this.connection = connection;
					// 연결 끊김.
					connection.onclose = () => {
						this.connection = null;

						this.leave();
					};

					// 메시지
					connection.onmessage = message => {
						this.handleMessage(JSON.parse(message.data));

						this.onMessage.forEach(handle => {
							handle(message.data);
						});
					};

					const joinResponse = await this._request('SubscribeStreaming', { streaming_id }, 5000);
					// const t2 = Date.now();

					if (joinResponse.status !== 200) {
						throw new Error(joinResponse?.payload?.message);
					}

					const result = joinResponse?.payload?.result;

					resolve({
						...result,
						url: getVodURLBase(this.urlBase) + `/api/media/v1/medias/${result.media.id}/manifest.mpd`,
					});
					// resolve(result);
				} catch (err) {
					console.log(err);
				}
			};

			connection.onerror = err => {
				this.connection = null;
				reject(err);
			};

			connection.onclose = err => {
				this.connection = null;

				resolve(true);
			};
		});
	}

	leave() {
		if (!!this.connection) {
			this.connection.close();
		}
	}

	clear() {
		this.groupId = '';
		this.nextId = 0;
		this.connection = null;
		this.onMessage = new Array();
	}

	_request(clazz, payload, timeout = 5000) {
		if (!this.connection || this.connection.readyState !== WebSocket.OPEN) {
			APP.UI.alertMessage('웹소켓 연결되지않았습니다. 새로고침 후 다시 시도해주세요.');
			return;
		}
		const requestID = `${Date.now()}-${++this.nextId}`;

		this.connection.send(
			JSON.stringify({
				class: clazz,
				id: requestID,
				payload,
			}),
		);

		return this._waitFor(
			data => JSON.parse(data),
			res => res?.payload?.id === requestID || res?.id === requestID,
			timeout,
			clazz,
		);
	}

	_subscribe(handle) {
		this.onMessage.push(handle);

		return () => {
			const index = this.onMessage.findIndex(element => {
				return element == handle;
			});

			if (index >= 0) {
				this.onMessage.splice(index, 1);
			}
		};
	}

	async _waitFor(hook, check, timeout, clazz) {
		return new Promise((resolve, reject) => {
			let timerID;
			let unsubcribe;

			const cleanup = () => {
				clearTimeout(timerID);
				unsubcribe();
			};

			unsubcribe = this._subscribe(data => {
				try {
					const result = hook(data);

					if (!check(result)) return;

					cleanup();
					resolve(result);
				} catch (err) {
					cleanup();
					// reject(err);
				}
			});

			timerID = setTimeout(() => {
				cleanup();
				// reject(clazz);
			}, timeout);
		});
	}
}

export default Player;
