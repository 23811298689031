import styled, { css } from 'styled-components';
export const LayoutContainerStyled = styled.div `
	width: 100%;
	height: 100%;
	margin: 0;
	display: flex;
	color: #fff;
	overflow: hidden;

	position: relative;
	display: inline-flex;
	width: ${props => (props.isHide ? 0 : '100%')};
	height: 100%;
	overflow: hidden;
	flex-direction: ${props => (props.horizontal ? 'column' : 'row')};
	justify-content: ${props => (props.isGrid ? 'center' : 'flex-start')};
	align-items: ${props => (props.isGrid ? 'center' : 'flex-start')};
	background: ${props => APP.config.style.main};
	.voice {
		display: inline-flex;
	}

	.gridSlide {
		display: grid;
		grid-auto-flow: row;
		width: 100%;
		height: 100%;
		grid-template-rows: repeat(auto-fit, minmax(100px, 1fr));
		overflow: hidden;
		${props => props.name === 'class' &&
    css `
				justify-items: center;
				gap: 15px;

				.video_label {
					width: 60%;
					left: 50%;
					transform: translate(-50%, -14px);
					/* height: 5%; */
					border-radius: 28px;
					padding: 12px;
					box-sizing: border-box;
				}
			`}
		.item {
			${props => props.name === 'class'
    ? css `
							width: 80% !important;
							height: 100% !important;
							border-radius: 30px;
						`
    : css `
							width: 100% !important;
							height: 100% !important;
						`}
		}
	}

	.horizontal_item {
		display: contents;
		margin: 0;
	}

	.mainFlex {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		align-items: center;

		&.full {
			width: 100%;
			height: 100%;
			background: #000;
		}
	}

	.test_sesminar {
		width: 100%;
		height: 100%;
		display: flex;
		flex-direction: row;

		> * {
			flex: 1;
			overflow: hidden;
		}
	}

	.gridFlex {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		align-items: center;
		width: auto !important;
		height: auto !important;
	}

	.horizontal {
		max-height: 98px;
		display: flex;
		justify-content: flex-start;
		align-items: flex-start;

		&.layout_center {
			justify-content: center;
		}

		&.non_expand {
			position: absolute;
		}
	}

	.vertical_grid {
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: flex-start;
		width: 100%;
		${props => props.name === 'class'
    ? css `
						height: inherit;
					`
    : css `
						height: 100%;
					`}
	}

	.item {
		box-sizing: border-box;
		position: relative;
		background-color: ${props => APP.config.style.main};
		margin: 0;
		border: 1px solid ${props => APP.config.style.main};
		overflow: hidden;

		&.mirror {
			video {
				transform: scale(-1, 1);
				-moz-transform: scale(-1, 1);
				-webkit-transform: scale(-1, 1);
				-o-transform: scale(-1, 1);

				object-fit: contain;
			}

			.video_label {
				border: 1.5px solid orange;
			}
		}
	}

	.expand_button {
		position: absolute;
		left: 147px;
		top: 40px;

		&.non {
			transform: rotate(180deg);
		}
	}

	.video_left {
		position: absolute;
		top: 8px;
		left: 8px;
	}

	.video_center {
		position: absolute;
		left: 8px;
		bottom: 8px;
	}

	.video_right {
		position: absolute;
		top: 8px;
		right: 8px;
		color: #fff;

		.hover {
			cursor: pointer;
		}
	}

	.video_label {
		position: absolute;
		box-sizing: border-box;
		bottom: 0px;
		overflow: hidden;
		padding: 0px 10px;
		font-size: 12px;
		display: flex;
		justify-content: center;
		align-items: center;
		gap: 8px;
		min-height: 25px;
		max-height: 62px;
		box-sizing: border-box;
		max-width: 360px;
		width: 80%;
		left: 50%;
		transform: translate(-50%, -14px);
		border-radius: 28px;
		padding: 4px 12px;
		background: rgba(0, 0, 0, 0.5);
		.nickname {
			flex: 5;
			display: flex;
			justify-content: center;
			align-items: center;
			overflow: hidden;

			span {
				line-height: 16px;
			}
		}
	}

	.click_button {
		&:not(.no-pointer) {
			cursor: pointer;
		}

		&:hover:not(.no-hover):not(.text-button) {
			@include hoverColor;

			> * {
				@include hoverColor;
			}

			svg {
				@include fillIconColor;
			}
		}
	}

	.videoWrap {
		overflow: hidden;
		width: 100%;
		height: 100%;
		position: relative;
		background-color: #000;

		video {
			width: 100%;
			height: 100%;
		}

		.default {
			position: absolute;
			width: 100%;
			height: 100%;
			position: relative;
			display: flex;
			justify-content: center;
			align-items: center;

			${props => APP.config.name === 'class' && `background: #123;`}
		}
	}

	.horizontal_item {
		.item {
			.video_label {
				height: fit-content;
				bottom: -11px;
			}
		}
	}
`;
export const PagingDesktopStyled = styled.div `
	position: relative;
	right: ${props => (props.type === 'next' ? '0' : 'auto')};
	left: ${props => (props.type === 'prev' ? '0' : 'auto')};
	width: ${props => (props.vertical ? '100%' : '30px')};
	min-height: ${props => (props.vertical ? '30px' : '100%')};
	background: #000;
	display: flex;
	justify-content: space-around;
	align-items: center;
	cursor: pointer;
	margin: 0;

	&:hover {
		svg {
			color: #76acfb !important;
		}
	}
`;
export const ScreenShareStyled = styled.div `
	display: flex;
	width: 100%;
	height: 100%;
	overflow: hidden;
	position: relative;

	.screen_layout {
		background: #000;
		.default {
			width: 100%;
			height: 100%;
			display: flex;
			justify-content: center;
			align-items: center;
		}
	}

	> * {
		margin: 0;
	}

	${props => props.screenOnly
    ? css `
					.screen_layout {
						width: 100%;
						height: 100%;
					}

					.member_container {
						position: absolute;

						width: 100%;
						height: 100%;
					}

					.member_layout {
						position: absolute;
						width: 172px;
						height: 98px;
						${props.position === 1
        ? css `
									left: 0;
									top: 0;
								`
        : props.position === 2
            ? css `
										top: 0;
										right: 0;
									`
            : props.position === 3
                ? css `
											right: 0;
											bottom: 0;
										`
                : css `
											left: 0;
											bottom: 0;
										`}
					}

					.btns_wrapper {
						position: absolute;
						width: 172px;
						height: 98px;
						z-index: 2;

						&:hover {
							.btns {
								display: block;
							}
						}

						${props.position === 1
        ? css `
									left: 0;
									top: 0;
								`
        : props.position === 2
            ? css `
										top: 0;
										right: 0;
									`
            : props.position === 3
                ? css `
											right: 0;
											bottom: 0;
										`
                : css `
											left: 0;
											bottom: 0;
										`}

						.btns {
							width: 100%;
							height: 100%;
							margin: 0;
							position: absolute;
							display: none;

							${props.position === 1
        ? css `
										left: 0;
										top: 0;
									`
        : props.position === 2
            ? css `
											top: 0;
											right: 0;
										`
            : props.position === 3
                ? css `
												right: 0;
												bottom: 0;
											`
                : css `
												left: 0;
												bottom: 0;
											`}
						}
					}
					.screen_button {
						position: absolute;

						svg {
							fill: #fff;
							stroke: #000;
						}

						&:hover {
							svg {
								fill: ${APP.config.style.focusColor};
							}
						}
						&.top {
							top: 8px;
							left: 50%;
							transform: translate(-50%, 0) rotate(-90deg);
						}

						&.right {
							right: 8px;
							top: 50%;
							transform: translate(0, -50%);
						}

						&.bottom {
							bottom: 8px;
							left: 50%;
							transform: translate(-50%, 0) rotate(90deg);
						}

						&.left {
							left: 8px;
							top: 50%;
							transform: translate(0, -50%) rotate(180deg);
						}
					}
				`
    : css `
					flex-direction: row-reverse;

					.screen_layout {
						width: ${APP.config.option.hideLayoutMember ? '100%' : 'calc(100% - 172px)'};
						height: 100%;
					}

					.member_container {
						display: flex;
						flex-direction: column;
					}
					.member_layout {
						width: 172px;
						flex: 1;
					}
				`}

	video {
		width: 100%;
		height: 100%;
		object-fit: contain;
	}
`;
export const DualStyled = styled.div `
	background: ${props => APP.config.style.main};
	display: flex;
	width: 100%;
	height: 100%;

	.btn-white {
		background: #ffffff;
		border-radius: 100px;
		padding: 6px 12px 6px 6px;
		display: flex;
		flex-direction: row;
		gap: 6px;
		align-items: center;
		justify-content: flex-start;
		flex-shrink: 0;
		position: relative;

		p {
			color: #101010;
			text-align: left;
			font-family: 'Pretendard-Regular', sans-serif;
			font-size: 14px;
			line-height: 18px;
			font-weight: 400;
			position: relative;
			display: flex;
			align-items: center;
			justify-content: flex-start;
		}
	}

	.popup {
		position: absolute;
		left: 0;
		top: 0;
		background: rgba(0, 0, 0, 0.2);
		width: 100%;
		height: 100%;
	}

	.guid {
		white-space: break-spaces;
		text-align: center;
	}

	.popup_content {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		width: 95%;

		.header {
			display: flex;
			border-bottom: 2px solid;
			padding: 5px 0;
			width: 100%;

			.title {
				margin: 0 auto 0 0;
				font-size: 15px;
				font-weight: bold;
			}

			.close {
				margin: 0 0 0 auto;
				cursor: pointer;
			}
		}

		.guid {
			white-space: break-spaces;
			text-align: center;
		}

		.content {
			display: flex;
			flex-direction: column;
			gap: 12px;
			background: #fff;
			padding: 12px;
			border-radius: 6px;
			width: 85%;
		}

		input {
			padding: 8px;
			box-sizing: border-box;
			border: none;
			border-bottom: 1px solid;
		}
	}

	.page_btns {
		margin: 0 0 0 auto;
		.text-button {
			margin: 0;
			padding: 0 12px;
			height: 99%;
		}
	}

	.dual_sidebar {
		width: 350px;
		height: 100%;
	}

	.dual_side_header {
		display: flex;
		padding: 12px 16px 12px 16px;
		gap: 10px;
		align-items: center;

		.side {
			display: flex;
			background: #334155;
			margin: 0;
			border-radius: 4px;
			gap: 6px;
			row-gap: 6px;
			svg,
			flex-shrink: 0;
  			width: 154px;
  			height: 36px;
			span {
				fill: #7786c2 !important;
				color: #7786c2 !important;
			}
			&.isfocus {
				background: #dde4ff;
				svg,
				span {
					fill: #7786c2 !important;
					color: #7786c2 !important;
				}
			}
		}
	}
	.dual_side_header2 {
		display: flex;
		height: 30px;
		width: 100%;
		gap: 4px;

		.side {
			background: rgb(255, 255, 255);
			margin: 0;
			flex: 1;
			border-radius: 0;
			gap: 6px;
			svg,
			span {
				fill: #7786c2 !important;
				color: #7786c2 !important;
			}
			&.isfocus {
				background: #e3eaff;
				svg,
				span {
					fill: #7786c2 !important;
					color: #7786c2 !important;
				}
			}
		}
	}
	.page_btns {
		display: flex;
		gap: 12px;
		padding: 4px;

		svg {
			fill: #fff !important;
		}
	}

	.dual-monitor {
		width: 100%;
		height: 100%;
		display: grid;
		grid-template-columns: repeat(5, 1fr); /* 5 columns */
		grid-template-rows: repeat(4, 1fr); /* 4 rows */

		gap: 24px;
		overflow: hidden;
		padding: 18px;
		box-sizing: border-box;

		.dual-item {
			z-index: 2;
			background: #27374b;
			border-radius: 12px;
			align-self: stretch;
			flex-shrink: 0;
			width: 100%;
			height: 100%;
			overflow: hidden;
			display: flex;
			justify-content: center;
			align-items: center;
			flex-direction: column;
			position: relative;

			.muted_member {
				position: absolute;
				text-align: center;
				color: #fff;
			}

			video {
				z-index: 1;
				width: 100%;
				height: 100%;
				object-fit: contain;
			}

			.nickname {
				width: 100%;
				overflow: hidden;
				z-index: 2;
				p {
					width: 80%;
					box-sizing: border-box;
				}
			}

			&.have-track {
				.nickname {
					color: #fff;
					overflow: hidden;
					display: flex;
					justify-content: center;
					align-items: center;
					text-align: center;
					flex-direction: column;
					gap: 24px;
					position: absolute;
					bottom: 10px;
				}
			}

			&:not(.have-track) {
				.nickname {
					color: #fff;
					width: 100%;
					overflow: hidden;
					height: 100%;
					display: flex;
					justify-content: center;
					align-items: center;
					text-align: center;
					flex-direction: column;
					gap: 24px;
				}
			}
		}
	}

	.downBtn {
		background: #123;
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 12px;
		margin: 0;

		&.right {
			transform: rotate(270deg);
		}

		&.left {
			transform: rotate(90deg);
		}
	}

	.dual_content {
		display: flex;
		flex-direction: column;
		flex: 1;
		height: 100%;
	
	}

	.dual_right_content {
		display: flex;
		flex-direction: column;
		flex: 1;
		height: ${props => (props.isScreenMode ? 'calc(100% - 198px)' : 'calc(100% - 60px)')};
		
		.sidebar {
			border-radius: 12px 12px 0px 0px;
		}
	}
`;
