import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { ClickButton } from '../base/ui';
import { removeShareItem, setFocusShareItem, updateShareItem } from './actions';
import { DocumentItem } from './components';
import { DEFAULT_DOCUMENT, DOCUMENT_MODE, VOD_MODE, WHITE_MODE } from './constants';
import { getDocumentItemByUUID } from './functions';
const DocumentItemContainer = React.memo((props) => {
    const item = useSelector((state) => getDocumentItemByUUID(state, props.uuid), shallowEqual);
    if (!item)
        return null;
    return React.createElement(DocumentItemRenderer, { item: item, ...props });
});
const DocumentItemRenderer = React.memo(({ item, deep, mode, uuid, focusItem, isFocus, permission, setFocus, }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { config, list, file, shared, complete } = useMemo(() => item, [item]);
    const labelList = useMemo(() => {
        let label_list = ['title'];
        if (config === VOD_MODE) {
            label_list = ['title', permission ? 'shared' : ''];
        }
        else if (uuid !== DEFAULT_DOCUMENT) {
            label_list = ['title', permission ? 'shared' : '', permission ? 'delete' : ''];
            if (isFocus) {
                if (mode !== VOD_MODE)
                    label_list = ['title', 'previous', permission ? 'shared' : '', permission ? 'delete' : ''];
            }
        }
        return label_list;
    }, [uuid, mode, config, isFocus, permission]);
    /**
     * (ACTION) 문서 포커스
     */
    const handlerFocusItem = useCallback((index, isTitle = false) => {
        if (!isFocus && list && list.length > 0 && uuid !== DEFAULT_DOCUMENT && config !== VOD_MODE) {
            setFocus(uuid);
        }
        else {
            if ((config !== DOCUMENT_MODE && isTitle) || !isTitle)
                dispatch(setFocusShareItem(uuid, index, config));
        }
    }, [dispatch, config, list, uuid, isFocus]);
    /**
     * (ACTION) 문서 삭제
     */
    const handlerDeleteItem = useCallback(() => dispatch(removeShareItem(uuid)), [dispatch, uuid]);
    /**
     * (ACTION) 문서 공유
     */
    const handlerShareDocument = useCallback(() => !shared && dispatch(updateShareItem(uuid, { uuid, shared: true })), [dispatch, shared, uuid]);
    /**
     * (ACTION) 문서 포커스 아웃
     */
    const handlerFocusOut = () => setFocus(undefined);
    const renderLabelBtn = useCallback((label) => {
        if (!complete && config !== WHITE_MODE && label === 'shared')
            return null;
        if (label === 'title') {
            if (file.name !== '')
                return file.name;
            else
                return t('share.whiteboard');
        }
        else {
            return (React.createElement(ClickButton, { icon: label, size: 16, color: label === 'previous' ? '#8bacff' : '#000', onClick: label === 'previous'
                    ? handlerFocusOut
                    : label === 'delete'
                        ? handlerDeleteItem
                        : label === 'shared'
                            ? handlerShareDocument
                            : null }));
        }
    }, [config, complete, file, handlerFocusOut, handlerDeleteItem, handlerShareDocument]);
    if (deep && !isFocus)
        return null;
    return (React.createElement(DocumentItem, { uuid: uuid, list: list, shared: shared, config: config, complete: complete, focus: focusItem.uuid === uuid, focusIndex: uuid === focusItem.uuid ? focusItem.index : null, permission: permission, isFocus: isFocus, labelList: labelList, renderLabelBtn: renderLabelBtn, handlerFocusItem: handlerFocusItem }));
});
export default DocumentItemContainer;
