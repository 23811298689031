import React, { useMemo } from 'react';
import { ClickButton, DivElement } from '../base/ui';
import styled from 'styled-components';
const AudioTestStyled = styled.div `
	padding: 8px;
	width: calc(100% - 70px);
	border-radius: 4px;
    border: 1px solid #C6CBD2;
    background: #FFF;
	display: inline-flex;
	-webkit-box-align: center;
	align-items: center;
    -webkit-box-pack: center;
	justify-content: center;
    box-sizing: border-box;
    cursor: none;
	flex-direction: row;
    position: relative;
    overflow: visible !important;
	font-style: normal;
    font-weight: 400;
    letter-spacing: -0.44px;

	.speaker_test_container {
		font-size: 12px;
		display: flex;
		width: 100%;
		justify-content: space-around;
		-webkit-box-align: center;
		align-items: center;
		gap: 12px;
		overflow: hidden;
	}

	.speaker_icon {
		display: inline-flex;
		font-size: 12px;
	}

	p {
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		width: calc(100% - 20px);
	}
`;
const MobileAudioTest = React.memo(({ children, }) => {
    const renderSelectIcon = useMemo(() => (React.createElement(ClickButton, { className: "no-hover select_icon", size: 18, icon: "speaker", color: "#C6CBD2" })), []);
    return (React.createElement(DivElement, { className: "device_select", style: { width: '100%', alignItems: 'center' } },
        React.createElement(AudioTestStyled, null,
            React.createElement("div", { className: 'speaker_test_container' },
                React.createElement("div", { className: 'speaker_icon' }, renderSelectIcon),
                React.createElement("p", null, "\uC2A4\uD53C\uCEE4 \uC74C\uB7C9 \uD14C\uC2A4\uD2B8"))),
        children));
});
export default MobileAudioTest;
