import { toState } from '../redux';
import { setIsMimacTeacher, setUserInformation } from './actions';
/**
 * 회원 가입
 */
export const signup = (data) => {
    return APP.API.signup(data);
};
/**
 * ID, PWD로 로그인
 */
export const loginById = (id, password, enc_type = 'base64', isSignup = false) => {
    return APP.API.login(id, password, enc_type).then(response => {
        if (response.complete) {
            if (isSignup)
                return { complete: true, data: { id, ...response.data } };
            return loginByToken(response.data);
        }
        else {
            APP.UI.alertMessage(response.data);
            return false;
        }
    });
};
/**
 * ACCESS_TOKEN으로 사용자 로그인
 */
export const loginByToken = async (tokens) => {
    let external;
    let response;
    if (tokens.external_token) {
        try {
            const verifyResponse = await APP.API.mimacPostVerifyToken({ access_token: tokens.external_token });
            const mimacTeacher = await fetch(`/api/mimac/v1/members?id=${verifyResponse.data.user.id}&level=03`)
                .then(res => res.json())
                .then(res => {
                // console.log('mimac teacher:', res);
                return res.result.total_count === 0 ? true : false;
            })
                .catch(err => {
                console.error('Failed to get mimac teacher:', err);
                return false;
            });
            APP.store.dispatch(setIsMimacTeacher(mimacTeacher));
            external = verifyResponse.data;
        }
        catch (error) {
            console.error('Failed to verify external token:', error);
            return { complete: false, error: error.message };
        }
    }
    try {
        const result = await APP.API.getUserInformation(tokens.access_token, tokens.refresh_token);
        if (!result.complete) {
            return { complete: false, data: result.data };
        }
        response = result;
    }
    catch (error) {
        console.error('Failed to get user information:', error);
        return { complete: false, error: error.message };
    }
    APP.store.dispatch(setUserInformation(tokens, response?.data, external));
    return { complete: true, data: { ...response, tokens } };
};
/**
 * CLASS MATE - 파트너 ACCESS_TOKEN으로 사용자 로그인
 */
export const loginByPartnerToken = (data) => {
    return APP.API.getUserInformation(data).then(response => {
        if (response.complete) {
            return loginByToken(response.data);
        }
        else {
            return { complete: false, data: response.data };
        }
    });
};
/**
 * 임시 사용자 로그인
 * @param data
 * @returns
 */
export const tempLogin = (isSignup = false) => {
    const { getState } = APP.store;
    if (!getLocalID(getState) && !getSearchData('storage_key')) {
        return APP.API.createTempUser().then(response => {
            if (response.complete) {
                return loginById(response.data.auth_name, response.data.auth_password, undefined, isSignup);
            }
            else {
                return { complete: false, data: response.data };
            }
        });
    }
    return { complete: false, data: 'account.alreadyLogin' };
};
/**
 * 중복 사용자 가져오기
 */
export const checkOccupiedUser = () => {
    const { getState } = APP.store;
    const user_id = getLocalID(getState);
    if (!user_id)
        return { complete: false, data: 'account.notLogin' };
    return APP.API.checkOccupiedUser(user_id).then(async (response) => {
        if (response.complete) {
            return { complete: true, data: response.data };
        }
        else {
            // 중복!
            if (response.data === 'dup') {
                if (navigator.product === 'ReactNative') {
                }
                else {
                    const answer = await APP.UI.confirmMessage('account.dupLoginGuid');
                    if (answer) {
                        // 중복 사용자 강퇴
                        return kickOccupiedUser(user_id);
                    }
                }
            }
            else {
                return { complete: false, data: response.data };
            }
        }
        return { complete: false, data: response.data };
    });
};
/**
 * 중복 사용자 강퇴
 */
export const kickOccupiedUser = (user_id) => {
    return APP.API.kickOccupiedUser(user_id).then(response => {
        if (response.complete) {
            return { complete: true, data: response.data };
        }
        else {
            return { complete: false, data: response.data };
        }
    });
};
/**
 * local reducer 가져오기,
 * @param {IStore['getState']} stateful
 * @returns
 */
const localState = (stateful) => {
    const state = toState(stateful);
    return state['features/base/local'];
};
/**
 * 로그인 여부 가져오기
 * @param {IStore['getState']} stateful
 * @returns
 */
export function isLogin(stateful) {
    const auth_name = getLocalAuthName(stateful);
    return Boolean(auth_name);
}
export function getIsTeacher(stateful) {
    return localState(stateful)?.externalTeacher;
}
/**
 * 현재 방 개설 권한 여부 가져오기
 * @param {IStore['getState']} stateful
 * @returns
 */
export function getLocalPermission(stateful) {
    const user = localState(stateful)?.user;
    return user?.role?.permissions;
}
/**
 * @param {IStore['getState']} stateful
 * @returns
 */
export function getLocalRole(stateful) {
    const user = localState(stateful)?.user;
    return user?.role?.name;
}
/**
 * 현재 로그인된 사용자 아이디
 * @param {IStore['getState']} stateful
 * @returns
 */
export function getLocalAuthName(stateful) {
    const user = localState(stateful)?.user;
    return user?.auth_name;
}
/**
 * 현재 로그인 된 사용자 user_id
 * @param {IStore['getState']} stateful
 * @returns
 */
export function getLocalID(stateful) {
    const user = localState(stateful)?.user;
    return !user?.uuid || user?.uuid === '' ? user?.user_id : user.uuid;
}
/**
 * 현재 로그인 된 사용자 토큰
 * @param {IStore['getState']} stateful
 * @returns
 */
export function getAccessToken(stateful) {
    const token = localState(stateful)?.token;
    return token?.access_token;
}
export function getExternalToken(stateful) {
    const token = localState(stateful)?.token;
    return token?.external_token;
}
export function getExternalUserId(stateful) {
    const user = localState(stateful)?.external;
    return user?.id;
}
export function getSearchData(key) {
    // 현재 페이지의 URL을 가져옵니다.
    const currentURL = window.location.search;
    if (currentURL.includes(key)) {
        return currentURL.split(`${key}=`)[1].split('&')[0];
    }
    else {
        return null;
    }
}
