import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import PeerConnection from '../../modules/server/PeerConnection';
import { MEMBER_STATUS, getBridgeSession, getMemberByUserID } from '../base/members';
import { Icon } from '../base/ui';
export const DualMemeber = React.memo(({ id }) => {
    const videoRef = useRef(null);
    const nicknameRef = useRef(null);
    const member = useSelector((state) => getMemberByUserID(state, id));
    const memberVideoMuted = useSelector((state) => getBridgeSession(state, id)?.video_muted);
    const [track, setTrack] = useState(null);
    const [peer, setPeer] = useState(null);
    const [muted, setMuted] = useState(false);
    const [lefted, setLefted] = useState(false);
    const handler = {
        addTrack: (streams, videoTrack) => {
            if (videoRef.current && streams) {
                videoRef.current.srcObject = streams;
                videoRef.current.autoplay = true;
            }
            setTrack(streams);
        },
        isMuted: (muted) => {
            setMuted(muted);
            // if (nicknameRef.current && videoRef.current) {
            // 	if (muted) {
            // 		videoRef.current.style.display = 'none';
            // 		nicknameRef.current.style.display = 'flex';
            // 	} else {
            // 		videoRef.current.style.display = 'flex';
            // 		nicknameRef.current.style.display = 'none';
            // 	}
            // }
        },
    };
    useEffect(() => {
        setMuted(memberVideoMuted);
    }, [memberVideoMuted]);
    useEffect(() => {
        if (member?.status === MEMBER_STATUS.VACATED) {
            setLefted(true);
            setTrack(null);
        }
        else {
            setLefted(false);
        }
    }, [member?.status]);
    useEffect(() => {
        // console.log('connect');
        // const jitsi = new JitsiDual();
        // jitsi.connect()?.then(connection => {
        // 	jitsi.join(connection, id, false, null, handler).catch(err => console.log(err));
        // });
        // return () => {
        // 	console.log('disconnect');
        // 	jitsi && jitsi.disconnect();
        // };
        if (member?.user_uuid && member.status === MEMBER_STATUS.OCCUPIDE) {
            // const peer = new PeerOfferConnection(member.user_uuid, member.member_uuid);
            // peer.connection(null);
            // const localMember = getLocalIDMember(APP.store.getState);
            const pc = new PeerConnection(member.user_uuid, member.member_uuid, false, handler);
            pc.initLocalStream(null, null);
            APP.peerconnections.push(pc);
            setPeer(pc);
        }
        else {
            setPeer(null);
        }
    }, [member?.user_uuid, member?.status]);
    useEffect(() => {
        return () => {
            if (peer) {
                peer?.disconnect();
                APP.peerconnections = APP.peerconnections.filter(p => p?.user_id !== member?.user_uuid);
            }
        };
    }, [peer]);
    const handlerDesciption = data => {
        const from_member_id = data.from;
        if (from_member_id === member?.member_uuid) {
            if (peer) {
                peer.setRemoteDescription(data.answer);
            }
        }
    };
    const handlerCandinate = data => {
        const from_member_id = data.from;
        if (from_member_id === member?.member_uuid) {
            if (peer) {
                peer.addIceCandidate(data.ice_candidate);
            }
        }
    };
    useEffect(() => {
        APP.eventManager.subscribe('CALL_ANSWER', handlerDesciption);
        APP.eventManager.subscribe('CALL_CANDIDATE', handlerCandinate);
        return () => {
            APP.eventManager.unsubscribe('CALL_ANSWER', handlerDesciption);
            APP.eventManager.unsubscribe('CALL_CANDIDATE', handlerCandinate);
        };
    }, [peer, member?.member_uuid]);
    return (React.createElement("div", { className: `dual-item ${track ? 'have-track' : ''}` },
        React.createElement("video", { key: member?.user_uuid, ref: videoRef, muted: true, autoPlay: true, playsInline: true, style: { display: `${track && !muted ? 'flex' : 'none'}` } }),
        React.createElement("div", { ref: nicknameRef, className: "nickname" },
            lefted ? (React.createElement(Icon, { icon: "icon-not-user", size: 92 })) : track ? (muted ? (React.createElement(Icon, { icon: "icon-off-camera", size: 92 })) : null) : (React.createElement(Icon, { icon: "icon-not-camera", size: 92 })),
            React.createElement("p", { className: "overText2" }, member?.nickname))));
});
