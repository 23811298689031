import { HIDE_CHAT, HIDE_MEMBER, OPEN_CHAT, OPEN_MEMBER, SET_LIVE_ENQUIRIES, TOGGLE_HIDE_CHAT, TOGGLE_HIDE_MEMBER, } from './actionTypes';
import { isChatOpend, isMemberOpend } from './functions';
export function toggleChat(isOpen) {
    return async (dispatch, getState) => {
        const open = isOpen !== undefined ? isOpen : !isChatOpend(getState);
        if (open)
            dispatch({ type: OPEN_CHAT });
        else
            dispatch({ type: HIDE_CHAT });
    };
}
export function toggleMember(isOpen) {
    return async (dispatch, getState) => {
        const open = isOpen !== undefined ? isOpen : !isMemberOpend(getState);
        if (open)
            dispatch({ type: OPEN_MEMBER });
        else
            dispatch({ type: HIDE_MEMBER });
    };
}
export const setLiveEnquiries = (liveEnquiries) => {
    return {
        type: SET_LIVE_ENQUIRIES,
        liveEnquiries,
    };
};
export const toggleHideChat = (isHide) => {
    return {
        type: TOGGLE_HIDE_CHAT,
        isHide,
    };
};
export const toggleHideMember = (isHide) => {
    return {
        type: TOGGLE_HIDE_MEMBER,
        isHide,
    };
};
