import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { getFilmStyle, getLayoutMemberByType } from '../functions';
import MemberItemContainer from './MemberItemContainer';
import { MemberContainer } from './layout';
const MembersLayout = React.memo(({ className, type, noPaging = false, maxStyle, outerSize, vertical = false, noShowMember = false, }) => {
    const members = useSelector((state) => getLayoutMemberByType(state, type));
    const [styles, setStyles] = useState({ width: 0, height: 0 });
    const [prev, setPrev] = useState(false);
    const [next, setNext] = useState(false);
    const { showMembers, memberCnt } = useMemo(() => {
        if (!members || members.length <= 0)
            return { showMembers: false, memberCnt: 0 };
        const memberCnt = members && members.length ? members.length : 0;
        return {
            showMembers: memberCnt > 0 ? true : false,
            memberCnt,
        };
    }, [members, type]);
    useEffect(() => {
        if (maxStyle) {
            setStyles(maxStyle);
        }
        else {
            if (outerSize) {
                let style = Object.assign(outerSize);
                if (!noPaging && prev)
                    style.width = style.width - 30;
                if (!noPaging && next)
                    style.width = style.width - 30;
                const newStyle = getFilmStyle(APP.store.getState, showMembers ? memberCnt : 1, style);
                setStyles(newStyle);
            }
        }
    }, [showMembers, memberCnt, maxStyle, outerSize, prev, next]);
    const membersLayout = useMemo(() => members &&
        members.map(m => {
            if (!m)
                return null;
            return (React.createElement(MemberItemContainer, { key: m, user_uuid: m, styles: styles, isMaxSize: Boolean(maxStyle), vertical: vertical, member: m }));
        }), [members, styles, maxStyle, vertical]);
    const noMemberLayout = useMemo(() => {
        return (React.createElement(MemberContainer, { className: `${className} full`, styles: styles },
            "no ",
            type));
    }, [className, styles, type]);
    const membersLayoutContainer = useMemo(() => (React.createElement(React.Fragment, null,
        React.createElement(MemberContainer, { className: className }, membersLayout))), [className, vertical, membersLayout, noPaging, prev, next]);
    const renderMembers = useMemo(() => {
        if (showMembers) {
            return membersLayoutContainer;
        }
        else {
            if (noShowMember)
                return null;
            return noMemberLayout;
        }
    }, [showMembers, noMemberLayout, membersLayoutContainer]);
    if (!members || members.length <= 0)
        return null;
    return renderMembers;
});
export default MembersLayout;
