import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Button } from '../common';
import { getPropertyValue } from '../settings';
import ChatSide from './ChatSide';
import HandlerRequest from './HandlerRequest';
import { LiveEnquiries } from './LiveEnquiries';
import MemberSide from './MemberSide';
import { SideBar } from './components';
const SideBarContainer = React.memo(({ isMonitoring = false, initChat, initMember, }) => {
    const individualLive = useSelector((state) => getPropertyValue(state, 'individualLive'));
    const [isTypeMember, setIsTypeMember] = useState(false);
    const { hideChat, hideMember, chat, member } = useSelector((state) => state['features/base/sidebar']);
    const isChat = isMonitoring ? initChat : chat;
    const isMember = isMonitoring ? initMember : member;
    const renderChat = useMemo(() => {
        if (!isChat)
            return null;
        return React.createElement(ChatSide, { isMonitoring: isMonitoring, hideChat: hideChat });
    }, [isChat, hideChat, isMonitoring]);
    const renderMember = useMemo(() => {
        if (!isMember)
            return null;
        return (React.createElement(React.Fragment, null,
            !hideMember && (React.createElement("div", { className: "sheader topHeader", style: { padding: '12px' } },
                React.createElement(Button, { className: `menu_${isTypeMember ? 'active' : 'unactive'}`, text: "\uCC38\uC11D\uC790 \uBAA9\uB85D", onClick: () => setIsTypeMember(true) }),
                React.createElement(Button, { className: `menu_${!isTypeMember ? 'active' : 'unactive'}`, text: individualLive ? '1:1 LIVE 대기 목록' : '손들기 요청 목록', onClick: () => setIsTypeMember(false) }))),
            isTypeMember ? (React.createElement(MemberSide, { isMonitoring: isMonitoring, hideMember: hideMember })) : individualLive ? (React.createElement(LiveEnquiries, { isMonitoring: isMonitoring, hideMember: hideMember })) : (React.createElement(HandlerRequest, { isMonitoring: isMonitoring, hideMember: hideMember }))));
    }, [isMember, isTypeMember, hideMember, isMonitoring]);
    if (!isChat && !isMember)
        return null;
    return (React.createElement(SideBar, { isChat: isChat, isMember: isMember, hideMember: hideMember, hideChat: hideChat, renderChat: renderChat, renderMember: renderMember }));
});
export default SideBarContainer;
