import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { ClickButton } from '../../ui';
import { bytesToSize, getJoinedTime } from '../functions';
import { ROLE, getLocalMemberRole } from '../../members';
const ChatFileItem = React.memo(({ message, time, uuid, timestamp }) => {
    const { t } = useTranslation();
    const isChatLock = useSelector((state) => state['features/base/settings'].policy.file_option === 'allow' ? false : true);
    const local_role = useSelector((state) => getLocalMemberRole(state));
    const joinTime = useSelector((state) => getJoinedTime(state));
    const lockPermission = useMemo(() => {
        if (isChatLock && local_role && ROLE.PARTICIPANT === local_role)
            return false;
        return true;
    }, [isChatLock, local_role]);
    const { fileId, name, size, link } = useMemo(() => {
        try {
            return JSON.parse(message);
        }
        catch {
            return { fileId: '', name: '', size: 0, link: '' };
        }
    }, [message]);
    const onClick = () => {
        if (!fileId)
            return;
        if (!lockPermission) {
            APP.UI.alertMessage('chat.noDownPermission');
            return;
        }
        const downloadLinkArr = link.split('/');
        fetch(`/fileshare/download/${downloadLinkArr[4]}/${downloadLinkArr[5]}`)
            .then(response => response.blob())
            .then(result => {
            var uri = URL.createObjectURL(result);
            var link = document.createElement('a');
            link.setAttribute('href', uri);
            link.download = name;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        })
            .catch(error => {
            APP.UI.alertMessage('chat.failDownload');
        });
    };
    if (!fileId) {
        return (React.createElement("div", { className: "message" },
            React.createElement("span", null,
                " ",
                time,
                " "),
            React.createElement("div", { className: "overText" }, t('chat.sending')),
            React.createElement(ClickButton, { icon: lockPermission ? 'fileDownOff' : 'fileDownOn', size: 36, onClick: onClick })));
    }
    return (React.createElement("div", { className: "message" },
        React.createElement("div", { className: "overText" },
            name,
            " "),
        React.createElement("span", null,
            " ",
            time,
            " "),
        React.createElement(ClickButton, { className: "download", icon: lockPermission ? 'fileDownOn' : 'fileDownOff', label: bytesToSize(size), size: 24, onClick: onClick })));
});
export default ChatFileItem;
