import React, { useMemo } from 'react';
import { ClickButton } from '../ui';
import { useSelector } from 'react-redux';
import { ROLE, getLocalMemberRole } from '../members';
import { updateRoomPolicy } from '../settings';
import { useDispatch } from 'react-redux';
const ChatLockFileOnOff = React.memo(() => {
    const dispatch = useDispatch();
    const isChatLock = useSelector((state) => state['features/base/settings'].policy.file_option === 'allow' ? false : true);
    const local_role = useSelector((state) => getLocalMemberRole(state));
    const lockPermission = useMemo(() => {
        if (local_role && ROLE.PARTICIPANT === local_role)
            return false;
        return true;
    }, [local_role]);
    // 방 환경 설정 함수
    const handlerUpdatePolicy = () => {
        if (lockPermission) {
            const file_option = !isChatLock ? 'deny' : 'allow';
            dispatch(updateRoomPolicy({ file_option }, false));
        }
    };
    return (React.createElement(ClickButton, { className: `${lockPermission ? '' : 'no-hover no-pointer'}`, icon: isChatLock ? 'fileDownOff' : 'fileDownOn', color: "#000", size: 20, onClick: handlerUpdatePolicy }));
});
export default ChatLockFileOnOff;
