import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import { getDevicePermission } from '../base/devices';
import { AbstractOneDepthLayoutButton, ChangeLayoutModeButton, PersonalSettingsButton, RoomTimer, } from '../base/header';
import { ROLE, checkRoleLevel, getLocalIDMember, getLocalMemberRole, getMemberByBridgeId } from '../base/members';
import { NotificationStyled } from '../base/notification/components/web/styles';
import { TestMain } from '../base/question/TestMain';
import { getPropertyValue } from '../base/settings';
import { getLocalVideoTrack, getTrackState } from '../base/tracks';
import { ClickButton, Icon } from '../base/ui';
import { isMobileDevice } from '../lobby/components';
import { getDualMonitor } from '../video-layout';
import { DualLayoutContainer } from '../video-layout/components';
import AudioContainer from './AudioContainer';
import { Room } from './components';
import { getPermissions, getRoomOption } from './functions';
import { useGlobalState } from '../app/GlobalStateProvider';
let wakeLock = null;
const RoomContainer = React.memo(({ isTest = false }) => {
    const { isDesktop } = useGlobalState();
    const isMobile = useMemo(() => !isDesktop, [isDesktop]);
    const displayName = useSelector((state) => state['features/base/settings'].displayName);
    const roomName = useSelector((state) => getRoomOption(state)?.name || '');
    const permissions = useSelector((state) => getPermissions(state));
    const enableLable = useSelector((state) => getPropertyValue(state, 'enableIconText'));
    const localJitsiId = useSelector((state) => getMemberByBridgeId(state, getLocalIDMember(state)));
    const enable_main_view = useSelector((state) => !getPropertyValue(state, 'policy').notification_option.enable_main_view);
    const isDualMonitor = useSelector((state) => getDualMonitor(state));
    const loaclVideoTrack = useSelector((state) => getLocalVideoTrack(getTrackState(state)));
    const devicePermissions = useSelector((state) => getDevicePermission(state));
    const isShowLvl = useSelector((state) => checkRoleLevel(getLocalMemberRole(state), ROLE.PRESENTER));
    const [cameraNoticeMessage, setCameraNoticeMessage] = useState();
    const [testEnter, setTestEnter] = useState(location.pathname.includes('test') ? false : true);
    const checkDeviceTrack = useCallback(() => {
        if (!enable_main_view) {
            setCameraNoticeMessage(undefined);
            return;
        }
        let message;
        if (!devicePermissions?.video) {
            message = '카메라 권한 설정 후 카메라를 켜주세요.';
        }
        else {
            if (!loaclVideoTrack) {
                message = '장치 관리에서 카메라를 다시 선택 후 카메라를 켜주세요.';
            }
            if (loaclVideoTrack?.muted) {
                message = '카메라가 꺼져 있습니다. 켜주세요.';
            }
        }
        setCameraNoticeMessage(message);
        const timeoutId = setTimeout(() => {
            setCameraNoticeMessage(undefined);
        }, 1000);
        return () => clearTimeout(timeoutId);
    }, [enable_main_view, loaclVideoTrack, devicePermissions]);
    const leaveRoom = () => {
        APP.management.leave();
    };
    useEffect(() => {
        window.addEventListener('beforeunload', leaveRoom);
        window.addEventListener('unload', leaveRoom);
        return () => {
            window.removeEventListener('unload', leaveRoom);
        };
    }, [leaveRoom]);
    async function keepScreenAwake() {
        try {
            wakeLock = await navigator.wakeLock.request('screen');
        }
        catch (err) {
            // console.error(`${err.name}, ${err.message}`);
        }
    }
    function releaseWakeLock() {
        if (wakeLock !== null) {
            wakeLock.release().then(() => {
                wakeLock = null;
                console.log('Wake Lock is released');
            });
        }
    }
    useEffect(() => {
        if (isMobileDevice()) {
            keepScreenAwake();
        }
        return () => {
            if (isMobileDevice()) {
                releaseWakeLock();
            }
        };
    }, []);
    useEffect(() => {
        if (location.pathname.includes('test'))
            return;
        checkDeviceTrack();
        const intervalId = setInterval(checkDeviceTrack, 300000); // 5 minutes
        return () => clearInterval(intervalId);
    }, [checkDeviceTrack]);
    const renderSettingBtn = useCallback((mode, iconStyles, onClick) => (React.createElement(PersonalSettingsButton, { key: mode, className: "room_label_icon", mode: mode, isMobile: isMobile, enableLable: enableLable, permissions: permissions, iconStyles: iconStyles, onClick: onClick })), [isMobile, enableLable, permissions]);
    const renderRoomItem = useCallback((list, iconStyles, renderHeadItem) => {
        return list.map((item) => {
            if (location.pathname.includes('test') &&
                !(item === 'test' ||
                    item === 'room_name' ||
                    item === 'room_count' ||
                    item === 'room_time' ||
                    item === 'out' ||
                    item === 'member')) {
                return null;
            }
            switch (item) {
                case 'room_name':
                    return (React.createElement("p", { className: "overText2", key: item }, roomName));
                case 'room_count':
                    return renderHeadItem(item, React.createElement(ClickButton, { className: "no-hover row_icon count", label: displayName, icon: !isMobile && 'personnel', size: 16 }));
                case 'room_time':
                    return renderHeadItem(item, React.createElement(ClickButton, { className: "no-hover row_icon time", label: React.createElement(RoomTimer, { timestamp: undefined }), icon: !isMobile && 'time', size: 18 }));
                case 'mode_change':
                    if (isMobile && APP.config.option.modeChange && !APP.config.option.isDetailMode) {
                        return renderSettingBtn('modeChange', iconStyles);
                    }
                    else {
                        return (React.createElement(ChangeLayoutModeButton, { key: item, className: "room_label_icon", iconStyles: iconStyles, enableLable: enableLable, permissions: permissions, fix_mode: APP.config.option.isDetailMode }));
                    }
                case 'one_depth_mode_change':
                    return (React.createElement(AbstractOneDepthLayoutButton, { key: item, className: "room_label_icon", isModal: false, enableLable: enableLable, permissions: permissions, iconStyles: iconStyles }));
                default:
                    return renderSettingBtn(item, iconStyles);
            }
        });
    }, [isMobile, roomName, displayName, enableLable, permissions, localJitsiId, renderSettingBtn]);
    const uiOption = useMemo(() => APP.config.ui[isMobile ? 'mobile' : 'desktop'], [isMobile]);
    return (React.createElement(React.Fragment, null,
        React.createElement(Room, { isTest: isTest, uiOption: uiOption, isMobile: isMobile, testEnter: testEnter, setTestEnter: setTestEnter, renderRoomItem: renderRoomItem }),
        testEnter && (React.createElement(React.Fragment, null,
            isDualMonitor && React.createElement(DualLayoutContainer, null),
            React.createElement(AudioContainer, null),
            React.createElement(TestMain, null),
            React.createElement(ToastContainer, null),
            cameraNoticeMessage && !isShowLvl && (React.createElement(NotificationStyled, null,
                React.createElement("div", { className: "n_item error" },
                    React.createElement(Icon, { icon: "error", size: 30, color: '#fff' }),
                    React.createElement("div", { className: "description" }, cameraNoticeMessage))))))));
});
export default RoomContainer;
