import styled from 'styled-components';

export const NotificationStyled = styled.div`
	position: absolute;
	left: 50%;
	bottom: 0;
	transform: translate(-50%, 0);

	.n_item {
		padding: 8px 12px;
		box-sizing: border-box;
		background: #fff;
		color: #000;
		border-radius: 10px;
		margin-bottom: 12px;

		display: flex;
		justify-content: center;
		align-items: center;
		gap: 16px;
		font-size: 18px;

		> * {
			margin: 0;
		}

		&.error {
			background: #d00000;
			color: #fff;
		}

		.title {
			display: flex;
			justify-content: center;
			align-items: center;
			gap: 12px;
		}
	}

	@media (max-width: 600px) {
		bottom: 50px;
	}
`;
