import { LEAVE_ROOM } from '../../room/actionTypes';
import ReducerRegistry from '../redux/ReducerRegistry';
import { HIDE_CHAT, HIDE_MEMBER, OPEN_CHAT, OPEN_MEMBER, SET_LIVE_ENQUIRIES, TOGGLE_HIDE_CHAT, TOGGLE_HIDE_MEMBER, } from './actionTypes';
const DEFAULT_STATE = {
    chat: false,
    member: false,
    liveEnquiries: undefined,
    hideMember: false,
    hideChat: false,
};
ReducerRegistry.register('features/base/sidebar', (state = DEFAULT_STATE, action) => {
    switch (action.type) {
        case HIDE_CHAT:
            return {
                ...state,
                chat: false,
            };
        case OPEN_CHAT:
            return {
                ...state,
                chat: true,
            };
        case OPEN_MEMBER:
            return {
                ...state,
                member: true,
            };
        case HIDE_MEMBER:
            return {
                ...state,
                member: false,
            };
        case SET_LIVE_ENQUIRIES:
            return {
                ...state,
                liveEnquiries: action.liveEnquiries,
            };
        case TOGGLE_HIDE_CHAT:
            return {
                ...state,
                hideChat: action.isHide,
            };
        case TOGGLE_HIDE_MEMBER:
            return {
                ...state,
                hideMember: action.isHide,
            };
        case LEAVE_ROOM:
            return DEFAULT_STATE;
    }
    return state;
});
