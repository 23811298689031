export const POSSIBLE_EXT_LIST = [
    'jpg',
    'jpeg',
    'gif',
    'bmp',
    'png',
    'mpg',
    'mpeg',
    'mp4',
    'mp3',
    'wav',
    'doc',
    'docx',
    'hwp',
    'txt',
    'pdf',
    'csv',
    'xls',
    'xlsx',
    'ppt',
    'pptx',
    'zip',
    'svg',
];
export const FILE_MAX_SIZE = 15;
export const NO_FILE = 'noFile';
export const NO_EXT_FILE = 'noExtFile';
export const EMPTY_FILE = 'emptyFile';
export const MAX_FILE = 'maxFile';
export const OK_FILE = 'okFile';
export const EXT_STRING = 'jpg, jpeg, gif, bmp, png, mpg, mpeg, mp4, mp3, wav, doc, docx, hwp, txt, pdf, csv, xls, xlsx, ppt, pptx, zip, svg';
export const getChatSize = () => {
    // Use type assertion to tell TypeScript that window has clientHeight
    return window ? (window?.outerHeight >= 720 ? 40 : 20) : 35;
};
