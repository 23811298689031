import React from 'react';
import { useTranslation } from 'react-i18next';
import { ScreenOptionStyled } from './styles.web';
function ScreenOption({ renderFrameBtn }) {
    const { t } = useTranslation();
    return (React.createElement(ScreenOptionStyled, null,
        React.createElement("div", { className: "screen_table" },
            React.createElement("div", { className: "screen_item" },
                React.createElement("div", { className: "screen_title" }, t('dialog.setFrame')),
                React.createElement("div", { className: "screen_frame" }, renderFrameBtn())))));
}
export default React.memo(ScreenOption);
