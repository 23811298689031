import React, { Fragment } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { DeviceSelection } from '../../device-selection';
import { getActiveGroupId } from '../../room';
import { AttendanceContainer } from '../../room-attendance';
import { SecurityDialog } from '../../room-lock';
import E2EEContainer from '../../room-lock/E2EEContainer';
import { getDualMonitor, toggleDualMonitor } from '../../video-layout';
import VirtaulBackGround from '../../virtual-background/components/VirtaulBackGround';
import { Dialog, openDialog, toggleDialog } from '../dialog';
import { getIsSafari } from '../environment/utils';
import { translate } from '../i18n';
import { PERMISSION, ROLE, checkRoleLevel, getLocalMemberRole } from '../members';
import SendNotificationPopup from '../notification/SendNotifiactionPopup';
import { getRecordStatus, startServerRecord, stopTempServerRecord } from '../record';
import { RECORDING_TYPE } from '../record/constant';
import { SettingContainerDialog } from '../settings';
import { ClickButton, DivElement } from '../ui';
import { FullScreenButton } from './components';
import { isMobileDevice } from '../../lobby/components';
const styles = {
    wrapper: {
        flexDirection: 'row',
        padding: 8,
        margin: 4,
        borderColor: '#000',
    },
    text: {
        marginLeft: 20,
    },
};
function OptionDialogContainer({ t, permissions, isMobile }) {
    const dispatch = useDispatch();
    const { localRole, isShowLvl } = useSelector((state) => {
        const localRole = getLocalMemberRole(state);
        return {
            localRole,
            isShowLvl: checkRoleLevel(localRole, ROLE.PRESENTER),
        };
    }, shallowEqual);
    const serverRecord = useSelector(state => getRecordStatus(state, RECORDING_TYPE.SERVER).enabled);
    const personalRecord = useSelector(state => getRecordStatus(state, RECORDING_TYPE.PERSONAL).enabled);
    const activeGroupId = useSelector(state => getActiveGroupId(state));
    const isDualMonitor = useSelector(state => getDualMonitor(state));
    // 분할 모드 count 설정
    // const renderGridCount = item => {
    // 	const openGridCountDialog = () => null; //dispatch(toggleDialog(ChangeGridCountDialog));
    // 	// 레이아웃 권한이 있는 사용자만 표출
    // 	return (
    // 		<ClickButton
    // 			key={item}
    // 			className="option_item"
    // 			icon={videoLayoutMode.grid}
    // 			label={t(`dialog.${item}`)}
    // 			color="#000"
    // 			onClick={openGridCountDialog}
    // 		/>
    // 	);
    // };
    // 보안 (비밀번호 설정)
    const renderPassword = item => {
        const openSecurityDialog = () => dispatch(toggleDialog(SecurityDialog));
        // 레이아웃 권한이 있는 사용자만 표출
        return (React.createElement(ClickButton, { key: item, className: "option_item", icon: item, label: t(`dialog.${item}`), color: "#000", size: 20, onClick: openSecurityDialog }));
    };
    // E2EE
    const renderE2EE = item => {
        const openE2EEDialog = () => {
            dispatch(toggleDialog(E2EEContainer));
        };
        // 레이아웃 권한이 있는 사용자만 표출
        return (React.createElement(ClickButton, { key: item, className: "option_item", icon: "e2ee", label: t(`dialog.${item}`), color: "#000", size: 20, onClick: openE2EEDialog }));
    };
    // 개인 녹화
    const renderPersonalREC = item => {
        const toggleRecord = () => {
            // dispatch(togglePersonalRecord(!personalRecord))
        };
        return (React.createElement(ClickButton, { key: item, className: "option_item", icon: item, label: t(`dialog.${personalRecord}SelfRecord`), color: "#000", size: 20, onClick: toggleRecord }));
    };
    // 서버 녹화
    const renderServerREC = item => {
        if (isMobileDevice())
            return null;
        const toggleRecord = () => {
            if (getIsSafari()) {
                APP.UI.alertMessage('notify.noSafari');
            }
            else {
                serverRecord ? dispatch(stopTempServerRecord()) : dispatch(startServerRecord());
            }
        };
        return (React.createElement(ClickButton, { key: item, className: "option_item", icon: item, label: t(`dialog.${serverRecord}ServerRecord`), color: "#000", size: 20, onClick: toggleRecord }));
    };
    // 장치 설정
    const renderSettingDevice = item => {
        const toggleSettingDevice = () => {
            dispatch(toggleDialog(DeviceSelection, { isDialog: true }));
        };
        return (React.createElement(ClickButton, { key: item, className: "option_item", icon: item, label: t(`dialog.${item}`), color: "#000", size: 20, onClick: toggleSettingDevice }));
    };
    // 설정
    const renderSetting = item => {
        if (APP.config.option.hideParticipant && !isShowLvl)
            return null;
        const onClick = () => {
            dispatch(toggleDialog(SettingContainerDialog, { permissions, isMobile }));
        };
        return (React.createElement(ClickButton, { key: item, className: "option_item", icon: "devicePermission", label: t(`dialog.setting`), color: "#000", size: 20, onClick: onClick }));
    };
    // 듀얼 모니터
    const renderDualMonitor = item => {
        const onClick = () => {
            dispatch(toggleDualMonitor(isDualMonitor));
        };
        const label = isDualMonitor ? 'close_dual' : 'open_dual';
        return (React.createElement(ClickButton, { key: item, className: "option_item", icon: item, label: t(`dialog.${label}`), color: "#000", size: 20, onClick: onClick }));
    };
    // 출석 체크
    const renderAttendance = item => {
        const openAttendance = () => {
            dispatch(openDialog(AttendanceContainer));
        };
        return (React.createElement(ClickButton, { key: item, className: "option_item", icon: item, label: t(`dialog.${item}`), color: "#000", onClick: openAttendance }));
    };
    // Blur
    const renderBlur = item => {
        const openBlur = () => {
            if (getIsSafari()) {
                APP.UI.alertMessage('notify.noSafari');
            }
            else {
                dispatch(toggleDialog(VirtaulBackGround));
            }
        };
        return (React.createElement(ClickButton, { key: item, className: "option_item", icon: item, label: 'dialog.blur', color: "#000", onClick: openBlur }));
    };
    // 공지
    const renderNotification = item => {
        const toggleNotification = () => {
            dispatch(toggleDialog(SendNotificationPopup));
        };
        return (React.createElement(ClickButton, { key: item, className: "option_item", icon: item, label: 'dialog.notification', color: "#000", onClick: toggleNotification }));
    };
    return (React.createElement(Dialog, { className: "option", titleKey: t('dialog.option'), width: "400" },
        React.createElement(DivElement, null, APP.config.optionList.map(item => {
            if (!APP.config.option[item] && item !== 'setting')
                return null;
            switch (item) {
                case 'notification':
                    if (!APP.config.option.notification || (APP.config.option.hideParticipant && !isShowLvl))
                        return null;
                    return renderNotification(item);
                case 'blur':
                    return renderBlur(item);
                case 'e2ee':
                    if (permissions.indexOf(PERMISSION.SECURE) === -1)
                        return null;
                    return renderE2EE(item);
                case 'security':
                    if (permissions.indexOf(PERMISSION.SECURE) === -1)
                        return null;
                    return renderPassword(item);
                case 'selfRecord':
                    if (permissions.indexOf(PERMISSION.SELF_RECORD) === -1)
                        return null;
                    return renderPersonalREC(item);
                case 'serverRecord':
                    if (permissions.indexOf(PERMISSION.SERVER_RECORD) === -1)
                        return null;
                    return renderServerREC(item);
                case 'settingDevice':
                    return renderSettingDevice(item);
                case 'setting':
                    return renderSetting(item);
                case 'dualMonitor':
                    if (localRole === ROLE.PARTICIPANT)
                        return null;
                    return renderDualMonitor(item);
                case 'devicePermission':
                    if (permissions.indexOf(PERMISSION.MEMBER_CAMERA) === -1 ||
                        permissions.indexOf(PERMISSION.MEMBER_MIC) === -1)
                        return null;
                    return renderDevicePermission(item);
                case 'fullscreen':
                    return (React.createElement(Fragment, { key: item },
                        React.createElement(FullScreenButton, { item: item, styles: styles })));
                case 'attendance':
                    if (localRole === ROLE.PARTICIPANT || localRole === ROLE.PRESENTER)
                        return null;
                    return renderAttendance(item);
            }
        }))));
}
export default translate(React.memo(OptionDialogContainer));
