import i18next from 'i18next';
import { default as React, Suspense, useEffect } from 'react';
import { I18nextProvider } from 'react-i18next';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Provider } from 'react-redux';
import { compose, createStore } from 'redux';
import Thunk from 'redux-thunk';
import { BaseContainer } from '../base/app';
import { DialogContainer } from '../base/dialog';
import { Loading } from '../base/loading';
import { MiddlewareRegistry, ReducerRegistry, StateListenerRegistry } from '../base/redux';
import { GlobalStateProvider } from './GlobalStateProvider';
function _createStore() {
    const reducer = ReducerRegistry.combineReducers({});
    const middleware = MiddlewareRegistry.applyMiddleware(Thunk);
    const composeEnhancers = compose;
    const store = createStore(reducer, composeEnhancers(middleware));
    StateListenerRegistry.subscribe(store);
    if (typeof APP !== 'undefined') {
        APP.store = store;
    }
    return store;
}
let store = null;
const test = async () => {
    const { complete, data } = await APP.API.getPublicSetting();
    if (complete) {
        const items = data.items;
        if (items && items.length > 0) {
            APP.config = items[0].ui;
        }
    }
    document.title = APP.config.title || 'Meetmate';
};
const AppContainer = React.memo(() => {
    const queryClient = new QueryClient();
    store = _createStore();
    useEffect(() => {
        // test();
        document.title = APP.config.title || 'Meetmate';
        const meta = document.createElement('meta');
        meta.name = 'google';
        meta.content = 'notranslate';
        document.getElementsByTagName('head')[0].appendChild(meta);
        const setVh = () => {
            const vh = window.innerHeight * 0.01;
            document.documentElement.style.setProperty('--vh', `${vh}px`);
        };
        setVh();
        document.querySelectorAll('img').forEach(img => {
            img.setAttribute('aria-hidden', 'true');
            img.style.pointerEvents = 'none';
        });
        window.addEventListener('resize', setVh);
        return () => {
            window.removeEventListener('resize', setVh);
        };
    }, []);
    return (React.createElement(I18nextProvider, { i18n: i18next },
        React.createElement(Provider, { store: store },
            React.createElement(Suspense, { fallback: React.createElement(Loading, { isLoad: true }) },
                React.createElement(QueryClientProvider, { client: queryClient },
                    React.createElement(GlobalStateProvider, null,
                        React.createElement(BaseContainer, null),
                        React.createElement(DialogContainer, null),
                        React.createElement(Loading, null)))))));
});
export default AppContainer;
