// import { showNotification } from '../../notifications/actions';
// import { NOTIFICATION_TIMEOUT_TYPE } from '../../notifications/constants';
import JitsiMeetJS, { JitsiTrackErrors } from '../lib-jitsi-meet';
import { createLocalTrack } from '../lib-jitsi-meet/functions.any';
import { CAMERA_FACING_MODE, MEDIA_TYPE, setAudioMuted, setVideoMuted, VIDEO_TYPE } from '../media';
import { TRACK_NO_DATA_FROM_SOURCE } from '../media/actionTypes';
import { updateSettings } from '../settings';
import { TRACK_ADDED, TRACK_REMOVED, TRACK_STOPPED, TRACK_UPDATED } from './actionTypes';
import { getLocalVideoTrack } from './functions';
/**
 * 지정된 로컬 트랙을 컨퍼런스에 추가합니다.
 *
 * @param {JitsiLocalTrack} newTrack - 컨퍼런스에 추가할 로컬 트랙.
 * @returns {Function}
 */
export function addLocalTrack(newTrack) {
    return async (dispatch) => {
        if (newTrack) {
            const setMuted = newTrack.isVideoTrack() ? setVideoMuted : setAudioMuted;
            const isMuted = newTrack.isMuted();
            await dispatch(setMuted(isMuted));
            return dispatch(_addTracks([newTrack]));
        }
    };
}
/**
 * 트랙의 음소거 상태가 변경되었다는 신호를 받았을 때를 위한 작업을 만듭니다.
 *
 * @param {(JitsiLocalTrack|JitsiRemoteTrack)} track - JitsiTrack instance.
 * @returns {{
 *     type: TRACK_UPDATED,
 *     track: Track
 * }}
 */
export function trackMutedChanged(track) {
    return {
        type: TRACK_UPDATED,
        track: {
            track,
            muted: track.isMuted(),
        },
    };
}
/**
 * 참가자 비디오 유형이 변경되는 경우에 대한 작업을 만듭니다.
 *
 * @param {(JitsiLocalTrack|JitsiRemoteTrack)} track - JitsiTrack instance.
 * @param {VIDEO_TYPE|undefined} videoType - Video type.
 * @returns {{
 *     type: TRACK_UPDATED,
 *     track: Track
 * }}
 */
export function trackVideoTypeChanged(track, videoType) {
    const mediaType = videoType === VIDEO_TYPE.CAMERA ? MEDIA_TYPE.VIDEO : MEDIA_TYPE.SCREENSHARE;
    return {
        type: TRACK_UPDATED,
        track: {
            track,
            videoType,
            mediaType,
        },
    };
}
/**
 * 제공된 {@code track}을 미러로 렌더링해야 하는 경우 true를 반환합니다.
 *
 * 다음과 같은 경우에만 동영상을 미러링 모드로 표시하고 싶습니다.
 * 1) 비디오 소스는 원격이 아닌 로컬입니다.
 * 2) 영상 소스는 데스크탑이 아닌 카메라(캡쳐)입니다.
 * 3) 카메라는 환경이 아닌 사용자를 포착합니다.
 *
 *
 * @param {(JitsiLocalTrack|JitsiRemoteTrack)} track - JitsiTrack instance.
 * @private
 * @returns {boolean}
 */
function _shouldMirror(track) {
    return track && track.isLocal() && track.isVideoTrack() && track.getCameraFacingMode() === CAMERA_FACING_MODE.USER;
}
/**
 * 전달된 JitsiLocalTrack이 소스에서 데이터 없음 이벤트를 트리거했음을 알립니다.
 *
 * @param {JitsiLocalTrack} track - The track.
 * @returns {{
 *     type: TRACK_NO_DATA_FROM_SOURCE,
 *     track: Track
 * }}
 */
export function noDataFromSource(track) {
    return {
        type: TRACK_NO_DATA_FROM_SOURCE,
        track,
    };
}
/**
 * 새 트랙이 회의에 추가되도록 신호를 받은 경우에 대한 작업을 만듭니다.
 *
 * @param {(JitsiLocalTrack|JitsiRemoteTrack)} track - JitsiTrack instance.
 * @param
 * @returns {Function}
 */
export function trackAdded(track, isScreen = undefined) {
    return async (dispatch) => {
        // 트랙 음소거 된 경우
        track.on(JitsiMeetJS.events.track.TRACK_MUTE_CHANGED, () => {
            dispatch(trackMutedChanged(track));
        });
        // 비디오 트랙 타입 변경된 경우 (native 에서 주로 사용)
        track.on(JitsiMeetJS.events.track.TRACK_VIDEOTYPE_CHANGED, type => dispatch(trackVideoTypeChanged(track, type)));
        // Jitsi 회의실 내 참가자 아이디 -> track 아이디로 지정
        // local
        const local = track.isLocal() && !isScreen;
        const mediaType = track.getVideoType() === VIDEO_TYPE.DESKTOP ? MEDIA_TYPE.SCREENSHARE : track.getType();
        let isReceivingData, participantId, notificationInfo;
        if (local && !isScreen) {
            participantId = 'local';
            // // 트랙을 변경할 때 src 알림 상태에서 데이터 없음을 재설정합니다. 컨텍스트가 장치별로 설정되어 있기 때문입니다.
            // dispatch(setNoSrcDataNotificationUid());
            // 트랙에서 데이터가 없는 경우
            isReceivingData = track.isReceivingData();
            track.on(JitsiMeetJS.events.track.NO_DATA_FROM_SOURCE, () => dispatch(noDataFromSource({ jitsiTrack: track })));
            if (!isReceivingData) {
                if (mediaType === MEDIA_TYPE.AUDIO) {
                    // const notificationAction = await dispatch(showNotification({
                    //     descriptionKey: 'dialog.micNotSendingData',
                    //     titleKey: 'dialog.micNotSendingDataTitle'
                    // }, NOTIFICATION_TIMEOUT_TYPE.LONG));
                    // notificationInfo = {
                    //     uid: notificationAction?.uid
                    // };
                }
            }
            dispatch(trackNoDataFromSourceNotificationInfoChanged(track, notificationInfo));
            // 트랙이 멈춘 경우
            track.on(JitsiMeetJS.events.track.LOCAL_TRACK_STOPPED, () => dispatch({
                type: TRACK_STOPPED,
                track: {
                    track,
                },
            }));
        }
        else {
            participantId = isScreen || track.getParticipantId();
            isReceivingData = true;
        }
        dispatch({
            type: TRACK_ADDED,
            track: {
                track,
                isReceivingData,
                local,
                mediaType,
                mirror: _shouldMirror(track),
                muted: track.isMuted(),
                participantId,
                videoStarted: false,
                videoType: track.videoType,
            },
        });
    };
}
/**
 * 소스 알림 정보에서 트랙의 데이터가 변경되지 않은 경우에 대한 작업을 만듭니다.
 *
 * @param {JitsiLocalTrack} track - JitsiTrack instance.
 * @param {Object} noDataFromSourceNotificationInfo - Information about no data from source notification.
 * @returns {{
 *     type: TRACK_UPDATED,
 *     track: Track
 * }}
 */
export function trackNoDataFromSourceNotificationInfoChanged(track, noDataFromSourceNotificationInfo) {
    return {
        type: TRACK_UPDATED,
        track: {
            track,
            noDataFromSourceNotificationInfo,
        },
    };
}
function _addTracks(tracks) {
    return dispatch => Promise.all(tracks.map(t => dispatch(trackAdded(t))));
}
/**
 * 통과된 트랙을 폐기합니다.
 *
 * @param {(track|remoteTrack)[]} tracks - List of tracks.
 * @private
 * @returns {Promise} - A Promise resolved once {@link JitsiTrack.dispose()} is
 * done for every track from the list.
 */
function _disposeTracks(tracks) {
    return Promise.all(tracks.map(t => {
        if (t.track && t.track.dispose) {
            t.track.dispose().catch((err) => {
                console.log(err);
                if (err.name !== JitsiTrackErrors.TRACK_IS_DISPOSED) {
                    throw err;
                }
            });
        }
        else if (t && t.dispose) {
            t.dispose().catch((err) => {
                console.log(err);
                if (err.name !== JitsiTrackErrors.TRACK_IS_DISPOSED) {
                    throw err;
                }
            });
        }
    }));
}
/**
 * 통과된 트랙을 폐기하고 제거하라는 신호를 보냅니다.
 *
 * @param {(track|remoteTrack)[]} tracks - List of tracks.
 * @protected
 * @returns {Function}
 */
export function _disposeAndRemoveTracks(tracks) {
    return async (dispatch) => {
        _disposeTracks(tracks).then(() => Promise.all(tracks.map(t => dispatch(trackRemoved(t)))));
    };
}
/**
 * 트랙이 이미 삭제된 경우 오류를 무시하고 지정된 트랙 또는 모든 로컬 트랙
 * (아무것도 전달되지 않은 경우)에서 JitsiLocalTrack#dispose()를 호출합니다.
 * 그런 다음 트랙을 제거하라는 신호를 보냅니다.
 *
 * @param {track|null} [track] - The local track that needs to be destroyed.
 * @returns {Function}
 */
export function destroyLocalTracks(track = null) {
    if (track) {
        return async (dispatch) => {
            await dispatch(_disposeAndRemoveTracks(track));
        };
    }
    return (dispatch, getState) => {
        dispatch(_disposeAndRemoveTracks(getState()['features/base/tracks'].filter(t => t.local)
            .map(t => t.track)));
    };
}
/**
 *
 */
/**
 * 트랙이 컨퍼런스에서 제거되도록 신호되었을 때 수행할 작업을 만듭니다.
 *
 * @param {(track|retmotTrack)} track - track instance.
 * @returns {{
 *     type: TRACK_REMOVED,
 *     track: Track
 * }}
 */
export function trackRemoved(track) {
    if (track.track && track.track.removeAllListeners) {
        track.track?.removeAllListeners(JitsiMeetJS.events.conference.TRACK_MUTE_CHANGED);
        track.track?.removeAllListeners(JitsiMeetJS.events.conference.TRACK_VIDEOTYPE_CHANGED);
        track.track?.removeAllListeners(JitsiMeetJS.events.conference.NO_DATA_FROM_SOURCE);
        return {
            type: TRACK_REMOVED,
            track,
        };
    }
    else if (track && track.removeAllListeners) {
        track?.removeAllListeners(JitsiMeetJS.events.conference.TRACK_MUTE_CHANGED);
        track?.removeAllListeners(JitsiMeetJS.events.conference.TRACK_VIDEOTYPE_CHANGED);
        track?.removeAllListeners(JitsiMeetJS.events.conference.NO_DATA_FROM_SOURCE);
        return {
            type: TRACK_REMOVED,
            track: {
                track,
            },
        };
    }
}
/**
 * Signal that track's video started to play.
 *
 * @param {(trackLocalTrack|trackRemoteTrack)} track - track instance.
 * @returns {{
 *     type: TRACK_UPDATED,
 *     track: Track
 * }}
 */
export function trackVideoStarted(track) {
    return {
        type: TRACK_UPDATED,
        track: {
            track,
            videoStarted: true,
        },
    };
}
/**
 * 별도의 removeTrack 및 addTrack을 사용하여 두 번의 재협상을 호출하는 대신 한 번의 재협상에 대해 한 트랙을 다른 트랙으로 바꿉니다.
 * 제거된 트랙도 폐기합니다.
 *
 * @param {JitsiLocalTrack|null} oldTrack - The track to dispose.
 * @param {JitsiLocalTrack|null} newTrack - The track to use instead.
 * @param {JitsiConference} [conference] - The conference from which to remove
 * and add the tracks. If one is not provided, the conference in the redux store
 * will be used.
 * @returns {Function}
 */
export function replaceLocalTrack(oldTrack, newTrack) {
    return async (dispatch) => {
        await APP.management.replaceTrack(oldTrack, newTrack);
        return dispatch(replaceStoredTracks(oldTrack, newTrack));
    };
}
/**
 * 저장된 트랙을 다른 트랙으로 바꿉니다.
 *
 * @param {JitsiLocalTrack|null} oldTrack - The track to dispose.
 * @param {JitsiLocalTrack|null} newTrack - The track to use instead.
 * @returns {Function}
 */
export function replaceStoredTracks(oldTrack, newTrack) {
    return async (dispatch) => {
        // 교체를 수행한 후 dispose를 호출합니다.
        // 트랙 자체가 제거된 후 새로운 o/a를 시도하고 수행합니다. 그것을하고
        // after는 JitsiLocalTrack.conference가 이미 있음을 의미합니다.
        // 지워졌으므로 o/a를 시도하지 않습니다.
        if (oldTrack) {
            await dispatch(_disposeAndRemoveTracks([oldTrack]));
        }
        if (newTrack) {
            const setMuted = newTrack.isVideoTrack() ? setVideoMuted : setAudioMuted;
            const isMuted = newTrack.isMuted();
            await dispatch(setMuted(isMuted));
            await dispatch(_addTracks([newTrack]));
        }
    };
}
/**
 * Toggles the facingMode constraint on the video stream.
 *
 * @returns {Function}
 */
export function toggleCamera() {
    return async (dispatch, getState) => {
        const state = getState();
        const tracks = state['features/base/tracks'];
        const localVideoTrack = getLocalVideoTrack(tracks)?.track;
        if (!localVideoTrack)
            return;
        const currentFacingMode = localVideoTrack.getCameraFacingMode();
        /**
         * FIXME: Ideally, we should be dispatching {@code replaceLocalTrack} here,
         * but it seems to not trigger the re-rendering of the local video on Chrome;
         * could be due to a plan B vs unified plan issue. Therefore, we use the legacy
         * method defined in conference.js that manually takes care of updating the local
         * video as well.
         */
        await APP.management.useVideoStream(null);
        const targetFacingMode = currentFacingMode === CAMERA_FACING_MODE.USER ? CAMERA_FACING_MODE.ENVIRONMENT : CAMERA_FACING_MODE.USER;
        // Update the flipX value so the environment facing camera is not flipped, before the new track is created.
        dispatch(updateSettings({ localFlipX: targetFacingMode === CAMERA_FACING_MODE.USER }));
        const newVideoTrack = await createLocalTrack('video', '', null, { facingMode: targetFacingMode });
        // FIXME: See above.
        await APP.management.useVideoStream(newVideoTrack);
    };
}
