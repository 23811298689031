import styled from 'styled-components';
// @ts-ignore
export const DeviceWrapperStyled = styled.div `
	height: 100%;
	width: 100%;
	min-width: 350px;
	position: relative;
	box-sizing: border-box;
	overflow: auto;

	.sub_guid {
		font-size: 12px;

		&.error {
			color: #f36958;
			font-size: 10px;
		}
	}

	${props => props.isMobile
    ? `
        background: #FFF;
        box-sizing: border-box;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;

        .option_wrapper {
            border-radius: 4px;
			top: -17px;
        }

        .page_previous {
            margin-right: 6px;
        }

        .device_item {
            gap: 10px;
        }

        .device_wrapper {
            padding: 33px 33px 16px;
            box-sizing: border-box;
            flex-direction: column;
            width: 100%;
            height: 100%;
			flex: 1;
    overflow: auto;

            > * { margin: 0;}
        }

        .device_list {
            padding: 18px;
            width: 100%;
			overflow: auto;
		
        }

        .device_container {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            margin: 0;
            gap: 6px;
			height: 100%;
    overflow: auto;

            .device_title {
                font-size: 16px;
            }
        }

        .audio_test_btn {
            font-size: 12px;
            padding: 10px 16px;
        }

        .options {
            position: absolute;
            width: 100%;
            top: 56px;
            
            font-size: 12px;
        }

        .device_btn {
            width: 100%;
            text-align: center;
            padding:22px 0;
            box-sizing: border-box;
            display: flex;
            justify-content: center;
            align-items: center;
            background: #8BACFF;
            color: #FFF;
            font-family: Pretendard;
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            letter-spacing: -0.28px;
            flex-direction: row-reverse;
            gap: 14px;

			svg {
				fill: #Fff!important;
			}

            > * { margin: 0;}
        }

        .deivce_check { font-size: 14px; gap: 10px; }
        .select-box { padding: 8px; width: calc(100% - 70px); }
        .selected-option { font-size: 12px; }
    `
    : `
        background: #F9FAFC;

        .page_previous {
            position: absolute;
            top: 34px;
            left: 34px;
            z-index: 2;
        }

        .device_item {
            gap: 20px;
        }

        .audio_test_btn {
            font-size: 1.4rem;
            padding: 1rem 1.8rem;
        }
        
        .select-box {
            width: 100%;
            :not(.error) {
                width: calc(100% - 100px); 
            }
            
            padding: 14px 24px 14px 14px;
            height: 52px;    
            &.video_select {
                
            }
        } 
        
        .device_title_container {
            flex: 1;
            justify-content: center;
            align-items: center;
            gap: 24px;  
            padding: 3rem;
            box sizing: border-box;
        }

        .device_container {
            align-items: flex-start;
            flex-direction: column;
            gap: 24px;
            display: inline-flex;
            justify-content: flex-start;
            align-items: flex-start;

            > * { margin: 0;}

            .device_title {
                font-size: 42px;
            }
        }
        
        .deivce_check { font-size: 18px; gap: 12px; }
        .video_wrap { margin-bottom: 22px!imporatnat; 
			display: flex;
			jusitfy-content: center;
			align-items: center;
			
		}
        .device_list { padding: 2rem; }

        .device_wrapper {
            .device_list {
                padding: 3rem;
            }
        }

        .option_wrapper {
			top: 56px;

        }

        ${props.isDialog
        ? `           
            .device_wrapper {
                display: flex;
                margin: 0;
                width: 100%;
                height: 100%;
            }

            .device_btn {
                width: 180px;
                height: 56px;
                justify-content: center;
                font-size: 18px;
            }

            .device_list {
                width: 100%;
            }
        `
        : `
            
            max-width: 100%;

            .device_wrapper {
                width: 80%;
                left: 50%;
                border-radius: 20px;
                box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.03);
                box-sizing: border-box;
                background: #FFF;
                max-width: 95%;
                gap: 24px;
            }

            .device_btn {
                width: 402px;
                height: 80px;
                width: 100%;
                max-width: 402px;
                justify-content: space-between;
            }
        `}
    `}

	.select-box {
		margin: 0;
		font-size: 16px;

		&.video_select,
		&.full {
			width: 100%;
		}
	}

	.device_title_container {
		display: flex;
		position: relative;
		overflow: auto;

		> * {
			margin: 0;
		}
		.device_title {
			color: #161c1f;
			font-family: SUITE;
			font-style: normal;
			font-weight: 700;
			letter-spacing: -0.84px;
		}
	}

	.device_wrapper {
		display: flex;
		flex-wrap: wrap;

		.device_input {
			display: flex;
			justify-content: flex-start;
			align-items: flex-start;
			gap: 12px;
			flex-wrap: wrap;
			width: ${props => (props.isDialog ? '50%' : '100%')};

			> * {
				margin: 0;
			}
		}

		.audio-input-preview {
			background: #d5d5d5;
			border-radius: 5px;
			height: 8px;
			flex: 1;

			.audio-input-preview-level {
				margin: 0;
				background: #8bacff;
				border-radius: 5px;
				height: 100%;
				-webkit-transition: width 0.1s ease-in-out;
				-moz-transition: width 0.1s ease-in-out;
				-o-transition: width 0.1s ease-in-out;
				transition: width 0.1s ease-in-out;
			}
		}

		.device_guid {
			color: #a1a2a5;
			white-space: pre;
			line-height: 160%;
			font-family: Pretendard;
			font-size: 26px;
			font-style: normal;
			font-weight: 300;
			letter-spacing: -0.52px;
		}

		.device_btn {
			flex-shrink: 0;
			border-radius: 10px;
			background: #8bacff;
			text-align: center;
			display: flex;
			flex-direction: row-reverse;
			align-items: center;
			padding: 28px 26px;
			box-sizing: border-box;
			margin-top: 18px !important;

			> * {
				margin: 0;
			}

			span {
				color: #fff;
				text-align: left;
				font-family: Pretendard;
				font-size: 24px;
				font-style: normal;
				font-weight: 700;
				letter-spacing: -0.4px;
			}

			> * {
				margin: 0;
			}
		}

		.video_wrap {
			min-height: 400px;
			width: 100%;
			flex: 1;
			position: relative;
			background: #000;
			border-radius: 12px;
			overflow: hidden;

			&.gray {
				background: #f5f7fa;
			}

			canvas {
				width: 100%;
				height: 100%;
				position: absolute;
			}

			.video-input-preview-display {
				width: 100%;
				height: 100%;
				position: absolute;
				object-fit: contain;
			}

			.video-center {
				left: 50%;
			}
		}

		.progressbar {
			flex: 1;
			position: relative;
			text-align: center;

			input[type='range'] {
				-webkit-appearance: none;
				width: 100%;
				cursor: pointer;
				border-radius: 0; /* iOS */
				transition: background 450ms ease-in;
				background: #d5d5d5;
				border-radius: 5px;
				height: 8px;
			}

			input[type='range']:focus {
				outline: none;
			}

			input[type='range']:not(.audio_level)::-webkit-slider-thumb {
				-webkit-appearance: none;
				width: 12px;
				height: 12px;
				background: #fff;
				border: 1px solid dodgerblue;
				border-radius: 50%;
				cursor: pointer;
			}

			input[type='range'].audio_level::-webkit-slider-thumb {
				display: none;
			}
			input[type='range'].audio_level::-moz-range-thumb {
				display: none;
			}

			input[type='range']:not(.audio_level, .mute_vertical_range)::-moz-range-thumb {
				-webkit-appearance: none;
				width: 10px;
				height: 10px;
				background: #fff;
				border: 1px solid dodgerblue;
				border-radius: 50%;
				cursor: pointer;
			}
		}

		.device_list {
			display: flex;
			flex-direction: column;
			flex: 1;

			${props => props.isDialog
    ? `
                align-items: center;
            `
    : `
                align-items: flex-start;
            `}

			> * {
				margin: 0;
			}
			gap: 20px;
			flex-direction: column;
			justify-content: flex-start;
			height: 100%;
			box-sizing: border-box;
		}

		.deivce_check {
			display: inline-flex;
			align-items: center;
			color: #a1a2a5;
			font-style: normal;
			font-weight: 500;
			letter-spacing: -0.36px;

			.checkbox-item {
				display: flex;
				justify-content: center;
				align-items: center;
				gap: 10px;
			}

			> * {
				margin: 0;
			}

			input {
				display: none;
			}
		}

		.device_item {
			display: flex;
			flex-direction: column;
			align-items: stretch;
			width: 100%;

			${props => props.isDialog
    ? `
                align-items: center;
            `
    : `
                align-items: flex-start;
            `}

			.device_select {
				position: relative;
				width: 100%;
				display: flex;
				gap: 12px;

				font-size: 18px;
				font-style: normal;
				font-weight: 400;
				letter-spacing: -0.44px;

				.select_icon {
					display: inline-flex;
					left: 24px;
				}
			}

			> * {
				margin: 0;
			}
		}
	}

	.audio_test_btn {
		box-sizing: border-box;
		border-radius: 4px;
		background: #c6cbd2;
		color: #fff;
		display: flex;
		justify-content: center;
		align-items: center;

		&.on {
			background: #8bacff;
		}
	}

	.mobile_speaker_test {
		display: flex;
		jusitfy-content: center;
		align-items: center;
		gap: 12px;

		p {
			font-weight: 900;
			color: #686868;
		}
	}
`;
