import moment from 'moment';
import { getRoomOption } from '../../room';
import { getLocalID } from '../local';
import { MiddlewareRegistry } from '../redux';
import { isChatOpend } from '../sidebar';
import { OPEN_CHAT, TOGGLE_HIDE_CHAT } from '../sidebar/actionTypes';
import { addMessageList, setIsChatRead } from './action';
import { ADD_FILE_MESSAGE, ADD_MESSAGE } from './actionTypes';
import { checkChatPermission, getMessageByUid } from './functions';
import { getChatSize } from './constants';
import { SET_DUAL_MONITOR } from '../../video-layout/actionTypes';
const renderChatList = (store, timestamp, isLt, isFirst) => {
    const local = getLocalID(store.getState());
    store.dispatch(setIsChatRead(true));
    const uuid = getRoomOption(store.getState()).uuid;
    const creationTime = timestamp ? moment(timestamp).toISOString() : moment().toISOString();
    let apiUrl = null;
    if (isLt) {
        apiUrl = `/api/meeting/v1/meetings/${uuid}/chat-logs?limit=${getChatSize()}&creation_time_lt=${encodeURIComponent(creationTime)}&orderby=${encodeURIComponent('creation_time DESC')}`;
    }
    else {
        apiUrl = `/api/meeting/v1/meetings/${uuid}/chat-logs?limit=${getChatSize()}&creation_time_gte=${encodeURIComponent(creationTime)}&orderby=${encodeURIComponent('creation_time')}`;
    }
    apiUrl &&
        fetch(apiUrl)
            .then(response => response.json())
            .then(data => {
            if (data.result) {
                const { count_in_meeting_group, items } = data.result;
                let messages = [];
                if (items) {
                    items.forEach((item) => {
                        const timestamp = new Date(item.creation_time).getTime();
                        messages.push({
                            uuid: timestamp.toString() + item.from_user_id,
                            type: item.type,
                            from: item.from_member_id,
                            isMe: local === item.from_user_id ? true : false,
                            fromNickname: item.from_nicknams,
                            fromRole: item.from_role,
                            message: item.message,
                            privateMessage: undefined,
                            timestamp,
                            // .format(militaryTime ? 'HH:mm' : 'LT'),
                        });
                    });
                }
                store.dispatch(addMessageList(messages, count_in_meeting_group, isFirst ? messages[messages.length - 1]?.uuid : null, isLt));
            }
        });
};
MiddlewareRegistry.register(store => next => async (action) => {
    switch (action.type) {
        case OPEN_CHAT:
            if (isChatOpend(store.getState()))
                return next(action);
            renderChatList(store, null, true, true);
            break;
        case 'UPDATE_DUAL_SIDE_OPEN':
        case SET_DUAL_MONITOR:
            renderChatList(store, null, true, true);
            break;
        case TOGGLE_HIDE_CHAT:
            if (!action.isHide) {
                renderChatList(store, null, true, true);
            }
            break;
        case 'UPDATE_LAST_ITEM_ID':
            const last_item_timestamp = getMessageByUid(store.getState, action.lastItemId)?.timestamp;
            // @ts-ignore
            last_item_timestamp && renderChatList(store, last_item_timestamp, true);
            break;
        case 'UPDATE_START_ITEM_ID':
            const start_item_timestamp = getMessageByUid(store.getState, action.startItemId)?.timestamp;
            // @ts-ignore
            start_item_timestamp && renderChatList(store, start_item_timestamp, false);
            break;
        case ADD_MESSAGE:
            let message = undefined;
            if (action.local) {
                const timestamp = new Date().getTime();
                message = {
                    uuid: timestamp.toString() + action.local.user_uuid,
                    type: 'text',
                    isMe: true,
                    from: action.local.user_uuid,
                    fromNickname: action.local.nickname,
                    fromRole: action.local.role,
                    message: action.message,
                    privateMessage: undefined,
                    timestamp,
                };
                if (action.isShared && message) {
                    if (checkChatPermission(store.getState, 'chat')) {
                        const response = await APP.management.sendMessage({ ...message, isMe: false });
                        if (response.status !== 200) {
                            APP.UI.alertMessage('채팅 전송 실패했습니다. 네트워크 확인해주세요.');
                            return;
                        }
                    }
                    else
                        return;
                }
            }
            else {
                message = {
                    ...action.message,
                };
            }
            if (!message)
                break;
            return next({
                ...action,
                message,
            });
        case ADD_FILE_MESSAGE:
            let fileMessage = undefined;
            if (action.local) {
                const file = action.message;
                const timestamp = new Date().getTime();
                const uuid = timestamp.toString() + action.local.user_uuid;
                fileMessage = {
                    uuid: uuid,
                    type: 'file',
                    isMe: true,
                    from: action.local.user_uuid,
                    fromNickname: action.local.nickname,
                    fromRole: action.local.role,
                    message: action.message.name,
                    privateMessage: undefined,
                    timestamp,
                };
                if (action.isShared && fileMessage) {
                    next({ type: ADD_MESSAGE, message: fileMessage });
                }
                const response = await APP.API.convertChatFile(file);
                if (response.complete) {
                    fileMessage = {
                        ...fileMessage,
                        message: JSON.stringify({
                            fileId: uuid,
                            name: fileMessage.message,
                            size: file.size,
                            link: response.data.download_link,
                        }),
                    };
                    if (action.isShared && fileMessage) {
                        if (checkChatPermission(store.getState, 'chat')) {
                            const response = await APP.management.sendMessage({ ...fileMessage, isMe: false });
                            if (response.status !== 200) {
                                APP.UI.alertMessage('채팅 전송 실패했습니다. 네트워크 확인해주세요.');
                                return;
                            }
                        }
                        else
                            return;
                    }
                }
            }
            else {
                fileMessage = {
                    ...action.message,
                };
            }
            if (!fileMessage)
                break;
            return next({
                ...action,
                type: ADD_MESSAGE,
                message: fileMessage,
            });
    }
    return next(action);
});
