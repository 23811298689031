import React, { createContext, useContext, useState, useEffect } from 'react';
import { isIosMobileBrowser } from '../base/environment/utils';
import { isMobileDevice } from '../lobby/components';
function isTabletDevice() {
    const userAgent = navigator.userAgent.toLowerCase();
    const isTouch = 'ontouchstart' in window || navigator.maxTouchPoints > 0;
    const isTabletSize = window.innerWidth >= 768 && window.innerWidth <= 1200;
    const isAndroid = userAgent.indexOf('android') > -1;
    const isiOS = /ipad|iphone|ipod/.test(userAgent) && !window.MSStream;
    if (isAndroid) {
        return !userAgent.includes('mobile') && isTabletSize;
    }
    else if (isiOS) {
        return ((userAgent.includes('ipad') ||
            (navigator.maxTouchPoints && navigator.maxTouchPoints > 2 && /MacIntel/.test(navigator.platform))) &&
            isTabletSize);
    }
    return isTouch && isTabletSize && !userAgent.includes('mobile');
}
// Initialize the context with a default value
const GlobalStateContext = createContext(undefined);
export const GlobalStateProvider = ({ children }) => {
    const [isMobile, setIsMobile] = useState(false);
    const [isTablet, setIsTablet] = useState(false);
    const [isDesktop, setIsDesktop] = useState(false);
    const [isIPhone, setIsIPhone] = useState(isIosMobileBrowser());
    const [testData, setTestData] = useState({});
    const [choiceSubject, setChoiceSubject] = useState({});
    const [position, setPosition] = useState({ top: 0, left: 0 });
    // Function to initialize position to bottom right of window
    const initializePosition = () => {
        const windowWidth = window.innerWidth;
        const windowHeight = window.innerHeight;
        const left = Math.max(0, Math.min(windowWidth - 172, windowWidth - 172));
        const top = Math.max(0, Math.min(windowHeight - 70, windowHeight - 70));
        setPosition({ top, left });
    };
    const updateResize = () => {
        initializePosition();
        updateDeviceState();
    };
    const updateDeviceState = () => {
        setIsMobile(window.innerWidth <= 767 || (isMobileDevice() && window.innerHeight < 767));
        setIsTablet((window.innerWidth > 767 && window.innerWidth < 1200) || (isTabletDevice() && window.innerHeight >= 767)
            ? true
            : false);
        setIsDesktop(window.innerWidth >= 1200);
        setIsIPhone(isIosMobileBrowser());
    };
    useEffect(() => {
        window.addEventListener('resize', updateResize);
        initializePosition();
        updateDeviceState();
        return () => window.removeEventListener('resize', updateResize);
    }, []);
    return (React.createElement(GlobalStateContext.Provider, { value: {
            isMobile,
            isTablet,
            isDesktop,
            isIPhone,
            testData,
            setTestData,
            position,
            setPosition,
            choiceSubject,
            setChoiceSubject,
        } }, children));
};
export const useGlobalState = () => {
    const context = useContext(GlobalStateContext);
    if (context === undefined) {
        throw new Error('useGlobalState must be used within a GlobalStateProvider');
    }
    return context;
};
