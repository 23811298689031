import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { getScreenLayoutType } from '../../screen-share';
import { SHARE_TYPE } from '../../screen-share/constants';
import MembersLayout from './MembersLayout';
import ScreenVideo from './ScreenVideo';
import { ScreenMember } from './layout';
import { ClickButton } from '../../base/ui';
const ScreenShareMember = React.memo(({ isMobile, screenLayoutType }) => {
    const [showMemberType, setShowMemberType] = useState();
    useEffect(() => {
        if (isMobile) {
            setShowMemberType([
                {
                    type: 'screen',
                    className: 'member_layout',
                    vertical: true,
                    noShowMember: false,
                    noPaging: false,
                },
            ]);
        }
        else {
            if (screenLayoutType === SHARE_TYPE) {
                setShowMemberType([
                    {
                        type: 'screen',
                        className: 'member_layout',
                        vertical: true,
                        noShowMember: false,
                        noPaging: false,
                    },
                ]);
            }
            else {
                setShowMemberType([
                    {
                        type: 'pin',
                        className: 'horizontal_item',
                        vertical: true,
                        noShowMember: true,
                        noPaging: true,
                    },
                    {
                        type: 'remote',
                        className: 'horizontal_item',
                        vertical: true,
                        noShowMember: true,
                        noPaging: false,
                    },
                ]);
            }
        }
    }, [screenLayoutType, isMobile]);
    return (showMemberType &&
        showMemberType.map(item => (React.createElement(MembersLayout, { key: item.type, className: item.className, type: item.type, vertical: item.vertical, noPaging: item.noPaging, noShowMember: item.noShowMember, maxStyle: { width: 172, height: 98 } }))));
});
const positionList = [
    ['right', 'bottom'],
    ['left', 'bottom'],
    ['top', 'left'],
    ['right', 'top'],
];
const ScreenModeLayout = React.memo(({ isMobile }) => {
    const [position, setPosition] = useState(1);
    const [onlyScreen, setOnlyScreen] = useState(false);
    const screenLayoutType = useSelector((state) => getScreenLayoutType(state));
    useEffect(() => {
        setOnlyScreen(screenLayoutType === SHARE_TYPE ? true : false);
    }, [screenLayoutType, isMobile]);
    const handlerSetPosition = (item) => {
        let newPosition;
        switch (item) {
            case 'right':
                newPosition = position === 1 ? 2 : 3;
                break;
            case 'bottom':
                newPosition = position === 1 ? 4 : 3;
                break;
            case 'left':
                newPosition = position === 2 ? 1 : 4;
                break;
            case 'top':
                newPosition = position === 3 ? 2 : 1;
                break;
            default:
                newPosition = position;
        }
        setPosition(newPosition);
    };
    const renderScreenShare = useMemo(() => React.createElement(ScreenVideo, null), []);
    const renderScreenShareMember = useMemo(() => {
        return APP.config.option.hideLayoutMember ? null : (React.createElement(ScreenShareMember, { isMobile: isMobile, screenLayoutType: screenLayoutType }));
    }, [isMobile, screenLayoutType]);
    const renderPositionBtn = useMemo(() => positionList[position - 1].map(item => (React.createElement(ClickButton, { key: item, className: `screen_button ${item}`, icon: "enter", size: 20, onClick: () => handlerSetPosition(item) }))), [positionList, position]);
    return (React.createElement(ScreenMember, { position: position, onlyScreen: onlyScreen, isMobile: isMobile, renderPositionBtn: renderPositionBtn, renderScreenShare: renderScreenShare, renderScreenShareMember: renderScreenShareMember }));
});
export default ScreenModeLayout;
