// (Action) 비밀번호 업데이트 
export const updatePassword = (password) => {
    return APP.API.udpateReservationRoom({ password })
        .then(response => {
        if (response.complete) {
            return { complete: true, data: response.data };
        }
        else {
            return { complete: false, data: response.data };
        }
    });
};
