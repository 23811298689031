import { toggleScreenShare } from '../../screen-share';
import { updateShareItem } from '../../share-layout';
import { setLayoutMode, videoLayoutMode } from '../../video-layout';

export default {
	changeMode: (dispatch, mode, current_mode) => {
		dispatch(setLayoutMode(mode));
	},
	shareCurrentMode: (dispatch, uuid, mode) => {
		const isWhiteBoard = mode === videoLayoutMode.white ? true : false;

		dispatch(updateShareItem(uuid, { uuid, shared: true, isWhiteBoard }));
	},
	toggleScreenShare: dispatch => {
		dispatch(toggleScreenShare());
	},
	// openScreenDialog: (dispatch, mode) => {
	//     if (mode === videoLayoutMode.screen) {
	//         APP.management.toggleScreenShare(false);
	//         dispatch(setLayoutMode(videoLayoutMode.grid));
	//     } else  {
	//         if (isMobileBrowser()) {
	//             if (navigator.product === 'ReactNative') {
	//                 APP.management.toggleScreenShare(true);
	//             } else {
	//                 APP.UI.alertMessage("error.notUseMoblie");
	//             }
	//         } else {
	//             dispatch(toggleDialog(ScreenSharePopUp));
	//         }

	//     }
	// },
};
