import styled, { css } from 'styled-components';
export const DocumentStyled = styled.div `
	overflow: hidden;
	width: 100%;
	height: ${props => (props.expand ? 'calc(100% - 98px)' : '100%')};
	display: flex;
	flex-direction: ${props => (props.direction === 'row' ? 'column' : 'row')};
	flex: 1;
	overflow: auto;
	.share_content {
		position: relative;
		width: 100%;
		height: ${props => (props.expand ? 'calc(100% - 98px)' : '100%')};
		display: flex;
		overflow: hidden;

		flex-direction: ${props => props.direction === 'row' ? (props.location === 'bottom' ? 'column-reverse' : 'column') : 'row'};
	}

	> * {
		margin: 0;
	}

	.share_list {
		box-sizing: border-box;
		> * {
			margin: 0;
		}
		.share_items {
			text-align: center;
		}
		${props => props => props.direction === 'row' && props.location === 'bottom'
    ? css `
						margin: 8px 0;
					`
    : `
                width: 172px;
                min-width: 172px;
                height: 100%;
                background: #fff;
                
            `}

		&.non_expand {
			padding-top: ${props => props => (props.direction === 'row' && props.location === 'bottom' ? 0 : '98px')};
		}

		.share_header {
			background: #8bacff;
			width: 100%;
			display: flex;
			justify-content: center;
			align-items: center;
			height: 36px;
			color: #fff;
			font-size: 14px;
			font-weight: 900;
			margin-bottom: 2px;
			padding: 0 8px;
			box-sizing: border-box;
			p {
				margin-left: 0;
				flex: 1;
			}
		}
		.add_btn_wrapper {
			display: flex;
			gap: 4px;
			margin-right: 0;
			flex: 1;
			justify-content: flex-end;

			> * {
				margin: 0;
			}
		}
		.add_btn {
			display: flex;
			flex-direction: row;
			margin: 0;
			justify-content: center;
			align-items: center;
			box-sizing: border-box;
			gap: 8px;

			&:hover {
				svg {
					color: #3066ef !important;
					fill: #3066ef !important;
					border-color: #3066ef !important;
				}
			}

			button {
				background-color: transparent;
				border: none;
			}
		}
	}

	.item_label {
		position: relative;
		overflow: hidden;
		color: #000;
		margin: 0 0 2px 0;
		display: flex;
		background: #edf8f9;
		justify-content: center;
		align-items: center;
		gap: 4px;
		height: 35px;
		min-height: 35px;
		text-align: center;
		padding-left: 6px;
		box-sizing: border-box;
		user-select: none;

		.label_child {
			display: flex;
			justify-content: center;
			align-items: center;

			.click_button {
				display: flex;
			}
		}

		&.focus {
			background: #b8e9f4;
		}

		&.cursor {
			cursor: pointer;
		}

		> * {
			min-width: 20px;
			min-height: 20px;
		}

		.title {
			padding: 8px 0;
			width: 100%;
			flex-grow: 1;
			display: block;
			overflow: hidden;
			text-overflow: ellipsis;
			word-break: break-all;
			margin: 0;
			white-space: nowrap;
		}

		.previous {
			&:hover {
				svg {
					color: #3066ef !important;
					fill: #3066ef !important;
					border-color: #3066ef !important;
				}
			}
		}

		.share_label {
			min-width: 8px;
			height: 100%;
			background: #48a2f1;
		}
	}

	.item_image {
		text-align: center;
		&:not(.no-pointer) {
			cursor: pointer;
		}

		.focus_img {
			border: 2px solid #b8e9f4;
		}
	}

	${props => props.name === 'class' &&
    css `
			.item_image_wrapper {
				overflow: auto;
				width: 100%;
				height: calc(100% - 37px);
				box-sizing: border-box;

				div {
					color: #000;
					display: flex;
					flex-wrap: wrap;
					gap: 12px;
					justify-content: center;

					img {
						border: 1px solid #545454;
						box-sizing: border-box;
						max-width: 80%;
						margin: 0;
					}
				}
			}
		`}

	${props => props.name === 'mate' &&
    css `

        .item_image_wrapper {
            box-sizing: border-box;
            overflow: auto;
            margin: 0;

            div { color: #000; }
        }

        .share_content {
            position: relative;
            display: flex;
            width: 100%;
            height: 100%;
            overflow: hidden;

        

            &.expand {
                width: calc(100% - 172px);
                
            }
        }

        .share_item {
            position: relative;
            width: 100%;
            max-height: 100%;
            overflow: hidden; 
            display: flex;
            flex-direction: column;       
            text-align: center;
    `}
`;
export const ToolButtonStyeld = styled.div `
	${props => props.direction === 'row'
    ? css `
					width: 100%;
					height: 47px;
					display: flex;

					.option_box {
						${props.location === 'bottom' ? `bottom: 48px;` : `top: 48px;`};
					}

					padding: 8px 0;
					box-sizing: border-box;

					> * {
						margin: 0;
					}
				`
    : css `
					margin: 0;
					min-width: 40px;
					width: 40px;
					height: 100%;
					text-align: center;
					float: left;
					background: #e6e6e6;
					box-sizing: border-box;
					display: inline-table;

					.option_box {
						left: 43px;
					}
				`};

	.hide_menu {
		width: 44px;
		height: 32px;
		display: flex;
		justify-content: center;
		align-items: center;
		border-right: 1px solid #545454;
		margin-right: 5px;
	}

	.hide {
		min-width: 43px;
		background: #545454;
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 8px 0;
		box-sizing: border-box;
	}

	.option_box {
		display: flex;
		position: absolute;
		color: #000;
		padding: 4px;
		box-sizing: border-box;
		background: #fff;
		border-radius: 4px;
		gap: 12px;
		min-height: 43px;
		padding: 0 12px;
		flex-wrap: wrap;
		width: fit-content;
		z-index: 2;
		justify-content: center;
		align-items: center;
		height: fit-content;
		padding: 12px;
		box-sizing: border-box;

		.zoom_box {
			&.option_scale {
				display: flex;
				width: 150px;
				svg:hover {
					color: #109cef;
					cursor: pointer;
				}
				span {
					margin: auto;
				}
			}

			&.field {
				display: flex;
				align-items: center;
				justify-content: center;
				height: 100%;
				position: relative;
				margin: 0;

				.value {
					font-size: 14px;
					color: #664aff;
					font-weight: 600;
					margin: 0 8px;
				}

				input {
					width: 170px;
					height: 3px;
					background: #ddd;
					border-radius: 5px;
					outline: none;
					border: none;
					z-index: 2222;

					&::-webkit-slider-thumb {
						width: 15px;
						height: 15px;
						background: red;
						border-radius: 50%;
						background: #664aff;
						border: 1px solid #664aff;
						cursor: pointer;
					}

					&::-moz-range-thumb {
						width: 20px;
						height: 20px;
						background: red;
						border-radius: 50%;
						background: #664aff;
						border: 1px solid #664aff;
						cursor: pointer;
					}

					&::-moz-range-progress {
						background: #664aff; //this progress background is only shown on Firefox
					}
				}

				label {
					position: absolute;
					top: 12px;
					color: #123;
				}
			}
		}
	}
`;
export const AddDocumentStyled = styled.div `
	.test {
		display: flex;
		justify-content: space-between;
		align-items: center;
		width: 100%;
	}
	${props => props.name === 'class'
    ? `
        display: flex;
        gap: 12px;
        margin: 4px 0;
    `
    : ``}
`;
