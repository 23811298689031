import React, { useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { ROLE, checkRoleLevel, getLocalMemberRole } from '../members';
import ChatBodyContainer from './ChatBodyContainer';
import ChatInputContainer from './ChatInputContainer';
import ChatNotificationContainer from './ChatNotificationContainer';
import { AddFile } from './components';
const Chat = React.memo(({ isMonitoring }) => {
    const { t } = useTranslation();
    const chatRef = useRef(null);
    const isShowLvl = useSelector((state) => checkRoleLevel(getLocalMemberRole(state), ROLE.PRESENTER));
    const enableChatView = useSelector((state) => state['features/base/settings'].policy.notification_option.enable_chat_view);
    const showSendChat = useMemo(() => !isMonitoring && (enableChatView || isShowLvl), [isMonitoring, enableChatView, isShowLvl]);
    return (React.createElement("div", { className: "chat-container" },
        React.createElement(ChatNotificationContainer, null),
        React.createElement("div", { className: "chat_content", ref: chatRef },
            React.createElement(ChatBodyContainer, null)),
        showSendChat && (React.createElement("div", { className: "chat_input_wrapper" },
            React.createElement("div", { className: "chat_head" },
                React.createElement("span", { className: "title" }, t('chat.inputLabel')),
                React.createElement("div", { className: "btn_left_wrapper" }, APP.config.option.hideParticipant && isShowLvl && React.createElement(AddFile, null))),
            React.createElement(ChatInputContainer, null)))));
});
export default Chat;
