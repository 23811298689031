import React from 'react';
import { useTranslation } from 'react-i18next';
import { ClickButton, Input } from '../../ui';
import { shallowEqual, useSelector } from 'react-redux';
import { getMemberCount } from '../../members';
export const SidebarTitle = ({ name, showBody, hideContent, iconStyles, }) => {
    const { t } = useTranslation();
    const memberCount = name === 'member' ? useSelector((state) => getMemberCount(state), shallowEqual) : 0;
    return (React.createElement("div", { className: "item-flex" },
        React.createElement(ClickButton, { className: "sArrow", icon: showBody ? 'up' : 'down', size: 24, color: "#000", label: t(`setting.${name}`), styles: iconStyles, onClick: hideContent }),
        name === 'member' && React.createElement("p", null,
            " (",
            memberCount,
            " \uBA85)")));
};
export const SidebarSearch = ({ onSwitchSearch, setOnSwitchSearch, iconStyles, }) => {
    return (React.createElement(ClickButton, { icon: "search", size: 18, color: onSwitchSearch ? '#8bacff' : '#000', styles: iconStyles, onClick: () => setOnSwitchSearch && setOnSwitchSearch(!onSwitchSearch) }));
};
export const SidebarClose = ({ handlerClose, iconStyles }) => {
    return React.createElement(ClickButton, { icon: "sideClose", size: 16, color: "#000", styles: iconStyles, onClick: handlerClose });
};
export const SidebarSearchInput = ({ handlerSearchText, styles, }) => {
    const { t } = useTranslation();
    return (React.createElement(Input, { className: "search_bar", placeholder: t('search.inputParticipant'), customStyles: styles, onChange: handlerSearchText }));
};
