import React, { useEffect, useMemo, useRef, useState, forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useSelector } from 'react-redux';
import { MebmerAudioItem, MebmerHandlerItem, MebmerVideoItem, MemberEtc } from './MemberItem';
import { MEMBER_STATUS } from './constans';
import { getMemberTrackByUserID } from './functions';
import { getPropertyValue } from '../settings/functions';
const MemberItemContainer = forwardRef(({ user_id, search, isMonitoring }, ref) => {
    const member = useSelector((state) => getMemberTrackByUserID(state, user_id, false), shallowEqual);
    const individualLive = useSelector((state) => getPropertyValue(state, 'individualLive'));
    if (!member || (search && member.nickname?.includes(search)))
        return null;
    return (React.createElement("div", { ref: ref },
        React.createElement(MemberItemRender, { member: member, isMonitoring: isMonitoring, individualLive: individualLive })));
});
export default MemberItemContainer;
export const MemberItemRender = React.memo(({ member, isMonitoring = false, individualLive = false, }) => {
    const containerRef = useRef(null);
    const { t } = useTranslation();
    const { user_uuid, nickname, pinned, local: isLocal, role: role_name, audioMuted, videoMuted, } = useMemo(() => member, [member]);
    const [changeNick, setChangeNick] = useState(false);
    useEffect(() => {
        const handlerChangenick = () => setChangeNick(!changeNick);
        if (isLocal) {
            APP.eventManager.subscribe('CHANGE_NICKNAME', handlerChangenick);
            return () => {
                APP.eventManager.unsubscribe('CHANGE_NICKNAME', handlerChangenick);
            };
        }
    }, [isLocal, changeNick]);
    return (React.createElement("div", { ref: containerRef, className: `item_member ${isLocal ? 'local' : ''}` },
        React.createElement("div", { className: "member_name_wrapper nonSelect" },
            React.createElement("p", { className: "role" }, t(`role.${role_name}`)),
            React.createElement("span", { className: "name", style: { color: isLocal ? '#8bacff' : '' } },
                ' ',
                !changeNick && (member.nickname || '-'),
                ' ')),
        React.createElement("div", { className: "member_icon_wrapper" }, member.status === MEMBER_STATUS.OCCUPIDE ? (['handler', 'mic', 'video', 'etc'].map(item => {
            // if (!APP.config.memberIcon.includes(item)) return null;
            switch (item) {
                case 'handler':
                    if (individualLive)
                        return null;
                    return (React.createElement(MebmerHandlerItem, { key: item, item: item, user_uuid: user_uuid, pinned: pinned, isLocal: isLocal, isMonitoring: isMonitoring }));
                case 'mic':
                    return (React.createElement(MebmerAudioItem, { key: item, item: item, isLocal: isLocal, user_uuid: user_uuid, audioMuted: audioMuted, isMonitoring: isMonitoring }));
                case 'video':
                    return (React.createElement(MebmerVideoItem, { key: item, item: item, isLocal: isLocal, user_uuid: user_uuid, videoMuted: videoMuted, isMonitoring: isMonitoring }));
                case 'etc':
                    return (React.createElement(MemberEtc, { key: item, item: item, role_name: role_name, nickname: nickname, user_uuid: user_uuid, videoMuted: videoMuted, isMonitoring: isMonitoring, isLocal: isLocal }));
            }
        })) : (React.createElement("div", null, "\uBBF8 \uCC38 \uC11D")))));
});
