import React, { useEffect, useState, useCallback, useRef } from 'react';
import { getTestTime } from './querys';
export const RemaingTimer = React.memo(({ test_id }) => {
    const [remainingTime, setRemainingTime] = useState(null);
    const endTimeRef = useRef(null);
    const syncIntervalRef = useRef(null);
    const fetchEndTime = useCallback(async () => {
        try {
            const response = await getTestTime(test_id);
            if (response?.result?.remaining !== undefined) {
                const serverRemainingTime = response.result.remaining;
                const serverTime = Date.now(); // 서버 시간 기준으로 계산
                endTimeRef.current = serverTime + serverRemainingTime * 1000;
                setRemainingTime(serverRemainingTime);
            }
        }
        catch (error) {
            console.error('Error fetching data:', error);
        }
    }, [test_id]);
    const updateTimer = useCallback(() => {
        if (endTimeRef.current === null)
            return;
        const now = Date.now();
        const newRemainingTime = Math.max(0, (endTimeRef.current - now) / 1000);
        setRemainingTime(newRemainingTime);
        if (newRemainingTime > 0) {
            syncIntervalRef.current = setTimeout(updateTimer, 100); // 100ms 마다 업데이트
        }
    }, []);
    useEffect(() => {
        fetchEndTime(); // 처음 로드할 때 서버 시간 가져오기
        const intervalId = setInterval(fetchEndTime, 10000); // 10초마다 서버와 동기화
        return () => {
            clearInterval(intervalId);
            if (syncIntervalRef.current !== null) {
                clearTimeout(syncIntervalRef.current);
            }
        };
    }, [fetchEndTime]);
    useEffect(() => {
        if (remainingTime !== null && remainingTime > 0) {
            updateTimer();
        }
        return () => {
            if (syncIntervalRef.current !== null) {
                clearTimeout(syncIntervalRef.current);
            }
        };
    }, [remainingTime, updateTimer]);
    const formatTime = (seconds) => {
        const hours = Math.floor(seconds / 3600);
        const minutes = Math.floor((seconds % 3600) / 60);
        const secs = Math.floor(seconds % 60);
        return [hours, minutes, secs].map(v => v.toString().padStart(2, '0')).join(':');
    };
    return (React.createElement("div", { className: "text-field", style: { margin: 0 } }, remainingTime !== null && remainingTime > 0 ? formatTime(remainingTime) : '00:00:00'));
});
