import React, { useMemo } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { ROLE, getMemberTrackByUserID } from '../../base/members';
import MemberAudio from './MemberAudio';
import { MemberItem, MemberVideo } from './layout';
import { FullScreen, useFullScreenHandle } from 'react-full-screen';
const MemberItemRenderer = React.memo(({ member, user_uuid, styles, isMaxSize, vertical }) => {
    const handler = useFullScreenHandle();
    const { local, nickname, videoTrack, audioTrack, role } = useMemo(() => member, [member]);
    const renderVideo = useMemo(() => (React.createElement(MemberVideo, { styles: styles, nickname: nickname, videoTrack: videoTrack, role: role, isFullScreen: role === ROLE.HOST, handlerFullScreen: handler.enter, handlerExitFullScreen: handler.exit })), [styles, videoTrack, nickname, role, handler]);
    const renderAudioMuted = useMemo(() => React.createElement(MemberAudio, { audioTrack: audioTrack }), [audioTrack]);
    return (React.createElement(FullScreen, { handle: handler, className: `${handler.active ? 'enable' : ''} ` },
        React.createElement(MemberItem, { isMaxSize: isMaxSize, local: local, role_name: role, styles: styles, vertical: vertical, renderVideo: renderVideo, renderAudioMuted: renderAudioMuted })));
});
const MemberItemContainer = React.memo((props) => {
    const member = useSelector((state) => getMemberTrackByUserID(state, props.user_uuid, true), shallowEqual);
    if (!member)
        return null;
    else
        return React.createElement(MemberItemRenderer, { ...props, member: member });
});
export default MemberItemContainer;
