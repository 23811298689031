import { getLocalMember, PERMISSION } from '../base/members';
import { isCheckPermission } from '../room/functions';
import { SET_SHARE_SCREEN_ID, SET_SHARE_SCREEN_OPTION } from './actionTypes';
import { getLocalSharingStatus, getStartScreenShare } from './functions';
/**
 * 화면 공유 시작 or 종료
 * @param {Boolean|undefiend} isStart
 * @returns
 */
export function toggleScreenShare(isStart) {
    return (dispatch, getState) => {
        const changeStatus = isStart | !getStartScreenShare(getState);
        if (changeStatus) {
            /**
             * 공유를 시작한 경우
             * 1. 공유 중인 경우 > 공유 중지
             * 2. 공유를 시작
             */
            if (getStartScreenShare(getState)) {
                console.log('공유를 중지하세요.');
                return;
            }
            else {
                dispatch(startScreen(false));
            }
        }
        else {
            dispatch(stopScreen(false));
        }
    };
}
/**
 * (SCREEN SHARE)
 * 화면 녹화 시작
 * @param {Boolean} isRemote
 * @returns
 */
function startScreen(isRemote = true) {
    return (dispatch, getState) => {
        APP.management
            .startScreenShare()
            .then(conference => {
            const jitsi_id = conference.myUserId();
            const member = getLocalMember(getState);
            dispatch(setShareScreenUserId(jitsi_id, member?.member_uuid, isRemote));
        })
            .catch(err => console.log(err));
    };
}
/**
 * (SCREEN SHARE)
 * 화면 녹화 종료
 * @returns
 */
export function stopScreen(isRemote = true) {
    return async (dispatch, getState) => {
        if (getLocalSharingStatus(getState)) {
            APP.management.stopScreenShare();
            return;
        }
        dispatch(setShareScreenUserId(null, null, isRemote));
    };
}
/**
 * 현재 화면 공유 중이거나 화면 공유 종료를 알림.
 * @param {String} jitsi_id
 * @param {String} member_uuid
 * @param {Boolean} isRemote
 * @returns
 */
export function setShareScreenUserId(jitsi_id, member_uuid, isRemote = false) {
    return async (dispatch, getState) => {
        if (!isRemote) {
            if (isCheckPermission(getState, PERMISSION.SHARE)) {
                const response = await APP.management.setScreenOption(jitsi_id, member_uuid);
                if (response.status !== 200) {
                    /**
                     * FIX ME
                     */
                    return;
                }
            }
            else
                return;
        }
        dispatch({
            type: SET_SHARE_SCREEN_ID,
            jitsi_id,
            member_uuid,
            isLocal: isRemote ? false : true && jitsi_id,
            isRemote,
        });
    };
}
/**
 * 사용자 UI 옵션 업데이트
 * @param {String|null} ui
 * @returns
 */
export function setShareScreenOption(ui) {
    return {
        type: SET_SHARE_SCREEN_OPTION,
        ui,
    };
}
