import _ from 'lodash';
import { SET_ACTIVE_GROUP_ID } from '../../room';
import ReducerRegistry from '../redux/ReducerRegistry';
import { ADD_LOCAL_MEMBER, ADD_MEMBER, ADD_RECORD, DELETE_MEMBER, DELETE_RECORD, LEFT_MEMBER, REMOVE_MEMBERS, SET_BRIDGE_ID, SET_MEMBER_VOICE, SET_SPEAKER_ID, UPDATE_MEMBER, } from './actionType';
import { MEMBER_STATUS, ROLE } from './constans';
import { checkRoleLevel } from './functions';
const DEFAULT_STATE = {
    local: '',
    remote: new Set(),
    pinIds: new Set(),
    recordIds: new Set(),
    voice: '',
    members: new Map(),
    memberIds: new Map(),
    bridgeList: new Map(),
    speakerId: '',
};
ReducerRegistry.register('features/base/members', (state = DEFAULT_STATE, action) => {
    let member;
    let memberState = _.cloneDeep(state);
    switch (action.type) {
        case ADD_LOCAL_MEMBER:
            member = action.member;
            memberState.local = member.user_uuid;
            memberState.members.set(member.user_uuid, member);
            memberState.memberIds.set(member.member_uuid, member.user_uuid);
            if (member.pinned && !checkRoleLevel(member.role, ROLE.MANAGER)) {
                memberState.pinIds.add(member.user_uuid);
            }
            // if (member.role === ROLE.HOST) state.hostList.add(member.user_uuid);
            return {
                ...memberState,
            };
        case ADD_MEMBER:
            // member 객체를 action에서 가져옴
            member = action.member;
            // permission 속성을 제외한 새로운 member 객체 생성
            const { permissions, ...memberWithoutPermissions } = member;
            // 나머지 처리
            if (member.pinned && !checkRoleLevel(member.role, ROLE.MANAGER)) {
                memberState.pinIds.add(memberWithoutPermissions.user_uuid);
            }
            memberState.remote.add(memberWithoutPermissions.user_uuid);
            memberState.members.set(memberWithoutPermissions.user_uuid, memberWithoutPermissions);
            memberState.memberIds.set(memberWithoutPermissions.member_uuid, memberWithoutPermissions.user_uuid);
            memberWithoutPermissions.bridge_sessions &&
                memberWithoutPermissions.bridge_sessions.length > 0 &&
                memberState.bridgeList.set(memberWithoutPermissions.user_uuid, memberWithoutPermissions.bridge_sessions[0]);
            // if (memberWithoutPermissions.role === ROLE.HOST) state.hostList.add(memberWithoutPermissions.user_uuid);
            return {
                ...memberState,
            };
        case LEFT_MEMBER:
            member = action.member;
            if (member.local)
                memberState.local = '';
            memberState.members.set(member.user_uuid, { ...member, status: MEMBER_STATUS.VACATED });
            memberState.bridgeList.delete(member.user_uuid);
            memberState.pinIds.delete(member.user_uuid);
            if (memberState.voice === member.user_uuid)
                memberState.voice = '';
            return {
                ...memberState,
            };
        case DELETE_MEMBER:
            member = action.member;
            if (member.local)
                memberState.local = '';
            // memberState.hostList.delete(member.user_uuid);
            memberState.pinIds.delete(member.user_uuid);
            if (memberState.voice === member.user_uuid)
                memberState.voice = '';
            return {
                ...memberState,
            };
        case ADD_RECORD:
            memberState.recordIds.add(action.user_uuid);
            return {
                ...memberState,
            };
        case DELETE_RECORD:
            memberState.recordIds.delete(action.user_uuid);
            return {
                ...memberState,
            };
        case SET_ACTIVE_GROUP_ID:
            memberState.pinIds = new Set();
            memberState.recordIds = new Set();
            return {
                ...memberState,
            };
        case UPDATE_MEMBER:
            member = action.member;
            if (!member.user_uuid)
                break;
            memberState.members.set(member.user_uuid, member);
            memberState.memberIds.set(member.member_uuid, member.user_uuid);
            memberState.members.set(member.user_uuid, member);
            if (!checkRoleLevel(member.role, ROLE.MANAGER)) {
                if (member.pinned) {
                    memberState.pinIds.add(member.user_uuid);
                }
                else {
                    memberState.pinIds.delete(member.user_uuid);
                }
            }
            const newMembers = new Map(state.members);
            newMembers.set(member.user_uuid, member);
            return {
                ...memberState,
                members: newMembers,
            };
        case SET_BRIDGE_ID:
            memberState.bridgeList.set(action.user_uuid, action.bridge_sessions);
            return {
                ...memberState,
            };
        case SET_MEMBER_VOICE:
            if (action.user_uuid)
                memberState.voice = action.user_uuid;
            return {
                ...memberState,
            };
        case SET_SPEAKER_ID:
            memberState.speakerId = action.user_uuid;
            return {
                ...memberState,
            };
        case REMOVE_MEMBERS:
            return {
                ...state,
                pinIds: DEFAULT_STATE.pinIds,
                members: DEFAULT_STATE.members,
                memberIds: DEFAULT_STATE.memberIds,
                bridgeList: DEFAULT_STATE.bridgeList
            };
    }
    return state;
});
// declare var APP: any;
// StateListenerRegistry.register((state: any) => getLocalGroup(state),
//     throttle((result, store) => {
//         const isJoined = isRoomJoined(store.getState);
//         if (!isJoined) return;
//         if (result.group_id && result.start === undefined) return;
//         const active_group = getActiveGroupId(store.getState);
//         const { group_id, group_role, start, role } = result;
//         if (role === ROLE.HOST) return;
//         if (start) {
//             if (group_id ) {
//                 if (active_group === group_id) store.dispatch(toggleLoad(false));
//                 else  APP.management.changeManagement(active_group, {group_id, group_role})
//             }
//         } else {
//             if (active_group !== '') {
//                 APP.management.changeManagement(group_id, {group_id: ''});
//             }
//         }
//     }, 1000, { leading: false, trailing: true }), {
//         deepEquals: true
//     }
// )
