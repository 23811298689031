import throttle from 'lodash/throttle';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAssignmentList, useGradesList } from './querys';
import { ErrataTable } from './ErrataTable';
const count = 20;
export const DSTL001_Submit_View = React.memo(({ hideTitle = false, test_id, member }) => {
    const { t } = useTranslation();
    const [testInfo, setTestInfo] = useState();
    const [score, setScore] = useState(0); // 총점 조회
    const { member_id, member_name } = useMemo(() => {
        return { member_id: member?.id || '', member_name: member?.name };
    }, [member]);
    // 과제 정보 조회
    const { data, refetch } = useAssignmentList({ id: test_id });
    // 성적 정보 조회
    const { data: gradesData, refetch: gradesRefetch } = useGradesList({
        test_id,
        member_id,
        orderby: ["IF(subject_item='공통',0,1)", 'subject_item'],
    });
    const refetchList = throttle(() => {
        if (!test_id)
            return;
        refetch();
    }, 1000, { leading: true, trailing: false });
    const refetchGradesList = throttle(() => {
        if (!test_id)
            return;
        gradesRefetch();
    }, 1000, { leading: true, trailing: false });
    useEffect(() => {
        if (!hideTitle)
            refetchList();
    }, [hideTitle, test_id, member_id]);
    useEffect(() => {
        refetchGradesList();
    }, [test_id, member_id]);
    useEffect(() => {
        if (!data)
            return;
        const { total_count, items } = data;
        if (total_count >= 1) {
            setTestInfo(items[0]);
        }
    }, [data]);
    useEffect(() => {
        if (!gradesData)
            return;
        const { total_count, items } = gradesData;
        if (total_count >= 1) {
            const score = items.reduce((acc, cur) => {
                if (cur.subject_item !== '') {
                    return acc + cur.score;
                }
                return acc;
            }, 0);
            setScore(score);
        }
        else {
            setScore(0);
        }
    }, [gradesData]);
    const test_type = useMemo(() => testInfo
        ? testInfo.type +
            `${testInfo.type === 'OMR' && testInfo.subject !== ''
                ? ' - ' + t(`assignment.${testInfo.subject}`)
                : ''}`
        : '', [testInfo]);
    if (!testInfo)
        return React.createElement("div", { className: "dstl001-submit-view" }, t('assignment.noAssignment'));
    return (React.createElement("div", { className: `${hideTitle ? 'hidetitle' : ''} dstl001-submit-view` },
        React.createElement("div", { className: "score-title" },
            hideTitle && !member_id ? t('assignment.scoreStatus2') : t('assignment.scoreStatus', { score }),
            member_name ? `- ${member_name}` : ''),
        hideTitle && !member_id ? (React.createElement("div", { className: "no_submit_wrap" }, t('assignment.noSubmit'))) : (React.createElement("div", { className: "errataTable_wrap" },
            React.createElement(ErrataTable, { subject_name: testInfo.subject, isDetail: member_id ? true : false, test_id: test_id, member_id: member_id })))));
});
