import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { getScreenShareTrackId } from '../../screen-share';
import { getTracksByTrackIdAndMediaType } from '../../base/tracks';
import { MEDIA_TYPE } from '../../base/media';
import { MemberVideo } from './layout';
import { FullScreen, useFullScreenHandle } from 'react-full-screen';
const ScreenVideo = React.memo(() => {
    const handler = useFullScreenHandle();
    const screenTrack = useSelector((state) => {
        const screenJitsiId = getScreenShareTrackId(state);
        return getTracksByTrackIdAndMediaType(state, screenJitsiId, MEDIA_TYPE.SCREENSHARE);
    }, shallowEqual);
    return (React.createElement(FullScreen, { handle: handler },
        React.createElement(MemberVideo, { videoTrack: screenTrack, isFullScreen: true, nickname: 'screen', handlerFullScreen: handler.enter, handlerExitFullScreen: handler.exit })));
});
export default ScreenVideo;
