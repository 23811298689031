import React, { useEffect, useRef, useState } from 'react';
import { JitsiTrackEvents } from '../../../lib-jitsi-meet';
import FullScreenBtn from './FullScreenBtn';
function getRandomIntervalInMs() {
    // 3분에서 5분 사이의 밀리초 범위
    const minIntervalMs = 2 * 60 * 1000; // 3분 = 180초 = 180,000밀리초
    const maxIntervalMs = 4 * 60 * 1000; // 5분 = 300초 = 300,000밀리초
    // minIntervalMs와 maxIntervalMs 사이의 랜덤한 밀리초를 생성
    const randomMs = Math.floor(Math.random() * (maxIntervalMs - minIntervalMs + 1)) + minIntervalMs;
    return randomMs;
}
// let watermarkPos: any = null;
// let showWaterMark: boolean = false;
// const Video = React.memo((props: Props) => {
// 	const fullscreenElement = useRef<HTMLDivElement>(null);
// 	const canvasElement = useRef<HTMLCanvasElement>(null);
// 	const [videoElement, setVideoElement] = useState<HTMLVideoElement | null>(null);
// 	const [canvasStyle, setCanvasStyle] = useState<{ width: number; height: number }>({ width: 0, height: 0 });
// 	// const [watermarkPos, setWatermarkPos] = useState<{ x: number; y: number }>({ x: 0, y: 0 });
// 	// const [showWatermark, setShowWatermark] = useState<boolean>(false);
// 	const [timeIntervalId, setTimeIntervalId] = useState<any>(null);
// 	const onVideoPlaying = () => {
// 		videoElement && videoElement.play().catch(err => {});
// 	};
// 	const renderCanvas = () => {
// 		if (canvasElement.current && videoElement) {
// 			const canvasWidth = canvasStyle.width;
// 			const canvasHeight = canvasStyle.height;
// 			const videoWidth = videoElement.videoWidth;
// 			const videoHeight = videoElement.videoHeight;
// 			const scale = Math.min(canvasWidth / videoWidth, canvasHeight / videoHeight);
// 			const x = canvasWidth / 2 - (videoWidth / 2) * scale;
// 			const y = canvasHeight / 2 - (videoHeight / 2) * scale;
// 			const ctx = canvasElement.current.getContext('2d');
// 			if (ctx) {
// 				ctx.clearRect(0, 0, canvasElement.current.width, canvasElement.current.height);
// 				// Draw the video frame
// 				ctx.drawImage(videoElement, x, y, videoWidth * scale, videoHeight * scale);
// 				// ctx.scale(devicePixelRatio, devicePixelRatio);
// 				// Draw the watermark if showWatermark is true
// 				if (showWaterMark && props.showWaterMark) {
// 					const watermarkText = props.nickname || '대성마이맥';
// 					ctx.font = '25px Arial';
// 					ctx.fillStyle = 'rgba(255, 255, 255, 0.5)';
// 					ctx.fillText(watermarkText, watermarkPos.x, watermarkPos.y);
// 				}
// 				videoElement.requestVideoFrameCallback(renderCanvas);
// 			}
// 		}
// 	};
// 	const drawCanvas = () => {
// 		if (canvasElement && canvasElement.current) {
// 			const ctx = canvasElement.current.getContext('2d');
// 			const fullElement = fullscreenElement.current;
// 			if (ctx && fullElement && videoElement) {
// 				ctx.drawImage(videoElement, 0, 0, fullElement.clientWidth, fullElement.clientHeight);
// 			}
// 		}
// 	};
// 	const _attachTrack = (videoTrack: any) => {
// 		if (!videoElement) return;
// 		if (!videoTrack || !videoTrack.track) {
// 			return;
// 		}
// 		videoTrack.track.attach(videoElement);
// 		videoTrack.track.on(JitsiTrackEvents.LOCAL_TRACK_STOPPED, drawCanvas);
// 		videoElement.play().catch(err => {});
// 		// videoElement.requestVideoFrameCallback(() => renderCanvas(showWatermark, watermarkPos));
// 	};
// 	const _detachTrack = (videoTrack: any) => {
// 		if (!videoElement) return;
// 		if (!videoTrack || !videoTrack.track) {
// 			return;
// 		}
// 		videoTrack.track.detach(videoElement);
// 		videoTrack.track.off(JitsiTrackEvents.LOCAL_TRACK_STOPPED, drawCanvas);
// 	};
// 	useEffect(() => {
// 		const videoElement = document.createElement('video');
// 		if (videoElement) {
// 			videoElement.volume = 0;
// 			videoElement.autoplay = true;
// 			videoElement.muted = true;
// 			videoElement.playsInline = true;
// 			videoElement.style.objectFit = 'contain';
// 			videoElement.onplaying = () => props.onVideoPlaying && props.onVideoPlaying();
// 		}
// 		setVideoElement(videoElement);
// 		const resizeObserver = new ResizeObserver(entries => {
// 			for (let entry of entries) {
// 				const { width, height } = entry.contentRect;
// 				if (!width && !height) return;
// 				setCanvasStyle({ width, height });
// 			}
// 		});
// 		if (fullscreenElement.current) {
// 			resizeObserver.observe(fullscreenElement.current);
// 		}
// 		return () => {
// 			if (resizeObserver && fullscreenElement.current) {
// 				resizeObserver.unobserve(fullscreenElement.current);
// 			}
// 		};
// 	}, []);
// 	useEffect(() => {
// 		const test =
// 			props.nickname &&
// 			props.showWaterMark &&
// 			setInterval(() => {
// 				if (canvasElement.current) {
// 					const ctx = canvasElement.current.getContext('2d');
// 					if (ctx) {
// 						const textWidth = ctx.measureText(props.nickname || '').width * 2;
// 						const textHeight = 25;
// 						showWaterMark = true;
// 						const canvas = canvasElement.current;
// 						const x = Math.max(
// 							Math.min(Math.random() * (canvas.width - textWidth), canvas.width - textWidth - 25),
// 							25,
// 						);
// 						const y = Math.max(
// 							Math.min(Math.random() * (canvas.height - textHeight), canvas.height - textHeight - 25),
// 							25,
// 						);
// 						watermarkPos = { x, y };
// 					}
// 				}
// 				setTimeout(() => {
// 					// setShowWatermark(false);
// 					showWaterMark = false;
// 				}, 1000);
// 			}, getRandomIntervalInMs());
// 		setTimeIntervalId(test);
// 		return () => {
// 			timeIntervalId && clearInterval(timeIntervalId);
// 		};
// 	}, [props.nickname, props.showWaterMark]);
// 	console.log('test');
// 	useEffect(() => {
// 		_attachTrack(props.videoTrack);
// 		return () => {
// 			_detachTrack(props.videoTrack);
// 			showWaterMark = false;
// 			watermarkPos = { x: 0, y: 0 };
// 		};
// 	}, [props.videoTrack, videoElement]);
// 	useEffect(() => {
// 		const devicePixelRatio = window.devicePixelRatio || 1;
// 		if (canvasElement && canvasElement.current) {
// 			canvasElement.current.width = canvasStyle.width;
// 			canvasElement.current.height = canvasStyle.height;
// 			canvasElement.current.getContext('2d')?.scale(devicePixelRatio, devicePixelRatio);
// 			canvasElement.current.getContext('2d')?.setTransform(1, 0, 0, 1, 0, 0);
// 			videoElement && videoElement.requestVideoFrameCallback(renderCanvas);
// 		}
// 	}, [canvasStyle]);
// 	return (
// 		<div ref={fullscreenElement} style={{ position: 'relative', width: '100%', height: '100%' }}>
// 			<canvas ref={canvasElement} className={`canvas_${props.id}`} {...canvasStyle} />
// 		</div>
// 	);
// });
let timeIntervalId = null;
const Video = React.memo((props) => {
    const fullscreenElement = useRef(null);
    const videoElement = useRef(null);
    const [videoStyle, setVideoStyle] = useState({ width: 0, height: 0 });
    const onVideoPlaying = () => {
        if (videoElement?.current)
            videoElement?.current.play().catch(err => { });
    };
    const _attachTrack = (videoTrack) => {
        if (!videoElement?.current)
            return;
        if (!videoTrack || !videoTrack.track) {
            return;
        }
        videoTrack.track.attach(videoElement?.current);
        onVideoPlaying();
    };
    const _detachTrack = (videoTrack) => {
        if (!videoElement?.current)
            return;
        if (!videoTrack || !videoTrack.track) {
            return;
        }
        videoTrack.track.detach(videoElement?.current);
    };
    const rePlayVideo = () => {
        if (videoElement?.current) {
            videoElement?.current.play().catch(err => { });
        }
    };
    useEffect(() => {
        const visibilitychange = () => {
            if (document.hidden) {
                videoElement?.current && videoElement?.current?.play();
            }
            else {
                rePlayVideo();
            }
        };
        document.addEventListener('visibilitychange', visibilitychange);
        const resizeObserver = new ResizeObserver(entries => {
            for (let entry of entries) {
                const { width, height } = entry.contentRect;
                if (!width && !height)
                    return;
                setVideoStyle({ width, height });
            }
        });
        if (videoElement?.current) {
            resizeObserver.observe(videoElement?.current);
        }
        return () => {
            if (resizeObserver && videoElement?.current) {
                resizeObserver.unobserve(videoElement?.current);
            }
            document.removeEventListener('visibilitychange', visibilitychange);
        };
    }, []);
    useEffect(() => {
        const element = videoElement.current;
        if (element) {
            element.volume = 0;
            element.autoplay = true;
            element.playsInline = true;
        }
        _attachTrack(props.videoTrack);
        // 만약 비디오 트랙이 멈췄을때 재 시작 할수 있도록
        // @ts-ignore
        props.videoTrack && props.videoTrack?.track.on(JitsiTrackEvents.LOCAL_TRACK_STOPPED, rePlayVideo);
        return () => {
            _detachTrack(props.videoTrack);
            // @ts-ignore
            props.videoTrack && props.videoTrack?.track.off(JitsiTrackEvents.LOCAL_TRACK_STOPPED, rePlayVideo);
        };
    }, [props.videoTrack]);
    useEffect(() => {
        timeIntervalId =
            props.nickname &&
                props.showWaterMark &&
                setInterval(() => {
                    if (!videoElement.current)
                        return;
                    const { offsetWidth: width, offsetHeight: height } = videoElement.current;
                    // Create and style the canvas element
                    const canvasElement = document.createElement('canvas');
                    canvasElement.style.position = 'absolute';
                    canvasElement.style.top = '0';
                    canvasElement.style.left = '0';
                    canvasElement.style.width = '100%';
                    canvasElement.style.height = '100%';
                    canvasElement.width = width;
                    canvasElement.height = height;
                    const ctx = canvasElement.getContext('2d');
                    const fontSize = 25;
                    if (ctx) {
                        const devicePixelRatio = window.devicePixelRatio || 1;
                        ctx.scale(devicePixelRatio, devicePixelRatio);
                        ctx.setTransform(1, 0, 0, 1, 0, 0);
                        const watermarkText = props.nickname || '대성마이맥';
                        ctx.font = `${fontSize}px Arial`;
                        ctx.fillStyle = 'rgba(255, 255, 255, 0.5)';
                        // Calculate text width and height
                        const textWidth = ctx.measureText(watermarkText).width * 2;
                        const textHeight = 25;
                        // Determine random position for the watermark
                        const x = Math.max(Math.min(Math.random() * (width - textWidth), width - textWidth - 25), 25);
                        const y = Math.max(Math.min(Math.random() * (height - textHeight), height - textHeight - 25), 25);
                        // Draw the watermark
                        ctx.fillText(watermarkText, x, y);
                        if (fullscreenElement.current) {
                            fullscreenElement.current.appendChild(canvasElement);
                        }
                    }
                    // Remove the canvas after 1 second
                    setTimeout(() => {
                        if (fullscreenElement.current && canvasElement) {
                            fullscreenElement.current.removeChild(canvasElement);
                        }
                    }, 1000);
                }, getRandomIntervalInMs());
        // getRandomIntervalInMs());
        return () => {
            timeIntervalId && clearInterval(timeIntervalId);
        };
    }, [props.nickname, props.showWaterMark]);
    return (React.createElement("div", { ref: fullscreenElement, style: { position: 'relative', width: '100%', height: '100%' } },
        React.createElement("video", { className: props.className, ref: videoElement, loop: true, playsInline: true, style: { position: 'relative', width: '100%', height: '100%', objectFit: 'contain' }, controls: false }),
        props.isFullScreen && (React.createElement(FullScreenBtn, { className: "video_right", video: videoElement.current, handlerFullScreen: props.handlerFullScreen, handlerExitFullScreen: props.handlerExitFullScreen }))));
});
export default Video;
