import { createGlobalStyle, css } from 'styled-components';
import { colorBox } from '../../../../css/theme';
export const InitStyled = createGlobalStyle `
    .buttons {
        display: inline-flex;
        gap: 12px;
    }


.hide-navigation {
	.header,
	.footer {
		display: none!important;
	}
}

    .center {
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
    }

    .overText {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .overText5 {
        display: -webkit-box;
        -webkit-line-clamp: 5;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        word-break: keep-all;
        overflow-wrap: break-word;
        white-space: break-spaces;
    }

    .overText2 {
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        word-break: keep-all;
        overflow-wrap: break-word;
        white-space: break-spaces;
    }

    .nonSelect {
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }

    .room_label_icon {
        &:not(.no-color) {
            ${() => colorBox(APP.config.style.iconButton)};
        }

        &.chat {
            &.isFocus, &-on, &:hover:not(.no-hover) {
                ${props => props.isDesktop && colorBox(APP.config.style.iconHoverButton, true)};
            } 
        }

        &.isFocus, &-on, &:hover:not(.no-hover) {
            ${props => props.isDesktop && colorBox(APP.config.style.iconHoverButton, true)};
=        }
        
    }

    .option_item {
        ${() => colorBox(APP.config.style.optionButton)};
        &:hover:not(.no-hover), &.isFocus {
            ${() => colorBox(APP.config.style.iconHoverButton)};
        }
    }

    .switch {
        position: relative;
        display: inline-block;
        margin-right: 10px;
        flex: none;

        input {
            opacity: 0;
            width: 0;
            height: 0;

            &:checked + .slider {
                background-color: ${() => APP.config.style.focusColor};
            }

            &:focus + .slider {
                box-shadow: 0 0 1px #2196F3;
            }            
        }
    }

    .wrap_center {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 24px;
    }

    .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #ccc;
        transition: .4s;
        border-radius: 34px;
        color: #fff;

        &:before {
            position: absolute;
            content: "";

            background-color: white;
            transition: .4s;
            border-radius: 50%;
        }
        
        .round {
            border-radius: 34px;

            &:before {
                border-radius: 50%;
            }
        }

        .text {
            text-align: center;
            width: 100%;
            height: 100%;
            line-height: 20px;
        }
    }

    .text-button {
        flex-shrink: 0;
        border-radius: 10px;
        background: rgb(139, 172, 255);
        text-align: center;
        display: flex;
        -webkit-box-align: center;
        align-items: center;
        padding: 16px 26px;
        box-sizing: border-box;
        -webkit-box-pack: center;
        justify-content: center;
        color: rgb(255, 255, 255);
        max-width: 100%;
    
        &.isSideBar {
            background: rgb(255, 255, 255);
        }
        &.isFocus {
            background: #618af1;
        }
    }

    &.canvas { 
        ${() => APP.config.name === 'class'
    ? css `
						svg {
							width: 44px;
							height: 36px;
						}
					`
    : css `
						width: 43px;
						height: 43px;
						display: flex;
						justify-content: center;
						align-items: center;
					`}

        &:not(.no-color) {
            ${() => colorBox(APP.config.style.canvasButton)};
            
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 10px auto;
        }       

        &:hover:not(.no-hover), &-on {
            ${() => colorBox(APP.config.style.canvasHoverButton)};
            border-radius: 4px;
        }
    }

    .click_button {
        &:not(.room_label_icon)
        :not(.canvas)
        :not(.enter-icon)
        :not(.no-color)
        :not(.sub_accout)
        :not(.text-button) {
            ${() => colorBox(APP.config.style.iconButton)};   
            
            &.isFocus, &-on, &:hover:not(.no-hover) {
                ${() => colorBox(APP.config.style.iconHoverButton)};
    
                svg {
                    -webkit-transition: all 0.2s; /* Safari */
                    transition: all 0.2s;
                }
            }
        }        

        &:not(.no-hover) {
            cursor: pointer;
        }       

        &.media-button {
            ${() => colorBox(APP.config.style.optionButton)};
            display: inline-flex;
            gap: 5px;
            align-items: center;
            margin: 0;

            &:hover:not(&.no-hover), &.isFocus {
                ${() => colorBox(APP.config.style.optionHoverButton)};
            }
        }

        
    }
`;
