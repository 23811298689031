import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { OnOffSwitch } from '../ui';
import { ROLE, checkRoleLevel, getLocalMemberRole } from '../members';
import { useSelector } from 'react-redux';
const ChatLockFile = React.memo(({ isChatLock, handlerUpdatePolicy, }) => {
    const { t } = useTranslation();
    const local_role = useSelector((state) => getLocalMemberRole(state));
    const isRole = useMemo(() => checkRoleLevel(local_role, ROLE.MANAGER), [local_role]);
    return (React.createElement(OnOffSwitch, { className: "label_option", guid: t('dialog.chatLockGuid'), label: isChatLock ? t('dialog.chatLockOn') : t('dialog.chatLockOff'), isShow: isChatLock, size: 20, handlerClick: () => isRole && handlerUpdatePolicy({ file_option: !isChatLock ? 'deny' : 'allow' }) }));
});
export default ChatLockFile;
