import React from 'react';
import { useTranslation } from 'react-i18next';
import { PopupStyled } from './styles.web';
const PopupContainer = React.memo(({ title, guid, children }) => {
    const { t } = useTranslation();
    const onClose = () => {
        APP.eventManager.publish('UPDATE_OPEN_CHAT_POPUP');
    };
    return (React.createElement(PopupStyled, null,
        React.createElement("div", { className: "popup" }),
        React.createElement("div", { className: "popup_content" },
            React.createElement("div", { className: "content wrap_center" },
                React.createElement("div", { className: "header" },
                    title && React.createElement("div", { className: "title" },
                        title,
                        " "),
                    React.createElement("span", { className: "close", onClick: onClose },
                        ' ',
                        t('dialog.close'),
                        ' ')),
                React.createElement("div", { className: "guid" },
                    guid,
                    " "),
                children))));
});
export default PopupContainer;
