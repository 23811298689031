import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import MemberItemContainer from './MemberItemContainer';
import { MemberList } from './components';
import { getMemberList } from './functions';
const MemberListContainer = React.memo(({ isMonitoring, search }) => {
    const list = useSelector((state) => getMemberList(state, {}, true)?.map(i => i.user_uuid), shallowEqual);
    const [displayedUsers, setDisplayedUsers] = useState([]);
    const [hasMore, setHasMore] = useState(true);
    const observer = useRef(null);
    useEffect(() => {
        setDisplayedUsers(list.slice(0, 30));
        setHasMore(list.length > 30);
    }, [list]);
    const lastUserRef = useCallback((node) => {
        if (observer.current)
            observer.current.disconnect();
        observer.current = new IntersectionObserver(entries => {
            if (entries[0].isIntersecting && hasMore) {
                setDisplayedUsers(prevDisplayedUsers => {
                    const newLength = prevDisplayedUsers.length + 30;
                    if (newLength >= list.length) {
                        setHasMore(false);
                        return list;
                    }
                    return list.slice(0, newLength);
                });
            }
        });
        if (node)
            observer.current.observe(node);
    }, [hasMore, list]);
    // 최적화: list가 변경될 때만 새로 랜더링
    const renderedList = useMemo(() => displayedUsers.map((user_id, index) => user_id ? (React.createElement(MemberItemContainer, { key: user_id, search: search, user_id: user_id, isMonitoring: isMonitoring, ref: index === displayedUsers.length - 1 ? lastUserRef : null })) : null), [displayedUsers, search, isMonitoring, lastUserRef, list]);
    return React.createElement(MemberList, null, renderedList);
});
export default MemberListContainer;
