import { isRoomJoined } from '../../room';
import { SET_DUAL_MONITOR } from '../../video-layout/actionTypes';
import { MEDIA_TYPE } from '../media';
import { MiddlewareRegistry } from '../redux';
import { toggleChat, toggleMember } from '../sidebar';
import { isLocalTrackMutedByMediaType } from '../tracks';
import { SETTINGS_UPDATED } from './actionTypes';
import { updateSettings } from './actions';
import './middleware.any';
MiddlewareRegistry.register(store => next => async (action) => {
    switch (action.type) {
        case SET_DUAL_MONITOR:
            if (action.isDualMonitor) {
                store.dispatch(toggleChat(true));
                store.dispatch(toggleMember(true));
                store.dispatch(updateSettings({ dualSideOpen: true }));
            }
            else {
                store.dispatch(updateSettings({ dualSideOpen: false }));
            }
            break;
        case SETTINGS_UPDATED:
            if (action.settings.userSelectedAudioOutputDeviceId) {
                APP.management.setAudioOutputDevice(action.settings.userSelectedAudioOutputDeviceId).catch(err => { });
            }
            if (isRoomJoined(store.getState())) {
                if (action.settings.userSelectedMicDeviceId) {
                    APP.management.updateTrack(action.settings.userSelectedMicDeviceId, MEDIA_TYPE.AUDIO, {
                        muted: action?.settings?.userSelectedAudioMuted ||
                            isLocalTrackMutedByMediaType(store.getState, MEDIA_TYPE.AUDIO),
                    });
                }
                if (action.settings.userSelectedCameraDeviceId) {
                    APP.management.updateTrack(action.settings.userSelectedCameraDeviceId, MEDIA_TYPE.VIDEO, {
                        muted: action?.settings?.userSelectedCameraMuted ||
                            isLocalTrackMutedByMediaType(store.getState, MEDIA_TYPE.VIDEO),
                    });
                }
            }
            break;
    }
    return next(action);
});
