import styled from 'styled-components';
// @ts-ignore
export const DialogStyled = styled.div `
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	color: #161c1f;
	font-family: Pretendard;
	font-size: 16px;

	&::before {
		content: '';
		background: #000;
		opacity: 0.5;
		display: block;
		position: absolute;
		width: 100%;
		height: 100%;
		z-index: 2;
	}

	.dialog_wrapper {
		position: relative;
		width: 100%;
		height: 100%;
		z-index: 2;
		display: flex;
		justify-content: center;
		align-items: center;

		.dialog_content {
			position: relative;
			max-width: 90%;
			max-height: 90%;
			overflow: hidden;
			padding: 2rem;
			box-sizing: border-box;
			border-radius: 20px;
			background: #fff;
			box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
			display: flex;
			flex-direction: column;
		}

		.dialog_header {
			display: flex;
			justify-content: center;
			border-bottom: 1px solid #e4e4e5;
			padding-bottom: 20px;
			margin: 0;
			min-width: 350px;

			.title {
				color: #000;
				font-family: Pretendard;
				font-size: 26px;
				font-style: normal;
				font-weight: 500;
				letter-spacing: -0.52px;
				flex: 1;
				text-align: center;
			}

			.underbar {
				width: 20px;
				background: #c7c7c7;
				height: 5px;
				border-radius: 9px;
			}

			.close {
				position: absolute;
				right: 1.2rem;
				top: 1.2rem;
				display: flex;
				gap: 8px;
				-webkit-align-items: flex-end;
				-webkit-box-align: flex-end;
				-ms-flex-align: flex-end;
				align-items: flex-start;
				-webkit-box-pack: end;
				-webkit-justify-content: flex-end;
				-ms-flex-pack: end;
				justify-content: space-around;
				z-index: 4;
			}
		}

		.dialog_body {
			padding: 12px;
			box-sizing: border-box;
			display: flex;
			justify-content: center;
			align-items: flex-start;
			width: 100%;
			height: 100%;
			overflow: auto;

			> * {
				margin: 0;
				width: 100%;
			}
		}
	}

	.buttons {
		width: 100%;
		.text-button {
			flex: 1;
		}
	}

	.text-button {
		width: 220px;
	}

	.btn_bundle {
		.text-button {
			width: auto;
		}
	}
`;
