import { throttle } from 'lodash';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { MockTestWebsocket } from '../../modules/server/MockTestWebsocket';
import { useGlobalState } from '../app/GlobalStateProvider';
import { Button } from '../base/common';
import { checkOccupiedUser } from '../base/local';
import { RemaingTimer } from '../base/question/RemaingTimer';
import { useAssignmentList } from '../base/question/querys';
import { updateTestStart } from '../base/settings';
import { Icon } from '../base/ui';
import { getRoomOption } from '../room';
// @ts-ignore
const TestModeStyled = styled.div `
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	position: relative;

	.device-container {
		display: flex;
		flex-direction: column;
		.device_wrapper {
			width: 100%;
			height: 100%;
			flex: 1;
		}
		.overText2 {
			white-space: break-spaces;
		}

		.device_title_container {
			width: 100%;
			height: 100%;

			.device_container,
			.device_guid {
				width: ${props => (!props.isDesktop ? '100%' : '402px')};
				overflow: hidden;
			}
		}
	}

	.device_btn > p {
		color: #fff;
		text-align: left;
		font-family: Pretendard;
		font-size: 24px;
		font-style: normal;
		font-weight: 700;
		letter-spacing: -0.4px;
		line-height: 40px;
	}
	.red_time {
		position: absolute;
		bottom: 20px;
		left: 50%;
		transform: translate(-50%, 0);
		.text-field {
			font-size: 60px;
			text-align: center;

			padding: 20px;
			box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5);
			box-sizing: border-box;
			background-color: red;
			border-radius: 20px;

			color: #fff;
		}
	}

	.test-mode-head {
		display: flex;
		padding: 20px;
		box-sizing: border-box;
		width: 100%;
		background-color: #000;
	}

	img {
		width: 100%;
		height: 100%;
		overflow: hidden;
	}
`;
export const OnlyTestMode = React.memo(({ setTestEnter }) => {
    const { isDesktop } = useGlobalState();
    const { t } = useTranslation();
    const conference_id = useSelector((state) => getRoomOption(state)?.uuid);
    const websocketRef = React.useRef();
    const [backgroundUrl, setBackGroundUrl] = React.useState('');
    const [join, setJoin] = React.useState(false);
    const [testId, setTestId] = React.useState('');
    const [item, setItem] = React.useState();
    const { data, refetch } = useAssignmentList({
        conference_id,
    });
    const refetchList = throttle(() => refetch(), 1000, { trailing: false, leading: true });
    const onQuitTest = async () => {
        if (websocketRef.current) {
            websocketRef.current.disconnect();
            websocketRef.current = null;
        }
        setJoin(false);
        setTestEnter(false);
        refetchList();
        window.close();
    };
    const onEnterTest = async () => {
        if (!item) {
            alert('모의고사가 없습니다.');
            onQuitTest();
            return;
        }
        const searchResponse = await APP.API.searchRoom({ uuid: conference_id }, false, true).then(async (response) => {
            if (response.complete) {
                const { items } = response.data;
                if (items && items.length > 0) {
                    const item = items[0];
                    if (item.status === 'closed') {
                        return true;
                    }
                    return false;
                }
                else {
                    return true;
                }
            }
            else {
                console.log(response.data);
                return false;
            }
        });
        if (searchResponse === false) {
            alert('이미 종료된 모의고사입니다.');
            onQuitTest();
            return false;
        }
        if (websocketRef.current) {
            setTestEnter(false);
            websocketRef.current?.disconnect();
            websocketRef.current = null;
            return;
        }
        const websocket = new MockTestWebsocket();
        websocketRef.current = websocket;
        checkOccupiedUser().then(res => {
            // if (!res.complete && res.data === 'dup') {
            // 	alert('이미 모의고사를 진행중인 사용자가 있습니다.');
            // 	return;
            // }
            websocket
                .connect(handlerMessage)
                .then(() => {
                // dispatch(setRoomJoined(true));
                setTestEnter(true);
            })
                .catch(err => {
                console.log(err);
                setTestEnter(false);
                websocketRef.current.disconnect();
                websocketRef.current = null;
            });
        });
    };
    const handlerQuit = () => {
        onQuitTest();
        alert('강의가 종료되었습니다.');
    };
    useEffect(() => {
        window.addEventListener('beforeunload', onQuitTest);
        APP.eventManager.subscribe('TEST_CLOSE', onQuitTest);
        return () => {
            window.removeEventListener('beforeunload', onQuitTest);
            APP.eventManager.unsubscribe('TEST_CLOSE', onQuitTest);
            onQuitTest();
        };
    }, []);
    useEffect(() => {
        const getLoadTest = async () => {
            // 마이맥 api
            const conferenceBackAPI = `/api/mimac/v1/conferences?id=${conference_id}&with_more_info=true`;
            const testResponse = await fetch(conferenceBackAPI)
                .then(res => res.json())
                .then(res => {
                return res.result;
            });
            if (testResponse &&
                testResponse.items.length > 0 &&
                testResponse.items[0].type &&
                testResponse.items[0].type === 'mock_test') {
                setBackGroundUrl(testResponse.items[0].background);
            }
        };
        if (conference_id) {
            getLoadTest();
        }
        setTestEnter(false);
        refetchList();
    }, [conference_id]);
    useEffect(() => {
        if (data && data.items.length > 0) {
            const test = data.items[0];
            setItem(test);
            setTestId(test.id);
            return;
        }
        setBackGroundUrl('');
        setItem(undefined);
    }, [data]);
    const handlerMessage = (data) => {
        switch (data.class) {
            case 'Room.Member.Session.Add':
                data.status === 200 && setJoin(true);
                break;
            case 'Meeting.Meeting.Closed':
            case 'MIMAC.Conference.Finished':
                handlerQuit();
                break;
            // case 'Room.Member.Session.Remove':
            // 	if (data.status === 200) {
            // 		setJoin(false);
            // 		setTestEnter(false);
            // 	}
            // 	break;
            case 'Event.Room.Member.Kicked':
                APP.store.dispatch(updateTestStart(false));
                onQuitTest();
                alert('같은 아이디로 접속되어 퇴장 되었습니다 아이디를 확인해 주세요');
                break;
            case 'Meeting.Meeting.Signaled':
                const { payload } = data.payload;
                refetchList();
                APP.eventManager.publish('UPDATE_ASSIGNMENT');
                if (payload.class === 'MIMAC.Test.Started') {
                    APP.store.dispatch(updateTestStart(true));
                }
                if (payload.class === 'MIMAC.Test.Finished') {
                    APP.eventManager.publish('UPDATE_ASSIGNMENT');
                }
        }
    };
    return (React.createElement(TestModeStyled, { className: "test_layout", isDesktop: isDesktop }, join ? (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "red_time" }, item?.status === 'open' ? (React.createElement(RemaingTimer, { test_id: item.id })) : (React.createElement("div", { className: "text-field", style: { margin: 0 } }, "00:00:00"))),
        React.createElement("img", { src: backgroundUrl, alt: "mock_test background" }),
        ' ')) : (React.createElement("div", { className: `device-container ` },
        React.createElement("div", { className: "device_wrapper" },
            React.createElement("div", { className: "device_title_container" },
                React.createElement("div", { className: "device_container" },
                    React.createElement(React.Fragment, null,
                        React.createElement(Icon, { icon: "device_connect", size: isDesktop ? 68 : 48, color: "#B9D1EF" }),
                        React.createElement("p", { className: "device_title" }, t('deviceSelection.testMode')),
                        React.createElement("p", { className: "device_guid overText2" }, t('deviceSelection.testModeGuid'))),
                    React.createElement(Button, { className: "device_btn", onClick: onEnterTest, text: 'deviceSelection.enterTestMode', option: {
                            buttonBeforeIcon: {
                                show: true,
                                name: 'nickname_enter',
                                size: 24,
                                color: '#FFF',
                            },
                        } }))))))));
});
