import React, { useMemo } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { getOccupiedRemoteBridgeId } from '../base/members';
import { getTracksByTrackIdAndMediaType } from '../base/tracks';
import AudioTrack from '../base/media/components/web/AudioTrack';
import { MEDIA_TYPE } from '../base/media';
import { getLocalSharingStatus, getScreenShareTrackId } from '../screen-share';
const AudioContainer = React.memo(() => {
    const memberIds = useSelector((state) => getOccupiedRemoteBridgeId(state, true), shallowEqual);
    const screenId = useSelector((state) => (getLocalSharingStatus(state) ? false : getScreenShareTrackId(state)), shallowEqual);
    const list = useMemo(() => [...memberIds, screenId], [memberIds, screenId]);
    return React.createElement("div", null, list && list.map((id) => (id ? React.createElement(MemberAudio, { key: id, trackId: id }) : null)));
});
const MemberAudio = React.memo(({ trackId }) => {
    const audioTrack = useSelector((state) => getTracksByTrackIdAndMediaType(state, trackId, MEDIA_TYPE.AUDIO), shallowEqual);
    return audioTrack && React.createElement(AudioTrack, { key: trackId, audioTrack: audioTrack });
});
export default AudioContainer;
