import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getMediaServerInfo, getRoomOption } from '../../room';
import { DocumentStyled } from '../../share-layout/components/styles.web';
import { ROLE, checkRoleLevel, getLocalMemberRole } from '../members';
function CommonNoteLayout({ isMobile, mode, expand, isLeft, renderShareLeft, style, }) {
    const { t } = useTranslation();
    const isShowLvl = useSelector((state) => checkRoleLevel(getLocalMemberRole(state), ROLE.PRESENTER));
    const mediaInfo = useSelector((state) => getMediaServerInfo(state));
    const roomCode = useSelector((state) => getRoomOption(state)?.code);
    const groupId = ''; //useSelector(state => getGroupId(state) || "");
    const serverPath = useMemo(() => {
        let serverPath = 'ep/p';
        if (mediaInfo && mediaInfo.type === 'jitsi' && typeof mediaInfo.url === 'string') {
            const shardName = mediaInfo.url.replaceAll('/xmpp-websocket', '');
            serverPath = `${shardName}/ep/p`;
        }
        return serverPath;
    }, [mediaInfo]);
    return (React.createElement(DocumentStyled, { name: APP.config.name, ...APP.config.ui.document, expand: expand },
        APP.config.option.hideParticipant && isShowLvl && isLeft && (React.createElement("div", { className: `share_list ${!expand && !isMobile && 'non_expand'}` },
            React.createElement("div", { className: "share_header" },
                React.createElement("p", null,
                    t(`share.${mode}`),
                    " ")))),
        renderShareLeft(),
        React.createElement("div", { style: { flex: '1', position: 'relative', background: '#f7f7f7' } },
            React.createElement("iframe", { src: `${window.location.origin}${serverPath}/${roomCode}_${groupId}`, style: style }))));
}
export default CommonNoteLayout;
