import moment from 'moment';
import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { Button } from '../common';
import { getHandlerRequestMember, getMemberByUserID, handlerLiveMember } from '../members';
import { connect } from '../redux';
import { toggleMember } from './actions';
import { SideBarHeader } from './components';
export const HandlerRequestStyled = styled.div `
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;
	overflow: hidden;
	> * {
		margin: 0;
	}

	.handler-btn {
		color: #799bff;
		font-family: 'Pretendard-Bold', sans-serif;
		font-size: 13px;
		font-weight: 700;
		border: 1px solid;
		padding: 8px;
		border-radius: 8px;
		width: 100%;
	}

	.handler-not-border-btn {
		color: #799bff;
		font-family: 'Pretendard-Bold', sans-serif;
		font-size: 13px;
		font-weight: 700;
		border: none;
		padding: 8px;
		border-radius: 8px;
	}

	.handler_wrap {
		display: flex;
		flex-direction: column;
		gap: 10px;
		padding: 8px 12px;
		width: 100%;
		flex: 1;
		overflow: auto;
		box-sizing: border-box;

		.box {
			margin: 0;
			border-radius: 6px;
			padding: 12px;
			display: flex;
			flex-direction: row;
			gap: 24px;
			align-items: center;
			justify-content: flex-start;

			.headline {
				font-size: 16px;
				font-weight: 700;
				line-height: 24px;
				text-overflow: ellipsis;
				overflow: hidden;
			}

			.btn-container {
				margin-right: 0;
			}
			.btn {
				cursor: pointer;
				padding: 6px 20px;

				p {
					min-width: auto;
				}
			}
			.hands-up {
				text-align: left;
				font-family: 'Pretendard-Regular', sans-serif;
				font-size: 12px;
				line-height: 18px;
				letter-spacing: -0.02em;
				font-weight: 400;

				&.accept {
					color: #8bacff;
				}

				&.time {
					color: #969696;
				}
			}
		}
	}
`;
const HandlerRequest = React.memo(({ handlerMember, isMonitoring, hideMember, }) => {
    const dispatch = useDispatch();
    const hideContent = () => dispatch(toggleHideMember(!hideMember));
    // @ts-ignore
    const handlerClose = () => dispatch(toggleMember(false));
    const renderHead = useMemo(() => (React.createElement(SideBarHeader, { name: "handler", isMonitoring: isMonitoring, showBody: !hideMember, hideContent: hideContent, handlerClose: handlerClose })), [isMonitoring, hideMember]);
    // Sort handlerMember to have pinned member at the top
    return (React.createElement(HandlerRequestStyled, null,
        renderHead,
        React.createElement("div", { className: "handler_wrap" }, handlerMember.map(member => member && React.createElement(MebmerItem, { key: member, memberId: member })))));
});
function _mapStateToProps(state) {
    const handlerMember = getHandlerRequestMember(state, false);
    return {
        handlerMember,
    };
}
export default connect(_mapStateToProps)(HandlerRequest);
export const MebmerItem = React.memo(({ memberId }) => {
    const dispatch = useDispatch();
    const member = useSelector((state) => getMemberByUserID(state, memberId));
    if (!member)
        return null;
    return (React.createElement("div", { className: "box", key: member.user_uuid, style: {
            backgroundColor: `${member.pinned ? `#F9FBFF` : '#F8F8F8'}`,
            border: `${member.pinned ? `2px solid #8bacff` : ''}`,
        } },
        React.createElement("div", { style: { marginLeft: 0, width: `200px` } },
            React.createElement("div", { className: "headline" }, member.nickname || '-'),
            React.createElement("div", { className: member.pinned ? 'hands-up accept' : member.hands_up_time ? 'hands-up time' : 'hands-up' }, member.pinned
                ? '현재 손들기 상태입니다'
                : member.hands_up_time
                    ? moment(member.hands_up_time).format('HH:mm')
                    : '요청을 취소함')),
        React.createElement("div", { className: "btn-container" },
            !member.pinned && (React.createElement(Button, { onClick: async () => {
                    dispatch(handlerLiveMember(member.user_uuid, true));
                }, handlerIcon: "accept" })),
            React.createElement(Button, { onClick: async () => {
                    dispatch(handlerLiveMember(member.user_uuid, false));
                }, handlerIcon: "reject" }))));
});
