export default {
	save: (key, value) => {
		try {
			if (key && value) localStorage.setItem(key, JSON.stringify(value));
		} catch (error) {}
	},
	remove: key => {
		localStorage.removeItem(key);
	},
	clear: () => {
		localStorage.clear();
	},
	get: key => {
		try {
			return JSON.parse(localStorage.getItem(key));
		} catch (error) {
			return {};
		}
	},
};
