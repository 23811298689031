import React, { useEffect } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import styled from 'styled-components';
import { RemaingTimer } from '../base/question/RemaingTimer';
import { getTestLayoutOptions } from './functions';
import { getRoomOption } from '../room';
import { useAssignmentList } from '../base/question/querys';
// @ts-ignore
const TestModeStyled = styled.div `
	.text-field {
		font-size: 60px;
		text-align: center;
		position: absolute;
		bottom: 20px;
		left: 50%;
		transform: translate(-50%, 0);
		padding: 20px;
		box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5);
		box-sizing: border-box;
		background-color: red;
		border-radius: 20px;

		color: #fff;
	}

	img {
		width: 100%;
		height: 100%;
		overflow: hidden;
	}
`;
export const TestMode = React.memo(() => {
    const conference_id = useSelector((state) => getRoomOption(state)?.uuid);
    const { backgroundUrl, testId } = useSelector((state) => getTestLayoutOptions(state), shallowEqual);
    const [item, setItem] = React.useState();
    const [doing, setDoing] = React.useState(false);
    const { data, refetch } = useAssignmentList({
        assignment: false,
        conference_id,
        id: testId,
    });
    useEffect(() => {
        APP.eventManager.subscribe('UPDATE_ASSIGNMENT', refetch);
        return () => {
            APP.eventManager.subscribe('UPDATE_ASSIGNMENT', refetch);
        };
    }, []);
    useEffect(() => {
        conference_id && refetch();
    }, [conference_id, testId]);
    useEffect(() => {
        if (data) {
            const { items } = data;
            if (data.items.length > 0) {
                setItem(items[0]);
                setDoing(items.find(i => i.status == 'open') ? true : false);
                return;
            }
        }
        setItem(undefined);
    }, [data]);
    return (React.createElement(TestModeStyled, { className: "test_layout" },
        item?.status === 'open' ? (React.createElement(RemaingTimer, { test_id: testId, testData: item })) : (React.createElement("div", { className: "text-field", style: { margin: 0 } }, "00:00:00")),
        React.createElement("img", { src: backgroundUrl, alt: "mock_test background" })));
});
