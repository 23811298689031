import { ReducerRegistry } from '../redux';
import { LOGOUT, SET_USER_INFO } from './actionTypes';
const DEFAULT_STATE = {
    token: { access_token: null, refresh_token: null, external_token: null },
    user: {
        auth_name: null,
        role: {
            id: undefined,
            name: undefined,
        },
        tenant_id: null,
        user_id: null,
        uuid: null,
    },
    external: undefined,
    externalTeacher: false,
};
ReducerRegistry.register('features/base/local', (state = DEFAULT_STATE, action) => {
    switch (action.type) {
        case LOGOUT:
            return {
                ...DEFAULT_STATE,
            };
        case SET_USER_INFO:
            return {
                ...state,
                user: action.user.user,
                external: action.external?.user,
                token: action.token,
            };
        case 'SET_IT_MIMAC_TEACHER':
            return {
                ...state,
                externalTeacher: action.isTeacher,
            };
    }
    return state;
});
