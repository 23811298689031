import _ from 'lodash';
import { LEAVE_ROOM, SET_ROOM_JOINED } from '../../room';
import { ReducerRegistry } from '../redux';
import { ADD_FILE, ADD_MESSAGE, ADD_MESSAGE_LIST, SET_CHOICE_MEMBER, SET_IS_CHAT_READ } from './actionTypes';
import { getChatSize } from './constants';
const DEFAULT_STATE = {
    messages: new Map(),
    fileList: new Map(),
    choiceMembers: new Map(),
    isRead: true,
    totalCount: 0,
    lastUid: null,
    checkTime: 0,
};
const sortMessagesByTimestamp = (messages) => {
    const sortedMessages = new Map([...messages.entries()].sort((a, b) => a[1].timestamp - b[1].timestamp));
    return sortedMessages;
};
ReducerRegistry.register('features/base/chat', (state = DEFAULT_STATE, action) => {
    const copyState = _.cloneDeep(state);
    switch (action.type) {
        case SET_ROOM_JOINED:
            if (action.joined) {
                return {
                    ...state,
                    checkTime: new Date().getTime(),
                };
            }
            break;
        case ADD_FILE:
            copyState.fileList.set(action.data.name, action.data);
            return copyState;
        case 'UPDATE_MESSAGE': {
            const newMessages = new Map(state.messages);
            const message = action.messages;
            newMessages.set(message.uuid, message);
            let updatedMessages = sortMessagesByTimestamp(newMessages);
            // Keep the last 50 messages if isUp is false
            const allMessages = Array.from(updatedMessages.values());
            updatedMessages = new Map(allMessages.slice(-getChatSize()).map(message => [message.uuid, message]));
            return {
                ...state,
                messages: updatedMessages,
            };
        }
        case ADD_MESSAGE: {
            // Create a new Map with the current messages
            const newMessages = new Map(state.messages);
            newMessages.set(action.message.uuid, action.message);
            let updatedMessages = sortMessagesByTimestamp(newMessages);
            // Keep the last 50 messages if isUp is false
            const allMessages = Array.from(updatedMessages.values());
            updatedMessages = new Map(allMessages.slice(-getChatSize()).map(message => [message.uuid, message]));
            return {
                ...state,
                messages: updatedMessages,
                totalCount: state.totalCount + 1,
                lastUId: action.message.uuid,
            };
        }
        case ADD_MESSAGE_LIST: {
            // Create a new Map with the current messages
            const newMessages = new Map(state.messages);
            action.messages.map((message) => {
                newMessages.set(message.uuid, message);
            });
            return {
                ...state,
                messages: sortMessagesByTimestamp(newMessages),
                totalCount: action.total_count,
                ...(action.last_uid ? { lastUId: action.last_uid } : {}),
            };
        }
        case 'REMOVE_CHAT_LIST': {
            return {
                ...state,
                messages: new Map(),
            };
        }
        // case ADD_MESSAGE_LIST: {
        // 	// Create a new Map with the current messages
        // 	const newMessages = new Map(state.messages);
        // 	// Add new messages to the Map
        // 	action.messages.forEach((message: IChatDetail) => {
        // 		newMessages.set(message.uuid, message);
        // 	});
        // 	// Sort messages by timestamp
        // 	const sortedMessages = sortMessagesByTimestamp(newMessages);
        // 	// Determine the range of messages to keep based on isUp
        // 	let updatedMessages = sortedMessages;
        // 	if (action.isUp) {
        // 		// Keep the first 50 messages if isUp is true
        // 		const allMessages = Array.from(sortedMessages.values());
        // 		updatedMessages = new Map(allMessages.slice(0, 50).map(message => [message.uuid, message]));
        // 	} else {
        // 		// Keep the last 50 messages if isUp is false
        // 		const allMessages = Array.from(sortedMessages.values());
        // 		updatedMessages = new Map(allMessages.slice(-50).map(message => [message.uuid, message]));
        // 	}
        // 	return {
        // 		...state,
        // 		messages: updatedMessages,
        // 		totalCount: action.total_count,
        // 		...(action.last_uid ? { lastUId: action.last_uid } : {}),
        // 	};
        // }
        case SET_CHOICE_MEMBER:
            return {
                ...state,
                choiceMembers: action.members,
            };
        case SET_IS_CHAT_READ:
            return {
                ...state,
                isRead: action.isRead,
                lastUid: undefined,
                totalConut: 0,
            };
        case LEAVE_ROOM:
            return {
                ...DEFAULT_STATE,
            };
    }
    return state;
});
