import { ADD_FILE, ADD_FILE_MESSAGE, ADD_MESSAGE, ADD_MESSAGE_LIST, SET_CHOICE_MEMBER, SET_IS_CHAT_READ, } from './actionTypes';
import { checkChatPermission } from './functions';
/**
 * 메시지 전송
 * @param {String} message
 */
export function addMessage(message, local, isShared = false) {
    return {
        type: ADD_MESSAGE,
        message,
        local,
        isShared,
    };
}
export function updateMessage(messages) {
    return {
        type: 'UPDATE_MESSAGE',
        messages,
    };
}
/**
 * 메시지 전송
 * @param {String} message
 */
export function addMessageList(messages, total_count, last_uid = null, isUp = false) {
    return {
        type: ADD_MESSAGE_LIST,
        messages,
        total_count,
        last_uid,
        isUp,
    };
}
export function updateLastItemId(lastItemId) {
    return {
        type: 'UPDATE_LAST_ITEM_ID',
        lastItemId,
    };
}
export function updateStartItemId(startItemId) {
    return {
        type: 'UPDATE_START_ITEM_ID',
        startItemId,
    };
}
/**
 * 파일 전송
 * @param file
 * @param group_id
 * @returns
 */
export function addFileMessage(message, local, isShared = false) {
    return {
        type: ADD_FILE_MESSAGE,
        message,
        local,
        isShared,
    };
}
/**
 * 파일 저장
 * @param data
 * @param isRemote
 * @returns
 */
export function addShareFile(data, isRemote = true) {
    return async (dispatch, getState) => {
        if (!isRemote) {
            if (checkChatPermission(getState, 'chat')) {
                const response = await APP.management.sendFile(data);
                if (response.status !== 200) {
                    /**
                     * (fix me)
                     */
                    console.log('addShareFile - error');
                    return;
                }
            }
            else
                return;
        }
        dispatch({
            type: ADD_FILE,
            data,
        });
    };
}
/**
 * 메시지 저장
 * @param data
 * @param isRemote
 * @returns
 */
export function addShareMessage(data, isRemote = true) {
    return {
        type: ADD_MESSAGE,
        data,
        isRemote,
    };
}
/**
 * 채팅 전송할 사용자 설정
 * @param members
 * @returns
 */
export function setChoiceMember(members) {
    return {
        type: SET_CHOICE_MEMBER,
        members,
    };
}
/**
 * 채팅 read
 */
export function setIsChatRead(isRead) {
    return {
        type: SET_IS_CHAT_READ,
        isRead,
    };
}
export function removeChatList() {
    return {
        type: 'REMOVE_CHAT_LIST',
    };
}
