export async function playStreaming({ urlBase, streamingAuth, streamingId, position, speed, }) {
    try {
        const url = `${urlBase}/api/media/v1/streamings/${streamingId}/play`;
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ position, speed, auth_token: streamingAuth }),
        });
        if (!response.ok) {
            throw new Error('스트리밍 토큰을 가져오는데 실패했습니다.');
        }
        const data = await response.json();
        return data;
    }
    catch (err) {
        throw new Error(err.message || 'An unknown error occurred');
    }
}
export async function stopStreaming({ urlBase, streamingAuth, streamingId, }) {
    if (!streamingAuth || !streamingId) {
        throw new Error('streamingAuth 또는 streamingId가 없습니다.');
    }
    try {
        const url = `${urlBase}/api/media/v1/streamings/${streamingId}/pause`;
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ auth_token: streamingAuth }),
        });
        if (!response.ok) {
            throw new Error('스트리밍 토큰을 가져오는데 실패했습니다.');
        }
        const data = await response.json();
        return data;
    }
    catch (err) {
        throw new Error(err.message || 'An unknown error occurred');
    }
}
export async function getStreaming({ urlBase, streamingId }) {
    try {
        const url = `${urlBase}/api/media/v1/streamings?id=${streamingId}`;
        const response = await fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        });
        if (!response.ok) {
            throw new Error('스트리밍 토큰을 가져오는데 실패했습니다.');
        }
        const data = await response.json();
        return data;
    }
    catch (err) {
        throw new Error(err.message || 'An unknown error occurred');
    }
}
