import { throttle } from 'lodash';
import React, { useEffect, useRef } from 'react';
import { useMutation } from 'react-query';
import { Button } from '../common';
import { Colors } from '../common/Colors';
import { RemaingTimer } from './RemaingTimer';
import { startTimer, useSubmitStatus } from './querys';
export const TestItem = React.memo(({ i, isTeacher, setSelectSubject, }) => {
    const textRef = useRef(null);
    const handlerStartTimer = useMutation(startTimer);
    const [submitCount, setSubmitCount] = React.useState(0);
    const [submitRate, setSubmitRate] = React.useState(0);
    const { data, refetch } = useSubmitStatus({
        id: i.id,
        member_id: '',
    });
    const refetchList = throttle(refetch, 1000, { leading: true, trailing: false });
    const handlerUpdateList = ({ test_id }) => {
        if (test_id === i.id)
            refetchList();
    };
    useEffect(() => {
        APP.eventManager.subscribe('UPDATE_SUBMITTED', handlerUpdateList);
        return () => {
            APP.eventManager.unsubscribe('UPDATE_SUBMITTED', handlerUpdateList);
        };
    }, []);
    useEffect(() => {
        if (isTeacher)
            refetchList();
    }, [i.id]);
    useEffect(() => {
        if (!data)
            return;
        const { total_count } = data;
        if (total_count > 0) {
            // console.log(data);
            const submitted = data.items.filter(i => i.corrects !== -1).length;
            setSubmitCount(submitted);
            setSubmitRate(((submitted / total_count) * 100).toFixed(2));
        }
        else {
            setSubmitCount(0);
        }
    }, [data]);
    return (React.createElement("div", { className: "wrap-box", key: i.id },
        React.createElement("div", { className: "field-global-001" },
            React.createElement("div", { className: "task-label" }, "\uD14C\uC2A4\uD2B8\uBA85"),
            React.createElement("div", { className: "fext-field" }, i.title)),
        React.createElement("div", { className: "field-global-001" },
            React.createElement("div", { className: "task-label" }, "\uC9C4\uD589\uC2DC\uAC04"),
            React.createElement("div", { className: "fext-field" }, i.status === 'created' ? (isTeacher ? (React.createElement(React.Fragment, null,
                React.createElement("input", { ref: textRef, type: "number", min: 0, defaultValue: i.duration, className: "fext-input" }),
                "\uBD84")) : (`${i.duration}분`)) : (React.createElement(RemaingTimer, { test_id: i.id, testData: i })))),
        isTeacher && (React.createElement(React.Fragment, null,
            React.createElement("div", { className: "field-global-001" },
                React.createElement("div", { className: "task-label" }, "\uC81C\uCD9C\uC778\uC6D0"),
                React.createElement("div", { className: "fext-field" },
                    submitCount,
                    " \uBA85")),
            React.createElement("div", { className: "field-global-001" },
                React.createElement("div", { className: "task-label" }, "\uC81C\uCD9C \uBE44\uC728"),
                React.createElement("div", { className: "fext-field" },
                    React.createElement("div", { className: "fext-field" },
                        submitRate,
                        "%"))))),
        React.createElement("div", { className: "btn-container" },
            React.createElement(Button, { className: "click_button undefined text-button no-color", text: isTeacher ? `common.${i.status}` : `common.show${i.status}`, styles: {
                    buttonWrap: {
                        backgroundColor: i.status === 'open'
                            ? Colors.pink
                            : i.status === 'created' && isTeacher
                                ? '#8bacff'
                                : Colors.w_grey,
                    },
                }, onClick: !isTeacher
                    ? i.status !== 'created'
                        ? () => {
                            setSelectSubject && setSelectSubject(i.id);
                        }
                        : undefined
                    : i.status === 'created'
                        ? () => {
                            try {
                                const duration = (textRef.current && textRef.current.value) || '1';
                                handlerStartTimer
                                    .mutateAsync({
                                    id: i.id,
                                    duration: Number(duration),
                                })
                                    .then(() => {
                                    APP.eventManager.publish('UPDATE_ASSIGNMENT');
                                });
                            }
                            catch (error) {
                                try {
                                    // Handle error
                                    APP.UI.alertMessage(error);
                                }
                                catch (error) {
                                    APP.UI.alertMessage('시작할 수 없습니다.');
                                }
                            }
                        }
                        : () => {
                            setSelectSubject && setSelectSubject(i.id);
                        } }))));
});
