import React, { useEffect, useMemo } from 'react';
import { DivElement, RangeComponent } from '../base/ui';
const AudioVolume = React.memo(({ defaultVolume, setSpeakerVolume }) => {
    const [volume, setVolume] = React.useState(50);
    useEffect(() => {
        setVolume(defaultVolume);
    }, []);
    useEffect(() => {
        if (defaultVolume !== volume)
            setSpeakerVolume(volume / 100);
        // APP.eventManager.publish('UPDATE_AUDIO_OUTPUT_VOLUME', { volume: volume / 100 });
    }, [volume]);
    const handlerChange = (value) => setVolume(value);
    const style = useMemo(() => {
        return {
            background: `linear-gradient(to right, #75B1FF 0%, #75B1FF ${volume}%, #d5d4d3 ${volume}%, #d5d4d3 100%)`,
        };
    }, [volume]);
    return (React.createElement(DivElement, { className: "progressbar" },
        React.createElement(RangeComponent, { min: "0", max: "100", step: "1", value: volume, handlerChange: handlerChange, style: style })));
});
export default AudioVolume;
