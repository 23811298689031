import { toState } from '../redux';
/**
 * 현재 녹화 상태 가져오기
 * @param {IStore['getState']} stateful
 * @param {string} type
 */
export function getRecordStatus(stateful, type) {
    const state = toState(stateful);
    const recordState = state['features/base/record'];
    return recordState[type];
}
