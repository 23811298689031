export function isMobileDevice() {
    const userAgent = navigator.userAgent.toLowerCase();
    const isTouch = 'ontouchstart' in window || navigator.maxTouchPoints > 0;
    const isMobileSize = window.innerWidth < 768;
    const isAndroid = userAgent.indexOf('android') > -1;
    const isiOS = /ipad|iphone|ipod/.test(userAgent) && !window.MSStream;
    if (isAndroid) {
        return userAgent.includes('mobile') || isMobileSize || isAndroid;
    }
    else if (isiOS) {
        return ((userAgent.includes('iphone') ||
            userAgent.includes('ipod') ||
            (navigator.maxTouchPoints && navigator.maxTouchPoints > 2 && /iPhone|iPod/.test(navigator.platform))) &&
            isMobileSize);
    }
    return isTouch && isMobileSize && userAgent.includes('mobile');
}
