import React, { Fragment, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Dialog } from "../base/dialog";
import { ClickButton, DivElement, Input, TextElement } from "../base/ui";
import { setRoomPassword } from "./action";
import { SecurityStyled } from "./components";
import { updatePassword } from "./functions";
const SecurityDialog = React.memo(({ isClick }) => {
    const dispatch = useDispatch();
    const { password, locked } = useSelector((state) => state['features/room-lock']);
    const [isLocked, setLocked] = useState(false);
    const [newPassword, setNewPassword] = useState(password);
    useEffect(() => {
        const isLocked = password ? true : false;
        setLocked(isLocked);
        setNewPassword(password);
    }, [password]);
    const handlerChangePassword = () => {
        const pwd = locked ? '' : (isLocked ? newPassword : '');
        if (locked)
            setLocked(false);
        updatePassword(pwd)
            .then(response => {
            if (response.complete) {
                dispatch(setRoomPassword(pwd));
            }
            else {
                APP.UI.alertMessage(response.data);
            }
        });
    };
    const renderSaveButton = useMemo(() => {
        if (!isLocked)
            return null;
        return (React.createElement(ClickButton, { className: 'text-button locked_btn', isText: true, onClick: handlerChangePassword, label: locked ? "roomLock.release" : "roomLock.add" }));
    }, [locked, isLocked, handlerChangePassword]);
    const renderTitle = useMemo(() => (React.createElement(DivElement, { className: 'title_wrapper' },
        React.createElement(TextElement, { text: 'roomLock.lockGuid' }),
        React.createElement(TextElement, { text: 'roomLock.password', textProps: { password } }))), [password]);
    const renderUnLockComponent = useMemo(() => {
        const handlerLock = () => setLocked(!isLocked);
        const handlerChangePassword = value => setNewPassword(value);
        return (React.createElement(Fragment, null,
            React.createElement(TextElement, { className: 'guid', text: 'roomLock.noLockGuid' }),
            React.createElement(DivElement, { className: 'title_wrapper nonSelect' },
                React.createElement(DivElement, { className: 'password password_false', onClick: handlerLock },
                    React.createElement(ClickButton, { className: `no-hover security security_${isLocked}`, icon: 'fill_circle', size: 22, color: '#fff', onClick: isClick ? handlerLock : null, label: `roomLock.${isLocked}Lock` })),
                isLocked && React.createElement(Input, { type: "text", defaultValue: password, customStyles: {
                        borderWidth: 1, borderColor: "#000",
                        padding: 3, width: 190,
                        paddingHorizontal: 12,
                        marginVertical: 12, marginHorizontal: "auto", borderRadius: 4
                    }, onChange: handlerChangePassword }))));
    }, [isClick, password, isLocked]);
    return (React.createElement(Dialog, { titleKey: "dialog.security" },
        React.createElement(SecurityStyled, null,
            locked ? renderTitle : renderUnLockComponent,
            renderSaveButton)));
});
export default SecurityDialog;
