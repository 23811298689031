import React, { useEffect, useMemo, useRef, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { isSideBarOpend } from '../base/sidebar';
import { VOD_MODE, WHITE_MODE } from '../share-layout/constants';
import NoDocument from './NoDocument';
import CanvasDrawLayout from './components/web/CanvasDrawLayout';
import CanvasTool from './components/web/CanvasTool';
import { CanvasLayoutStyled } from './components/web/styles';
import { PEN } from './constants';
import { getFitSize } from './functions';
import { getPropertyValue } from '../base/settings';
import ControlBarConatiner from './ControlBarContainer';
import { ROLE, checkRoleLevel, getLocalMemberRole } from '../base/members';
const CanvasLayoutContainter = React.memo(({ isMobile = false, expand, isLeft, focusIndex, documentItem, isVod, renderShareLeft, }) => {
    const fullCanvasRef = useRef();
    const isShowLvl = useSelector((state) => checkRoleLevel(getLocalMemberRole(state), ROLE.PRESENTER));
    const option = useSelector((state) => state['features/canvas'].option, shallowEqual);
    const tool = useSelector((state) => state['features/canvas'].tool, shallowEqual);
    const sidebar = useSelector((state) => isSideBarOpend(state), shallowEqual);
    const openSide = useSelector((state) => getPropertyValue(state, 'dualSideOpen'));
    const isWidthFit = useMemo(() => option.isWidthFit, [option.isWidthFit]);
    const scale = useMemo(() => option.scale, [option.scale]);
    // 사용자가 현재 사용하는 도구
    const [focusTool, setFocusTool] = useState(PEN);
    const [drawCanvas, setDrawCanvas] = useState({ width: 0, height: 0 });
    const [fullCanvas, setFullCanvas] = useState({ width: 0, height: 0 });
    const isDocumentVod = useMemo(() => (documentItem?.config === VOD_MODE ? true : false), [documentItem?.config]);
    useEffect(() => {
        setFullCanvas(prevFull => {
            return {
                width: prevFull.width,
                height: prevFull.height - (isVod ? 50 : 0),
            };
        });
    }, [isVod]);
    useEffect(() => {
        if (!documentItem)
            return;
        // 화이트 보드일 경우 캔버스 스타일 16:9 비율 고정
        if (documentItem?.config === WHITE_MODE) {
            const canvasStyle = getFitSize(fullCanvas, { width: 16, height: 9 }, isWidthFit);
            setDrawCanvas(canvasStyle);
        }
    }, [documentItem?.config, fullCanvas, isWidthFit]);
    const renderTool = useMemo(() => {
        if (isVod)
            return null;
        return (React.createElement(CanvasTool, { isMobile: isMobile || fullCanvas.height < 664, tool: tool, option: option, isVod: isVod, uuid: documentItem?.uuid, index: focusIndex, focusTool: focusTool, setFocusTool: setFocusTool }));
    }, [
        isVod,
        isDocumentVod,
        option,
        tool,
        focusTool,
        isMobile,
        fullCanvas.height,
        documentItem?.uuid,
        focusIndex,
        isShowLvl,
    ]);
    useEffect(() => {
        const resizeObserver = new ResizeObserver(entries => {
            for (let entry of entries) {
                const { width, height } = entry.contentRect;
                if (!width && !height)
                    return;
                setFullCanvas({ width, height: height - (isVod ? 50 : 0) });
            }
        });
        const fullElement = fullCanvasRef.current;
        if (fullElement) {
            if (fullElement) {
                resizeObserver.observe(fullElement);
            }
        }
        return () => {
            if (resizeObserver && fullCanvasRef.current) {
                resizeObserver.unobserve(fullCanvasRef.current);
            }
        };
    }, [isVod]);
    return (React.createElement(React.Fragment, null,
        APP.config.option.hideParticipant && isShowLvl && renderShareLeft(renderTool),
        React.createElement(CanvasLayoutStyled, { ref: fullCanvasRef, isVod: isDocumentVod },
            APP.config.option.hideParticipant &&
                isShowLvl &&
                documentItem &&
                documentItem.config !== VOD_MODE && (React.createElement(ControlBarConatiner, { uuid: documentItem.uuid, mode: documentItem.config, scale: scale, isWidthFit: isWidthFit, page: focusIndex, maxPage: documentItem?.list?.length || 1 })),
            !documentItem && React.createElement(NoDocument, { isVod: isVod, focusIndex: focusIndex }),
            documentItem && (React.createElement(CanvasDrawLayout, { isVod: isVod, option: option, tool: tool, focusIndex: focusIndex, documentItem: documentItem, fullCanvas: fullCanvas, drawCanvas: drawCanvas, setDrawCanvas: setDrawCanvas, setFocusTool: setFocusTool })))));
});
export default CanvasLayoutContainter;
