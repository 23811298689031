import { ReducerRegistry } from '../base/redux';
const DEFAULT_STATE = {
    roomId: undefined,
    remoteMembers: [],
    isConnection: undefined,
};
ReducerRegistry.register('features/dualRoom', (state = DEFAULT_STATE, action) => {
    switch (action.type) {
        case 'SET_CONNECTION_DUAL':
            return {
                ...state,
                isConnection: action.isConnection,
            };
    }
    return state;
});
