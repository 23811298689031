import React, { useMemo, useState } from 'react';
import { Player } from '../../../base/vod/components/Player';
import { DOCUMENT_MODE, VOD_MODE } from '../../../share-layout/constants';
import CanvasDraw from './CanvasDraw';
import PDFViewer from './PDFViewer';
const CanvasDrawLayout = React.memo(({ focusIndex: index, isVod, tool, documentItem, option, drawCanvas, fullCanvas, setDrawCanvas, setFocusTool, }) => {
    const [canvasPos, setCanvasPos] = useState({ x: 0, y: 0 });
    const { fullWidth, fullHeight, scale, isWidthFit } = useMemo(() => {
        const scale = option.scale / 100;
        return {
            fullWidth: drawCanvas.width * scale,
            fullHeight: drawCanvas.height * scale,
            scale,
            isWidthFit: option.isWidthFit,
        };
    }, [option.scale, option.isWidthFit, drawCanvas]);
    const { config, isDocumentMode, documentPage, uuid } = useMemo(() => {
        return {
            isDocumentMode: documentItem?.config === DOCUMENT_MODE,
            documentPage: documentItem?.page,
            uuid: documentItem?.uuid,
            config: documentItem?.config,
        };
    }, [documentItem?.config, documentItem?.page, documentItem?.uuid]);
    const renderVodPlayer = useMemo(() => {
        return (React.createElement(Player, { uuid: uuid, fullCanvas: fullCanvas, canvasPos: canvasPos, isWidthFit: isWidthFit, urlList: documentItem?.list, scale: scale, isComplete: documentPage, streamingId: uuid, setDrawCanvas: setDrawCanvas }));
    }, [uuid, scale, documentItem?.list, canvasPos, documentPage, documentItem?.user_uuid, fullCanvas, isWidthFit]);
    const renderDocumentPDF = useMemo(() => {
        if (!isDocumentMode)
            return null;
        if (!documentPage)
            return null;
        return (React.createElement(PDFViewer, { index: index, documentPage: documentPage, fullCanvas: fullCanvas, canvasPos: canvasPos, scale: scale, isWidthFit: isWidthFit, setDrawCanvas: setDrawCanvas }));
    }, [index, documentPage, fullCanvas, canvasPos, scale, isWidthFit]);
    const renderDraw = useMemo(() => {
        if (!uuid)
            return null;
        if (isVod)
            return null;
        return (React.createElement(CanvasDraw, { isVod: isVod, uuid: uuid, index: index, isDocumentMode: isDocumentMode, drawCanvas: drawCanvas, fullCanvas: fullCanvas, scale: scale, canvasPos: canvasPos, tool: tool, option: option, setCanvasPos: setCanvasPos, setFocusTool: setFocusTool }));
    }, [isVod, uuid, index, isDocumentMode, drawCanvas, fullCanvas, scale, canvasPos, tool, option]);
    if (!documentItem)
        return null;
    return (React.createElement("div", { className: "canvas_draw", style: {
            width: '100%',
            height: '100%',
            margin: isVod ? 0 : 'auto',
            overflow: 'hidden',
        } },
        documentPage && config === VOD_MODE && renderVodPlayer,
        renderDocumentPDF,
        renderDraw));
});
export default CanvasDrawLayout;
