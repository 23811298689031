import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { SHARE_MODE_LIST, VIDEO_MODE_LIST, getCurrentMode, videoLayoutMode } from '../../../video-layout';
import { hideSheet } from '../../dialog';
import { isMobileBrowser } from '../../environment/utils';
import { ClickButton } from '../../ui';
import AbstractChangeLayout from '../AbstractChangeLayout';
// @ts-ignore
import OneDepthChangeLayoutButton from './OneDepthChangeLayoutButton';
function getFoucs(mode, current_mode) {
    if (VIDEO_MODE_LIST.indexOf(current_mode) !== -1 && mode === 'video_mode') {
        return true;
    }
    else if (SHARE_MODE_LIST.indexOf(current_mode) !== -1 && mode === 'share_mode') {
        return true;
    }
    else if (videoLayoutMode.screen === current_mode && mode === videoLayoutMode.screen) {
        return true;
    }
    return false;
}
function AbstractOneDepthLayoutButton({ className, isModal, enableLable, permissions, iconStyles, }) {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const current_mode = useSelector((state) => getCurrentMode(state));
    const [list, setList] = useState([]);
    useEffect(() => {
        let list = [];
        if (permissions.indexOf('layout') !== -1) {
            list = ['video_mode'];
        }
        if (permissions.indexOf('share') !== -1) {
            list = [...list, APP.config.ui.layout.screenShare && videoLayoutMode.screen, 'share_mode'];
        }
        setList(list);
    }, [permissions]);
    const handlerClickEvent = (mode) => {
        if (mode === 'video_mode') {
            AbstractChangeLayout.changeMode(dispatch, videoLayoutMode.default, current_mode);
        }
        else if (mode === 'share_mode') {
            AbstractChangeLayout.changeMode(dispatch, videoLayoutMode.document, current_mode);
        }
        else if (mode === videoLayoutMode.screen) {
            AbstractChangeLayout.toggleScreenShare(dispatch);
        }
        dispatch(hideSheet());
    };
    return (React.createElement(OneDepthChangeLayoutButton, { t: t, isModal: isModal }, list.map((mode) => (React.createElement(OneDepthItem, { key: mode, mode: mode, className: className, current_mode: current_mode, enableLable: enableLable, iconStyles: iconStyles, handlerClickEvent: handlerClickEvent })))));
}
export default AbstractOneDepthLayoutButton;
const OneDepthItem = React.memo(({ mode, className, current_mode, enableLable, iconStyles, handlerClickEvent, }) => {
    const { t } = useTranslation();
    const isFocus = (mode === 'video_mode' && VIDEO_MODE_LIST.includes(current_mode)) || getFoucs(mode, current_mode)
        ? 'isFocus'
        : null;
    const onClick = () => handlerClickEvent(mode);
    if (mode === videoLayoutMode.screen) {
        if (isMobileBrowser())
            return null;
    }
    return (React.createElement(ClickButton, { key: mode, className: `${className} ${isFocus}`, icon: mode, label: enableLable && t(`layout.${mode}`), size: enableLable ? 20 : APP.config.style.enableIconSize, styles: iconStyles, onClick: onClick }));
});
