import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useGlobalState } from '../../app/GlobalStateProvider';
import { getRoomOption } from '../../room';
import { Button } from '../common';
import { Dialog } from '../dialog';
import { getIsTeacher } from '../local';
import { updateTestStart } from '../settings';
import { DragTest } from './DragTest';
import { QuestionSolve } from './QuestionSolve';
import { SubmissionContainer } from './SubmissionContainer';
import { TestItem } from './TestItem';
import { useAssignmentList } from './querys';
export const TestContainer = React.memo(({ testStart, hide, setHide, test_id, }) => {
    const { isDesktop } = useGlobalState();
    const dispatch = useDispatch();
    const conference_id = useSelector((state) => getRoomOption(state)?.uuid);
    const isTeacher = useSelector((state) => getIsTeacher(state));
    const [doing, setDoing] = React.useState(false);
    const [items, setItems] = React.useState([]);
    const [selectSubject, setSelectSubject] = React.useState();
    const { data, refetch } = useAssignmentList({
        assignment: false,
        conference_id,
        ...(!isTeacher && { status: ['open', 'closed'] }),
    });
    useEffect(() => {
        test_id && setSelectSubject(test_id);
    }, [test_id]);
    useEffect(() => {
        APP.eventManager.subscribe('UPDATE_ASSIGNMENT', refetch);
        return () => {
            APP.eventManager.subscribe('UPDATE_ASSIGNMENT', refetch);
        };
    }, []);
    useEffect(() => {
        conference_id && refetch();
    }, [conference_id]);
    useEffect(() => {
        if (data) {
            const { items } = data;
            if (data.items.length > 0) {
                setItems(items);
                setDoing(items.find(i => i.status == 'open') ? true : false);
                return;
            }
        }
        setItems([]);
    }, [data]);
    useEffect(() => {
        if (doing) {
            dispatch(updateTestStart(true));
        }
        else {
            dispatch(updateTestStart(false));
        }
    }, [doing]);
    const renderTestItem = useMemo(() => items && items.length > 0 ? (items.map(i => (React.createElement(TestItem, { key: i.id, i: i, isTeacher: isTeacher, setSelectSubject: setSelectSubject })))) : (React.createElement("div", null, "\uC2DC\uC791\uB41C \uD14C\uC2A4\uD2B8\uAC00 \uC5C6\uC2B5\uB2C8\uB2E4.")), [items, isTeacher]);
    const renderItem = useMemo(() => {
        return !selectSubject ? (renderTestItem) : isTeacher ? (React.createElement(SubmissionContainer, { isTest: true, test_id: selectSubject })) : (React.createElement(QuestionSolve, { test_id: selectSubject }));
    }, [isTeacher, selectSubject, renderTestItem]);
    if (hide) {
        return (React.createElement("div", { className: "hide_test" },
            React.createElement(DragTest, { hide: hide, setHide: setHide })));
    }
    if (!testStart) {
        return React.createElement("div", { style: { position: 'absolute', top: 0, left: 0 } });
    }
    return (React.createElement(Dialog, { className: "test", titleKey: "dialog.test", onCancel: () => {
            doing ? setHide(true) : dispatch(updateTestStart(false));
        } },
        React.createElement("div", { className: `test_container ${!isDesktop ? 'mobile' : 'desktop'}` },
            React.createElement(React.Fragment, null,
                renderItem,
                !selectSubject ? null : (React.createElement(Button, { className: "btn-default", option: {
                        buttonBeforeIcon: {
                            // @ts-ignore
                            icon: 'arrow-left',
                            size: 16,
                            className: 'icon',
                            color: 'var(--color-black)',
                        },
                    }, styles: {
                        buttonWrap: {
                            margin: 0,
                        },
                    }, text: "common.back", onClick: () => setSelectSubject(undefined) }))))));
});
