import React, { useEffect, useMemo, useState } from 'react';
import { useMutation } from 'react-query';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Button } from '../common';
import { toggleLoad } from '../loading';
import { postEnquiries, useLiveEnquiries } from '../question/querys';
import { HandlerRequestStyled } from './HandlerRequest';
import { toggleHideMember, toggleMember } from './actions';
import { SideBarHeader } from './components';
import { getRoomOption } from '../../room';
export const LiveEnquiries = React.memo(({ isMonitoring, hideMember }) => {
    const dispatch = useDispatch();
    const conference_id = useSelector((state) => getRoomOption(state).uuid);
    const hideContent = () => dispatch(toggleHideMember(!hideMember));
    // @ts-ignore
    const handlerClose = () => dispatch(toggleMember(false));
    const [members, setMembers] = useState([]);
    const [list, setList] = useState([]);
    const live_enquiry = useSelector((state) => state['features/base/sidebar'].liveEnquiries, shallowEqual);
    const acceptedMuntation = useMutation(postEnquiries);
    const { data, refetch } = useLiveEnquiries({
        id: conference_id,
        with_more_info: true,
    });
    useEffect(() => {
        APP.eventManager.subscribe('UPDATE_LIVE_ENQUIRY', refetch);
        return () => {
            APP.eventManager.unsubscribe('UPDATE_LIVE_ENQUIRY', refetch);
        };
    }, []);
    useEffect(() => {
        refetch();
    }, [conference_id]);
    useEffect(() => {
        if (data) {
            const { total_count, items } = data;
            if (total_count > 0) {
                setList(items[0].waiting);
                setMembers(items[0].members.filter(m => m.role_name === 'participant'));
                return;
            }
        }
        // setList([{id: '1', name: 'test'}]); UI 테스트용
        setList([]);
        setMembers([]);
    }, [data]);
    const renderHead = useMemo(() => (React.createElement(SideBarHeader, { name: "liveEnquiries", isMonitoring: isMonitoring, showBody: !hideMember, hideContent: hideContent, handlerClose: handlerClose })), [isMonitoring, hideMember]);
    if (!conference_id)
        return;
    return (React.createElement(HandlerRequestStyled, null,
        renderHead,
        React.createElement("div", { className: "handler_wrap" },
            members &&
                members.map(m => (React.createElement("div", { className: "box", key: m.meet_id },
                    React.createElement("div", { style: { marginLeft: 0 } },
                        React.createElement("div", { className: "headline" },
                            `${m.nickname}(${m.identifier})`,
                            " ")),
                    React.createElement("div", { className: "btn-container" },
                        React.createElement(Button, { className: "btn btn-error", text: m.status === 'occupied' ? '내보내기' : '미입장', onClick: m.status === 'occupied'
                                ? async () => {
                                    m.meet_id && (await APP.management.setMemberKick(m.meet_id));
                                }
                                : undefined }))))),
            list.map((member, index) => (React.createElement("div", { className: "box", key: member.id },
                React.createElement("div", { style: { marginLeft: 0 } },
                    React.createElement("div", { className: "headline" }, `${member.name}(${member.idf})`)),
                React.createElement("div", { className: "btn-container" }, index === 0 ? (React.createElement(Button, { className: "handler-btn", text: "\uC218\uB77D", onClick: async () => {
                        try {
                            // if (!list || (list && list.length <= 0)) {
                            // 	alert('수락할 수 있는 1:1 LIVE 요청이 없습니다.');
                            // 	return;
                            // }
                            dispatch(toggleLoad(true));
                            if (live_enquiry) {
                                const mmt_id = members.find(filterMember => live_enquiry === filterMember.member_id)?.meet_id;
                                // set choicemember mmid
                                mmt_id && (await APP.management.setMemberKick(mmt_id));
                            }
                            setTimeout(async () => {
                                conference_id &&
                                    (await acceptedMuntation.mutateAsync(conference_id));
                                dispatch(toggleLoad(false));
                            }, 2000);
                        }
                        catch (err) {
                            try {
                                // Handle error
                                APP.UI.alertMessage(err);
                            }
                            catch (error) {
                                APP.UI.alertMessage('알 수 없는 오류가 발생했습니다.');
                            }
                            dispatch(toggleLoad(false));
                        }
                        finally {
                            refetch();
                        }
                    } })) : (React.createElement(Button, { className: "handler-not-border-btn", text: "\uB300\uAE30\uC911" })))))))));
});
