import { createSelector } from 'reselect';
import { getMemberByBridgeId, getPinnedMembersBySeminar, getScreenUserID, getSpeakerId } from '../base/members';
import { ReducerRegistry, StateListenerRegistry } from '../base/redux';
import { getPropertyValue } from '../base/settings';
import { getTrackState } from '../base/tracks';
import { isMobileDevice } from '../lobby/components';
import { LEAVE_ROOM, isRoomJoined } from '../room';
import { getScreenLayoutType, getScreenShareTrackId } from '../screen-share';
import { SET_DUAL_MONITOR, SET_EXPAND, SET_LAYOUT_MEMBERS, SET_LAYOUT_MODE, SET_LAYOUT_STYLE, SET_LAYOUT_TYPE, SET_MAX_PAGE, SET_PAGE, SET_REMOTE_MEMBER, } from './actionTypes';
import { setLayoutMembers, setMaxPage, setPage, setRemoteMember } from './actions';
import { LAYOUT_TYPE, videoLayoutMode } from './constants';
import { allocateUsersByVideoQuality, getCurrentMode, getDualMember, getLayoutType, getLayoutWidthAndHeight, getPage, getSortMember, } from './functions';
const DEFAULT_STATE = {
    mode: videoLayoutMode.default,
    viewVisible: {
        [videoLayoutMode.screen]: false,
        [videoLayoutMode.vod]: false,
        [videoLayoutMode.white]: false,
        [videoLayoutMode.document]: false,
        [videoLayoutMode.note]: false,
    },
    isDualMonitor: false,
    layoutWidth: 0,
    layoutHeight: 0,
    layoutType: LAYOUT_TYPE.desktop,
    page: 1,
    maxPage: 1,
    expand: false,
    sortMember: [],
    remote: [],
    pin: [],
    voice: [],
    seminar: [],
    options: {
        isTestMode: false,
        backgroundUrl: '',
        testId: '',
    },
};
ReducerRegistry.register('features/video-layout', (state = DEFAULT_STATE, action) => {
    switch (action.type) {
        case SET_LAYOUT_MODE:
            return {
                ...state,
                mode: action.mode,
                viewVisible: action.visibleList,
            };
        case SET_LAYOUT_STYLE:
            return {
                ...state,
                layoutWidth: action.width,
                layoutHeight: action.height,
            };
        case SET_LAYOUT_TYPE:
            return {
                ...state,
                layoutType: action.layoutType,
            };
        case SET_MAX_PAGE:
            return {
                ...state,
                maxPage: action.maxPage,
            };
        case SET_PAGE:
            return {
                ...state,
                page: action.page,
            };
        case SET_LAYOUT_MEMBERS:
            return {
                ...state,
                pin: action.pin,
                voice: action.voice,
                seminar: action.seminar,
                screen: action.screen,
            };
        case SET_REMOTE_MEMBER:
            return {
                ...state,
                remote: action.remote,
            };
        case SET_EXPAND:
            return {
                ...state,
                expand: action.expand,
            };
        case SET_DUAL_MONITOR:
            return {
                ...state,
                isDualMonitor: action.isDualMonitor,
            };
        case LEAVE_ROOM:
            return {
                ...state,
                isDualMonitor: false,
                layoutWidth: 0,
                layoutHeight: 0,
                layoutType: LAYOUT_TYPE.desktop,
                page: 1,
                maxPage: 1,
                expand: false,
                mode: videoLayoutMode.default,
                viewVisible: {
                    [videoLayoutMode.screen]: false,
                    [videoLayoutMode.vod]: false,
                    [videoLayoutMode.white]: false,
                    [videoLayoutMode.document]: false,
                    [videoLayoutMode.note]: false,
                },
            };
        case 'SET_TEST_MODE':
            return {
                ...state,
                options: {
                    isTestMode: action.isTestMode,
                    backgroundUrl: action.backgroundUrl,
                    testId: action.testId,
                },
            };
        case 'SET_TEST_ID':
            return {
                ...state,
                options: {
                    testId: action.testId,
                },
            };
        case 'CLEAR_TEST_OPTION':
            return {
                ...state,
                options: {
                    isTestMode: true,
                    backgroundUrl: '',
                    testId: '',
                },
            };
        case 'SET_SORT_CHANGE':
            return {
                ...state,
                sortMember: action.sortMember,
            };
    }
    return state;
});
StateListenerRegistry.register(
// @ts-ignore
(state) => {
    if (!isRoomJoined(state))
        return false;
    const isDualMonitor = state['features/video-layout'].isDualMonitor;
    if (!isDualMonitor)
        return false;
    const mode = getCurrentMode(state);
    const layoutType = getLayoutType(state);
    const page = getPage(state).page;
    const screenLayoutType = getScreenLayoutType(state);
    const layoutStyle = getLayoutWidthAndHeight(state);
    const grid_count = getPropertyValue(state, 'grid_count');
    const sortMember = getSortMember(state);
    return getDualMember(state, {
        isDualMonitor,
        mode,
        layout_type: layoutType,
        page,
        screenLayoutType,
        layoutStyle,
        grid_count,
        sortMember,
    });
}, (result, store) => {
    if (!result)
        return;
    const { page, maxPage, remote } = result;
    if (maxPage < page)
        store.dispatch(setPage(maxPage));
    else {
        store.dispatch(setMaxPage(maxPage));
        store.dispatch(setRemoteMember(remote));
    }
}, {
    deepEquals: true,
});
const getMemberSelector = createSelector([(state, members) => members, state => state], (members, state) => {
    const mode = getCurrentMode(state);
    const layout_type = getLayoutType(state) || LAYOUT_TYPE.desktop;
    let seminar = [];
    let pin = [];
    let screen = [];
    if (layout_type === LAYOUT_TYPE.desktop &&
        [videoLayoutMode.note, videoLayoutMode.white, videoLayoutMode.document, videoLayoutMode.vod].includes(mode)) {
        const seminarId = getSpeakerId(state);
        if (seminarId)
            seminar = [seminarId];
    }
    if (mode === videoLayoutMode.seminar) {
        const seminarId = getSpeakerId(state);
        const pinIds = getPinnedMembersBySeminar(state);
        if (seminarId)
            seminar = [seminarId];
        if (pinIds && pinIds.length > 0)
            pin = pinIds;
    }
    if (mode === videoLayoutMode.screen) {
        const screenId = getScreenUserID(state);
        if (screenId)
            screen = [screenId];
    }
    return { seminar, pin, screen, voice: [] };
});
StateListenerRegistry.register(
// @ts-ignore
(state) => getMemberSelector(state), (result, store) => {
    if (!result)
        return;
    const { pin, voice, seminar, screen } = result;
    store.dispatch(setLayoutMembers({ pin, voice, seminar, screen }));
}, {
    deepEquals: true,
});
const getJitsiIdsSelector = createSelector([(state, members) => members, state => state], (members, state) => {
    return typeof members === 'string'
        ? members
        : members && members.length > 0
            ? members.map(r => getMemberByBridgeId(state, r)).filter(Boolean)
            : [];
});
const getBridgeMemberSelector = createSelector([(state, members) => members, state => state], (members, state) => {
    const mode = getCurrentMode(state);
    const { pin, seminar } = state['features/video-layout'];
    const pinJitsiIds = getJitsiIdsSelector(state, pin);
    const seminarJitsiIds = getJitsiIdsSelector(state, seminar);
    const screenJitsiIds = [getScreenShareTrackId(state)];
    const screenType = getLayoutType(state);
    const layoutType = isMobileDevice()
        ? LAYOUT_TYPE.mobile
        : screenType === LAYOUT_TYPE.mobile
            ? LAYOUT_TYPE.tablet
            : screenType;
    const selectTrack = getTrackState(state).filter(i => [...pinJitsiIds, ...seminarJitsiIds, ...screenJitsiIds].includes(i.participantId));
    return {
        selectTrack,
        mode,
        remote: [],
        pin: pinJitsiIds,
        voice: [],
        seminar: seminarJitsiIds,
        screen: screenJitsiIds,
        layoutType,
    };
});
StateListenerRegistry.register(getBridgeMemberSelector, (result, store) => {
    if (!result)
        return;
    const receiverConstraints = allocateUsersByVideoQuality(store.getState(), result);
    APP.management.setConstraints(receiverConstraints);
}, {
    deepEquals: true,
});
