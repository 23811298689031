import { MEDIA_TYPE, VIDEO_MUTISM_AUTHORITY, setAudioMuted, setVideoMuted } from '../base/media';
import { ROLE, checkRoleLevel, getLocalMember } from '../base/members';
import { MiddlewareRegistry } from '../base/redux';
import { UPDATE_ROOM_POLICY, getPropertyValue } from '../base/settings';

MiddlewareRegistry.register(store => next => async action => {
	let local = getLocalMember(store.getState);

	let role = local?.role;
	let pinned = local?.pinned;

	switch (action.type) {
		case UPDATE_ROOM_POLICY:
			if (!action.policy) return next(action);
			const policy = action.policy;
			const prevPolicy = getPropertyValue(store.getState(), 'policy');

			const prevPolicyCamera = prevPolicy?.initial_camera === 'following' ? 'on' : prevPolicy?.initial_camera;
			const prevPolicyMic = prevPolicy?.initial_mic === 'following' ? 'on' : prevPolicy?.initial_mic;

			const changePolicyCamera = policy.initial_camera === 'following' ? 'on' : policy.initial_camera;
			const changePolicyMic = policy.initial_mic === 'following' ? 'off' : policy.initial_mic;

			if (prevPolicyCamera !== changePolicyCamera || prevPolicyMic !== changePolicyMic) {
				const policy = action.policy || prevPolicy;

				if (role !== ROLE.HOST && !pinned) {
					if (policy.initial_camera === 'off')
						store.dispatch(setVideoMuted(false, MEDIA_TYPE.VIDEO, VIDEO_MUTISM_AUTHORITY.USER, false));
					if (changePolicyMic === 'off') store.dispatch(setAudioMuted(true, MEDIA_TYPE.AUDIO, false));
				}

				if (policy.initial_camera === 'following' || policy.initial_mic === 'following') {
					const iCamera = policy.initial_camera === 'off' ? 'off' : 'on';
					const iMic = policy.initial_mic === 'off' ? 'off' : 'on';

					return next({
						...action,
						policy: {
							...policy,
							initial_camera: iCamera,
							initial_mic: iMic,
						},
					});
				}
			}
	}

	return next(action);
});
