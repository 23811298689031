import { throttle } from 'lodash';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { useSelector } from 'react-redux';
import styled, { css } from 'styled-components';
import { useGlobalState } from '../../app/GlobalStateProvider';
import { Button } from '../common';
import { Colors } from '../common/Colors';
import { getExternalUserId } from '../local';
import { DetailQuestionTable } from './DetailQuestionTable';
import { ErrataTable } from './ErrataTable';
import MyPdfViewer from './MyPdfViewer';
import { RemaingTimer } from './RemaingTimer';
import { postSaveSubmitAnswer, postSubmitAnswer, useAssignmentList, useSubmitStatus } from './querys';
// @ts-ignore
const QuestionSolveStyled = styled.div `
	display: flex;
	flex-direction: column;
	width: 100%;
	gap: 16px;
	height: 100%;
	overflow: auto;
	flex-wrap: wrap;

	.field-001 {
		display: flex;
		flex-direction: row;
		gap: 10px;
		align-items: center;
		justify-content: flex-start;
		align-self: stretch;
		flex: 1;
		position: relative;
	}

	.pdf-control {
		background: rgba(77, 77, 77, 0.8);
		border-radius: 100px;
		padding: 6px 28px 6px 28px;
		display: flex;
		flex-direction: row;
		gap: 14px;
		align-items: center;
		justify-content: center;
		// position: absolute;
		min-width: 182px;
		// left: 50%;
		// top: 14px;
		color: var(--color-white, #ffffff);
		// transform: translateX(-50%);
		z-index: 10;

		> * {
			margin: 0;
		}
	}

	.pdf-control-body {
		min-height: 500px;
		overflow: auto;
	}

	.test-area {
		display: flex;
		flex-direction: column;
		gap: 10px;
		align-items: flex-start;
		justify-content: flex-start;
		flex-shrink: 0;
		position: relative;
		overflow: hidden;

		.fext-field {
			width: 100%;
			height: 100%;
			overflow: hidden;
		}
	}

	.file-container {
		flex-direction: ${props => (!props.$isDesktop ? 'column' : 'row')};

		.fext-field {
			display: flex;
			flex-direction: column;
			gap: 8px;
		}

		.dropdown-list {
			z-index: 2;
		}
	}

	.task-label {
		color: var(--color-black, #101010);
		text-align: left;
		font-family: var(--h5-font-family, 'NotoSansKr-Bold', sans-serif);
		font-size: var(--h5-font-size, 16px);
		line-height: var(--h5-line-height, 24px);
		font-weight: var(--h5-font-weight, 700);
		position: relative;
		align-self: stretch;
		display: flex;
		align-items: center;
		justify-content: flex-start;
	}

	.test-area,
	.field-001 {
		margin: 0;
		> * {
			margin: 0;
		}
	}

	.react-pdf__Document {
		width: 100%;
		height: 100%;
	}

	.no_file,
	.react-pdf__message--error {
		width: 100%;
		height: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		background: #d5d5d5;
		border-radius: 8px;
	}

	.test-area-before-submit {
		width: 100%;
		.fext-field {
			flex: 1;
		}
	}

	@media screen and (max-width: 738px) {
		.file-container {
			flex-direction: column;
			height: 100%;

			.test-area {
				min-height: 482px;

				.fext-field {
					flex: 1;
				}
			}
		}
	}
	${props => props.$isMobile && mobileStyles}
`;
const mobileStyles = css `
	.task-list-002 {
		display: flex;
		flex-direction: column;
		gap: 0px;
		align-items: flex-start;
		justify-content: flex-start;
		align-self: stretch;
		flex-shrink: 0;
		position: relative;
		width: 100%;

		/* .table-header {
			width: 100%;
			background: var(--color-silver, #f3f4f8);
			border-style: solid;
			border-color: var(--color-grey-w, #b6bac0);
			border-width: 1px 0px 0px 0px;
			padding: 10px 16px 10px 16px;
			display: flex;
			flex-direction: row;
			gap: 20px;
			align-items: flex-start;
			justify-content: flex-start;
			flex-shrink: 0;
			position: relative;

			color: var(--color-black, #101010);
			text-align: center;
			font-family: var(--b4-font-family, 'NotoSansKr-Regular', sans-serif);
			font-size: var(--b4-font-size, 14px);
			line-height: var(--b4-line-height, 28px);
			font-weight: var(--b4-font-weight, 400);
		} */

		.item {
			border-style: solid;
			border-color: var(--color-silver, #f3f4f8);
			border-width: 1px 0px 1px 0px;
			padding: 12px 0px 12px 0px;
			display: flex;
			flex-direction: column;
			gap: 6px;
			align-items: flex-start;
			justify-content: center;
			align-self: stretch;
			flex-shrink: 0;
			position: relative;

			.input_answer_wrapper {
				background: #ffffff;
				border-radius: 4px;
				border-style: solid;
				border-color: var(--color-grey-w, #b6bac0);
				border-width: 1px;
				padding: 4px 10px 4px 10px;
				display: flex;
				flex-direction: row;
				gap: 6px;
				align-items: center;
				justify-content: center;
				flex-shrink: 0;
				position: relative;
				color: var(--color-grey-l, #83868a);
				text-align: left;
				font-family: var(--b4-font-family, 'NotoSansKr-Regular', sans-serif);
				font-size: var(--b4-font-size, 14px);
				line-height: var(--b4-line-height, 28px);
				font-weight: var(--b4-font-weight, 400);
				position: relative;
			}

			.body-item {
				width: 100%;
			}

			color: var(--color-black, #101010);
			text-align: center;
			font-family: var(--b4-font-family, 'NotoSansKr-Regular', sans-serif);
			font-size: var(--b4-font-size, 12px);
			line-height: var(--b4-line-height, 28px);
			font-weight: var(--b4-font-weight, 400);
		}
	}
`;
export const QuestionSolve = React.memo(({ test_id }) => {
    const { t } = useTranslation();
    const { isMobile, isDesktop } = useGlobalState();
    const memberId = useSelector((state) => getExternalUserId(state));
    const [testData, setTestData] = React.useState();
    const [submitData, setSubmitData] = React.useState([]);
    const [isSubmit, setIsSubmit] = React.useState(false);
    const [totalScore, setTotalScore] = React.useState(0);
    const [subject, setSubject] = React.useState('');
    const { choiceSubject, setChoiceSubject } = useGlobalState();
    const submitAnswerMutation = useMutation(postSubmitAnswer);
    const saveSubmitAnswerMutation = useMutation(postSaveSubmitAnswer);
    const { data, refetch } = useAssignmentList({
        assignment: false,
        id: test_id,
    });
    const { data: submitStatus, refetch: refetchSubmit } = useSubmitStatus({
        id: test_id,
        member_id: memberId,
    });
    const refecthList = throttle(() => {
        refetch();
        refetchSubmit();
    }, 1000, { trailing: false, leading: true });
    useEffect(() => {
        if (!memberId)
            return;
        if (test_id && test_id !== 'select') {
            refecthList();
        }
    }, [test_id, memberId]);
    useEffect(() => {
        if (!submitStatus)
            return;
        const { items } = submitStatus;
        if (items.length > 0) {
            const test = items.find(i => i.member_id === memberId);
            if (test) {
                setIsSubmit(test.corrects !== -1);
                setTotalScore(test.score);
            }
        }
    }, [submitStatus]);
    useEffect(() => {
        if (!data) {
            setTestData(undefined);
            return;
        }
        const { items } = data;
        if (data.items.length > 0) {
            setTestData(items[0]);
        }
        else {
            setTestData(undefined);
        }
    }, [data]);
    useEffect(() => {
        if (!test_id)
            return;
        if (choiceSubject[test_id]) {
            testData?.status === 'open' && !isSubmit && onPrevSubmit();
        }
    }, [choiceSubject, test_id]);
    const onSubmit = () => {
        if (!test_id) {
            APP.UI.alertMessage('테스트를 선택해주세요');
            return;
        }
        try {
            const emptyData = submitData.filter(i => !i.myAnswer || i.myAnswer.trim() === '').map(i => i.number);
            if (emptyData && emptyData.length > 0) {
                APP.UI.alertMessage(emptyData.toString() + '번 항목에 답변하지않았습니다.');
                return;
            }
            // Wrap the function body with startTransition
            const myAnswerList = submitData
                .map(i => {
                return {
                    question_id: i.id,
                    values: i.myAnswer && [i.myAnswer],
                };
            })
                .filter(i => i.values);
            submitAnswerMutation
                .mutateAsync({
                id: test_id,
                props: myAnswerList,
            })
                .then(() => {
                refecthList();
            });
        }
        catch (error) {
            try {
                const errorMessage = error.split('Error: ')[1];
                // Handle error
                APP.UI.alertMessage(errorMessage);
            }
            catch (error) {
                APP.UI.alertMessage('답변을 제출할 수 없습니다.');
            }
        }
    };
    const onPrevSubmit = () => {
        if (!test_id) {
            APP.UI.alertMessage('테스트를 선택해주세요');
            return;
        }
        try {
            const myAnswerList = submitData
                .map(i => {
                return {
                    question_id: i.id,
                    values: i.myAnswer && [i.myAnswer],
                };
            })
                .filter(i => i.values);
            saveSubmitAnswerMutation
                .mutateAsync({
                id: test_id,
                props: {
                    selected_item: choiceSubject[test_id],
                    answer: myAnswerList,
                },
            })
                .then(() => {
                refecthList();
            });
        }
        catch (error) {
            try {
                const errorMessage = error.split('Error: ')[1];
                // Handle error
                APP.UI.alertMessage(errorMessage);
            }
            catch (error) {
                APP.UI.alertMessage('답변을 제출할 수 없습니다.');
            }
        }
    };
    const handlerChoiceSubject = () => {
        if (test_id) {
            setChoiceSubject(prevChoiceSubject => ({
                ...prevChoiceSubject,
                [test_id]: subject,
            }));
        }
    };
    if (!memberId)
        return null;
    if (!test_id || !testData || test_id === 'select')
        return null;
    return (React.createElement(QuestionSolveStyled, { "$isMobile": isMobile, "$isDesktop": isDesktop },
        React.createElement("div", { className: "field-short" },
            React.createElement("div", { className: "field-001" },
                React.createElement("div", { className: "task-label overText" }, "\uD14C\uC2A4\uD2B8\uBA85"),
                React.createElement("div", { className: "fext-field" },
                    React.createElement("p", { className: "overText" }, testData.title))),
            React.createElement("div", { className: "field-001" }, testData.status === 'closed' || testData.status === 'created' ? (React.createElement("h3", { style: { color: Colors.pink } }, t(`assignment.${testData.status}Guid`))) : (React.createElement(React.Fragment, null,
                React.createElement("div", { className: "task-label overText" }, "\uB0A8\uC740 \uC2DC\uAC04"),
                React.createElement(RemaingTimer, { test_id: test_id, testData: testData }))))),
        testData.status === 'open' || testData.status === 'closed' ? (React.createElement("div", { className: "field-short file-container " },
            (testData.status === 'closed' ? true : choiceSubject[test_id]) &&
                testData.attachments &&
                testData.attachments.length > 0 && (React.createElement("div", { className: "test-area" },
                React.createElement("div", { className: "task-label overText" }, "\uD14C\uC2A4\uD2B8 \uD30C\uC77C"),
                React.createElement("div", { className: "fext-field" },
                    React.createElement(MyPdfViewer, { attachments: testData.attachments })))),
            React.createElement("div", { className: isSubmit ? 'test-area' : 'test-area-before-submit', style: {
                    flexDirection: 'column',
                    height: '100%',
                    overflow: 'hidden',
                    alignItems: 'flex-start',
                } },
                React.createElement("div", { className: "task-label" },
                    "\uBB38\uD56D \uD480\uC774 ",
                    testData.status === 'open' && isSubmit && `- ${totalScore}점`),
                React.createElement("div", { className: "fext-field" }, testData.status === 'open' && !isSubmit ? (React.createElement(DetailQuestionTable, { member_id: memberId, isOpen: true, type: testData.type, test_id: test_id, setData: setSubmitData, subject: subject, subject_name: testData.subject, setSubject: setSubject })) : (React.createElement(ErrataTable, { subject_name: testData.subject, isDetail: true, test_id: testData.id, member_id: memberId })))))) : null,
        testData.status === 'open' &&
            !isSubmit &&
            (choiceSubject[test_id] ? (React.createElement("div", { className: "btn-container", style: { flexDirection: 'row' } },
                React.createElement(Button, { className: "btn-error", text: "\uC784\uC2DC\uC800\uC7A5", styles: {
                        buttonWrap: {
                            marginRight: 0,
                        },
                    }, onClick: onPrevSubmit }),
                React.createElement(Button, { className: "btn-black", text: "common.submit", styles: {
                        buttonWrap: {
                            marginRight: 0,
                        },
                    }, onClick: onSubmit }))) : (React.createElement(Button, { className: "btn-black", text: "common.ok", styles: {
                    buttonWrap: {
                        marginRight: 0,
                    },
                }, onClick: handlerChoiceSubject })))));
});
