import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ChangeNickname from '../ChangeNickname';
const MemberItem = React.memo(({ name, role_name, isLocal, control, changeNick, renderOption, renderRoleList, renderKick, renderCotrol, hideControl, }) => {
    const { t } = useTranslation();
    const containerRef = useRef(null);
    const [hover, setHover] = useState(false);
    const handleClick = useCallback((event) => {
        if (hover)
            return;
        else
            hideControl();
    }, [hover, containerRef]);
    useEffect(() => {
        control && document.addEventListener('click', handleClick);
        return () => {
            document.removeEventListener('click', handleClick);
        };
    }, [control, handleClick]);
    return (React.createElement("div", { ref: containerRef, className: `item_member ${isLocal ? 'local' : ''}`, onMouseEnter: () => setHover(true), onMouseLeave: () => setHover(false) },
        React.createElement("div", { className: "member_name_wrapper nonSelect" },
            React.createElement("p", { className: "role" }, t(`role.${role_name}`)),
            changeNick ? (React.createElement(ChangeNickname, { nickname: name })) : (React.createElement("span", { className: "name", style: { color: isLocal ? '#8bacff' : '' } },
                !changeNick && (name || '-'),
                ' '))),
        React.createElement("div", { className: "member_icon_wrapper" }, renderOption()),
        control && (React.createElement("div", { className: "control control_true" },
            renderRoleList(),
            renderKick(),
            renderCotrol()))));
});
export default MemberItem;
export const NoAttend = React.memo(() => {
    return React.createElement("div", null, " \uBBF8 \uCC38 \uC11D ");
});
export const GroupName = React.memo(({ group_id }) => {
    return React.createElement("div", { className: "overText" },
        " ",
        group_id,
        " ");
});
