import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getPropertyValue } from '../base/settings';
import { Icon } from '../base/ui';
import { getLayoutMemberByType } from '../video-layout/functions';
import { DualMemeber } from './DualMemeber';
export const DualMonitor = React.memo(() => {
    const remote = useSelector((state) => getLayoutMemberByType(state, 'remote') || []);
    const grid_count = useSelector((state) => getPropertyValue(state, 'grid_count'));
    const renderedItems = useMemo(() => Array.from({ length: grid_count }).map((_, index) => remote[index] ? (React.createElement(DualMemeber, { key: remote[index], id: remote[index] })) : (React.createElement("div", { className: "dual-item", key: `no-user-${index}` },
        React.createElement(Icon, { icon: "icon-not-user", size: 92 })))), [remote]);
    return React.createElement("div", { className: "dual-monitor" }, renderedItems);
});
