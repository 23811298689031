import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { ROLE, checkRoleLevel, getLocalMemberRole } from '../../base/members';
import { ClickButton } from '../../base/ui';
import ScreenOption from './ScreenOption';
export const ScreenSetting = React.memo(({ frame, renderSubmitBtn, handlerUpdatePolicy, }) => {
    const { t } = useTranslation();
    const isShowLvl = useSelector((state) => checkRoleLevel(getLocalMemberRole(state), ROLE.PRESENTER));
    const [screenFrame, setScreenFrame] = useState(15);
    useEffect(() => {
        setScreenFrame(frame);
    }, [frame]);
    /**
     * 화면 공유 프레임 설정
     */
    const renderFrameBtn = () => {
        if (!isShowLvl) {
            return React.createElement(ClickButton, { className: "isFocus disable", isText: true, label: screenFrame });
        }
        return [5, 15, 30].map(item => (React.createElement(ClickButton, { key: item, className: item === screenFrame ? 'isFocus' : '', isText: true, label: t(`dialog.${item}screen`), onClick: () => setScreenFrame(item) })));
    };
    const handlerClick = () => {
        const screenOption = {
            permission: false,
            frame: screenFrame,
            type: 'screen',
        };
        handlerUpdatePolicy({
            screen_option: screenOption,
        });
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(ScreenOption, { renderFrameBtn: renderFrameBtn }),
        isShowLvl && renderSubmitBtn(handlerClick)));
});
