import React, { useRef, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getLocalMember } from '../members';
import { Input } from '../ui';
import { addMessage } from './action';
import { toast } from 'react-toastify';
const ChatInputContainer = React.memo(() => {
    const inputRef = useRef(null);
    const hiddenInputRef = useRef(null);
    const dispatch = useDispatch();
    const local = useSelector((state) => getLocalMember(state));
    const [canSendMessage, setCanSendMessage] = useState(true);
    const onSendMessage = () => {
        toast.dismiss('chat-input-delay');
        if (inputRef.current) {
            const message = inputRef.current.value.trim();
            if (message === '' || !canSendMessage)
                return;
            dispatch(addMessage(message, local, true));
            inputRef.current.value = '';
            setTimeout(() => (inputRef.current.value = ''), 100);
            setCanSendMessage(false); // Disable sending temporarily
        }
    };
    const onKeyDown = (e) => {
        e.stopPropagation();
        if ((e.key === 'Enter' || e.key === 'NumpadEnter') && !e.shiftKey) {
            onSendMessage && onSendMessage();
            return;
        }
        if (e.key === 'Space' && !e.shiftKey) {
            e.preventDefault(); // Prevent space key input
        }
    };
    // Effect to reset canSendMessage after 1 second
    useEffect(() => {
        if (!canSendMessage) {
            const timer = setTimeout(() => {
                setCanSendMessage(true);
            }, 1000);
            return () => clearTimeout(timer);
        }
    }, [canSendMessage]);
    return (React.createElement(React.Fragment, null,
        React.createElement("input", { type: "text", ref: hiddenInputRef, style: { position: 'absolute', left: '-9999px', top: '-9999px' } }),
        React.createElement(Input, { ref: inputRef, className: "chat_input", textarea: true, minRows: 3, maxRows: 3, onKeyPress: canSendMessage
                ? e => onKeyDown(e)
                : e => {
                    if ((e.key === 'Enter' || e.key === 'NumpadEnter') && !e.shiftKey) {
                        e.preventDefault();
                    }
                } })));
});
export default ChatInputContainer;
