import _ from 'lodash';
import { getLocalMemberRole } from '../base/members';
import { StateListenerRegistry } from '../base/redux';
import ReducerRegistry from '../base/redux/ReducerRegistry';
import { ADD_SHARE_ITEM, ADD_SHARE_LIST, DELETE_SHARE_ITEM, SET_FOCUS_SHARE_ITEM, } from './actionTypes';
import { updateShareItem } from './actions';
import { DEFAULT_DOCUMENT, DEFAULT_INDEX, DOCUMENT_MODE, VOD_MODE, WHITE_MODE } from './constants';
import { getFocusItemByMode } from './functions';
import { LEAVE_ROOM, getActiveGroupId, isRoomJoined } from '../room';
const DEFAULT_STATE = {
    whiteboard: new Set(),
    document: new Set(),
    vod: new Set(),
    focus: {
        whiteboard: { uuid: DEFAULT_DOCUMENT, index: DEFAULT_INDEX },
        document: { uuid: undefined, index: undefined },
        vod: { uuid: undefined, index: undefined },
    },
    visibleList: new Set(),
};
let intervalId = null;
ReducerRegistry.register('features/share-layout', (state = DEFAULT_STATE, action) => {
    switch (action.type) {
        case LEAVE_ROOM:
            setInterval && clearInterval(setInterval);
            return { ...DEFAULT_STATE };
        // 초기 문서 저장
        case ADD_SHARE_LIST:
            state.whiteboard = action.whiteboard_list;
            state.document = action.document_list;
            state.vod = action.vod_list;
            state.visibleList = action.visible_list;
            return {
                ...state,
            };
        // 문서 아이템 저장
        case ADD_SHARE_ITEM:
            const copyState = _.cloneDeep(state);
            if (copyState.whiteboard && action.data.config === WHITE_MODE)
                copyState.whiteboard.add(action.data.uuid);
            if (copyState.document && action.data.config === DOCUMENT_MODE)
                copyState.document.add(action.data.uuid);
            if (copyState.vod && action.data.config === VOD_MODE)
                copyState.vod.add(action.data.uuid);
            copyState.visibleList = new Set([...copyState.visibleList].filter(item => item.uuid !== action.uuid));
            copyState.visibleList.add(action.data);
            // 중복을 제거할 때 사용할 Set
            const uniqueObjects = new Set();
            // 중복 제거 로직
            const filteredObjects = [...copyState.visibleList].filter(obj => {
                const uuid = obj.uuid;
                if (!uniqueObjects.has(uuid)) {
                    uniqueObjects.add(uuid);
                    return true;
                }
                return false;
            });
            copyState.visibleList = new Set(filteredObjects);
            return { ...copyState };
        // 문서 아이템 삭제
        case DELETE_SHARE_ITEM:
            state.whiteboard.delete(action.uuid);
            state.document.delete(action.uuid);
            state.vod.delete(action.uuid);
            state.visibleList = new Set([...state.visibleList.values()].filter(item => item.uuid !== action.uuid));
            return {
                ...state,
            };
        case SET_FOCUS_SHARE_ITEM:
            state.focus[action.mode] = { uuid: action.uuid, index: action.index };
            return {
                ...state,
            };
    }
    return state;
});
StateListenerRegistry.register((state) => {
    const active_group = getActiveGroupId(state);
    const local_role = getLocalMemberRole(state);
    const focus_id = getFocusItemByMode(state, VOD_MODE);
    const isJoined = isRoomJoined(state);
    return {
        active_group,
        role: local_role,
        focus_id,
        isJoined,
    };
}, async ({ active_group, role, focus_id, isJoined }, store, prevState) => {
    if (!isJoined)
        return;
    if (!role)
        return;
    store.dispatch(updateShareItem(focus_id.uuid, { user_uuid: undefined, page: 'complete' }, true));
    // intervalId = setInterval(() => updateStreamingAuth(store, store.getState()), 1000 * 60 * 10);
}, {
    deepEquals: true,
});
