import React, { useMemo, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { ClickButton, DivElement, TextElement } from '../ui';
import { hideDialog } from './actions';
import { DialogStyled } from './components';
import { useGlobalState } from '../../app/GlobalStateProvider';
const Dialog = React.memo(({ className, titleKey, width, height, isFit, style, hideCancelButton, openDropButton, onCancel, onDrop, children, }) => {
    const dispatch = useDispatch();
    const { isMobile } = useGlobalState();
    const containerRef = useRef(null);
    const dialogRef = useRef(null);
    const dialogStyle = useMemo(() => {
        return {
            width: `${isFit ? '100%' : width ? `${width}px` : 'auto'}`,
            height: `${isFit ? '100%' : `${height}px`}`,
            ...style,
        };
    }, [width, height, style]);
    const handleHideDialog = () => {
        if (onCancel)
            onCancel();
        else
            dispatch(hideDialog());
    };
    const renderHead = useMemo(() => (React.createElement(DivElement, { className: "dialog_header" },
        React.createElement(TextElement, { className: "title overText", text: titleKey }),
        React.createElement("div", { className: "close" },
            openDropButton && React.createElement(ClickButton, { className: "underbar", onClick: onDrop }),
            !hideCancelButton && (React.createElement(ClickButton, { icon: "common_del", size: 18, color: "#C7C7C7", onClick: handleHideDialog }))))), [titleKey, hideCancelButton, handleHideDialog]);
    return (React.createElement(DialogStyled, { ref: containerRef, className: `${isMobile ? 'mobile' : 'desktop'}` },
        React.createElement(DivElement, { ref: dialogRef, className: `dialog_wrapper ${className}` },
            React.createElement(DivElement, { className: "dialog_content", style: dialogStyle },
                renderHead,
                React.createElement(DivElement, { className: "dialog_body" }, children)))));
});
export default Dialog;
