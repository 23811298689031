import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { getCurrentMode, setLayoutMode, videoLayoutMode } from '../../../video-layout';
import { Dialog, toggleDialog } from '../../dialog';
import { ROLE, checkRoleLevel, getLocalMemberRole } from '../../members';
import { ClickButton, DivElement } from '../../ui';
import { useGlobalState } from '../../../app/GlobalStateProvider';
const ChangeModeButton = React.memo(({ permissions, fix_mode }) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { isDesktop } = useGlobalState();
    const isShowLvl = useSelector((state) => {
        const role = getLocalMemberRole(state);
        return checkRoleLevel(role, ROLE.PARTICIPANT);
    });
    const mode = useSelector((state) => getCurrentMode(state));
    const layout_list = useMemo(() => {
        let list = [];
        if (fix_mode) {
            list = [fix_mode];
        }
        else {
            if (permissions.indexOf('layout') !== -1) {
                const video_layout = [
                    videoLayoutMode.grid,
                    videoLayoutMode.pin,
                    videoLayoutMode.voice,
                    videoLayoutMode.seminar,
                ];
                list = [...list, ...video_layout];
            }
            if (permissions.indexOf('share') !== -1) {
                const share_layout = [videoLayoutMode.document, videoLayoutMode.note, videoLayoutMode.vod];
                list = [...list, ...share_layout];
            }
        }
        return list;
    }, [fix_mode]);
    if (!isShowLvl)
        return null;
    return (React.createElement(Dialog, { titleKey: "setting.modeChange", className: "option" },
        React.createElement(DivElement, { className: "layout_mode" }, layout_list.map(name => {
            if (!APP.config.ui.layout[name]) {
                return null;
            }
            const isFocus = name === mode;
            return (React.createElement(ClickButton, { key: name, className: `option_item ${isFocus ? 'isFocus' : ''}`, icon: name, color: "#000", label: t(`layout.${name}`), onClick: () => {
                    dispatch(setLayoutMode(name));
                    if (!isDesktop)
                        dispatch(toggleDialog());
                } }));
        }))));
});
export default ChangeModeButton;
