import React, { useMemo } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { updateRoomPolicy } from '../base/settings';
import { OnOffSwitch } from '../base/ui';
const SmallRaiseHandToggleStyled = styled.div `
	.switch {
		height: 20px;
		width: 100px;
		margin: 0;

		input:checked + .slider:before {
			transform: translateX(80px);
		}

		.slider:before {
			width: 20px;
			height: 20px;
			border: 1px solid #d5d5d5;
			box-sizing: border-box;
		}

		.text {
			text-align: center;
		}
	}
`;
export const SmallRaiseHandToggle = React.memo(() => {
    const dispatch = useDispatch();
    const { hands_up_policy, hands_up_policy_time } = useSelector((state) => {
        return {
            hands_up_policy: state['features/base/settings'].policy.hands_up,
            hands_up_policy_time: state['features/base/settings'].policy.hands_up_time,
        };
    }, shallowEqual);
    const label = useMemo(() => (hands_up_policy === 'off' ? 'OFF' : 'ON'), [hands_up_policy]);
    const handlerClick = () => {
        const changeup = hands_up_policy === 'off' ? 'keep' : 'off';
        console.log(changeup, hands_up_policy, hands_up_policy_time);
        // if (hands_up_policy === changeup) return;
        dispatch(
        // @ts-ignore
        updateRoomPolicy({ hands_up: hands_up_policy === 'off' ? 'keep' : 'off', hands_up_time: hands_up_policy_time }, false));
    };
    return (React.createElement(SmallRaiseHandToggleStyled, null,
        React.createElement(OnOffSwitch, { label: label, isShow: hands_up_policy !== 'off', handlerClick: handlerClick })));
});
