import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import NewWindow from 'react-new-window';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from '../../base/common';
import ToggleSideBar from '../../base/header/components/ToggleSideBar';
import { getPropertyValue, updateDualSideOpen } from '../../base/settings';
import { SideBarContainer } from '../../base/sidebar';
import { ClickButton } from '../../base/ui';
import { DualMonitor } from '../../dualConference';
import { setSortChange, toggleDualMonitor } from '../actions';
import { getCurrentMode } from '../functions';
import PageButton from './PageButton';
import { DualStyled } from './layout/styles.web';
import moment from 'moment';
import { getRemoteId } from '../../base/members';
function DualLayoutConatiner() {
    const { t } = useTranslation();
    const newWindowNode = useRef();
    const layoutElement = useRef();
    const dispatch = useDispatch();
    const mode = useSelector((state) => getCurrentMode(state));
    const openSide = useSelector((state) => getPropertyValue(state, 'dualSideOpen'));
    const [windowOpen, setWindowOpen] = useState(false);
    const [outerStyle, setOuterStyle] = useState({ width: 960, height: 960 });
    const [prev, setPrev] = useState(false);
    const [next, setNext] = useState(false);
    const alertMessage = (data) => {
        if (newWindowNode.current) {
            const elementWindow = newWindowNode.current.state.externalWindow;
            if (elementWindow) {
                if (data?.message)
                    elementWindow.alert(t(data.message));
                if (data?.confirmMessage) {
                    const result = elementWindow.confirm(t(data.confirmMessage));
                    if (result)
                        data.confrimSuccess && data.confrimSuccess();
                }
            }
        }
    };
    useEffect(() => {
        APP.eventManager.subscribe('ALERT_DUAL_LAYOUT', alertMessage);
        return () => {
            APP.eventManager.unsubscribe('ALERT_DUAL_LAYOUT', alertMessage);
        };
    }, []);
    useEffect(() => {
        if (windowOpen && newWindowNode) {
            const element = newWindowNode.current.state.externalWindow;
            if (element && element.window) {
                element.window.addEventListener('resize', setContainerStyle);
            }
        }
    }, [windowOpen]);
    useEffect(() => {
        setContainerStyle();
    }, [openSide]);
    useEffect(() => {
        setWindowOpen(true);
        window.addEventListener('resize', setContainerStyle);
        return () => {
            setWindowOpen(false);
            window.removeEventListener('resize', setContainerStyle);
        };
    }, []);
    const unload = () => {
        dispatch(toggleDualMonitor());
        setWindowOpen(false);
    };
    const handlerSide = () => {
        // dispatch(updateSettings({ dualSideOpen: !openSide }));
        dispatch(updateDualSideOpen(!openSide));
    };
    const setContainerStyle = () => {
        if (layoutElement && layoutElement.current) {
            const { width, height } = layoutElement.current.getBoundingClientRect();
            setOuterStyle({ width, height });
        }
    };
    const onClick = () => {
        const members = getRemoteId(APP.store.getState, [], moment().format('YYYY-MM-DD HH:mm:ss'));
        dispatch(setSortChange(members));
    };
    const renderButton = (type, onClick) => {
        return (React.createElement(Button, { className: `click_button text-button `, text: type === 'next' ? '다음' : '이전', option: {
                ...(type === 'next'
                    ? {
                        buttonAfterIcon: {
                            show: true,
                            className: 'arrow-right',
                            name: 'arrow',
                            size: 16,
                            color: '#fff',
                            stroke: '#fff',
                        },
                    }
                    : {
                        buttonBeforeIcon: {
                            show: true,
                            className: 'arrow',
                            name: 'arrow',
                            size: 16,
                            color: '#fff',
                            stroke: '#fff',
                        },
                    }),
            }, onClick: onClick }));
    };
    return (React.createElement(NewWindow, { ref: newWindowNode, title: "Meemate", features: { width: 960, height: 960 }, onUnload: unload, copyStyles: true },
        React.createElement(DualStyled, null,
            openSide && (React.createElement("div", { className: "dual_sidebar" },
                React.createElement("div", { className: "dual_side_header" },
                    React.createElement(ToggleSideBar, { className: "side", mode: "chat", isText: true, size: 16, label: t(`setting.chat`), isDualMonitor: true }),
                    React.createElement(ToggleSideBar, { className: "side", mode: "member", size: 16, isText: true, label: t(`setting.member`), isDualMonitor: true })),
                React.createElement("div", { className: "dual_right_content" },
                    React.createElement(SideBarContainer, null)))),
            React.createElement("div", { className: "dual_content", style: { width: `${openSide ? 'calc(100% - 350px)' : '100%'}` } },
                React.createElement("div", { className: "dual_side_header2" },
                    React.createElement(ClickButton, { className: `downBtn ${openSide ? 'left' : 'right'}`, icon: "down", size: 20, onClick: handlerSide }),
                    React.createElement("div", { className: "page_btns" },
                        React.createElement(Button, { className: "btn btn-white", text: "\uB808\uC774\uC544\uC6C3 \uC815\uB82C", option: {
                                buttonBeforeIcon: {
                                    show: true,
                                    name: 'layout-sort',
                                    size: 16,
                                    color: '#fff',
                                    stroke: '#fff',
                                },
                            }, onClick: onClick }),
                        React.createElement(PageButton, { show: prev, type: "prev", vertical: false, setPrev: setPrev, setNext: setNext, renderButton: renderButton }),
                        React.createElement(PageButton, { show: next, type: "next", vertical: false, setPrev: setPrev, setNext: setNext, renderButton: renderButton }))),
                React.createElement(DualMonitor, null)))));
}
export default React.memo(DualLayoutConatiner);
