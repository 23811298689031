import moment from 'moment';
import { fetchErrorData } from '../../../../modules/API/API';
function getWebsocketURLBase(urlBase) {
    if (urlBase.startsWith('http:')) {
        return `ws:${urlBase.substring(5)}`;
    }
    else if (urlBase.startsWith('https:')) {
        return `wss:${urlBase.substring(6)}`;
    }
    throw new Error('알 수 없는 프로토콜');
}
const formatMessage = msg => {
    if (!msg || !msg?.format) {
        return '';
    }
    if (!msg?.params) {
        return msg.format;
    }
    return msg.format.replace(/\{(\w+)\}/g, (match, p1) => {
        return msg.params[p1];
    });
};
export class PlayerWebsocket {
    constructor() {
        this.onMessage = [];
        this.connection = null;
        this.nextId = 0;
        this.timeDiff = 0;
        this.streamingId = '';
        this.timeInterval = null;
    }
    cleanUp() {
        this.onMessage = [];
        this.nextId = 0;
        this.timeDiff = 0;
        this.streamingId = '';
    }
    connect(urlBase, handleMessage) {
        const connection = new WebSocket(`wss://${urlBase}/api/media/v1/websocket`);
        return new Promise((resolve, reject) => {
            this.connection = connection;
            connection.onopen = async () => {
                this.timeInterval = setInterval(async () => {
                    await this._request('GetServerTime', null, 5000);
                }, 1000 * 60);
                connection.onmessage = message => {
                    handleMessage && handleMessage(JSON.parse(message.data));
                    this.onMessage.forEach(handle => handle(message.data));
                };
                connection.onclose = async () => {
                    this.timeInterval && clearInterval(this.timeInterval);
                    await fetchErrorData('vod websocket close');
                    this.connection = null;
                    reject(false);
                };
                resolve(true);
            };
            connection.onerror = async (err) => {
                this.timeInterval && clearInterval(this.timeInterval);
                this.connection = null;
                alert('네트워크 연결이 불안정합니다. 다시 접속 부탁드립니다.');
                await fetchErrorData({
                    message: 'vod websocket error',
                    data: err,
                });
                reject(err);
            };
        });
    }
    disconnect() {
        this.connection?.close();
    }
    getTimeDiff() {
        return new Promise(async (resolve, reject) => {
            try {
                const t1 = Date.now();
                const message = await this._request('GetServerTime', null, 5000);
                const t2 = Date.now();
                if (message?.status != 200) {
                    throw new Error(formatMessage(message?.payload?.message));
                }
                const serverTime = Date.parse(message?.payload?.result?.time);
                this.timeDiff = t1 + (t2 - t1) / 2 - serverTime;
                return resolve(this.timeDiff);
            }
            catch (err) {
                reject(formatMessage(err));
            }
        });
    }
    // 스트리밍 참여
    joinStreaming(id) {
        if (!id)
            return Promise.resolve();
        if (this.streamingId === id) {
            return Promise.resolve();
        }
        return new Promise(async (resolve, reject) => {
            try {
                const joinResponse = await this._request('SubscribeStreaming', {
                    streaming_id: id,
                });
                if (joinResponse?.status != 200) {
                    throw new Error(joinResponse?.payload?.message);
                }
                this.timeDiff = await this.getTimeDiff();
                this.streamingId = id;
                const { play_position, speed, status, play_time, time, media, pause_time } = joinResponse?.payload?.result;
                const isPaused = moment(play_time).isBefore(moment(pause_time));
                resolve({
                    play_position,
                    speed,
                    status,
                    time,
                    play_time: isPaused ? pause_time : play_time,
                    isPaused,
                    media,
                });
            }
            catch (err) {
                reject(err);
            }
        });
    }
    close() {
        try {
            this.connection?.close();
        }
        catch (err) {
            console.log(err);
        }
    }
    _request(clazz, payload, timeout = 5000) {
        if (this.connection && this.connection.readyState === WebSocket.OPEN) {
            const requestID = `${Date.now()}-${++this.nextId}`;
            this.connection.send(JSON.stringify({
                class: clazz,
                id: requestID,
                payload,
            }));
            return this._waitFor(data => JSON.parse(data), res => res?.payload?.id === requestID || res?.id === requestID, timeout, clazz);
        }
        console.log('웹소켓 연결 안됨.', payload);
        return Promise.reject('api.refreshError');
    }
    _subscribe(handle) {
        this.onMessage.push(handle);
        return () => {
            const index = this.onMessage.findIndex(element => element === handle);
            if (index >= 0) {
                this.onMessage.splice(index, 1);
            }
        };
    }
    async _waitFor(hook, check, timeout, clazz) {
        return new Promise((resolve, reject) => {
            let timerID;
            let unsubscribe;
            const cleanup = () => {
                clearTimeout(timerID);
                unsubscribe();
            };
            unsubscribe = this._subscribe(data => {
                try {
                    const result = hook(data);
                    if (!check(result))
                        return;
                    cleanup();
                    resolve(result);
                }
                catch (err) {
                    cleanup();
                    reject(err);
                }
            });
            timerID = setTimeout(() => {
                cleanup();
                reject(new Error(`${clazz} timeout`));
            }, timeout);
        });
    }
}
