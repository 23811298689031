import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useGlobalState } from '../../app/GlobalStateProvider';
import { ROLE, checkRoleLevel, getLocalMemberRole } from '../../base/members';
import { SHARE_MODE_LIST, VIDEO_MODE_LIST, videoLayoutMode } from '../constants';
import { getDualMonitor, getFilmStyle, isShowByType } from '../functions';
import MembersLayout from './MembersLayout';
import ScreenModeLayout from './ScreenModeLayout';
import ShareModeLayout from './ShareModeLayout';
import { MemberContainer } from './layout';
const ShareMemberLayout = React.memo(() => {
    return (React.createElement(MembersLayout, { className: "horizontal_item", type: "seminar", noShowMember: true, maxStyle: { width: 172, height: 98 } }));
});
const LayoutScreen = React.memo(({ mode, layoutStyle }) => {
    const showPin = useSelector((state) => isShowByType(state, 'pin'));
    const isDualMonitor = useSelector((state) => getDualMonitor(state));
    const localRole = useSelector((state) => getLocalMemberRole(state));
    const [isLeft, setIsLeft] = useState(true);
    const { isDesktop } = useGlobalState();
    const isMobile = useMemo(() => !isDesktop, [isDesktop]);
    /**
     * 화면 공유
     */
    const renderScreenLayout = useMemo(() => React.createElement(ScreenModeLayout, { isMobile: isMobile || isDualMonitor }), [isMobile, isDualMonitor]);
    /**
     * 문서 공유
     */
    const renderDocumentLayout = useMemo(() => {
        const isShowLvl = checkRoleLevel(localRole, ROLE.PRESENTER);
        return (React.createElement(React.Fragment, null,
            !isShowLvl || isMobile || !isLeft || isDualMonitor ? null : (React.createElement(MemberContainer, { className: `horizontal non_expand` },
                React.createElement(ShareMemberLayout, null))),
            React.createElement(ShareModeLayout, { mode: mode, isMobile: isMobile || isDualMonitor, isLeft: isLeft, setIsLeft: setIsLeft })));
    }, [localRole, mode, isMobile, isLeft, isDualMonitor]);
    /**
     * 세미나 모드
     */
    const renderSeminarLayout = useMemo(() => {
        const outerSize = showPin ? getFilmStyle(APP.store.getState, 2, layoutStyle) : layoutStyle;
        return (React.createElement("div", { className: "test_sesminar" },
            React.createElement(MembersLayout, { className: "mainFlex", noShowMember: true, type: "seminar", outerSize: outerSize }),
            React.createElement(MembersLayout, { className: "gridFlex", noShowMember: true, type: "pin", outerSize: outerSize })));
    }, [layoutStyle, showPin]);
    const renderMobileLayout = useMemo(() => (React.createElement("div", { className: "vertical_grid" },
        React.createElement(MembersLayout, { className: "gridSlide", noShowMember: true, vertical: true, type: videoLayoutMode.seminar === mode ? 'seminar' : 'remote' }),
        videoLayoutMode.seminar === mode && (React.createElement(MembersLayout, { className: "gridSlide", noShowMember: false, vertical: true, type: "pin" })))), [mode]);
    const renderLayout = useMemo(() => {
        if (isMobile && VIDEO_MODE_LIST.includes(mode)) {
            return renderMobileLayout;
        }
        switch (mode) {
            case videoLayoutMode.screen:
                return renderScreenLayout;
            case videoLayoutMode.seminar:
                return renderSeminarLayout;
            default:
                if (SHARE_MODE_LIST.includes(mode)) {
                    return renderDocumentLayout;
                }
                return renderSeminarLayout;
        }
    }, [isMobile, mode, renderDocumentLayout, renderSeminarLayout, renderMobileLayout]);
    return renderLayout;
});
export default React.memo(LayoutScreen);
