import { tempEnterRoom } from '../../room';
import { APP_WILL_MOUNT } from '../app';
import { PAGE_TYPE } from '../app/constants';
import { MiddlewareRegistry } from '../redux';
import { getDisplayName, updateSettings } from '../settings';
// @ts-ignore
import { storage } from '../util';
import { getSearchData, loginByToken } from './functions';
MiddlewareRegistry.register(store => next => async (action) => {
    switch (action.type) {
        case APP_WILL_MOUNT:
            const storageKey = getSearchData('storage_key');
            const storageToken = storageKey
                ? storage.get(storageKey)?.token
                : location.search.includes('hidden') && storage.get('token');
            if (storageToken) {
                await loginByToken(storageToken);
            }
            const data = storageKey ? storage.get(storageKey) : null;
            if (data && data.ui_option) {
                const uiOption = data.ui_option;
                store.dispatch(updateSettings({
                    displayName: uiOption.defaultNickname || 'test',
                    enableLobby: uiOption.enableLobbyPage || false,
                    enableNickname: uiOption.enableNicknamePage || false,
                }));
            }
            if (location.pathname.includes('room') || location.pathname.includes('test')) {
                const uuid = location.pathname.split('/').pop();
                if (uuid) {
                    tempEnterRoom({ uuid }).then(response => {
                        if (!response.complete) {
                            APP.UI.alertMessage(response.data, null, true);
                            APP.eventManager.publish('UPDATE_PAGE', {
                                page: PAGE_TYPE.LOBBY,
                            });
                        }
                        else {
                            if (location.pathname.includes('test')) {
                                APP.eventManager.publish('UPDATE_PAGE', {
                                    page: PAGE_TYPE.TEST_MAIN,
                                });
                            }
                            else {
                                APP.eventManager.publish('UPDATE_PAGE', {
                                    page: getDisplayName(store.getState) ? PAGE_TYPE.DEVICE : PAGE_TYPE.NICKNAME,
                                });
                            }
                        }
                    });
                }
            }
            else {
                const pathArray = window.location.pathname.split('/');
                const secondPathValue = pathArray[1];
                if (['test', 'error', 'login', 'signup'].includes(secondPathValue)) {
                    APP.eventManager.publish('UPDATE_PAGE', {
                        page: secondPathValue,
                    });
                }
            }
            break;
    }
    return next(action);
});
