import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dialog, toggleDialog } from '../base/dialog';
import { translate } from '../base/i18n';
import { ROLE, checkRoleLevel, getLocalMember } from '../base/members';
import { ClickButton } from '../base/ui';
import { getActiveGroupId } from './functions';
import RoomLeave from './components/RoomLeave';
import { PAGE_TYPE } from '../base/app/constants';
const FORCE_END = 'forceEnd';
const GROUP_OUT = 'group_out';
const TERMINATE = 'terminate';
function AbstractRoomLeave({ t }) {
    const dispatch = useDispatch();
    const local = useSelector((state) => getLocalMember(state));
    const active_group = useSelector((state) => getActiveGroupId(state));
    const [list, setList] = useState([TERMINATE]);
    const handlerTerminate = () => {
        APP.management.leave();
        // TERMINATE 버튼 클릭 시 실행되는 코드
        self.close();
        APP.eventManager.publish('UPDATE_PAGE', {
            page: PAGE_TYPE.ERROR,
        });
    };
    useEffect(() => {
        const newButtonList = checkRoleLevel(local?.role, ROLE.MANAGER)
            ? active_group === ''
                ? [FORCE_END, TERMINATE]
                : [GROUP_OUT, TERMINATE]
            : [TERMINATE];
        setList(newButtonList);
    }, [local?.role, active_group]);
    const handlerForceEnd = async () => {
        //APP.API.forceEndRoom(),
        Promise.all([APP.API.forceEndRoom()])
            .then(result => {
            dispatch(toggleDialog());
        })
            .catch(err => {
            console.log(err);
            APP.UI.alertMessage(err);
        });
    };
    const handlerClick = useCallback(type => {
        switch (type) {
            case GROUP_OUT:
                // handlerGroupout();
                break;
            case FORCE_END:
                handlerForceEnd();
                break;
            case TERMINATE:
                handlerTerminate();
                break;
        }
    }, [handlerForceEnd, handlerTerminate]);
    const renderButtons = useMemo(() => list.map(name => (React.createElement(ClickButton, { key: name, isText: true, label: t(`dialog.${name}`), onClick: () => handlerClick(name) }))), [list, t, handlerClick]);
    return (React.createElement(Dialog, { titleKey: t('dialog.rommLeave'), width: "450" },
        React.createElement(RoomLeave, { guid: list.includes(FORCE_END) ? t('dialog.forceEndGuid') : t('dialog.terminateGuid'), renderButtons: renderButtons })));
}
export default React.memo(translate(AbstractRoomLeave));
