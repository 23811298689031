import { PAGE_TYPE } from '../../../features/base/app/constants';
import JitsiMeetJS, { JitsiConnectionEvents } from '../../../features/base/lib-jitsi-meet';

class JitsiConnection {
	constructor() {
		this.connection = null;
		this.connecting = false;
	}

	// jitsi 연결
	connect(config) {
		if (this.connection) {
			// 이미 연결 되어있는 상태
			return;
		}

		if (this.connecting) {
			// 연결 중인 상태
			return;
		}

		// this.connecting = true;
		// jitsi websocket connect
		const connection = new JitsiMeetJS.JitsiConnection(null, null, config);

		// 연결 이벤트 등록
		return new Promise((resolve, reject) => {
			connection.addEventListener(
				JitsiMeetJS.events.connection.CONNECTION_ESTABLISHED,
				handleConnectionEstablished,
			);
			connection.addEventListener(JitsiMeetJS.events.connection.CONNECTION_FAILED, handleConnectionFailed);
			// connection.addEventListener(JitsiMeetJS.events.connection.CONNECTION_DISCONNECTED, retryConnection);
			// connection.addEventListener(JitsiMeetJS.events.connection.CONNECTION_INTERRUPTED, retryConnection);

			function handleConnectionEstablished() {
				// connection
				this.connection = connection;
				this.connecting = false;
				// 이벤트 삭제
				unsubscribe();

				resolve(connection);
			}

			function handleConnectionFailed(error, message, credentials, details) {
				this.connection = null;
				this.connecting = false;
				// 연결 실패
				connection.removeEventListener(JitsiConnectionEvents.CONNECTION_FAILED, handleConnectionFailed);

				reject(error);
			}

			function unsubscribe() {
				connection.removeEventListener(
					JitsiMeetJS.events.connection.CONNECTION_ESTABLISHED,
					handleConnectionEstablished,
				);

				connection.removeEventListener(JitsiMeetJS.events.connection.CONNECTION_FAILED, handleConnectionFailed);
			}

			this.connection = connection;
			connection.connect();
		});
	}

	// jitsi 연결 끊기
	disconnect() {
		if (this.connection) {
			this.connection.disconnect();

			this.connection = null;
			this.connecting = false;
		}
	}
}

export default JitsiConnection;
