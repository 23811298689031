import React, { useEffect, useState } from 'react';
import { Button } from '../../../common';
const FullScreenBtn = ({ className, video, handlerFullScreen, handlerExitFullScreen, autoPlay = true }) => {
    const [isFullscreen, setIsFullscreen] = useState(false);
    const [errorOpen, setErrorOpen] = useState(false);
    const enterFullscreen = () => {
        try {
            if (video)
                video.controls = false; // Hide controls
            if (handlerFullScreen) {
                handlerFullScreen();
            }
        }
        catch (error) {
            const roomContainer = document.getElementById('room-container');
            if (roomContainer) {
                roomContainer.classList.add('hide-navigation');
            }
            setErrorOpen(true);
        }
        finally {
            if (video) {
                video.controls = false; // Show controls when exiting fullscreen
                autoPlay && video.play().catch(err => { }); // Continue playing if it was playing before
            }
        }
    };
    const exitFullscreen = () => {
        try {
            if (video)
                video.controls = false; // Hide controls
            if (errorOpen) {
                const roomContainer = document.getElementById('room-container');
                if (roomContainer) {
                    roomContainer.classList.remove('hide-navigation');
                }
                return;
            }
            if (handlerExitFullScreen) {
                handlerExitFullScreen();
            }
        }
        catch (error) {
            const roomContainer = document.getElementById('room-container');
            if (roomContainer) {
                roomContainer.classList.remove('hide-navigation');
            }
        }
        finally {
            if (video) {
                video.controls = false; // Show controls when exiting fullscreen
                autoPlay && video.play().catch(err => { }); // Continue playing if it was playing before
            }
        }
    };
    const toggleFullscreen = () => {
        if (isFullscreen) {
            exitFullscreen();
        }
        else {
            enterFullscreen();
        }
        setIsFullscreen(!isFullscreen);
    };
    useEffect(() => {
        const handleFullscreenChange = () => {
            video.controls = false; // Show controls when exiting fullscreen
            autoPlay && video.play(); // Continue playing if it was playing before
            if (!document.fullscreenElement || !document.webkitFullscreenElement) {
                setIsFullscreen(false);
            }
        };
        document.addEventListener('fullscreenchange', handleFullscreenChange);
        document.addEventListener('webkitfullscreenchange', handleFullscreenChange);
        return () => {
            document.removeEventListener('fullscreenchange', handleFullscreenChange);
            document.removeEventListener('webkitfullscreenchange', handleFullscreenChange);
        };
    }, [errorOpen, video]);
    useEffect(() => {
        return () => {
            try {
                const roomContainer = document.getElementById('room-container');
                if (roomContainer && roomContainer.classList.contains('hide-navigation')) {
                    roomContainer.classList.remove('hide-navigation');
                }
                setIsFullscreen(false);
            }
            catch (err) { }
        };
    }, []);
    return (React.createElement(Button, { id: "fullscreenBtn", className: className, onClick: toggleFullscreen, option: {
            buttonBeforeIcon: {
                show: true,
                name: isFullscreen ? 'fullscreenOn' : 'fullscreenOff',
                size: 25,
                color: '#fff',
                className: 'handlerIcon',
            }
        } })
    // <button id="fullscreenBtn" className={className} onClick={toggleFullscreen}>
    // 	<Icon icon={isFullscreen ? 'fullscreenOff' : 'fullscreenOn'} size={25} fill="#fff" stroke='#fff' />
    // </button>
    );
};
export default FullScreenBtn;
