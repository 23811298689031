import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { useGlobalState } from '../../app/GlobalStateProvider';
import { Button } from '../common';
const DraggableContainer = styled.div `
	position: absolute;
	top: ${props => props.position.top}px;
	left: ${props => props.position.left}px;

	.button {
		cursor: ${props => (props.dragging ? 'grabbing' : 'pointer')}!important;
	}
`;
export const DragTest = ({ hide, setHide }) => {
    const { position, setPosition } = useGlobalState();
    const [dragging, setDragging] = useState(false);
    const [offset, setOffset] = useState({ x: 0, y: 0 });
    const containerRef = useRef(null);
    const buttonRef = useRef(null);
    const clickIgnored = useRef(false);
    const handleMouseUp = () => {
        if (dragging) {
            setDragging(false);
        }
    };
    const handleTouchEnd = () => {
        if (dragging) {
            setDragging(false);
        }
    };
    useEffect(() => {
        document.addEventListener('mouseup', handleMouseUp);
        document.addEventListener('mousemove', handleMouseMove);
        document.addEventListener('touchend', handleTouchEnd);
        document.addEventListener('touchmove', handleTouchMove, { passive: false }); // Add passive: false here
        return () => {
            document.removeEventListener('mousemove', handleMouseMove);
            document.removeEventListener('mouseup', handleMouseUp);
            document.removeEventListener('touchend', handleTouchEnd);
            document.removeEventListener('touchmove', handleTouchMove);
        };
    }, [dragging]);
    const handleMouseDown = (e) => {
        setDragging(true);
        const rect = containerRef.current?.getBoundingClientRect();
        if (rect) {
            setOffset({
                x: e.clientX - rect.left,
                y: e.clientY - rect.top,
            });
        }
    };
    const handleTouchStart = (e) => {
        setDragging(true);
        const rect = containerRef.current?.getBoundingClientRect();
        if (rect) {
            setOffset({
                x: e.touches[0].clientX - rect.left,
                y: e.touches[0].clientY - rect.top,
            });
        }
        e.preventDefault(); // Prevent default touch behavior
    };
    const handleMouseMove = (e) => {
        if (dragging && containerRef.current) {
            const newLeft = Math.max(0, Math.min(e.clientX - offset.x, window.innerWidth - 155));
            const newTop = Math.max(0, Math.min(e.clientY - offset.y, window.innerHeight - 46));
            setPosition({ left: newLeft, top: newTop });
        }
    };
    const handleTouchMove = (e) => {
        if (dragging && containerRef.current) {
            const newLeft = Math.max(0, Math.min(e.touches[0].clientX - offset.x, window.innerWidth - 155));
            const newTop = Math.max(0, Math.min(e.touches[0].clientY - offset.y, window.innerHeight - 46));
            setPosition({ left: newLeft, top: newTop });
            e.preventDefault(); // Prevent default touch behavior
        }
    };
    const handleButtonClick = (e) => {
        if (dragging || clickIgnored.current) {
            e.stopPropagation();
            e.preventDefault();
            clickIgnored.current = false; // Reset click ignored state
        }
        else {
            setHide(false);
        }
    };
    return (React.createElement(DraggableContainer, { ref: containerRef, position: position, dragging: dragging, onMouseDown: handleMouseDown, onTouchStart: handleTouchStart },
        React.createElement(Button, { ref: buttonRef, className: "btn-default", option: {
                buttonBeforeIcon: {
                    name: 'arrow-left',
                    size: 16,
                    className: 'icon',
                    color: 'var(--color-black)',
                },
            }, styles: {
                buttonWrap: {
                    margin: 0,
                },
            }, text: "\uD14C\uC2A4\uD2B8\uB85C \uB3CC\uC544\uAC00\uAE30", onDoubleClick: handleButtonClick, draggable: false })));
};
