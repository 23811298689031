import React from 'react';
import { useSelector } from 'react-redux';
import { ClickButton } from '../ui';
import { checkRoleLevel, getLocalMemberRole, getMemberRecordList } from './functions';
import { useDispatch } from 'react-redux';
import { toggleDialog } from '../dialog';
import RecordMemberListContainer from './RecordMemberListContainer';
import { ROLE } from './constans';
const RecordButton = React.memo(({ iconStyles }) => {
    const dispatch = useDispatch();
    const showBtn = useSelector((state) => checkRoleLevel(getLocalMemberRole(state), ROLE.MANAGER)
        ? getMemberRecordList(state).length > 0
            ? true
            : false
        : false);
    const onClick = () => dispatch(toggleDialog(RecordMemberListContainer));
    if (!showBtn)
        return null;
    return React.createElement(ClickButton, { isText: true, className: "record_list", label: 'R E C', styles: iconStyles, onClick: onClick });
});
export default RecordButton;
