import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { AudioRoutePicker } from '../../../mobile/audio-mode/components';
import AbstractRoomLeave from '../../../room/AbstractRoomLeave';
import { openDialog, toggleDialog, toggleSheet } from '../../dialog';
import { isMobileBrowser } from '../../environment/utils';
import { ROLE, RecordButton, checkRoleLevel, getLocalMemberRole } from '../../members';
import { OptionDialogContainer } from '../../options';
import { SettingContainerDialog, getPropertyValue, updateSettings } from '../../settings';
import { ClickButton } from '../../ui';
import ChangeModeButton from './ChangeModeButton';
import ToggleAudioButton from './ToggleAudioButton';
import ToggleSideBar from './ToggleSideBar';
import ToggleVideoButton from './ToggleVideoButton';
import ToggleVideoChange from './ToggleVideoChange';
import { useSelector } from 'react-redux';
import { LocalHandsup } from '../../local/LocalHandsup';
function PersonalSettingsButton({ className, mode, isMobile, enableLable, iconStyles, permissions, onClick, }) {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const isShowLvl = useSelector((state) => checkRoleLevel(getLocalMemberRole(state), ROLE.PRESENTER));
    const individualLive = useSelector((state) => getPropertyValue(state, 'individualLive'));
    const testStart = useSelector((state) => getPropertyValue(state, 'testStart'));
    const iconSize = useMemo(() => (enableLable ? 20 : APP.config.style.enableIconSize), [enableLable]);
    const renderComponent = useMemo(() => {
        switch (mode) {
            case 'recordList':
                return React.createElement(RecordButton, { iconStyles: iconStyles });
            case 'camera':
                return (React.createElement(ToggleVideoButton, { key: mode, className: className, iconStyles: iconStyles, label: enableLable ? t(`setting.${mode}`) : undefined, enableLable: enableLable }));
            case 'mic':
                return (React.createElement(ToggleAudioButton, { key: mode, className: className, iconStyles: iconStyles, label: enableLable ? t(`setting.${mode}`) : undefined, enableLable: enableLable }));
            case 'text-out':
                onClick = () => dispatch(openDialog(AbstractRoomLeave));
                return (React.createElement(ClickButton, { key: mode, isText: true, className: className, label: t(`setting.out`), styles: iconStyles, onClick: onClick }));
            case 'out':
                onClick = () => {
                    if (location.pathname.includes('test')) {
                        APP.eventManager.publish('TEST_CLOSE');
                        return;
                    }
                    dispatch(openDialog(AbstractRoomLeave));
                };
                return (React.createElement(ClickButton, { key: mode, className: className, icon: mode, label: enableLable ? t(`setting.out`) : undefined, size: iconSize, styles: iconStyles, onClick: onClick }));
            case 'chat':
                return (React.createElement(ToggleSideBar, { key: mode, mode: mode, className: className, iconStyles: iconStyles, isText: false, label: enableLable ? t(`setting.${mode}`) : undefined, isDualMonitor: false }));
            // 손들기 on/off
            case 'handler':
                if (isShowLvl)
                    return null;
                if (individualLive)
                    return null;
                return (React.createElement(LocalHandsup, { className: className, mode: mode, label: enableLable ? t(`setting.${mode}`) : undefined, size: iconSize, styles: iconStyles, onClick: onClick }));
            // 참석자 on/off
            case 'member':
                return (React.createElement(ToggleSideBar, { key: mode, mode: mode, className: className, iconStyles: iconStyles, isText: false, label: enableLable ? t(`setting.${mode}`) : undefined, isDualMonitor: false }));
            case 'option':
                const showDialog = navigator.product === 'ReactNative' ? toggleSheet : toggleDialog;
                // @ts-ignore
                onClick = () => dispatch(
                // @ts-ignore
                showDialog(OptionDialogContainer, { permissions, isMobile }));
                return (React.createElement(ClickButton, { key: mode, className: className, icon: mode, label: enableLable ? t(`setting.${mode}`) : undefined, size: iconSize, styles: iconStyles, onClick: onClick }));
            case 'setting':
                if (APP.config.option.hideParticipant && !isShowLvl)
                    return null;
                onClick = () => {
                    dispatch(
                    // @ts-ignore
                    toggleDialog(SettingContainerDialog, {
                        permissions,
                        isMobile,
                    }));
                };
                return (React.createElement(ClickButton, { key: mode, className: className, icon: mode, label: enableLable ? t(`setting.${mode}`) : undefined, size: iconSize, styles: iconStyles, onClick: onClick }));
            case 'test':
                if (individualLive)
                    return null;
                return (React.createElement(React.Fragment, null,
                    React.createElement(ClickButton, { key: mode, icon: mode, className: className, label: enableLable ? t(`setting.${mode}`) : undefined, size: iconSize, color: testStart ? APP.config.style.focusColor : APP.config.style.iconColor, styles: iconStyles, onClick: () => {
                            dispatch(updateSettings({ testStart: !testStart }));
                            APP.eventManager.publish('OPEN_TEST', { changeHide: !testStart });
                        } })));
            case 'modeChange':
                if (permissions.indexOf('layout') === -1 && permissions.indexOf('share') === -1)
                    return null;
                onClick = () => {
                    const showDialog = navigator.product === 'ReactNative' ? toggleSheet : toggleDialog;
                    // @ts-ignore
                    dispatch(showDialog(ChangeModeButton, { permissions }));
                };
                return (React.createElement(ClickButton, { className: className, icon: mode, size: iconSize, label: enableLable ? t(`setting.${mode}`) : undefined, styles: iconStyles, onClick: onClick }));
            case 'videoChange':
                if (isMobileBrowser()) {
                    return (React.createElement(ToggleVideoChange, { key: mode, className: className, iconStyles: iconStyles, enableLable: enableLable, mode: mode, label: enableLable ? t(`setting.${mode}`) : undefined }));
                }
            case 'micChange':
                if (isMobileBrowser()) {
                    onClick = () => {
                        const showDialog = navigator.product === 'ReactNative' ? toggleSheet : toggleDialog;
                        // @ts-ignore
                        dispatch(showDialog(AudioRoutePicker));
                    };
                    return (React.createElement(ClickButton, { key: mode, className: className, icon: mode, size: iconSize, label: enableLable ? t(`setting.${mode}`) : null, styles: iconStyles, onClick: onClick }));
                }
        }
    }, [mode, isMobile, enableLable, permissions, onClick, testStart, iconSize]);
    return renderComponent;
}
export default PersonalSettingsButton;
