export const setConfig = () => {
	const search = location.search;
	const name = getParameterByName('type');

	if (name === 'mate') {
		return 'Mate';
	} else if (name === 'classmate') {
		return 'ClassMate';
	} else if (name === 'ispark') {
		return 'Ispark';
	} else if (name === 'mimac') {
		return 'Mimac';
	} else if (name === 'senstee') {
		return 'Senstee';
	} else if (name === 'merti') {
		return 'Merti';
	}

	return 'Mimac';
};

function getParameterByName(name) {
	name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
	var regex = new RegExp('[\\?&]' + name + '=([^&#]*)'),
		results = regex.exec(location.search);
	return results == null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
}
