import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Layer, Stage } from 'react-konva';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { ROLE, checkRoleLevel, getLocalMember } from '../../../base/members';
import { deleteCanvaskey, setCanvasPoint } from '../../actions';
import { CHOICE, FIGURE_LIST, HANDLER, LINE_ERASER, PART_ERASER, PEN, TEXT } from '../../constants';
import CanvasDrawLine from './CanvasDrawLine';
import ScrollCanvas from './ScrollCanvas';
const CanvasDraw = React.memo(({ isVod, uuid, index, fullCanvas, drawCanvas, scale, canvasPos, tool, option, isDocumentMode, setCanvasPos, setFocusTool, }) => {
    const dispatch = useDispatch();
    const stageRef = useRef(null);
    const local = useSelector((state) => getLocalMember(state));
    const [down, setDown] = useState();
    const [line, setLine] = useState();
    const [selectedId, setSelectedId] = useState();
    const { user_uuid: localId, role } = local;
    const { width: stageWidth, height: stageHeight, isHorizontalScroll, isVerticalScroll, horizontalWidth, verticalHeight, } = useMemo(() => {
        const width = Math.min(fullCanvas.width, drawCanvas.width * scale);
        const height = Math.min(fullCanvas.height, drawCanvas.height * scale);
        const isHorizontalScroll = fullCanvas.width < drawCanvas.width * scale ? true : false;
        const isVerticalScroll = fullCanvas.height < drawCanvas.height * scale ? true : false;
        const horizontalWidth = isHorizontalScroll ? width / ((drawCanvas.width * scale) / width) + 10 : 0;
        const verticalHeight = isVerticalScroll ? height / ((drawCanvas.height * scale) / height) : 0;
        return {
            width,
            height,
            isHorizontalScroll,
            isVerticalScroll,
            horizontalWidth,
            verticalHeight,
        };
    }, [fullCanvas, drawCanvas, scale, isVod]);
    useEffect(() => {
        window.addEventListener('mouseup', onMouseUp);
        return () => {
            window.removeEventListener('mouseup', onMouseUp);
        };
    }, []);
    useEffect(() => {
        if (!line || !down)
            return;
        if (line.canvasTool.tool === HANDLER.name)
            return;
        if (!checkRoleLevel(role, ROLE.PRESENTER))
            return;
        dispatch(setCanvasPoint({
            uuid,
            index,
            canvasTool: line.canvasTool,
            points: line.points,
            key: down,
        }));
    }, [line]);
    useEffect(() => {
        setCanvasPos({
            x: Math.min(Math.max(canvasPos.x, -(drawCanvas.width * scale - stageWidth)), 0),
            y: Math.max(-(drawCanvas.height * scale - stageHeight), Math.min(canvasPos.y, 0)),
        });
    }, [scale]);
    useEffect(() => {
        if (!down) {
            if (line && line.canvasTool.tool === HANDLER.name) {
                const moveX = isHorizontalScroll && line.canvasTool.figureWidth
                    ? (line.canvasTool.flipX ? 1 : -1) * line.canvasTool.figureWidth
                    : 0;
                const moveY = isVerticalScroll && line.canvasTool.figureHeight
                    ? (line.canvasTool.flipY ? 1 : -1) * line.canvasTool.figureHeight
                    : 0;
                setCanvasPos({
                    x: Math.min(Math.max(canvasPos.x - moveX, -(drawCanvas.width * scale - stageWidth)), 0),
                    y: Math.max(-(drawCanvas.height * scale - stageHeight), Math.min(canvasPos.y - moveY, 0)),
                });
            }
            setLine(undefined);
        }
    }, [down]);
    // 현재 드로우한 point x, y
    const getPointerPosition = (pos) => {
        if (stageRef.current) {
            const pointer = pos ? pos : stageRef.current.getRelativePointerPosition();
            if (tool === HANDLER.name) {
                return pointer;
            }
            else {
                const x = (pointer.x - canvasPos.x) / scale;
                const y = (pointer.y - canvasPos.y) / scale;
                return { x, y };
            }
        }
        return { x: 0, y: 0 };
    };
    const setDrawLine = useCallback(() => {
        if (!line)
            return;
        let newLine = line;
        const points = getPointerPosition();
        switch (tool) {
            case PEN.name:
            case LINE_ERASER.name:
                newLine = Object.assign(line, { points: line.points.concat([points.x, points.y]) });
            default:
                if (FIGURE_LIST.includes(tool) || tool === TEXT.name || tool === HANDLER.name) {
                    const deltaX = points.x - line.canvasTool.points.x;
                    const deltaY = points.y - line.canvasTool.points.y;
                    const pX = deltaX < 0 ? points.x : line.points[0];
                    const pY = deltaY < 0 ? points.y : line.points[1];
                    newLine = {
                        ...line,
                        canvasTool: {
                            ...line.canvasTool,
                            flipX: deltaX < 0 ? true : false,
                            flipY: deltaY < 0 ? true : false,
                            figureWidth: Math.max(Math.abs(deltaX), 10),
                            figureHeight: Math.max(Math.abs(deltaY), 10),
                        },
                        points: [pX, pY],
                    };
                }
                setLine({ ...newLine });
                break;
        }
    }, [tool, line]);
    const handlerUpdateLine = (data) => {
        if (!checkRoleLevel(role, ROLE.PRESENTER))
            return;
        dispatch(setCanvasPoint({ uuid, index, ...data, key: data.canvasTool.key }));
    };
    const updateDeleteLine = (line_key) => {
        if (line_key && line_key.includes('stage'))
            return;
        dispatch(deleteCanvaskey(uuid, index, line_key));
    };
    const updateChoiceSelected = (line_key) => {
        if ((line_key && line_key.includes('stage')) || line_key === selectedId)
            setSelectedId(undefined);
        else
            setSelectedId(line_key);
    };
    const onMouseDown = canvas => {
        canvas.evt.preventDefault();
        if (canvas && canvas.target.attrs.name === 'text' && tool === TEXT.name) {
            setSelectedId(canvas.target.attrs.className);
            return;
        }
        if (canvas && canvas.target.attrs.className === 'scroll')
            return;
        setFocusTool(PEN);
        const key = Date.now().toString() + '_' + localId;
        setDown(key);
        if (CHOICE.name === tool)
            return;
        if (PART_ERASER.name === tool)
            return;
        const points = getPointerPosition();
        const newLine = {
            canvasTool: {
                key,
                ...drawCanvas,
                ...option,
                tool,
                points,
            },
            points: [points.x, points.y],
        };
        setLine(newLine);
    };
    const onMouseMove = canvas => {
        canvas.evt.preventDefault();
        if (!down)
            return;
        switch (tool) {
            case HANDLER.name:
            case CHOICE.name:
                return;
            case PART_ERASER.name:
                const line_eraser_key = canvas.target.attrs.className;
                updateDeleteLine(line_eraser_key);
                return;
            default:
                setDrawLine();
                break;
        }
    };
    const onMouseUp = canvas => {
        if (!down)
            return;
        switch (tool) {
            case HANDLER.name:
                break;
            case PART_ERASER.name:
                const line_eraser_key = canvas.target.attrs.className;
                updateDeleteLine(line_eraser_key);
                break;
            case CHOICE.name:
                const line_key = canvas.target.attrs.className;
                updateChoiceSelected(line_key);
                break;
        }
        setDrawLine();
        setDown(undefined);
    };
    const onWheel = canvas => {
        const dx = canvas.evt.deltaX;
        const dy = canvas.evt.deltaY;
        const moveX = isHorizontalScroll ? dx : 0;
        const moveY = isVerticalScroll ? dy : 0;
        setCanvasPos({
            x: Math.min(Math.max(canvasPos.x - moveX, -(drawCanvas.width * scale - stageWidth)), 0),
            y: Math.max(-(drawCanvas.height * scale - stageHeight), Math.min(canvasPos.y - moveY, 0)),
        });
    };
    return (React.createElement(DrawStyled, { isVod: isVod, width: drawCanvas.width, height: drawCanvas.height },
        React.createElement("div", { id: "canvas_wrapper", className: "stage canvas_input" }),
        React.createElement(Stage, { ref: stageRef, className: `stage ${!isDocumentMode && !isVod ? 'white' : ''}`, width: stageWidth, height: stageHeight, onMouseDown: onMouseDown, onTouchStart: onMouseDown, onMouseMove: onMouseMove, onTouchMove: onMouseMove, onMouseUp: onMouseUp, onTouchEnd: onMouseUp, onWheel: onWheel },
            React.createElement(Layer, { scaleX: scale, scaleY: scale, x: canvasPos.x, y: canvasPos.y },
                React.createElement(CanvasDrawLine, { uuid: uuid, index: index, drawCanvas: drawCanvas, selectedId: selectedId, handlerUpdateLine: handlerUpdateLine })),
            React.createElement(Layer, null,
                React.createElement(ScrollCanvas, { uuid: uuid, index: index, scale: scale, stageWidth: stageWidth, stageHeight: stageHeight, horizontalWidth: horizontalWidth, verticalHeight: verticalHeight, canvasPos: canvasPos, setCanvasPos: setCanvasPos })))));
});
const DrawStyled = styled.div `
	top: 0;
	left: 0;

	.stage {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}

	width: ${props => props.width}px;
	height: ${props => props.height}px;

	${props => props.isVod
    ? `
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;    
    `
    : `
        position: relative;
        margin: 0;
    `}

	.konvajs-content {
		margin: 0;
	}
`;
export default CanvasDraw;
