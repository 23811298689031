import JitsiMeetJS, {
	JitsiConferenceEvents,
	JitsiConnectionEvents,
	JitsiTrackErrors,
} from '../../../features/base/lib-jitsi-meet';
import { trackAdded } from '../../../features/base/tracks';
import { getWebsocketURLBase } from '../../../features/base/ui/utils';
import { getMediaServerInfo, getRoomOption } from '../../../features/room';
import { getLocalSharingStatus, getStartScreenShare, setShareScreenUserId } from '../../../features/screen-share';

const config = {
	connect: {
		hosts: {
			domain: 'mate.net',
			muc: 'muc.mate.net',
		},
	},
	conference: {
		createVADProcessor: null, // rnnoise
		p2p: true,
		enableUnifiedOnChrome: true,
		enableNoisyMicDetection: true,
		openBridgeChannel: 'websocket',
		disableSimulcast: true,
		enableLayerSuspension: true,
		videoQuality: {
			maxBitratesVideo: {
				low: 100000,
				standard: 500000,
				high: 2000000,
			},
		},
	},
};

class ScreenShare {
	constructor() {
		this.createScreenShareTrack = null;

		this.desktopStream = null;
		this.connection = null;
		this.conference = null;
		this.shareUserID = null;

		this.videoSwitchInProgress = false;

		this._unToggleScreenSharing = this._unToggleScreenSharing.bind(this);
		this.screenConnect = this.screenConnect.bind(this);
		this.disposeVideoStream = this.disposeVideoStream.bind(this);
	}

	init(createScreenShareTrack) {
		this.createScreenShareTrack = createScreenShareTrack;
	}

	isScreenSharing() {
		return this.shareUserID;
	}

	setDesktopStream = stream => {
		this.desktopStream = stream;
	};

	toggleScreenSharing(toggle) {
		if (this.videoSwitchInProgress) {
			return Promise.reject('Switch in progress.');
		}
		if (!JitsiMeetJS.isDesktopSharingEnabled()) {
			return Promise.reject('Cannot toggle screen sharing: not supported.');
		}

		if (toggle) {
			return this.switchToScreenSharing();
		} else {
			return this._unToggleScreenSharing();
		}
	}

	switchToScreenSharing() {
		this.videoSwitchInProgress = true;

		// jitsiNodeAPI.ipc.send('start-sharing');

		// jitsiNodeAPI.ipc.on('sharing-started', () => {
		//     console.log('Screen sharing started');
		// });

		return this.createScreenShareTrack({ unToggleScreenSharing: this._unToggleScreenSharing })
			.then(streams => {
				if (streams) this.setDesktopStream(streams);
				return this.screenConnect().then(conference => {
					return Promise.resolve(conference);
				});
			})
			.catch(error => {
				this.videoSwitchInProgress = false;

				if (this._untoggleScreenSharing) this._untoggleScreenSharing();

				return Promise.reject(error);
			});
	}

	screenConnect() {
		return new Promise((resolve, reject) => {
			const connectConfig = Object.assign({}, config.connect);

			const mediaInfo = getMediaServerInfo(APP.store.getState);
			connectConfig.serviceUrl = `${getWebsocketURLBase()}${mediaInfo.url}?type=screen`;

			const connection = new JitsiMeetJS.JitsiConnection(null, null, connectConfig);
			connection.addEventListener(JitsiConnectionEvents.CONNECTION_ESTABLISHED, () => {
				this.videoSwitchInProgress = false;

				this.connection = connection;
				const roomCode = getRoomOption(APP.store.getState).code;
				const conference = this.connection.initJitsiConference(roomCode, {
					openBridgeChannel: 'websocket',
				});

				this.desktopStream &&
					this.desktopStream.map(track => {
						const myUserId = conference.myUserId();
						APP.store.dispatch(trackAdded(track, myUserId));

						conference.addTrack(track);
					});

				conference.on(JitsiConferenceEvents.CONFERENCE_LEFT, (...args) => {
					this._unToggleScreenSharing();
				});

				conference.join();

				this.conference = conference;
				return resolve(conference);
			});

			connection.addEventListener(JitsiConnectionEvents.CONNECTION_FAILED, () => {
				this.connection = null;
				this.disposeVideoStream();
				// this.appContext.mateConnection.disableScreenButton('화면 공유 네트워크 오류가 발생하였습니다. 다시 한번 시도해주세요.');
			});

			connection.addEventListener(JitsiConnectionEvents.CONNECTION_DISCONNECTED, () => {
				this.connection = null;
				this.disposeVideoStream();
			});

			connection.connect();
		});
	}

	_unToggleScreenSharing() {
		if (getLocalSharingStatus(APP.store.getState)) {
			APP.store.dispatch(setShareScreenUserId(null, null, false));

			this.disposeVideoStream();

			if (this.conference) {
				this.conference.leave();
				this.conference = null;
			}

			if (this.connection) {
				this.connection.disconnect();
				this.connection = null;
			}
		}
	}

	disposeVideoStream() {
		this.videoSwitchInProgress = false;

		this.desktopStream &&
			this.desktopStream.map(async track => {
				track && track.dispose && (await track.dispose());
			});
	}
}

function _handleScreenSharingError(error) {
	let descriptionKey;
	let titleKey;

	if (error.name === JitsiTrackErrors.SCREENSHARING_USER_CANCELED) {
		descriptionKey = 'dialog.screenSharingCancel';
		titleKey = 'dialog.screenSharingCancelError';
	} else if (error.name === JitsiTrackErrors.PERMISSION_DENIED) {
		descriptionKey = 'dialog.screenSharingPermissionDeniedError';
		titleKey = 'dialog.screenSharingFailedTitle';
	} else if (error.name === JitsiTrackErrors.CONSTRAINT_FAILED) {
		descriptionKey = 'dialog.cameraConstraintFailedError';
		titleKey = 'deviceError.cameraError';
	} else if (error.name === JitsiTrackErrors.SCREENSHARING_GENERIC_ERROR) {
		descriptionKey = 'dialog.screenSharingFailed';
		titleKey = 'dialog.screenSharingFailedTitle';
	}
}

export default new ScreenShare();
