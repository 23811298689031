import React, { Fragment, useEffect, useState } from 'react';
// @ts-ignore
import Chat from './Chat';
const ChatContainer = React.memo(({ isMonitoring }) => {
    const [popup, setPopup] = useState();
    const handlerPopup = (data) => {
        const popupComponents = data?.componet ? React.createElement(data?.componet, data?.props) : null;
        setPopup(popupComponents);
    };
    useEffect(() => {
        APP.eventManager.subscribe('UPDATE_OPEN_CHAT_POPUP', handlerPopup);
        return () => {
            APP.eventManager.unsubscribe('UPDATE_OPEN_CHAT_POPUP', handlerPopup);
        };
    }, []);
    return (React.createElement(Fragment, null,
        React.createElement(Chat, { isMonitoring: isMonitoring }),
        popup));
});
export default ChatContainer;
