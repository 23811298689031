import { getExternalToken } from '../local';
import { fetchData, objectToQueryString, useCustomQuery } from './functions';
let getToken = () => {
    return getExternalToken(APP.store);
};
// (테스트 및 과제) 리스트 조회
const getAssignmentList = async (queryString) => {
    const url = `/api/mimac/v1/tests?${queryString}`; // API 엔드포인트 생성
    return fetchData(url);
};
// (테스트 및 과제) 점수 현황 조회
export const getScoreStatus = async ({ id, queryString }) => {
    const url = `/api/mimac/v1/tests/${id}/score-status?${queryString}`; // API 엔드포인트 생성
    return fetchData(url);
};
// (테스트 및 과제) 성적 조회
export const getStatistics = async ({ test_id, queryString }) => {
    const url = `/api/mimac/v1/tests/${test_id}/statistics?${queryString}`; // API 엔드포인트 생성
    return fetchData(url);
};
// (테스트 및 과제) 질문 조회
const getQuestionList = async (queryString) => {
    const url = `/api/mimac/v1/questions?${queryString}`; // API 엔드포인트 생성
    return fetchData(url);
};
// (테스트 및 과제) 제출 현황 조회
export const getSubmitStatus = async (data) => {
    const queryString = objectToQueryString(data.props); // props를 쿼리 문자열로 변환
    const url = `/api/mimac/v1/tests/${data.id}/submit-status?${queryString}`; // API 엔드포인트 생성
    return fetchData(url);
};
// (테스트 및 과제) 제출 현황 조회
export const getLoadAnswer = async (data) => {
    const queryString = objectToQueryString(data.props); // props를 쿼리 문자열로 변환
    const url = `/api/mimac/v1/tests/${data.id}/load-answer?${queryString}`; // API 엔드포인트 생성
    return fetchData(url);
};
// (회의실) conference 리스트 조회
const getConferenceList = async (queryString) => {
    const url = `/api/mimac/v1/conferences?${queryString}`; // API 엔드포인트 생성
    return fetchData(url);
};
// (테스트 및 과제) 풀이 조회
const getAnswerList = async (queryString) => {
    const url = `/api/mimac/v1/answers?${queryString}`; // API 엔드포인트 생성
    return fetchData(url);
};
// (테스트 및 과제) 리스트 삭제
export const startTimer = async ({ id, duration }) => {
    // const access_token = getCookieValue('access_token');
    const url = `/api/mimac/v1/tests/${id}/timer`;
    return fetchData(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${getToken()}`,
        },
        body: JSON.stringify({ duration }),
    });
};
// (테스트 및 과제) 질문 출제 등록
export const postSubmitAnswer = async (data) => {
    const url = `/api/mimac/v1/tests/${data.id}/submit-answer`;
    return fetchData(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${getToken()}`,
        },
        body: JSON.stringify({ answers: data.props }),
    });
};
// (테스트 및 과제) 질문 출제 등록
export const postSaveSubmitAnswer = async (data) => {
    const url = `/api/mimac/v1/tests/${data.id}/save-answer`;
    return fetchData(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${getToken()}`,
        },
        body: JSON.stringify({ selected_item: data.props.selected_item, answers: data.props.answer }),
    });
};
// (account) 사용자 정보 조회 -useUserInfo
export const postVerifyToken = async (props) => {
    const url = `/api/mimac/user/v1/verify-auth-token`;
    return fetchData(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${props.access_token}`,
        },
        // body: JSON.stringify(props),
    });
};
// (테스트 및 과제) 성적 조회
export const getGrades = async (queryString) => {
    const url = `/api/mimac/v1/grades?${queryString}`; // API 엔드포인트 생성
    return fetchData(url);
};
// (테스트 및 과제) 질문 가져오기
export const useAnswerList = (data) => {
    const queryString = objectToQueryString(data);
    return useCustomQuery(['answer', queryString], () => getAnswerList(queryString));
}; // (테스트 및 과제) 질문 가져오기
export const useQuestionList = (data) => {
    const queryString = objectToQueryString(data);
    return useCustomQuery([`question`, queryString], () => getQuestionList(queryString));
};
// (테스트 및 과제) 점수 현황 가져오기
export const useSubmitStatus = (data) => {
    const queryString = objectToQueryString(data);
    return useCustomQuery([`submit_status`, queryString], () => getSubmitStatus(data));
};
// (테스트 및 과제) 리스트 가져오기
export const useAssignmentList = (data) => {
    const queryString = objectToQueryString(data); // props를 쿼리 문자열로 변환
    return useCustomQuery([`assignment_${data}`, data], () => getAssignmentList(queryString));
};
// (회의실) conference 리스트 조회 -useConferenceList
export const useConferenceList = (data) => {
    const queryString = objectToQueryString(data); // props를 쿼리 문자열로 변환
    return useCustomQuery(`conferences_${queryString}`, () => getConferenceList(queryString));
};
// 테스트 임시저장 불러오기
export const useLoadAnswer = (data) => {
    const queryString = objectToQueryString(data);
    return useCustomQuery(`loadAnswer_${queryString}`, () => getLoadAnswer({ id: data.id, props: data.props }));
};
// (테스트 및 과제) 성적 가져오기
export const useGradesList = (data) => {
    const queryString = objectToQueryString(data);
    return useCustomQuery(`grades_${queryString}`, () => getGrades(queryString));
};
// (테스트 및 과제) 통계 가져오기
export const useStatistics = (data) => {
    const queryString = objectToQueryString(data);
    return useCustomQuery(`statistics_${queryString}`, () => getStatistics({ test_id: data.test_id, queryString }));
};
// (테스트 및 과제) 점수 현황 가져오기
export const useScoreStatus = (data) => {
    const queryString = objectToQueryString(data);
    return useCustomQuery([`score_${data}`, data], () => getScoreStatus({ id: data.id, queryString }));
};
// 대기열 조회
export const getLiveEnquiries = async (queryString) => {
    const url = `/api/mimac/v1/enquiries?${queryString}`; // API 엔드포인트 생성
    return fetchData(url);
};
export const useLiveEnquiries = (data) => {
    const queryString = objectToQueryString(data);
    return useCustomQuery([`live_${data}`, data], () => getLiveEnquiries(queryString));
};
// 대기열 등록 수락
export const postEnquiries = async (id) => {
    const url = `/api/mimac/v1/enquiries/${id}/queue-pop`;
    return fetchData(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${getToken()}`,
        },
    });
};
// (회의실) 1:1 라이브 대기열 해제
export const postoutEnquiriesList = async (id) => {
    const url = `/api/mimac/v1/enquiries/${id}/queue-out`;
    return fetchData(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${getToken()}`,
        },
    });
};
export const getTestTime = async (id) => {
    const url = `/api/mimac/v1/tests/${id}/timer`;
    return fetchData(url, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
        },
    });
};
