import React, { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { ChatContainer } from '../chat';
import { toggleChat, toggleHideChat } from './actions';
import { SideBarHeader } from './components';
const ChatSide = React.memo(({ isMonitoring, groupId, hideChat }) => {
    const dispatch = useDispatch();
    const hideContent = () => dispatch(toggleHideChat(!hideChat));
    const handlerClose = () => dispatch(toggleChat(false));
    const renderHead = useMemo(() => (React.createElement(SideBarHeader, { name: "chat", isMonitoring: isMonitoring, showBody: !hideChat, hideContent: hideContent, handlerClose: handlerClose })), [isMonitoring, hideChat]);
    const renderBody = useMemo(() => {
        if (hideChat)
            return null;
        return React.createElement(ChatContainer, { isMonitoring: isMonitoring, groupId: groupId });
    }, [hideChat, isMonitoring, groupId]);
    return (React.createElement(React.Fragment, null,
        renderHead,
        renderBody));
});
export default ChatSide;
