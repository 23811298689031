import _ from 'lodash';
import { getLocalID } from '../local';
import { MEDIA_TYPE } from '../media/constants';
import { toState } from '../redux';
import { getTracksByTrackIdAndMediaType } from '../tracks/functions';
import { MEMBER_STATUS, MEMBER_STATUS_LEVEL, ROLE, ROLE_LEVEL } from './constans';
import moment from 'moment';
export function isLocalParticipant(stateful) {
    const localRole = getLocalMemberRole(stateful) || ROLE.PARTICIPANT;
    return ![ROLE.ADMIN, ROLE.HOST, ROLE.MANAGER].includes(localRole);
}
/**
 *
 */
export function getRoleMenu(local_role, remote_role) {
    switch (local_role) {
        case ROLE.ADMIN:
        case ROLE.HOST:
        case ROLE.MANAGER:
            if (checkRoleLevel(local_role, remote_role)) {
                return ['presenter', 'handler', 'mic', 'voice', 'pin', 'etc'];
            }
    }
}
/**
 * 모든 사용자 가져오기 + filter
 * @param {Function} stateful
 * @param {Array} status
 * @returns
 */
export function getMemberList(stateful, data, isSort = true) {
    const state = toState(stateful);
    const { local, remote } = state['features/base/members'];
    const list = [local, ...remote];
    const status = data?.status || null;
    const excludePin = data?.excludePin || false;
    const excludeLocal = data?.excludeLocal || false;
    const excludeVoice = data?.excludeVoice || false;
    const excludeIds = data?.excludeIds || null;
    const nickname = data?.nickname || null;
    const excludeHost = data?.excludeHost || null;
    const excludeManager = data?.excludeManager || null;
    const voice = getVoiceMember(state);
    const members = [];
    for (let i = 0; i < list.length; i++) {
        const uuid = list[i];
        if (!uuid)
            continue;
        const user = getMemberTrackByUserID(state, uuid, true);
        if (!user)
            continue;
        const { status: userStatus, local: userLocal, user_uuid: userUUID, pinned: userPinned, role: userRole, nickname: userNickname, } = user;
        const role_name = userRole;
        if ((status ? status.includes(userStatus) : true) &&
            (excludeLocal ? !userLocal : true) &&
            (excludeVoice ? voice !== userUUID : true) &&
            (excludePin ? !userPinned : true) &&
            (excludeHost ? role_name !== ROLE.HOST : true) &&
            (excludeIds ? !excludeIds.includes(userUUID) : true) &&
            (nickname && userNickname ? userNickname.includes(nickname) : true) &&
            (excludeManager ? !checkRoleLevel(role_name, ROLE.PRESENTER) : true)) {
            members.push(user);
        }
    }
    if (isSort) {
        members.sort((a, b) => {
            const nicknameComparison = (a.nickname && b.nickname && a.nickname.localeCompare(b.nickname)) || 0;
            const localComparison = (b?.local === true ? 1 : 0) - (a?.local === true ? 1 : 0);
            const pinnedComparison = +b.pinned - +a.pinned;
            const voiceComparison = voice === a.user_uuid ? -1 : 0;
            const roleComparison = ROLE_LEVEL.indexOf(a.role) - ROLE_LEVEL.indexOf(b.role);
            const statusComparison = MEMBER_STATUS_LEVEL.indexOf(a.status) - MEMBER_STATUS_LEVEL.indexOf(b.status);
            if (localComparison !== 0) {
                return localComparison;
            }
            if (statusComparison !== 0) {
                return statusComparison;
            }
            if (roleComparison !== 0) {
                return roleComparison;
            }
            if (nicknameComparison !== 0) {
                return nicknameComparison;
            }
            if (pinnedComparison !== 0) {
                return pinnedComparison;
            }
            if (voiceComparison !== 0) {
                return voiceComparison;
            }
        });
    }
    return members;
}
export function getOccupiedRemoteBridgeId(stateful, excludeLocal) {
    const state = toState(stateful);
    const members = _.cloneDeep(state['features/base/members'].members);
    const remote = [...members.values()]
        .filter(i => i.status === 'occupied' && (excludeLocal ? !i?.local : true))
        .map(i => {
        return getMemberByBridgeId(stateful, i.user_uuid);
    });
    return remote;
}
export function getRemoteId(stateful, excludeIds = [], type) {
    const state = toState(stateful);
    const copyRemote = _.cloneDeep(state['features/base/members'].members);
    let remote = [];
    if (type) {
        const typeTime = moment(type);
        // 필터링: excludeIds에 해당하지 않고, PRESENTER가 아닌 멤버들만
        const filteredMembers = [...copyRemote.values()].filter(i => !excludeIds.includes(i.user_uuid) && !checkRoleLevel(i.role, ROLE.PRESENTER));
        // join_time이 기준 시간보다 빠른 사용자 (앞으로 이동) - vacated가 아닌 사용자만
        const earlyJoiners = filteredMembers
            .filter(i => moment(i.join_time).isSameOrBefore(typeTime) && i.status !== 'vacated') // 기준 시간보다 빠르고 vacated가 아닌 사용자
            .sort((a, b) => a.nickname.localeCompare(b.nickname)); // 닉네임 기준 정렬
        // join_time이 기준 시간보다 늦거나 vacated 상태인 사용자 (뒤로 이동)
        const lateJoiners = filteredMembers
            .filter(i => moment(i.join_time).isAfter(typeTime) || i.status === 'vacated') // 기준 시간보다 늦거나 vacated인 사용자
            .sort((a, b) => a.nickname.localeCompare(b.nickname)); // 닉네임 기준 정렬
        // 두 그룹을 합침
        remote = [...earlyJoiners, ...lateJoiners].map(i => i.user_uuid);
    }
    else {
        // 기준 시간이 없을 때는 단순히 닉네임 순으로 정렬
        remote = [...copyRemote.values()]
            .filter(i => !excludeIds.includes(i.user_uuid) && !checkRoleLevel(i.role, ROLE.PRESENTER))
            .sort((a, b) => a.nickname.localeCompare(b.nickname)) // 닉네임 기준 정렬
            .map(i => i.user_uuid);
    }
    return remote;
}
export function getMembersByMember(members, isAll = false) {
    if (isAll)
        return (members
            .filter(member => member.hands_up || (member.pinned && member.role === ROLE.PARTICIPANT))
            // @ts-ignore
            .sort((a, b) => a?.hands_up_time - b?.hands_up_time));
    return members
        .filter(member => member.hands_up || (member.pinned && member.role === ROLE.PARTICIPANT))
        .map(member => member.user_uuid);
}
export function getHandlerRequestMember(stateful, isAll = false) {
    const members = getMemberList(stateful, {
        status: [MEMBER_STATUS.OCCUPIDE],
    }, false);
    const filteredAndSortedMembers = members
        .filter(member => member.hands_up || (member.pinned && member.role === ROLE.PARTICIPANT))
        .sort((a, b) => +b.pinned - +a.pinned || a.hands_up_time - b.hands_up_time);
    if (isAll) {
        return filteredAndSortedMembers;
    }
    return filteredAndSortedMembers.map(member => member.user_uuid);
}
export function getHandlerMember(stateful, isAll = false) {
    const pinList = toState(stateful)['features/base/members'].pinIds;
    const members = getMemberList(stateful, {
        status: [MEMBER_STATUS.OCCUPIDE],
        filterGroup: true,
    }, false).filter(member => pinList.has(member.user_uuid) && member.role === ROLE.PARTICIPANT);
    if (isAll)
        return members;
    else
        return members.map(member => member.user_uuid);
}
export function getMemberIdAndNicknameList(stateful, data, isSort = true) {
    const members = getMemberList(stateful, data, isSort);
    return members.map(t => {
        return { user_uuid: t.user_uuid, nickname: t.nickname };
    });
}
export function getMemberIdsList(stateful, data, isSort = true) {
    const members = getMemberList(stateful, data, isSort);
    return members
        .sort((a, b) => {
        a.nickname && b.nickname && a.nickname.localeCompare(b.nickname);
    })
        .map(t => t.user_uuid);
}
/**
 * 현재 입장 중인 사용자 수 가져오기
 * @param {*} stateful
 * @returns
 */
export function getMemberCount(stateful) {
    const members = getMemberList(stateful, {
        status: [MEMBER_STATUS.OCCUPIDE],
        filterGroup: true,
    }, false);
    return members.length;
}
/**
 * 음성 사용자 가져오기
 * @param {Function} stateful
 * @returns
 */
export function getVoiceMember(stateful) {
    const state = toState(stateful);
    return state['features/base/members'].voice;
}
/**
 * 로컬 사용자 정보 가져오기
 * @param {*} stateful
 * @returns
 */
export function getLocalMember(stateful) {
    const state = toState(stateful);
    const local_uuid = state['features/base/members'].local;
    const local = getMemberByUserID(stateful, local_uuid);
    if (!local)
        return null;
    return local;
}
/**
 * 로컬 사용자의 권한 정보 가져오기
 * @param {*} stateful
 * @returns
 */
export function getLocalMemberRole(stateful) {
    const localID = getLocalID(stateful);
    if (!localID)
        return null;
    const local = getMemberTrackByUserID(stateful, localID, false);
    if (local) {
        return local.active_group === '' ? local.role : local.group_role;
    }
    return null;
}
/**
 * 모든 사용자 ID 조회(방에 없는 사용자 까지)
 * @param {Function} stateful
 * @param {string} user_uuid
 * @returns
 */
export function getMemberByUserID(stateful, user_uuid) {
    const state = toState(stateful);
    const members = state['features/base/members'].members;
    return user_uuid ? members.get(user_uuid) : null;
}
/**
 * 현재 권한 확인
 * @param {Function} stateful
 * @param {string} user_uuid
 */
export function getRoleByUserID(stateful, user_uuid) {
    const members = getMemberByUserID(stateful, user_uuid);
    return members && members.role;
}
/**
 * 사용자 member ID로 user ID 가져오기
 */
export function getUserIDByMemberId(stateful, member_uuid) {
    const state = toState(stateful);
    const memberIDs = state['features/base/members']?.memberIds;
    if (!member_uuid)
        return null;
    return memberIDs && memberIDs.get(member_uuid);
}
/**
 * 사용자 아이디로 사용자 트랙 아이디 가져오기
 * @param {Function} stateful
 * @param {string} user_uuid
 * @returns
 */
export function getBridgeSession(stateful, user_uuid) {
    const state = toState(stateful);
    const bridgeList = state['features/base/members'].bridgeList;
    return bridgeList.get(user_uuid);
}
export function getMemberByBridgeId(stateful, user_uuid) {
    const data = getBridgeSession(stateful, user_uuid);
    return data?.id;
}
export function getLoaclMemberTrack(stateful) {
    const localID = getLocalID(stateful);
    if (!localID)
        return undefined;
    const member = getMemberTrackByUserID(stateful, localID);
    return member;
}
/**
 * 사용자 아이디로 트랙 정보 가져오기
 * @param {Function} stateful
 * @param {string} user_uuid
 */
export function getMemberTrackByUserID(stateful, user_uuid, isTrack = true) {
    const state = toState(stateful);
    const member = getMemberByUserID(state, user_uuid);
    if (!member)
        return null;
    if (isTrack) {
        const jitsi_id = getMemberByBridgeId(state, user_uuid);
        const videoTrack = jitsi_id && getTracksByTrackIdAndMediaType(state, member.local ? 'local' : jitsi_id, MEDIA_TYPE.VIDEO);
        const audioTrack = jitsi_id && getTracksByTrackIdAndMediaType(state, member.local ? 'local' : jitsi_id, MEDIA_TYPE.AUDIO);
        return {
            ...member,
            videoTrack,
            audioTrack,
        };
    }
    else {
        const bridge_sessions = getBridgeSession(state, user_uuid);
        return {
            ...member,
            videoMuted: bridge_sessions?.video_muted,
            audioMuted: bridge_sessions?.audio_muted,
        };
    }
}
/**
 * local 사용자 아이디 가져오기
 * @param {Function} stateful
 * @returns
 */
export function getLocalIDMember(stateful) {
    const state = toState(stateful);
    return state['features/base/members'].local;
}
/**
 * 권한 레벨 확인
 * @param {string} localRole
 * @param {string} memberRole
 * @returns
 */
export function checkRoleLevel(localRole, memberRole) {
    if (!localRole || !memberRole)
        return false;
    if (ROLE_LEVEL.indexOf(localRole) >= ROLE_LEVEL.indexOf(memberRole))
        return false;
    return true;
}
/**
 * 사용자 핀 가져오기
 * @param {IStore['getState']} stateful
 * @param {String|null} excludeId
 * @returns
 */
export function getPinnedMember(stateful, excludeId, excludeLocal = false) {
    const state = toState(stateful);
    const pinList = state['features/base/members'].pinIds;
    const copyPin = _.cloneDeep(pinList);
    excludeId && copyPin.delete(excludeId);
    excludeLocal && copyPin.delete(getLocalIDMember(stateful));
    return copyPin;
}
/**
 * 사용자 핀 개수 가져오기
 * @param {IStore['getState']} stateful
 * @param {String|null} excludeId
 * @returns
 */
export function getPinnedMemberCount(stateful, excludeId) {
    const pin = getPinnedMember(stateful, excludeId);
    return pin.size;
}
/**
 * 핀 사용자 가져오기
 * @param {IStore['getState']} stateful
 * @param {Boolean} isSeminar
 * @returns {string[]}
 */
export function getPinnedMembersBySeminar(stateful) {
    const state = toState(stateful);
    const pin = getPinnedMember(state);
    return Array.from(pin);
}
/**
 * 그룹 아이디로 사용자 검색
 * @param {*} stateful
 * @param {string} group_id
 * @returns
 */
export function getGroupMemberByGroupId(stateful, group_id) {
    const state = toState(stateful);
    const members = state['features/base/members'].members;
    return group_id ? [...members.values()].filter(member => member.group_id === group_id) : [members.values()];
}
/**
 * 입장된 사용자 그룹 아이디로 사용자 검색
 * @param {*} stateful
 * @param {string} group_id
 * @returns
 */
export function getOccupiedMemberByGroupId(stateful, group_id) {
    const state = toState(stateful);
    const members = state['features/base/members'].members;
    return [...members.values()].filter(member => member.group_id === group_id && member.status === MEMBER_STATUS.OCCUPIDE && member.group_type === '');
}
/**
 * 녹화 사용자 가져오기
 * @param {*} stateful
 * @returns
 */
export function getMemberRecordList(stateful) {
    const state = toState(stateful);
    const list = state['features/base/members'].recordIds;
    return list && list.size > 0 ? [...list] : [];
}
/**
 * 현재 공유 중인 사용자 아이디 가져오기
 * @param {IStore['getState']} stateful
 * @returns
 */
export function getScreenUserID(stateful) {
    const state = toState(stateful);
    const share_member = state['features/screen-share'].share_member;
    if (share_member) {
        const member = getMemberByUserID(stateful, share_member);
        if (member)
            return share_member;
        else {
            const user_uuid = getUserIDByMemberId(stateful, share_member);
            return user_uuid;
        }
    }
    return null;
}
/**
 * 로컬 그룹 사용자 가져오기
 *
 * @param {IStore['getState']} stateful
 * @returns
 */
export function getLocalGroup(stateful) {
    const state = toState(stateful);
    const local = getLocalMember(state);
    const group = getGroupByUid(state, local?.group_id);
    return {
        role: local?.role,
        group_id: local?.group_id,
        group_role: local?.group_role,
        start: group?.start,
        group_name: group?.name,
    };
}
export async function getProcessedMember(member) {
    // 닉네임 없는 경우 무조건 닉네임 조회
    let nickname = member.nickname || '';
    let group_id = member.group_id || '';
    let group_role = member.group_role || '';
    let active_group = '';
    let group_type = '';
    const response = await APP.API.getRemoteUserInformation({ user_id: member.user_uuid });
    if (response.complete) {
        if (!nickname || nickname === '') {
            const item = response.data?.find((item) => item.nickname && item.nickname !== '');
            if (item)
                nickname = item.nickname;
        }
        // 그룹 조회
        // 새로 들어온 사용자
        const item = response.data?.find((item) => item.group_id !== '' && item.status !== MEMBER_STATUS.BANNED);
        if (item) {
            group_id = item.group_id;
            group_role = item.role.name;
            group_type = item.type;
            active_group = item.status === MEMBER_STATUS.OCCUPIDE ? item.group_id : '';
        }
    }
    return { ...member, nickname, group_role, group_id, group_type, active_group };
}
export function getSpeakerId(stateful) {
    const state = toState(stateful);
    const speakerId = state['features/base/members'].speakerId;
    const userId = getUserIDByMemberId(stateful, speakerId);
    if (userId) {
        const member = getMemberByUserID(stateful, userId);
        if (member?.status === MEMBER_STATUS.OCCUPIDE)
            return member.user_uuid;
    }
    return getLocalID(stateful);
}
export function getAllMemberIds(stateful) {
    const state = toState(stateful);
    const members = state['features/base/members'].members;
    if (!members) {
        return [];
    }
    else {
        // 미리 인덱스를 계산하여 비교시 재계산하지 않도록 합니다.
        const statusLevelMap = new Map(MEMBER_STATUS_LEVEL.map((status, index) => [status, index]));
        const roleLevelMap = new Map(ROLE_LEVEL.map((role, index) => [role, index]));
        return Array.from(members.entries())
            .sort(([keyA, a], [keyB, b]) => {
            // 1. local 비교 (로컬 검색 1순위)
            if (a.local !== b.local) {
                return a.local ? -1 : 1;
            }
            // 2. status 비교 (상태 2순위)
            const statusA = statusLevelMap.get(a.status) || 0;
            const statusB = statusLevelMap.get(b.status) || 0;
            if (statusA !== statusB) {
                return statusB - statusA; // 높은 상태가 먼저 오도록 변경
            }
            // 3. role 비교 (권한 3순위)
            const roleA = roleLevelMap.get(a.role) || 0;
            const roleB = roleLevelMap.get(b.role) || 0;
            if (roleA !== roleB) {
                return roleB - roleA; // 높은 권한이 먼저 오도록 변경
            }
            // 4. nickname 비교 (닉네임 4순위)
            return (a.nickname || '').localeCompare(b.nickname || '');
        })
            .map(([key, _]) => key);
    }
}
