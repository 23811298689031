import { ReducerRegistry } from '../base/redux';
import { LEAVE_ROOM, SET_ACTIVE_GROUP_ID, SET_ROOM_JOINED, SET_ROOM_OPTION, SET_ROOM_PERMISSIONS } from './actionTypes';
const DEFAULT_STATE = {
    option: {
        name: undefined,
        code: undefined,
        uuid: undefined,
    },
    password_checking: false,
    media_server_info: undefined,
    start_time: 0,
    permissions: [],
    group_id: '',
    joined: false,
};
ReducerRegistry.register('features/room', (state = DEFAULT_STATE, action) => {
    switch (action.type) {
        case SET_ROOM_OPTION:
            if (!action.option)
                break;
            return {
                ...state,
                option: action.option,
                password_checking: action.password_checking,
                media_server_info: action.media_server_info,
                start_time: action.start_time,
            };
        case SET_ROOM_PERMISSIONS:
            return {
                ...state,
                permissions: action.permissions,
            };
        case SET_ROOM_JOINED:
            return {
                ...state,
                joined: action.joined,
            };
        case SET_ACTIVE_GROUP_ID:
            return {
                ...state,
                group_id: action.group_id,
            };
        case LEAVE_ROOM:
            return DEFAULT_STATE;
    }
    return state;
});
