import { useQuery } from 'react-query';
//  API 호출 함수 정의
export const fetchData = async (url, options) => {
    try {
        const response = await fetch(url, options);
        if (!response.ok) {
            const result = await response.json();
            throw new Error(result?.message?.format);
        }
        return response.json();
    }
    catch (e) {
        console.log(e);
        APP.UI.alertMessage(e.message);
    }
};
// object convert string
export function objectToQueryString(obj) {
    if (obj === undefined || obj === null) {
        return '';
    }
    return Object.keys(obj)
        .map(key => {
        const value = obj[key];
        if (value === undefined)
            return;
        if (Array.isArray(value)) {
            return value.map(v => `${encodeURIComponent(key)}=${encodeURIComponent(v)}`).join('&');
        }
        else {
            return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
        }
    })
        .join('&');
}
export const useCustomQuery = (key, getData) => {
    return useQuery(key, getData, {
        keepPreviousData: true, // 윈도우 포커스 시에 데이터를 다시 요청
        select: (data) => data.result,
        enabled: false, // 쿼리가 자동으로 실행될지 여부
        cacheTime: 300000, // 5분 동안 캐시 유지
        staleTime: 60000, // 50분이 지난 후 데이터가 만료됨
        refetchInterval: 5000, // 5초마다 데이터 재로딩
        refetchOnWindowFocus: false, // 브라우저 창에 포커스 시 다시 실행 여부
        retry: 3, // 실패 시 재시도 횟수
        suspense: true, // Suspense 사용 여부
    });
};
