export const config = {
    name: 'senstee',
    defaultLanguage: 'ko',
    version: '2.2-001',
    lobby: {
        showLogin: true,
        showMyPage: true,
        logo: {
            background: `url(${logo}) no-repeat center/contain`,
            width: '220px',
            minHeight: '68px',
        },
        background: `url(${bgLobby}) no-repeat 65% center/cover fixed`,
        subText: null, // innovation 에서만 사용
        boxWidth: null, // innovation 에서만 사용
    },
    nickname: {
        showLogin: false,
        showMyPage: false,
        logo: {
            background: `url(${logo}) no-repeat center/contain`,
            width: '220px',
            minHeight: '68px',
        },
        background: `url(${bgNickname}) no-repeat 65% center/cover fixed`,
        subText: null, // innovation 에서만 사용
        boxWidth: null, // innovation 에서만 사용
    },
    device: {
        background: {
            background: `url(${bgDevice}) no-repeat 65% center/cover fixed`,
        },
    },
    main: {
        logo: {
            background: `url(${smallLogo}) no-repeat center/contain `,
            width: '111px',
            height: '33px',
        },
    },
    ui: {
        layout: {
            GRID_MODE: true,
            PINNED_MODE: true,
            VAD_MODE: true,
            SEMINAR_MODE: true,
            whiteboard: true,
            document: true,
            common_note: true,
            screenShare: true,
            vod: true,
        },
        document: {
            direction: 'column',
            location: 'left',
        },
        film: {
            pin: true,
        },
        desktop: {
            header: {
                show: true,
                direction: 'row',
                left: ['room_name', 'room_count', 'room_time', 'connection'],
                middle: ['mode_change'],
                right: ['recordList', 'chat', 'member', 'option'],
            },
            footer: {
                show: true,
                direction: 'column',
                left: ['one_depth_mode_change'],
                right: ['setting', 'camera', 'mic', 'out'],
            },
            floating: {
                show: false,
                left: null,
            },
        },
        mobile: {
            header: {
                show: true,
                direction: 'row',
                left: [
                    'connection',
                    'mode_change',
                    'room_name',
                    'room_count',
                    'room_time',
                ],
                middle: null,
                right: ['setting'],
            },
            footer: {
                show: true,
                direction: 'row',
                left: null,
                middle: ['camera', 'mic', 'chat', 'member', 'option', 'out'],
                right: null,
            },
            floating: {
                show: false,
                left: null,
            },
        },
        close: {
            shutdown: true, // 강제 퇴장
            close: true, // 방 나가기
        },
    },
    optionList: [
        'blur',
        'security',
        'selfRecord',
        'dualMonitor',
        'serverRecord',
        'notification',
        'settingDevice',
    ],
    // 기능에 대한 옵션
    option: {
        e2ee: false,
        grid: true,
        security: true,
        dualMonitor: true,
        selfRecord: false,
        serverRecord: true,
        devicePermission: true,
        setHandlerMode: true,
        changeLabel: true,
        changeScreenMode: true,
        settingDevice: true,
        fullscreen: false,
        group: false,
        workbook: true, // 통합 문서 (document + whiteboard)
        invite: true,
        enableLable: true,
        modeChange: true,
        gridCountSet: true,
        security: true,
        invite: true,
        notificationSet: true, // 알림 옵션 설정,
        shareListPopup: false,
        attendance: false,
        chatFileLock: false,
        blur: true,
        vodFile: true,
        vodUrl: true,
        survey: false,
        notification: true, // 공지 사항
    },
    style: {
        subAccountColor: '#000',
        main: '#123',
        video_wrap: '#000',
        label: '#000',
        focusColor: '#29abe3',
        iconButton: {
            background: null,
            color: '#fff',
            iconBackColor: '#fff',
            iconStrokeColor: null,
        },
        iconHoverButton: {
            background: null,
            color: '#29abe3',
            iconBackColor: '#29abe3',
            iconStrokeColor: null,
        },
        optionButton: {
            background: null,
            color: '#000',
            iconBackColor: '#000',
            iconStrokeColor: null,
        },
        optionHoverButton: {
            background: null,
            color: '#29abe3',
            iconBackColor: '#29abe3',
            iconStrokeColor: null,
        },
        textButton: {
            background: '#494949',
            color: '#fff',
        },
        textHoverButton: {
            background: '#29abe3',
            color: '#fff',
        },
        canvasButton: {
            background: null,
            color: null,
            iconBackColor: '#545454',
            iconStrokeColor: '#545454',
        },
        canvasHoverButton: {
            background: '#b5b5b5',
            color: '#545454',
            iconBackColor: '#545454',
            iconStrokeColor: '#545454',
        },
    },
};
