import { createLocalTrack } from '../../features/base/lib-jitsi-meet/functions.any';
import { getPropertyValue } from '../../features/base/settings';
class PeerConnection {
    constructor(user_id, member_id, isAnswer, handler) {
        this.iceCandidateBuffer = [];
        this.createPeerConnection = () => {
            const iceServer = getPropertyValue(APP.store.getState, 'iceServer');
            const pc = new RTCPeerConnection({
                iceServers: iceServer,
            });
            pc.onicecandidate = event => {
                if (event.candidate) {
                    // console.log(`Candidate : ${JSON.stringify(event.candidate)}`);
                    // if (event.candidate.type !== 'srflx') {
                    // 	console.log('Skip ICE candidate:', event.candidate);
                    // 	return;
                    // }
                    if (pc.remoteDescription) {
                        // console.log('New ICE candidate:', event.candidate);
                        APP.management.callCandidateMember({
                            user_uuid: this.user_id,
                            member_uuid: this.member_id,
                            ice_candidate: event.candidate,
                        });
                    }
                    else {
                        // console.log('Remote description is not set yet. Buffering ICE candidate.');
                        this.iceCandidateBuffer.push(event.candidate);
                    }
                }
                else {
                    console.log('ICE gathering complete');
                }
            };
            pc.ontrack = event => {
                // console.log(event);
                // 여기서 원격 스트림을 처리하세요
                this.track = event.streams[0];
                this.trackId = event.track.id;
                this.handler.addTrack(event.streams[0], event.track);
            };
            pc.onconnectionstatechange = ev => {
                // console.log('Connection state changed:', pc.connectionState);
                if (pc.connectionState === 'disconnected') {
                    this.disconnect();
                }
                // if (pc.connectionState === 'closed' || pc.connectionState === 'failed') {
                // 	this.isClosed = true;
                // 	this.iceCandidateBuffer = [];
                // }
            };
            pc.oniceconnectionstatechange = async (ev) => {
                // console.log('ICE connection state changed:', pc.iceConnectionState);
                if (pc.iceConnectionState === 'disconnected' || pc.iceConnectionState === 'failed') {
                    if (this.isClosed || !this.peer)
                        return;
                    const peer = this.peer;
                    if (!this.isAnswer) {
                        try {
                            await new Promise(resolve => {
                                let timer;
                                const onTime = () => {
                                    if (pc.signalingState === 'stable') {
                                        clearTimeout(timer);
                                        return resolve();
                                    }
                                    timer = setTimeout(onTime, 1000);
                                };
                                timer = setTimeout(onTime, 1000);
                            });
                            const offer = await peer.createOffer({ iceRestart: true });
                            await peer.setLocalDescription(offer);
                            APP.management.callOfferMember({
                                user_uuid: this.user_id,
                                member_uuid: this.member_id,
                                offer: peer.localDescription,
                            });
                        }
                        catch (err) {
                            console.error('OfferRetry:', err);
                        }
                    }
                }
            };
            pc.onsignalingstatechange = ev => {
                // console.log('Signaling state changed:', pc.signalingState);
                if (pc.signalingState === 'closed') {
                    this.iceCandidateBuffer = [];
                }
            };
            pc.onicegatheringstatechange = event => {
                if (pc.iceGatheringState === 'complete') {
                    // 여기서 연결 시도
                }
            };
            return pc;
        };
        this.user_id = user_id;
        this.member_id = member_id;
        this.isAnswer = isAnswer;
        this.iceCandidateBuffer = [];
        this.handler = handler;
        this.isClosed = false;
        this.createPeerConnection = this.createPeerConnection.bind(this);
        this.muteVideo = this.muteVideo.bind(this);
    }
    checkMember(memberId) {
        return this.member_id === memberId;
    }
    muteVideo(isMuted) {
        const sender = this.peer.getSenders().find(s => s.track && s.track.kind === 'video');
        if (sender && sender.track) {
            sender.track.enabled = !isMuted;
        }
        else if (this.track && this.track.track) {
            this.track.track.enabled = !isMuted;
        }
        else {
            console.log('No video track found to mute/unmute');
        }
        this.handler && this.handler?.isMuted(isMuted);
    }
    async replaceTrack(newTrackId) {
        if (!newTrackId || newTrackId === this.trackId) {
            // console.log('New track ID is the same or not provided. No replacement needed.');
            return;
        }
        try {
            // Create a new track with the specified resolution
            const newTrack = await createLocalTrack('video', newTrackId, 5000, {
                resolution: 180,
            });
            if (!newTrack || !newTrack.track) {
                throw new Error('Failed to create new track');
            }
            const sender = this.peer.getSenders().find(s => s.track && s.track.kind === 'video');
            if (sender) {
                await sender
                    .replaceTrack(newTrack.track)
                    .then(() => {
                    console.log('Track replaced successfully');
                })
                    .catch(error => {
                    console.log('Track replaced successfully');
                });
            }
            else {
                // console.log('No video sender found. Adding new track.');
                this.peer.addTrack(newTrack.track, newTrack.stream);
            }
            // Update the current track reference
            if (this.track && this.track.track) {
                this.track.track.stop(); // Stop the old track
            }
            this.track = newTrack;
            this.trackId = newTrackId;
        }
        catch (error) {
            // console.error('Error replacing track:', error);
        }
    }
    async initLocalStream(userSelectedCameraDeviceId, offer) {
        const peer = this.createPeerConnection();
        this.peer = peer;
        let localTrack = null;
        if (userSelectedCameraDeviceId) {
            const tracks = await createLocalTrack('video', userSelectedCameraDeviceId, 5000, {
                resolution: 360,
            });
            await peer.addTrack(tracks.track, tracks.stream);
            this.trackId = tracks.deviceId;
            this.track = tracks; // Update the current track reference
            localTrack = tracks; // Assuming createLocalTrack returns an array of tracks
        }
        if (localTrack) {
            this.track = localTrack.stream;
        }
        if (this.isAnswer) {
            try {
                await peer.setRemoteDescription(new RTCSessionDescription(offer));
                const answer = await peer.createAnswer();
                await peer.setLocalDescription(answer);
                APP.management.callAnswerMember({
                    user_uuid: this.user_id,
                    member_uuid: this.member_id,
                    answer: peer.localDescription,
                });
                const iceCandidates = [...this.iceCandidateBuffer];
                this.iceCandidateBuffer = [];
                for (const iceCandidate of iceCandidates) {
                    // console.log('New ICE candidate:', iceCandidate);
                    APP.management.callCandidateMember({
                        user_uuid: this.user_id,
                        member_uuid: this.member_id,
                        ice_candidate: iceCandidate,
                    });
                }
            }
            catch (err) {
                console.error('Answer:', err);
            }
        }
        else {
            peer.addTransceiver('video', { direction: 'recvonly' });
            try {
                const offer = await peer.createOffer();
                await peer.setLocalDescription(offer);
                APP.management.callOfferMember({
                    user_uuid: this.user_id,
                    member_uuid: this.member_id,
                    offer: peer.localDescription,
                });
            }
            catch (err) {
                console.error('Offer:', err);
            }
        }
    }
    addIceCandidate(iceCandidate) {
        (async () => {
            if (this.isClosed || !this.peer || !iceCandidate)
                return;
            const peer = this.peer;
            try {
                // console.log('Adding ice candidate:', iceCandidate);
                // let ipv6 = false;
                // split(iceCandidate.candidate, ' ').forEach((item, index) => {
                // 	if (index === 4) {
                // 		if (split(item, ':').length  > 1) {
                // 			ipv6 = true;
                // 		}
                // 	}
                // });
                // if (ipv6) {
                // 	console.log('Skip IPv6:', iceCandidate);
                // 	return;
                // }
                await peer.addIceCandidate(iceCandidate);
            }
            catch (err) {
                console.error('IceCandidate:', err);
            }
        })();
    }
    setRemoteDescription(description) {
        (async () => {
            if (this.isClosed || !this.peer)
                return;
            const peer = this.peer;
            try {
                // console.log('Setting remote description:', description);
                await peer.setRemoteDescription(new RTCSessionDescription(description));
                const iceCandidates = [...this.iceCandidateBuffer];
                this.iceCandidateBuffer = [];
                for (const iceCandidate of iceCandidates) {
                    // console.log('New ICE candidate:', iceCandidate);
                    APP.management.callCandidateMember({
                        user_uuid: this.user_id,
                        member_uuid: this.member_id,
                        ice_candidate: iceCandidate,
                    });
                }
            }
            catch (err) {
                console.error(':', err);
            }
        })();
    }
    disconnect() {
        if (this.peer) {
            this.peer.close();
            this.peer = null;
            this.isClosed = true;
            this.iceCandidateBuffer = [];
            if (!Array.isArray(APP.peerconnections)) {
                APP.peerconnections = [];
            }
            const currentUserId = this.user_id;
            APP.peerconnections = APP.peerconnections.filter(p => {
                if (p && typeof p === 'object' && 'user_id' in p) {
                    return p.user_id !== currentUserId;
                }
                return false; // 유효하지 않은 peer 객체는 제외
            });
        }
    }
}
export default PeerConnection;
