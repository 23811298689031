import React, { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { MEDIA_TYPE, VIDEO_MUTISM_AUTHORITY, setVideoMuted } from '../../media';
import { getLocalTrack, getTrackState, isLocalTrackMutedByMediaType } from '../../tracks';
import { ClickButton } from '../../ui';
import { ROLE, checkRoleLevel, getLocalMemberRole } from '../../members';
const ToggleVideoButton = React.memo(({ className, label, enableLable, iconStyles, }) => {
    const dispatch = useDispatch();
    const local_role = useSelector((state) => getLocalMemberRole(state));
    const localTrack = useSelector((state) => getLocalTrack(getTrackState(state), MEDIA_TYPE.VIDEO), shallowEqual);
    const [checkRole, setCheckRole] = useState(false);
    useEffect(() => {
        setCheckRole(checkRoleLevel(local_role, ROLE.PRESENTER));
    }, [local_role]);
    const initial_camera = useSelector((state) => {
        const policy = state['features/base/settings'].policy;
        return policy.initial_camera;
    });
    const videoMuted = useSelector((state) => isLocalTrackMutedByMediaType(state, MEDIA_TYPE.VIDEO));
    const [icon, setIcon] = useState('camera_on');
    const [color, setColor] = useState(APP.config.style.focusColor);
    const _toggleVideoMuted = () => {
        if (APP.config.option.controlDevice && initial_camera === 'off' && !checkRole) {
            APP.UI.alertMessage('현재 권한이 없습니다.');
            return;
        }
        if (!localTrack)
            return;
        dispatch(setVideoMuted(!videoMuted, MEDIA_TYPE.VIDEO, VIDEO_MUTISM_AUTHORITY.USER, false));
    };
    useEffect(() => {
        setColor(videoMuted ? 'red' : APP.config.style.focusColor);
        setIcon(videoMuted ? 'camera_off' : 'camera_on');
    }, [videoMuted]);
    return (React.createElement(ClickButton, { className: `${className} no-hover`, icon: icon, size: enableLable ? 20 : 24, label: label, color: color, styles: iconStyles, onClick: _toggleVideoMuted }));
});
export default ToggleVideoButton;
