import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { isCheckPermission } from '../../room';
import { Button } from '../common';
import { MEDIA_TYPE, setAudioMuted, setVideoMuted } from '../media';
import { getPropertyValue } from '../settings/functions';
import { ClickButton } from '../ui';
import { setMemberPinned, setMemberRole } from './actions';
import { CHANGE_ROLE_LIST, ROLE } from './constans';
import { checkRoleLevel, getLocalMember } from './functions';
const ITEM_ICONS = {
    presenter: 'presenter',
    handler: 'raise_hand',
    mic: 'mic',
    video: 'camera',
    pin: 'pinned',
    etc: 'etc',
};
const ITEM_COLORS = {
    true: '#8bacff',
    false: '#545454',
};
// export const MemberPresenterItem = React.memo(
// 	({
// 		item,
// 		role_name,
// 		isLocal,
// 		isMonitoring,
// 	}: {
// 		item: string;
// 		role_name: string;
// 		isLocal: boolean;
// 		isMonitoring: boolean;
// 	}) => {
// 		const dispatch = useDispatch();
// 		const permission = useSelector((state: IStore['getState']) => isCheckPermission(state, item));
// 		const local = useSelector((state: IStore['getState']) => getLocalMember(state));
// 		const icon = ITEM_ICONS[item];
// 		const color = ITEM_COLORS[ROLE.PRESENTER === role_name ? 'true' : 'false'] || '#545454';
// 		const onClick = () => {
// 			if (!local) return;
// 			if (local?.local) {
// 				if (local.role === ROLE.PRESENTER) return;
// 				dispatch(requestPresentation(true, local.member_uuid));
// 			} else {
// 				const change_role = role_name === ROLE.PRESENTER ? ROLE.PARTICIPANT : ROLE.PRESENTER;
// 				dispatch(setMemberRole(local?.user_uuid, change_role));
// 			}
// 		};
// 		if (!local) return null;
// 		return (
// 			<ClickButton
// 				className={`nonSelect ${!permission || isMonitoring ? 'no-hover' : ''}`}
// 				icon={icon}
// 				size="15"
// 				color={color}
// 				onClick={permission && !isMonitoring ? onClick : null}
// 			/>
// 		);
// 	},
// );
export const MebmerHandlerItem = React.memo(({ item, user_uuid, pinned, isLocal, isMonitoring, }) => {
    const dispatch = useDispatch();
    const hands_up_policy = useSelector((state) => getPropertyValue(state, 'policy')?.hands_up_policy);
    const local = useSelector((state) => getLocalMember(state));
    const permission = useSelector((state) => checkRoleLevel(local?.role, ROLE.PRESENTER) ? true : local?.local);
    const icon = ITEM_ICONS[item];
    const color = ITEM_COLORS[pinned ? 'true' : 'false'] || '#545454';
    const onClick = () => {
        dispatch(setMemberPinned(user_uuid, !pinned));
    };
    if (hands_up_policy === 'off')
        return null;
    if (isLocal ? false : !checkRoleLevel(local?.role, ROLE.PRESENTER))
        return null;
    return (React.createElement(ClickButton, { icon: icon, size: "15", color: color, onClick: permission && !isMonitoring ? onClick : null }));
});
export const MebmerAudioItem = React.memo(({ item, user_uuid, audioMuted, isLocal, isMonitoring, }) => {
    const dispatch = useDispatch();
    const permission = useSelector((state) => isCheckPermission(state, 'control member mic'));
    const initial_mic = useSelector((state) => getPropertyValue(state, 'policy')?.initial_mic);
    const local = useSelector((state) => getLocalMember(state));
    const icon = ITEM_ICONS[item];
    const color = ITEM_COLORS[audioMuted ? 'false' : 'true'] || '#545454';
    const onClick = async () => {
        if (isLocal) {
            if (APP.config.option.controlDevice &&
                initial_mic === 'off' &&
                !checkRoleLevel(local?.role, ROLE.PRESENTER)) {
                APP.UI.alertMessage('현재 권한이 없습니다.');
                return;
            }
            dispatch(setAudioMuted(!audioMuted, false));
        }
        else {
            await APP.management.setAudio(user_uuid, !audioMuted);
        }
    };
    if (!local)
        return null;
    return (React.createElement(ClickButton, { icon: `${icon}_${audioMuted ? 'off' : 'on'}`, size: "15", color: color, onClick: permission && !isMonitoring ? onClick : null }));
});
export const MebmerVideoItem = React.memo(({ item, user_uuid, videoMuted, isLocal, isMonitoring, }) => {
    const dispatch = useDispatch();
    const permission = useSelector((state) => isCheckPermission(state, 'control member camera'));
    const initial_camera = useSelector((state) => getPropertyValue(state, 'policy')?.initial_camera);
    const local = useSelector((state) => getLocalMember(state));
    const icon = ITEM_ICONS[item];
    const color = ITEM_COLORS[!videoMuted ? 'true' : 'false'] || '#545454';
    const onClick = async () => {
        if (isLocal) {
            if (APP.config.option.controlDevice &&
                initial_camera === 'off' &&
                !checkRoleLevel(local?.role, ROLE.PRESENTER)) {
                APP.UI.alertMessage('현재 권한이 없습니다.');
                return;
            }
            dispatch(setVideoMuted(!videoMuted, MEDIA_TYPE.VIDEO));
        }
        else {
            await APP.management.setVideo(user_uuid, !videoMuted);
        }
    };
    if (!local)
        return null;
    return (React.createElement(ClickButton, { icon: `${icon}_${videoMuted ? 'off' : 'on'}`, size: "15", color: color, onClick: permission && !isMonitoring ? onClick : null }));
});
export const MemberEtc = React.memo(({ item, role_name, nickname, user_uuid, videoMuted, isLocal, isMonitoring, }) => {
    const rolePermision = useSelector((state) => isCheckPermission(state, 'control member role'));
    const kickPermission = useSelector((state) => isCheckPermission(state, 'kick'));
    const local = useSelector((state) => getLocalMember(state));
    const color = ITEM_COLORS[!videoMuted ? 'true' : 'false'] || '#545454';
    const [control, setControl] = useState(false);
    const onClick = async () => {
        setControl(!control);
    };
    if (!local)
        return;
    if (isLocal ? true : !checkRoleLevel(local?.role, ROLE.PRESENTER))
        return null;
    return (React.createElement(React.Fragment, null,
        React.createElement(ClickButton, { icon: item, size: "15", color: color, onClick: (kickPermission || rolePermision) && !isMonitoring ? onClick : null }),
        control && (React.createElement("div", { className: "control control_true" },
            React.createElement(MemberRoleChange, { role_name: role_name, user_uuid: user_uuid, setControl: setControl }),
            React.createElement(MemberKick, { user_uuid: user_uuid, nickname: nickname, setControl: setControl, isDualWindow: false })))));
});
export const MemberRoleChange = React.memo(({ role_name, user_uuid, setControl, }) => {
    const dispatch = useDispatch();
    const onClick = async (change_role) => {
        await dispatch(setMemberRole(user_uuid, change_role));
        setControl(false);
    };
    return CHANGE_ROLE_LIST.map((item, index) => (React.createElement(Button, { key: item, text: `role.${item}`, styles: {
            buttonWrap: {
                backgroundColor: role_name === item ? '#8bacff' : '#d5d5d5',
                color: role_name === item ? '#fff' : '#545454',
                height: '100%',
            },
        }, onClick: () => onClick(item) })));
});
export const MemberKick = React.memo(({ user_uuid, nickname = 'anonymous', setControl, isDualWindow, }) => {
    const { t } = useTranslation();
    const onClick = () => {
        const guid = t('member.kickGuid', { nickname });
        const kickMember = async () => await APP.management.setMemberKick(user_uuid);
        if (isDualWindow) {
            APP.eventManager.publish('ALERT_DUAL_LAYOUT', {
                confirmMessage: guid,
                confrimSuccess: kickMember,
            });
        }
        else {
            if (navigator.product === 'ReactNative') {
                APP.UI.confirmMessage('member.kick', 'member.kickGuid', null, kickMember, null, {
                    nickname,
                });
            }
            else {
                if (window.confirm(guid)) {
                    kickMember();
                }
            }
        }
        setControl(false);
    };
    return (React.createElement(Button, { text: "member.kick", styles: {
            buttonWrap: {
                backgroundColor: '#d5d5d5',
                color: '#545454',
                height: '100%',
            },
        }, onClick: onClick }));
});
