import React, { useEffect, useMemo, useState } from 'react';
import VideoTrack from '../../../base/media/components/web/VideoTrack';
import { ROLE, checkRoleLevel, getLocalMemberRole } from '../../../base/members';
import { useSelector } from 'react-redux';
import { getDisplayName, getPropertyValue } from '../../../base/settings';
const MemberVideo = React.memo(({ videoTrack, nickname, isFullScreen = false, role, handlerFullScreen, handlerExitFullScreen, }) => {
    const [muted, setMuted] = useState(true);
    const display_name = useSelector((state) => getDisplayName(state));
    const local_role = useSelector((state) => getLocalMemberRole(state));
    const individualLive = useSelector((state) => getPropertyValue(state, 'individualLive'));
    useEffect(() => {
        setMuted(!videoTrack || videoTrack.muted);
    }, [videoTrack]);
    const renderMutedVideo = useMemo(() => {
        return (React.createElement("div", { className: "default" },
            React.createElement("div", { className: "circleText" },
                React.createElement("div", { className: " overText" }, nickname))));
    }, [nickname]);
    const isLocalStudent = useMemo(() => !checkRoleLevel(local_role, ROLE.MANAGER), [local_role]);
    const isTeacher = useMemo(() => checkRoleLevel(role, ROLE.MANAGER), [role]);
    return muted ? (renderMutedVideo) : (React.createElement(VideoTrack, { className: "member_video", videoTrack: videoTrack, isFullScreen: individualLive ? false : isFullScreen, muted: false, showWaterMark: isLocalStudent && isTeacher, nickname: display_name, handlerFullScreen: handlerFullScreen, handlerExitFullScreen: handlerExitFullScreen }));
});
export default MemberVideo;
