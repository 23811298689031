import React, { useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import { isMobileDevice } from '../../../lobby/components';
import { Button, DropDown } from '../../common';
import { Colors } from '../../common/Colors';
import FullScreenBtn from '../../media/components/web/FullScreenBtn';
// @ts-ignore
const ControlsContainer = styled.div `
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 4;

	.controls-container {
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		background: linear-gradient(to top, rgba(0, 0, 0, 0.7), transparent);
		display: flex;
		flex-direction: column;
		padding: 10px;
		transition: opacity 0.3s ease-in-out;
		display: ${props => (props.isVisible ? 'flex' : 'none')};
	}
`;
// @ts-ignore
const ControlsRow = styled.div `
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	margin-top: 10px;
	> * {
		margin: 0;
	}

	.left_wrap {
		display: flex;
		justify-content: center;
		align-items: center;
		gap: 12px;
	}

	.button {
		background: none;
		border: none;
		color: white;
		cursor: pointer;
		transition: transform 0.1s ease-in-out;
		outline: none;
		display: flex;
		align-items: center;

		&:hover {
			transform: scale(1.1);
		}

		&:active {
			transform: scale(0.9);
		}
	}

	.time-display {
		color: white;
		font-size: 15px;
		line-height: 25px;
		width: max-content;
		display: flex;
		align-items: center;
	}
`;
// @ts-ignore
const ProgressBarContainer = styled.div `
	width: 100%;
	height: 10px; // 5에서 10으로 증가
	background-color: rgba(255, 255, 255, 0.3);
	border-radius: 5px; // 2.5에서 5로 증가
	cursor: pointer;
	position: relative;

	.full-progress-bar {
		height: 100%;
		background-color: #8bacff;
		border-radius: 5px; // 2.5에서 5로 증가
		margin: 0;
	}
	.progress-bar {
		width: 17px; // 12에서 17로 증가
		height: 17px; // 12에서 17로 증가
		background-color: #8bacff;
		border-radius: 50%;
		position: absolute;
		top: 50%;
		transform: translate(-50%, -50%);
		cursor: pointer;
	}
`;
// @ts-ignore
const QualitySelector = styled.div `
	select {
		background: rgba(0, 0, 0, 0.5);
		color: white;
		border: 1px solid rgba(255, 255, 255, 0.5);
		border-radius: 4px;
		padding: 6px 10px;
		font-size: 14px;
		margin-right: 15px;
		cursor: pointer;
		transition: all 0.3s ease;

		&:hover,
		&:focus {
			background: rgba(0, 0, 0, 0.7);
			outline: none;
		}

		option {
			background: #222;
		}
	}
`;
// @ts-ignore
const VolumeControl = styled.div `
	display: flex;
	align-items: center;
`;
// @ts-ignore
const VolumeSlider = styled.input `
	width: 0;
	opacity: 0;
	transition:
		width 0.3s ease-in-out,
		opacity 0.3s ease-in-out;

	${VolumeControl}:hover & {
		min-width: 65px; // 60에서 65로 증가
		width: 125px; // 120에서 125로 증가
		opacity: 1;
		margin-left: 12px;
	}

	-webkit-appearance: none;
	background: transparent;
	cursor: pointer;

	&::-webkit-slider-thumb {
		-webkit-appearance: none;
		height: 17px; // 12에서 17로 증가
		width: 17px; // 12에서 17로 증가
		border-radius: 50%;
		background: #ffffff;
		cursor: pointer;
		margin-top: -4px; // -4에서 -6.5로 조정 (트랙 높이의 절반)
	}

	&::-webkit-slider-runnable-track {
		width: 100%;
		height: 9px; // 4에서 9로 증가
		background: rgba(255, 255, 255, 0.5);
		border-radius: 4.5px; // 2에서 4.5로 증가
	}

	&:focus {
		outline: none;
	}
`;
const formatTime = (time) => {
    const hours = Math.floor(time / 3600);
    const minutes = Math.floor((time % 3600) / 60);
    const seconds = Math.floor(time % 60);
    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
};
let customTime = null;
const CustomControls = ({ videoRef, playing, onPlay, onPause, duration, currentTime, onSeek, volume, onVolumeChange, handlerFullScreen, handlerExitFullScreen, availableQualities, selectedQuality, handleQualityChange, }) => {
    const [isHovering, setIsHovering] = useState(true);
    const progressRef = useRef(null);
    const handlePlayPause = () => {
        if (playing) {
            onPause();
        }
        else {
            onPlay();
        }
    };
    const handleSeekChange = (e) => {
        if (!progressRef.current)
            return;
        const rect = progressRef.current.getBoundingClientRect();
        const seekPosition = (e.clientX - rect.left) / rect.width;
        onSeek(seekPosition * duration);
    };
    const handleVolumeIconClick = () => {
        onVolumeChange(volume === 0 ? 1 : 0);
    };
    const progressPercentage = isNaN(currentTime / duration) ? 0 : Math.max((currentTime / duration) * 100, 0);
    const qualityOptions = useMemo(() => [
        { id: 'auto', title: 'Auto' },
        ...availableQualities.map(quality => ({
            id: quality.qualityIndex.toString(),
            title: `${quality.height}p (${quality.bitrate} kbps)`,
        })),
    ], [availableQualities]);
    return (React.createElement(ControlsContainer, { isVisible: isHovering, onMouseEnter: () => {
            if (customTime)
                clearTimeout(customTime);
            setIsHovering(true);
        }, onMouseLeave: () => {
            customTime = setTimeout(() => setIsHovering(false), 3000);
        }, onTouchStart: e => {
            if (customTime)
                clearTimeout(customTime);
            setIsHovering(true);
        }, onTouchEnd: () => {
            customTime = setTimeout(() => setIsHovering(false), 3000);
        } },
        React.createElement("div", { className: "controls-container" },
            React.createElement(ProgressBarContainer, { ref: progressRef, onClick: handleSeekChange },
                React.createElement("div", { className: "full-progress-bar", style: { width: `${progressPercentage}%` } }),
                React.createElement("div", { className: "progress-bar", style: { left: `${progressPercentage}%` } })),
            React.createElement(ControlsRow, null,
                React.createElement("div", { className: "left_wrap" },
                    React.createElement(Button, { option: {
                            buttonBeforeIcon: {
                                show: true,
                                name: playing ? 'vodPause' : 'vodStart',
                                size: 25,
                                color: playing ? '#8bacff' : '#fff',
                                className: 'handlerIcon',
                            },
                        }, onClick: handlePlayPause }),
                    React.createElement("div", { className: "time-display" },
                        formatTime(currentTime),
                        " / ",
                        formatTime(duration)),
                    React.createElement(VolumeControl, null,
                        React.createElement(Button, { option: {
                                buttonBeforeIcon: {
                                    show: true,
                                    name: volume === 0 ? 'vodVolumeOff' : 'vodVolumeOn',
                                    size: 25,
                                    color: volume === 0 ? Colors.accent : '#fff',
                                    className: 'handlerIcon',
                                },
                            }, onClick: handleVolumeIconClick }),
                        !isMobileDevice() && (React.createElement(VolumeSlider, { type: "range", min: "0", max: "1", step: "0.1", value: volume, onChange: e => onVolumeChange(parseFloat(e.target.value)) })))),
                React.createElement("div", { className: "left_wrap" },
                    React.createElement(QualitySelector, { className: "quality-selector" },
                        React.createElement(DropDown, { className: "dropdown-live", value: qualityOptions.find(option => option.id === selectedQuality.toString()), list: qualityOptions, setDropValue: item => {
                                handleQualityChange(item.id === 'auto' ? 'auto' : parseInt(item.id, 10));
                            }, noBottom: true, styles: {
                                dropWrap: {
                                    minWidth: 120,
                                    maxWidth: 200,
                                },
                                titleWrap: {
                                    background: Colors.transparent,
                                },
                                titleText: {
                                    color: Colors.white,
                                },
                                itemWrap: {
                                    left: 'auto',
                                    right: 44,
                                },
                            }, option: {
                                downIcon: {
                                    color: '#fff',
                                },
                            }, isDisable: false })),
                    React.createElement(FullScreenBtn, { autoPlay: false, className: "screen-full-btn", video: videoRef.current, handlerFullScreen: handlerFullScreen, handlerExitFullScreen: handlerExitFullScreen }))))));
};
export default CustomControls;
