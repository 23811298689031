import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { getPropertyValue } from '../settings';
import { TestContainer } from './TestContainer';
export const TestMain = React.memo(() => {
    const testStart = useSelector((state) => getPropertyValue(state, 'testStart'));
    const [hide, setHide] = React.useState(false);
    const [test_id, setTestId] = React.useState();
    const toggleTest = ({ changeHide, test_id }) => {
        setHide(changeHide);
        setTestId(test_id);
    };
    useEffect(() => {
        APP.eventManager.subscribe('TOGGLE_TEST', toggleTest);
        return () => {
            APP.eventManager.unsubscribe('TOGGLE_TEST', toggleTest);
        };
    }, []);
    useEffect(() => {
        setHide(false);
    }, [testStart]);
    return React.createElement(TestContainer, { hide: hide, setHide: setHide, testStart: testStart, test_id: test_id });
});
