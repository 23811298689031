import i18next from 'i18next';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

let chatInputDelay = null;
export default {
	alertMessage: (message, props, isAlert, option) => {
		if (isAlert) {
			message && window.alert(i18next.t(message, props));
			return;
		}

		message &&
			toast(i18next.t(message, props), {
				position: 'top-right',
				autoClose: 500,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
				...option,
			});
	},
	confirmMessage: message => {
		if (message) {
			return confirm(i18next.t(message));
		}

		return false;
	},
};
