export const command = {
	'auth-setting': 'User.Auth.Set',
	'join-room': 'Room.Member.Join',
	'left-room': 'Room.Member.Leave',
	'left-member-room': 'Room.Member.Left',
	shared_screen: 'Room.View.SharedScreen.Share',
	document: 'Room.View.Document.Focus',
	whiteboard: 'Room.View.Whiteboard.Focus',
	PINNED_MODE: 'Room.Layout.Pin',
	GRID_MODE: 'Room.Layout.Grid',
	VAD_MODE: 'Room.Layout.Vad',
	SEMINAR_MODE: 'Room.Layout.Seminar',
	'set-layout-visiblity': 'Room.View.Visibility',
	'set-role': 'Room.Member.Role.Set',
	'member-kick': 'Room.Member.Kick',
	'member-kicked': 'Room.Member.kicked',
	'member-add-pin': 'Room.Member.Pin.Add',
	'member-del-pin': 'Room.Member.Pin.Delete',
	'send-text': 'Room.Chat.Text',
	'send-private-text': 'Room.Member.Chat.Text',
	'send-file': 'Room.Chat.File',
	'set-jitsi-id': 'Room.Member.BridgeSession.Set',
	'set-all-video-false': 'Room.Camera.TurnOn',
	'set-all-video-true': 'Room.Camera.TurnOff',
	'set-all-audio-false': 'Room.Mic.TurnOn',
	'set-all-audio-true': 'Room.Mic.TurnOff',
	'set-video-false': 'Room.Member.Camera.TurnOn',
	'set-video-true': 'Room.Member.Camera.TurnOff',
	'set-audio-false': 'Room.Member.Mic.TurnOn',
	'set-audio-true': 'Room.Member.Mic.TurnOff',
	'start-attendance': 'Meeting.AttendanceStarted',
	'share-survey': 'Room.Survey.Share',
	'reply-survey': 'Room.Survey.Reply',
	'request-presentation': 'Room.Presentation.Request',
	'response-presentation': 'Room.Presentation.Response',
	'set-document': 'Room.View.Document.Set',
	'del-document': 'Room.View.Document.Del',
	'set-member-record': 'Room.Member.Record',
	'set-canvas': 'Room.View.Document.Canvas.Set',
	'del-canvas': 'Room.View.Document.Canvas.Del',
	'set-vod-file': 'Room.View.Vod.File.Set',
	'del-vod-file': 'Room.View.Vod.File.Del',
	'set-vod-url': 'Room.View.Vod.Url.Set',
	'del-vod-url': 'Room.View.Vod.Url.Del',
	'focus-vod-item': 'Room.View.Vod.Share',
	'send-notification': 'Room.Notification.Send',
	'recive-notification': 'Room.Notification.Receive',
	'group-crated': 'Meeting.Meeting.GroupCreated',
	'group-raised': 'Meeting.Meeting.GroupEventRaised',
	'group-changed': 'Meeting.Meeting.GroupStatusChanged',
	'group-name-changed': 'Meeting.Meeting.GroupNameChanged',
	'group-assined': 'Meeting.Member.GroupAssigned',
	'group-member-left': 'Meeting.Member.GroupLeft',
	'group-status': 'Room.Group.All',
	'group-remove': 'Meeting.Meeting.GroupRemoved',
	'set-policy': 'Room.Policy.Update',
	'set-grid-count': 'Room.GridCount.Set',
	'set-handler-up': 'Room.Member.HandsUp',
	'set-handler-down': 'Room.Member.HandsDown',
	'set-nickname': 'Room.Member.Nickname.Set',
};
