import dashjs from 'dashjs';
import moment from 'moment';
import React, { useCallback } from 'react';
import { FullScreen, useFullScreenHandle } from 'react-full-screen';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { fetchErrorData } from '../../../../modules/API/API';
import { ROLE, checkRoleLevel, getLocalIDMember, getLocalMemberRole } from '../../members';
import { getDisplayName } from '../../settings';
import CustomControls from './CustomControls';
import { PlayerWebsocket } from './playerWebsocket';
import { getStreaming, playStreaming, stopStreaming } from './vodPalyerApi';
// @ts-ignore
const PlayerStyled = styled.div `
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 100%;
	height: 100%;
	object-fit: contain;

	.loading {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		justify-content: center;
		align-items: center;
		display: flex;
		z-index: 1;
		background: rgba(0, 0, 0, 1);
	}

	video {
		position: absolute;
		z-index: 2;
		width: 100%;
		height: 100%;
		object-fit: contain;
		background: #000;
	}

	.screen-full-btn {
		color: #fff !important;
		background: transparent !important;
		font-size: 16px;
		cursor: pointer;
		margin-right: 5px;
	}
`;
function getRandomIntervalInMs() {
    // 3분에서 5분 사이의 밀리초 범위
    const minIntervalMs = 2 * 60 * 1000; // 3분 = 180초 = 180,000밀리초
    const maxIntervalMs = 4 * 60 * 1000; // 5분 = 300초 = 300,000밀리초
    // minIntervalMs와 maxIntervalMs 사이의 랜덤한 밀리초를 생성
    const randomMs = Math.floor(Math.random() * (maxIntervalMs - minIntervalMs + 1)) + minIntervalMs;
    return randomMs;
}
let timeIntervalId = null;
let player = null;
let websocket = null;
let retryCount = 0;
const maxRetries = 3;
const desiredResolutions = [1080, 720, 360, 180];
export const Player = React.memo(({ uuid, fullCanvas, scale, canvasPos, isWidthFit, urlList, isComplete, streamingId = '', setDrawCanvas, }) => {
    const handle = useFullScreenHandle();
    const videoRef = React.useRef(null);
    const member_id = useSelector((state) => getLocalIDMember(state));
    const showWaterMark = useSelector((state) => !checkRoleLevel(getLocalMemberRole(state), ROLE.PRESENTER));
    const nickname = useSelector((state) => getDisplayName(state));
    const [loading, setLoading] = React.useState(true);
    const [streamingAuth, setStreamingAuth] = React.useState();
    const [hasPermission, setHasPermission] = React.useState(false);
    const [currentTime, setCurrentTime] = React.useState(0);
    const [duration, setDuration] = React.useState(0);
    const [playing, setPlaying] = React.useState(false);
    const [mediaUrl, setMediaUrl] = React.useState();
    const [playRate, setPlayRate] = React.useState(0);
    const [volume, setVolume] = React.useState(0);
    const [availableQualities, setAvailableQualities] = React.useState([]);
    const [selectedQuality, setSelectedQuality] = React.useState('auto');
    // handler 플레이어 시작
    const handlerPlayVideo = async () => {
        try {
            const videoPlayerElement = document.getElementById('videoPlayer') || videoRef.current;
            player && player.play();
            if (videoPlayerElement) {
                await videoPlayerElement.play().catch(() => { });
            }
            setPlaying(true);
        }
        catch (err) { }
    };
    // handler 플레이어 정지
    const handlerPauseVideo = async () => {
        try {
            const videoPlayerElement = document.getElementById('videoPlayer') || videoRef.current;
            player && player.pause();
            if (videoPlayerElement) {
                videoPlayerElement.pause();
            }
            setPlaying(false);
        }
        catch (err) {
            console.log('videoPlayerElement err', err);
        }
    };
    // (Event handler) 웹소켓 이벤트 handler
    const handleMessage = async (data) => {
        switch (data.class) {
            case '@Streaming.StreamingPlayed':
                const result = data.payload.result;
                try {
                    const { position: play_position, speed, at } = result;
                    const timeDiff = websocket ? websocket.timeDiff : 0;
                    const videoPlayerElement = document.getElementById('videoPlayer') || videoRef.current;
                    if (videoPlayerElement) {
                        const play_time = Math.max(0, (play_position - timeDiff) / 1000);
                        setPlayRate(play_time);
                        handlerPlayVideo();
                    }
                }
                catch (err) {
                    APP.UI.alertMessage('스트리밍 오류입니다. 다시 시도해주세요. - 재생 동기화 실패', undefined, false, {
                        type: 'error',
                    });
                    setPlaying(false);
                    await fetchErrorData('Streaming.StreamingPlayed Error' + JSON.stringify(err));
                }
                break;
            case '@Streaming.StreamingPaused':
                handlerPauseVideo();
                break;
        }
    };
    // updateQualityOptions 함수 수정
    const updateQualityOptions = useCallback(() => {
        if (!player) {
            console.warn('Player instance is not available.');
            return;
        }
        const bitrateInfos = player.getBitrateInfoListFor('video');
        if (!bitrateInfos || bitrateInfos.length === 0) {
            console.warn('No bitrate information available.');
            return;
        }
        const qualities = bitrateInfos
            .map((info, index) => ({
            bitrate: Math.round(info.bitrate / 1000), // kbps로 변환
            height: info.height,
            width: info.width,
            qualityIndex: index,
        }))
            .filter(quality => desiredResolutions.includes(quality.height))
            .sort((a, b) => b.height - a.height);
        if (qualities.length === 0) {
            console.warn('No matching qualities found for the desired resolutions.');
        }
        setAvailableQualities(qualities);
    }, [player]);
    const handleQualityChange = (quality) => {
        if (!player) {
            console.warn('Player instance is not available.');
            return;
        }
        setSelectedQuality(quality);
        if (quality === 'auto') {
            player.updateSettings({
                streaming: {
                    abr: {
                        autoSwitchBitrate: {
                            video: true,
                        },
                    },
                },
            });
        }
        else {
            player.updateSettings({
                streaming: {
                    abr: {
                        autoSwitchBitrate: {
                            video: false,
                        },
                    },
                },
            });
            player.setQualityFor('video', quality);
        }
        // Clear buffer and reinitialize the stream
        // setTimeout(() => {
        // 	player.attachSource(player.getSource());
        // }, 100);
    };
    // Add this function to your component
    const forceQualityUpdate = () => {
        if (player && selectedQuality !== 'auto') {
            player.setQualityFor('video', selectedQuality);
            player.attachSource(player.getSource());
        }
    };
    // 플레이어에 참여하기 (웹소켓 연결)
    const joinStreaming = () => {
        if (streamingId && websocket && isComplete === 'complete') {
            websocket
                .joinStreaming(streamingId)
                .then(
            // @ts-ignore
            async (response) => {
                if (!response)
                    return;
                setMediaUrl(`${urlList[0]}//${urlList[1]}/api/media/v1/medias/${response.media.id}/manifest.mpd`);
            })
                .catch(err => {
                fetchErrorData('vod websocket SubscribeStreaming error - 스트리밍 입장 실패 웹소켓 연결 실패 ');
                APP.UI.alertMessage('스트리밍 오류입니다. 다시 시도해주세요. - 스트리밍 입장 실패', undefined, false, {
                    type: 'error',
                });
            });
        }
    };
    const getCurrentPlaybackTimeFromServer = async () => {
        try {
            const response = await getStreaming({ urlBase: `${urlList[0]}//${urlList[1]}`, streamingId });
            if (!response)
                return 0;
            const items = response.result.items;
            if (items && items.length > 0) {
                const item = items[0];
                if (item) {
                    const { play_position, speed, status, time, play_time, pause_time } = item;
                    let return_duration = 0;
                    if (status === 'stopped') {
                        // 재생 시간이 종료 시간보다 작은 경우 처리
                        if (moment(play_time).isBefore(moment(pause_time))) {
                            return_duration =
                                (moment.duration(moment(pause_time).diff(moment(play_time))).asMilliseconds() +
                                    play_position) /
                                    1000;
                        }
                        else {
                            return_duration = play_position / 1000;
                        }
                    }
                    else {
                        const play_duration = moment
                            .duration(moment(time).diff(moment(play_time)))
                            .asMilliseconds();
                        const surveryPosition = play_duration + play_position;
                        const localPosition = surveryPosition - websocket.timeDiff;
                        return_duration = localPosition / 1000;
                    }
                    return return_duration;
                }
            }
            return 0; // 아이템이 없거나, 계산에 문제가 발생한 경우 기본값 반환
        }
        catch (error) {
            console.error('Error fetching playback time:', error);
            return 0; // 에러 발생 시 기본값 반환
        }
    };
    // 스트리밍 데이터 로드
    const initLoadData = () => {
        if (player) {
            updateQualityOptions();
            forceQualityUpdate();
            getStreaming({ urlBase: `${urlList[0]}//${urlList[1]}`, streamingId }).then((response) => {
                if (!response)
                    return;
                const items = response.result.items;
                if (items && items.length > 0) {
                    const item = items[0];
                    onloadedmetadata(item, player ? player.duration() : 0);
                }
            });
        }
    };
    // 비디오 메타데이터 로드
    const onloadedmetadata = async (response, duration) => {
        setVolume(0);
        if (response) {
            const { play_position, speed, status, time, play_time, pause_time } = response;
            if (status === 'stopped') {
                // 재생 시간이 종료 시간보다 작은 경우 처리
                if (moment(play_time).isBefore(moment(pause_time))) {
                    const play_duration = moment.duration(moment(pause_time).diff(moment(play_time))).asMilliseconds() +
                        play_position;
                    setPlayRate(play_duration / 1000);
                }
                else {
                    setPlayRate(play_position / 1000);
                }
                handlerPauseVideo();
                setLoading(false);
                return;
            }
            const play_duration = moment.duration(moment(time).diff(moment(play_time))).asMilliseconds();
            const surveryPosition = play_duration + play_position;
            const localPosition = surveryPosition - websocket.timeDiff;
            if (duration < localPosition / 1000) {
                player && player.seek(0);
                setPlayRate(0);
                handlerPauseVideo();
                setLoading(false);
                return;
            }
            setPlayRate(localPosition / 1000);
            handlerPlayVideo();
            setLoading(false);
        }
    };
    React.useEffect(() => {
        return () => {
            if (websocket) {
                retryCount = 0;
                websocket.cleanUp();
                websocket.disconnect();
                player = null;
                websocket = null;
                retryCount = 0;
            }
        };
    }, []);
    React.useEffect(() => {
        const handleVisibilityChange = () => {
            if (document.visibilityState === 'hidden') {
            }
            else {
                websocket.cleanUp();
                initLoadData();
            }
        };
        document.addEventListener('visibilitychange', handleVisibilityChange);
        return () => {
            document.removeEventListener('visibilitychange', handleVisibilityChange);
        };
    }, [initLoadData]);
    React.useEffect(() => {
        timeIntervalId =
            nickname &&
                showWaterMark &&
                setInterval(() => {
                    const videoPlayerElement = document.getElementById('playerFullElement') || videoRef.current;
                    const { offsetWidth: width, offsetHeight: height } = videoPlayerElement;
                    // Create and style the canvas element
                    const canvasElement = document.createElement('canvas');
                    canvasElement.style.position = 'absolute';
                    canvasElement.style.top = '0';
                    canvasElement.style.left = '0';
                    canvasElement.style.width = '100%';
                    canvasElement.style.height = '100%';
                    canvasElement.width = width;
                    canvasElement.height = height;
                    const ctx = canvasElement.getContext('2d');
                    if (ctx) {
                        //
                        const watermarkText = nickname || '대성마이맥';
                        ctx.font = '25px Arial';
                        ctx.fillStyle = 'rgba(255, 255, 255, 0.5)';
                        // Calculate text width and height
                        const textWidth = ctx.measureText(watermarkText).width * 2;
                        const textHeight = 25;
                        // Determine random position for the watermark
                        const x = Math.max(Math.min(Math.random() * (width - textWidth), width - textWidth - 25), 25);
                        const y = Math.max(Math.min(Math.random() * (height - textHeight), height - textHeight - 25), 25);
                        // Draw the watermark
                        ctx.fillText(watermarkText, x, y);
                        const devicePixelRatio = window.devicePixelRatio || 1;
                        ctx.scale(devicePixelRatio, devicePixelRatio);
                        ctx.setTransform(1, 0, 0, 1, 0, 0);
                        if (videoPlayerElement) {
                            videoPlayerElement.appendChild(canvasElement);
                        }
                    }
                    // Remove the canvas after 1 second
                    setTimeout(() => {
                        if (videoPlayerElement && canvasElement) {
                            videoPlayerElement.removeChild(canvasElement);
                        }
                    }, 1000);
                }, getRandomIntervalInMs());
        //
        return () => {
            timeIntervalId && clearInterval(timeIntervalId);
        };
    }, [nickname, showWaterMark]);
    React.useEffect(() => {
        const video = document.getElementById('playerFullElement') || videoRef.current;
        if (!video || !player)
            return;
        player && player.seek(playRate);
        video.currentTime = playRate;
    }, [playRate]);
    React.useEffect(() => {
        if (player) {
            player.setVolume(volume);
        }
        const videoPlayerElement = document.getElementById('videoPlayer') || videoRef.current;
        if (videoPlayerElement) {
            videoPlayerElement.volume = volume;
        }
    }, [volume]);
    React.useEffect(() => {
        if (websocket) {
            retryCount = 0;
            websocket.cleanUp();
            websocket.disconnect();
        }
        if (!streamingId)
            return;
        setLoading(true);
        const initWebsocket = async () => {
            let auth = undefined;
            const response = await APP.API.generateStreamingAuth(member_id, uuid);
            if (response.complete) {
                auth = response.data.auth;
            }
            setStreamingAuth(auth);
            setHasPermission(auth ? true : false);
            if (urlList && urlList.length > 1) {
                const playerWebsocket = new PlayerWebsocket();
                playerWebsocket
                    .connect(urlList[1], handleMessage)
                    .then(() => {
                    websocket = playerWebsocket;
                    joinStreaming();
                })
                    .catch(err => {
                    fetchErrorData('websocket error - vod 웹소켓 연결 실패');
                    APP.UI.alertMessage('스트리밍 오류입니다. 다시 시도해주세요. - 웹소켓 연결 실패', undefined, false, {
                        type: 'error',
                    });
                    setPlaying(false);
                });
            }
        };
        initWebsocket();
    }, [streamingId]);
    React.useEffect(() => {
        if (mediaUrl) {
            let handlerTimeUpdate; // 재시도 타이머를 관리할 변수
            // 에러가 있을때 재시도
            const handlerError = err => {
                if (retryCount < maxRetries) {
                    const retryDelay = Math.pow(2, retryCount) * 1000; // 지수적 백오프
                    handlerTimeUpdate = setTimeout(() => {
                        retryCount++;
                        player && player.attachSource(mediaUrl); // 재시도 시 소스 다시 첨부
                    }, retryDelay);
                }
                else {
                    APP.UI.alertMessage('지원되지 않는 기기입니다.', undefined, true, {
                        type: 'error',
                    });
                }
            };
            const playBackEnded = () => {
                const videoPlayerElement = document.getElementById('videoPlayer') || videoRef.current;
                if (videoPlayerElement) {
                    setPlayRate(0);
                    handlerPauseVideo();
                }
            };
            const dashPlayer = dashjs.MediaPlayer().create();
            const videoPlayerElement = document.getElementById('videoPlayer') || videoRef.current;
            if (videoPlayerElement) {
                dashPlayer.initialize(videoPlayerElement, mediaUrl, false);
                dashPlayer.on(dashjs.MediaPlayer.events.STREAM_INITIALIZED, async () => {
                    var currentPlaybackTime = await getCurrentPlaybackTimeFromServer();
                    if (dashPlayer && dashPlayer.duration() > currentPlaybackTime) {
                        dashPlayer.seek(currentPlaybackTime);
                    }
                }); // 스트림 초기화 핸들러 등록
                dashPlayer.on(dashjs.MediaPlayer.events.ERROR, handlerError); // 에러 핸들러 등록
                dashPlayer.on(dashjs.MediaPlayer.events.PLAYBACK_ENDED, playBackEnded); // 재생 종료 핸들러 등록]
                dashPlayer.on(dashjs.MediaPlayer.events.QUALITY_CHANGE_RENDERED, updateQualityOptions); // 재생 종료 핸들러 등록
                dashPlayer.updateSettings({
                    streaming: {
                        buffer: {
                            initialBufferLevel: 4,
                            fastSwitchEnabled: true,
                            bufferPruningInterval: 4, // 1초마다 버퍼 정리
                        },
                        abr: {
                            autoSwitchBitrate: {
                                video: true,
                            },
                        },
                    },
                });
                dashPlayer.attachSource(mediaUrl); // `posix:${125444}`); // 미디어 소스 첨부
                setDuration(dashPlayer.duration());
                player = dashPlayer;
            }
            return () => {
                if (player) {
                    player.off(dashjs.MediaPlayer.events.STREAM_INITIALIZED, initLoadData);
                    player.off(dashjs.MediaPlayer.events.ERROR, handlerError); // 에러 핸들러 해제
                    player.off(dashjs.MediaPlayer.events.PLAYBACK_ENDED, playBackEnded); // 재생 종료 핸들러 해제
                    player.off(dashjs.MediaPlayer.events.QUALITY_CHANGE_RENDERED, updateQualityOptions); // 재생 종료 핸들러 등록
                    if (handlerTimeUpdate)
                        clearTimeout(handlerTimeUpdate); // 타이머 클리어
                    player.destroy(); // 플레이어 파괴
                }
            };
        }
    }, [mediaUrl]);
    // 플레이어 시간 변경
    const onTimeChange = async (currentTime) => {
        if (!hasPermission)
            return;
        const videoPlayerElement = document.getElementById('videoPlayer') || videoRef.current;
        if (videoPlayerElement) {
            videoPlayerElement.currentTime = currentTime;
            if (player) {
                const isPlaying = playing;
                await onPlay();
                if (!isPlaying)
                    await onStop();
            }
        }
    };
    // 플레이어 재생
    const onPlay = async () => {
        if (!streamingId || !streamingAuth)
            return;
        const videoPlayerElement = document.getElementById('videoPlayer') || videoRef.current;
        setPlaying(true);
        let position = 0;
        let speed = 1;
        if (videoPlayerElement) {
            position = Math.floor(videoPlayerElement.currentTime * 1000);
            speed = videoPlayerElement.playbackRate * 1000;
        }
        try {
            await playStreaming({
                streamingAuth,
                streamingId,
                position,
                speed,
                urlBase: `${urlList[0]}//${urlList[1]}`,
            });
        }
        catch (err) {
            APP.UI.alertMessage('스트리밍 재생에 실패했습니다.', undefined, false, { type: 'error' });
        }
    };
    // 플레이어 정지
    const onStop = async () => {
        if (!streamingId || !streamingAuth)
            return;
        setPlaying(false);
        try {
            await stopStreaming({ streamingAuth, streamingId, urlBase: `${urlList[0]}//${urlList[1]}` });
        }
        catch (err) {
            APP.UI.alertMessage('스트리밍 중지에 실패했습니다.', undefined, false, { type: 'error' });
            setPlaying(true);
        }
    };
    return (React.createElement(FullScreen, { className: "full-screen full player", handle: handle },
        React.createElement(PlayerStyled, { id: "playerFullElement" },
            React.createElement("video", { ref: videoRef, id: "videoPlayer", onTimeUpdate: e => setCurrentTime(e.target.currentTime), onLoadedMetadata: e => setDuration(e.target.duration), onCanPlayThrough: async () => {
                    if (loading)
                        initLoadData();
                }, playsInline: true, controls: false, muted: volume === 0, disablePictureInPicture: true, style: { visibility: loading ? 'hidden' : 'visible' } }),
            React.createElement(CustomControls, { videoRef: videoRef, playing: playing, onPlay: onPlay, onPause: onStop, duration: duration, currentTime: currentTime, onSeek: onTimeChange, volume: volume, onVolumeChange: setVolume, handlerFullScreen: handle.enter, handlerExitFullScreen: handle.exit, availableQualities: availableQualities, selectedQuality: selectedQuality, handleQualityChange: handleQualityChange }),
            React.createElement("div", { className: "loading" }, "\uB85C\uB529\uC911"))));
});
