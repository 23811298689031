import styled from 'styled-components';

export const HandlerStyled = styled.div`
	.handler_content {
		display: flex;
	}

	.hand_item {
		.click_button {
			display: flex;
			flex-direction: column;
			gap: 12px;
			flex: 1;
			align-items: center;
		}

		> * {
			margin: 0;
		}

		.hand_input {
			display: flex;
			justify-content: center;
			align-items: center;
			gap: 8px;

			> * {
				margin: 0;
			}
		}

		input {
			border: 1px solid #d5d5d5;
			padding: 4px 12px;
			box-sizing: border-box;
			width: 65%;
			margin: 0 auto;
			text-align: center;
			display: flex;
			border-radius: 8px;
		}
	}
`;
