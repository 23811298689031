import styled from 'styled-components';
export const PopupStyled = styled.div `
	.popup {
		position: absolute;
		left: 0;
		top: 0;
		background: rgba(0, 0, 0, 0.2);
		width: 100%;
		height: 100%;
	}

	.guid {
		white-space: break-spaces;
		text-align: center;
	}

	.popup_content {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		width: 95%;

		.header {
			display: flex;
			border-bottom: 2px solid;
			padding: 5px 0;
			width: 100%;

			.title {
				margin: 0 auto 0 0;
				font-size: 15px;
				font-weight: bold;
			}

			.close {
				margin: 0 0 0 auto;
				cursor: pointer;
			}
		}

		.guid {
			white-space: break-spaces;
			text-align: center;
		}

		.content {
			display: flex;
			flex-direction: column;
			gap: 12px;
			background: #fff;
			padding: 12px;
			border-radius: 6px;
			width: 85%;
		}

		input {
			padding: 8px;
			box-sizing: border-box;
			border: none;
			border-bottom: 1px solid;
		}
	}
`;
