import moment from 'moment';
import React, { useMemo } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { getPropertyValue } from '../settings';
import { ChatItem } from './components';
import { getMessageByUid } from './functions';
const ChatItemContainer = React.memo(({ uuid }) => {
    const message = useSelector((state) => getMessageByUid(state, uuid), shallowEqual);
    if (!message)
        return null; // 조기 반환 시 명시적으로 null을 반환하도록 수정
    return React.createElement(ChatRenderContainer, { message: message });
});
export default ChatItemContainer;
export const ChatRenderContainer = React.memo(({ message }) => {
    const militaryTime = useSelector((state) => getPropertyValue(state, 'militaryTime'));
    const time = useMemo(() => (militaryTime ? moment(message.timestamp).format('HH:mm') : moment(message.timestamp).format('LT')), [militaryTime, message.timestamp]);
    const to = useMemo(() => message.fromNickname?.split('(')[0] || '', [message.fromNickname]);
    return (React.createElement(ChatItem, { to: to, type: message.type, message: message.message, isMe: message.isMe, fromRole: message.fromRole, uuid: message.uuid, time: time, timestamp: message.timestamp }));
});
