import React from 'react';
import { ROLE } from '../../../base/members';
function MemberItem({ local, role_name, styles, isMaxSize, vertical, renderVideo, renderAudioMuted, renderPin, }) {
    return (React.createElement("div", { className: `item video-track-element ${local && role_name !== ROLE.HOST && role_name !== ROLE.MANAGER ? 'mirror' : 'no_mirror'}`, style: isMaxSize
            ? vertical
                ? { width: `${styles.width}px`, maxHeight: `${styles.height}px`, height: '100%' }
                : { width: `${styles.width}px`, height: `${styles.height}px` }
            : styles
                ? { width: `${styles.width}px`, height: `${styles.height}px` }
                : {} },
        React.createElement("div", { className: "videoWrap" },
            renderVideo,
            React.createElement("div", { className: "video_center" },
                renderAudioMuted,
                renderPin))));
}
export default React.memo(MemberItem);
