import React, { useEffect, useRef, useState } from 'react';
import { ClickButton, DivElement } from '../base/ui';
const TEST_SOUND_PATH = '/libs/sounds/ring.wav';
const AudioOutputPreview = React.memo(({ deviceId, handlerVolume }) => {
    const [isOn, setIsOn] = useState(false);
    const audioRef = useRef(null);
    useEffect(() => {
        if (audioRef.current) {
            audioRef.current.volume = handlerVolume;
        }
    }, [handlerVolume]);
    useEffect(() => {
        if (audioRef.current) {
            if (isOn) {
                audioRef.current.play().catch(console.error);
            }
            else {
                audioRef.current.pause();
            }
        }
    }, [isOn]);
    useEffect(() => {
        if (audioRef.current && deviceId && audioRef.current.setSinkId) {
            audioRef.current.setSinkId(deviceId).catch(console.error);
        }
    }, [deviceId]);
    return (React.createElement(DivElement, { className: "audio-output-preview" },
        React.createElement(ClickButton, { className: `audio_test_btn ${isOn ? 'on' : ''}`, label: 'deviceSelection.testAudio', onClick: () => setIsOn(!isOn) }),
        React.createElement("audio", { ref: audioRef, loop: true, src: TEST_SOUND_PATH })));
});
export default AudioOutputPreview;
