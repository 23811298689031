import JitsiMeetJS, { JitsiConnectionQualityEvents } from '../../../features/base/lib-jitsi-meet';
import { createLocalTrack } from '../../../features/base/lib-jitsi-meet/functions.any';
import { MEDIA_TYPE } from '../../../features/base/media';
import { getLocalTracks, getTrackState } from '../../../features/base/tracks';
import { getWebsocketURLBase } from '../../../features/base/ui/utils';
import { startListeningForStats } from '../../../features/connection-indicator';
import { getMediaServerInfo } from '../../../features/room';
import JitsiConference from './conference';
import { default as Connection } from './connection';
import { default as ScreenShare } from './screenSharing';
import JitsiTracks from './tracks';

const config = {
	connect: {
		serviceUrl: `wss://mate3.dev.meetmate.co.kr/jitsi-1/xmpp-websocket?1716794433000`,
		hosts: {
			domain: 'mate.net',
			muc: 'muc.mate.net',
		},
	},
	conference: {
		p2p: false,
		enableUnifiedOnChrome: true,
		enableForcedReload: false,
		enableNoisyMicDetection: false,
		openBridgeChannel: 'websocket',
		videoQuality: {
			maxBitratesVideo: {
				low: 100000,
				standard: 500000,
				high: 2000000,
			},
		},
	},
};

class JitsiManagement {
	constructor() {
		this._localTracksInitialized = false;
		this.Connection = new Connection();
		this.Conference = new JitsiConference();
		this.Tracks = new JitsiTracks();
	}

	setConstraints(data) {
		if (this.Conference) {
			this.Conference.setReceiverConstraints(data);
		}
	}

	// 회의실 connect
	connectAndJoin(isTemp, user_id) {
		if (this.Connection.connection) {
			return Promise.resolve(this.Conference.conference);
		}

		const connectConfig = Object.assign({}, config.connect);
		const mediaInfo = getMediaServerInfo(APP.store.getState);

		connectConfig.serviceUrl = `${getWebsocketURLBase()}${mediaInfo.url}?${Date.parse(new Date())}`;

		return new Promise((resolve, reject) => {
			ScreenShare.init(this.createScreenShareTrack.bind(this));

			// 회의실 입장을 위한 jitsi connect
			this.Connection.connect(connectConfig).then(connection => {
				const conferenceConfig = location.search.includes('disableSimulcast')
					? { disableSimulcast: true }
					: { disableSimulcast: false };

				const joinConfig = Object.assign(conferenceConfig, config.conference);

				console.log(joinConfig);
				// 회의실 입장
				const handler = {
					handlerCreateTracks: options => this.Tracks.createTracks(options),
				};

				return this.Conference.join(connection, joinConfig, handler, isTemp, user_id)
					.then(conference => {
						!isTemp && APP.store.dispatch(startListeningForStats(conference));
						this.conference = conference;

						resolve(conference);
					})
					.catch(err => reject(err));
			});
		});
	}

	conferenceAddedTrack(mediaType) {
		const localTracks = getLocalTracks(getTrackState(APP.store.getState));

		const handleTrack = track => {
			if (!track) return;

			track.track.unmute();

			// Add track to the conference
			this.Conference.conference.addTrack(track.track);
		};

		if (localTracks) {
			for (const track of localTracks) {
				if (mediaType) {
					if (mediaType === MEDIA_TYPE.VIDEO && track.mediaType === MEDIA_TYPE.VIDEO) {
						handleTrack(track);
					} else if (mediaType === MEDIA_TYPE.AUDIO && track.mediaType === MEDIA_TYPE.AUDIO) {
						handleTrack(track);
					}
				} else {
					handleTrack(track);
				}
			}
		}
	}

	conferenceRemovedTrack(mediaType) {
		const localTracks = getLocalTracks(getTrackState(APP.store.getState));

		const handleTrack = track => {
			if (!track) return;

			if (track.mediaType === MEDIA_TYPE.AUDIO) track.track.mute();

			// Add track to the conference
			this.Conference.conference.removeTrack(track.track);
		};

		if (localTracks) {
			for (const track of localTracks) {
				if (mediaType) {
					if (mediaType === MEDIA_TYPE.VIDEO && track.mediaType === MEDIA_TYPE.VIDEO) {
						handleTrack(track);
					} else if (mediaType === MEDIA_TYPE.AUDIO && track.mediaType === MEDIA_TYPE.AUDIO) {
						handleTrack(track);
					}
				} else {
					handleTrack(track);
				}
			}
		}
	}

	conferenceMutedVideoTrack(isMuted) {
		this.Conference.conferenceMutedVideoTrack(isMuted);
	}

	conferenceReplaceTrack(newTrackId) {
		this.Conference.conferenceReplaceTrack(newTrackId);
	}

	toggleE2EE(isEnable) {
		if (this.Conference && this.Conference.conference) this.Conference.conference.toggleE2EE(isEnable);
	}

	// 회의실 disconnect
	disconnect() {
		return new Promise((resolve, reject) => {
			this.Conference.leave();
			this.Connection.disconnect();
			resolve();
		});
	}

	/**
	 * 녹화 시작
	 * @returns
	 */
	startScreenShare() {
		return ScreenShare.toggleScreenSharing(true);
	}

	/**
	 * 녹화 종료
	 * @returns
	 */
	stopScreenShare() {
		return ScreenShare.toggleScreenSharing(false);
	}

	createScreenShareTrack(options) {
		return this.Tracks.createScreenShareTrack(options);
	}

	getScreenSharing() {
		return ScreenShare.isScreenSharing();
	}

	async updateTrack(deviceId, type, options) {
		const newTrack = await createLocalTrack(type, deviceId, 5000);
		if (options?.muted !== undefined && options.muted) newTrack?.mute();
		if (type === MEDIA_TYPE.VIDEO) {
			this.Conference.useVideoStream(newTrack);
		} else {
			this.Conference.useAudioStream(newTrack);
		}
	}

	replaceTrack(oldTrack, newTrack) {
		return this.Conference.replaceTrack(oldTrack, newTrack);
	}

	async useVideoStream(videoTrack) {
		await this.Conference.useVideoStream(videoTrack);
	}

	/**
	 * AddEventListener
	 */
	/**
	 * 로컬 사용자의 연결 상태 이벤트 등록
	 * @param {function} handler
	 */
	registerUpdateLocalStats(handler) {
		const conference = this.Conference.conference;
		conference && conference.on(JitsiConnectionQualityEvents.LOCAL_STATS_UPDATED, handler);
	}

	/**
	 * remote 사용자의 연결 상태 이벤트 등록
	 * @param {function} handler
	 */
	registerUpdateRemoteStats(handler) {
		const conference = this.Conference.conference;

		conference.on(JitsiConnectionQualityEvents.REMOTE_STATS_UPDATED, handler);
	}

	/**
	 * Audio Output
	 */
	getAudioOutputDeviceId() {
		return JitsiMeetJS.mediaDevices.getAudioOutputDevice();
	}
	async setAudioOutputDevice(newId = '') {
		JitsiMeetJS.mediaDevices.setAudioOutputDevice(newId).catch(err => {
			if (!newId) return;
			const audioElement = document.createElement('audio');

			try {
				audioElement?.setSinkId(newId);
				console.log(`Audio output device changed to: ${newId}`);
			} catch (error) {
				console.log('Error setting audio output device:', error);
			}
		});
	}
}

export default JitsiManagement;
