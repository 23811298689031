export const config = {
	name: 'mimac',
	title: 'Mimac',
	defaultLanguage: 'ko',
	lobby: {
		showLogin: true,
		showMyPage: true,
		logoUrl: '/libs/images/meetmate_logo.svg',
		logoWidth: {
			mobile: 72,
			desktop: 130,
		},
		prevBackgroundUrl: '/libs/images/main_bg.png',
		prevBackground: 'no-repeat center/cover fixed',
		backgroundUrl: '/libs/images/bg_lobby.png',
		background: 'no-repeat center/cover fixed',
		subText: null,
		boxWidth: null,
	},
	nickname: {
		backgroundUrl: '/libs/images/bg_nickname.png',
		background: 'no-repeat center/cover fixed',
	},
	iceServers: [{ urls: 'stun:stun.l.google.com:19302' }],
	ui: {
		layout: {
			GRID_MODE: false,
			PINNED_MODE: false,
			VAD_MODE: false,
			SEMINAR_MODE: true,
			whiteboard: true,
			document: true,
			common_note: true,
			screenShare: true,
			vod: true,
		},
		document: {
			direction: 'column',
			location: 'left',
		},
		film: {
			pin: false,
		},
		desktop: {
			header: {
				show: true,
				direction: 'row',
				left: ['room_name', 'room_count', 'room_time', 'connection'],
				middle: ['mode_change'],
				right: ['recordList', 'test', 'chat', 'member', 'option'],
			},
			footer: {
				show: true,
				direction: 'column',
				left: ['one_depth_mode_change'],
				right: ['handler', 'setting', 'camera', 'mic', 'out'],
			},
			floating: {
				show: false,
				left: null,
			},
		},
		mobile: {
			header: {
				show: true,
				direction: 'row',
				left: ['connection', 'mode_change', 'room_name', 'room_count', 'room_time'],
				middle: null,
				right: ['test', 'handler', 'setting'],
			},
			footer: {
				show: true,
				direction: 'row',
				left: null,
				middle: ['camera', 'mic', 'chat', 'member', 'option', 'out'],
				right: null,
			},
			floating: {
				show: false,
				left: null,
			},
		},
		close: {
			shutdown: true,
			close: true,
		},
	},

	maxMember: '무제한',
	memberIcon: ['handler', 'mic', 'video', 'etc'],
	settingList: ['notificationSet', 'changeScreenMode'],
	optionList: ['grid', 'blur', 'security', 'dualMonitor', 'serverRecord', 'notification', 'settingDevice'],
	option: {
		e2ee: false,
		pin: false,
		grid: true,
		security: true,
		dualMonitor: true,
		selfRecord: false,
		serverRecord: true,
		devicePermission: true,
		setHandlerMode: true,
		changeScreenMode: true,
		settingDevice: true,
		fullscreen: true,
		group: false,
		workbook: true,
		file: true,
		modeChange: true,
		gridCountSet: true,
		invite: false,
		notificationSet: true,
		shareListPopup: false,
		attendance: false,
		chatFileLock: false,
		blur: true,
		vodFile: true,
		vodUrl: false,
		survey: false,
		isDetailMode: false,
		notification: true,
		controlDevice: true,
		hideParticipant: true,
		disableLobbyReservation: true,
		diableSignup: false,
		hideSettingNotification: true,
		hideNotification: true,
		hideLayoutMember: true,
		hideLayoutChange: true,
	},
	style: {
		enableIconSize: 24,
		subAccountColor: '#000',
		main: '#123',
		video_wrap: '#000',
		label: '#000',
		labelText: '#fff',
		playerColor: '#fff',
		focusColor: '#8bacff',
		iconButton: {
			background: null,
			color: '#fff',
			iconBackColor: '#fff',
			iconStrokeColor: null,
		},
		iconHoverButton: {
			background: null,
			color: '#8bacff',
			iconBackColor: '#8bacff',
			iconStrokeColor: '#8bacff',
		},
		optionButton: {
			background: null,
			color: '#000',
			iconBackColor: '#000',
			iconStrokeColor: null,
		},
		optionHoverButton: {
			background: null,
			color: '#8bacff',
			iconBackColor: '#8bacff',
			iconStrokeColor: null,
		},
		textButton: {
			background: '#494949',
			color: '#fff',
		},
		textHoverButton: {
			background: '#8bacff',
			color: '#fff',
		},
		canvasButton: {
			background: null,
			color: null,
			iconBackColor: '#545454',
			iconStrokeColor: '#545454',
		},
		canvasHoverButton: {
			background: '#b5b5b5',
			color: '#545454',
			iconBackColor: '#545454',
			iconStrokeColor: '#545454',
		},
	},
};
