import { throttle } from 'lodash';
import React, { useCallback, useEffect, useRef, useState, useMemo } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import { Loading } from '../../base/loading';
import { Button, DropDown } from '../common';
import { Colors } from '../common/Colors';
// Make sure to set the correct worker path
pdfjs.GlobalWorkerOptions.workerSrc = '/libs/pdf/pdf.worker.min.mjs';
function MyPdfViewer({ attachments }) {
    const [filepath, setFilepath] = useState(null);
    const [dropdownList, setDropdownList] = useState([]);
    const [selected, setSelected] = useState({ id: 'select', title: 'common.select' });
    const [numPages, setNumPages] = useState(0);
    const [pageNumber, setPageNumber] = useState(1);
    const [pageScale, setPageScale] = useState(1);
    const [containerWidth, setContainerWidth] = useState(0);
    const [containerHeight, setContainerHeight] = useState(0);
    const [imageStyle, setImageStyle] = useState({ width: 0, height: 0 });
    const containerRef = useRef(null);
    useEffect(() => {
        let list = [{ id: 'select', title: 'common.select' }];
        attachments.forEach(a => {
            a && list.push({ id: a, title: a.split('/').pop() || '파일' });
        });
        setDropdownList(list);
        setSelected(list[1]);
    }, [attachments]);
    useEffect(() => {
        setPageNumber(1);
        setNumPages(0);
        if (selected.id !== 'select') {
            setFilepath(selected.id);
        }
        else {
            setFilepath(null);
        }
    }, [selected]);
    const updatePdfStyle = useCallback(() => {
        if (imageStyle.height) {
            const scale = containerHeight / imageStyle.height;
            setPageScale(scale);
        }
    }, [containerHeight, imageStyle]);
    const throttledUpdatePdfStyle = useCallback(throttle(updatePdfStyle, 100), [updatePdfStyle]);
    useEffect(() => {
        throttledUpdatePdfStyle();
        return () => throttledUpdatePdfStyle.cancel();
    }, [containerHeight, containerWidth, imageStyle, throttledUpdatePdfStyle]);
    const onResize = useCallback(entries => {
        const [entry] = entries;
        if (entry) {
            setContainerWidth(entry.contentRect.width);
            setContainerHeight(entry.contentRect.height);
        }
    }, []);
    useEffect(() => {
        const observer = new ResizeObserver(onResize);
        const container = containerRef.current;
        if (container) {
            observer.observe(container);
        }
        return () => {
            if (container) {
                observer.unobserve(container);
            }
        };
    }, [onResize]);
    const onDocumentLoadSuccess = (pdf) => {
        setNumPages(pdf.numPages);
        pdf.getPage(1).then((page) => {
            setImageStyle({
                width: page.view[2],
                height: page.view[3],
            });
        });
    };
    // Memoize the options object to prevent unnecessary reloads
    const pdfOptions = useMemo(() => ({
        cMapUrl: '/cmaps/',
        cMapPacked: true,
    }), []);
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "pdf-control" },
            React.createElement(DropDown, { className: "dropdown-icon", list: dropdownList, value: selected, setDropValue: setSelected, option: {
                    titleIcon: {
                        show: true,
                        size: 22,
                        name: 'menu-pdf',
                        color: Colors.white,
                    },
                    downIcon: {
                        show: false,
                    },
                    titleText: {
                        show: false,
                    },
                } }),
            filepath && (React.createElement(React.Fragment, null,
                React.createElement(Button, { option: {
                        buttonBeforeIcon: {
                            show: true,
                            name: 'arrow',
                            size: 22,
                            color: Colors.white,
                        },
                    }, onClick: () => pageNumber > 1 && setPageNumber(pageNumber - 1) }),
                React.createElement("p", null,
                    pageNumber,
                    " / ",
                    numPages),
                React.createElement(Button, { option: {
                        buttonBeforeIcon: {
                            show: true,
                            name: 'arrow',
                            size: 22,
                            className: 'arrow-right',
                            color: Colors.white,
                        },
                    }, onClick: () => pageNumber < numPages && setPageNumber(pageNumber + 1) })))),
        React.createElement("div", { ref: containerRef, className: "test-area-body pdf-control-body" }, filepath ? (React.createElement(Document, { file: filepath, onLoadSuccess: onDocumentLoadSuccess, options: pdfOptions, loading: React.createElement(Loading, { isLoad: true }), onLoadError: err => console.error(err) },
            React.createElement(Page, { scale: pageScale, pageNumber: pageNumber, loading: React.createElement(Loading, { isLoad: true }) }))) : (React.createElement("div", { className: "no_file" }, "\uD30C\uC77C\uC744 \uC120\uD0DD\uD574\uC8FC\uC138\uC694")))));
}
export default MyPdfViewer;
