import { isRoomJoined } from '../../room';
import { MEDIA_TYPE } from '../media';
import { getBridgeSession, getLocalIDMember } from '../members';
import { StateListenerRegistry } from '../redux';
import ReducerRegistry from '../redux/ReducerRegistry';
import { TRACK_ADDED, TRACK_REMOVED, TRACK_UPDATED } from './actionTypes';
import { getTrackState, isTrackMutedByMediaType } from './functions';
/**
 * Track type.
 *
 * @typedef {object} Track
 * @property {JitsiLocalTrack|JitsiRemoteTrack} track - The associated
 * {@code track} instance. Optional for local tracks if those are still
 * being created (ie {@code getUserMedia} is still in progress).
 * @property {Promise} [gumProcess] - If a local track is still being created,
 * it will have no {@code track}, but a {@code gumProcess} set to a
 * {@code Promise} with and extra {@code cancel()}.
 * @property {boolean} local=false - If the track is local.
 * @property {MEDIA_TYPE} mediaType=false - The media type of the track.
 * @property {boolean} mirror=false - The indicator which determines whether the
 * display/rendering of the track should be mirrored. It only makes sense in the
 * context of video (at least at the time of this writing).
 * @property {boolean} muted=false - If the track is muted.
 * @property {(string|undefined)} participantId - The ID of the participant whom
 * the track belongs to.
 * @property {boolean} videoStarted=false - If the video track has already
 * started to play.
 * @property {(VIDEO_TYPE|undefined)} videoType - The type of video track if
 * any.
 */
/**
 * Reducer function for a single track.
 *
 * @param {Track|undefined} state - Track to be modified.
 * @param {Object} action - Action object.
 * @param {string} action.type - Type of action.
 * @param {string} action.name - Name of last media event.
 * @param {string} action.newValue - New participant ID value (in this
 * particular case).
 * @param {string} action.oldValue - Old participant ID value (in this
 * particular case).
 * @param {Track} action.track - Information about track to be changed.
 * @param {Participant} action.participant - Information about participant.
 * @returns {Track|undefined}
 */
function track(state, action) {
    switch (action.type) {
        case TRACK_UPDATED: {
            const t = action.track;
            if (state.track === t.track) {
                for (const p in t) {
                    // @ts-ignore
                    if (state[p] !== t[p]) {
                        return {
                            ...state,
                            ...t,
                        };
                    }
                }
            }
            break;
        }
    }
    return state;
}
/**
 * Listen for actions that mutate (e.g. Add, remove) local and remote tracks.
 */
ReducerRegistry.register('features/base/tracks', (state = [], action) => {
    switch (action.type) {
        case TRACK_UPDATED:
            return state.map((t) => track(t, action));
        case TRACK_ADDED: {
            let withoutTrackStub = state;
            if (action.track.local) {
                withoutTrackStub = state.filter((t) => !t.local || t.mediaType !== action.track.mediaType);
            }
            return [...withoutTrackStub, action.track];
        }
        case TRACK_REMOVED:
            return state.filter((t) => t.track !== action.track.track);
        default:
            return state;
    }
});
StateListenerRegistry.register((state) => {
    if (!isRoomJoined(state))
        return false;
    return {
        bridge: getBridgeSession(state, getLocalIDMember(state)),
        isAudioMuted: isTrackMutedByMediaType(state, 'local', MEDIA_TYPE.AUDIO),
        isVideoMuted: isTrackMutedByMediaType(state, 'local', MEDIA_TYPE.VIDEO),
    };
}, (result, store) => {
    if (!result)
        return;
    else if (!result.bridge)
        return;
    if (result.isAudioMuted !== result.bridge.isAudioMuted || result.isVideoMuted !== result.bridge.isVideoMuted) {
        APP.management.setBridgeId({
            ...result.bridge,
            audio_muted: result.isAudioMuted,
            video_muted: result.isVideoMuted,
        });
    }
}, {
    deepEquals: true,
});
StateListenerRegistry.register((state) => isTrackMutedByMediaType(state, 'local', MEDIA_TYPE.VIDEO), (isVideoMuted, store) => {
    if (!isRoomJoined(store.getState))
        return;
    APP.management.conferenceMutedVideoTrack(isVideoMuted);
}, {
    deepEquals: true,
});
StateListenerRegistry.register((state) => {
    const track = getTrackState(state).find(t => t.local && t.mediaType === MEDIA_TYPE.VIDEO);
    return track?.deviceId ? track.deviceId : track?.track?.deviceId;
}, 
// throttle(
(deviceId, store) => {
    if (!isRoomJoined(store.getState))
        return;
    console.log({ deviceId });
    deviceId && APP.management.conferenceReplaceTrack(deviceId);
}, {
    deepEquals: true,
});
// StateListenerRegistry.register(
// 	(state: IStore['getState']) => {
// 		const remote = getLayoutMemberByType(state, 'remote');
// 		if (!remote) return;
// 		const video_muted_list = remote.map(r => {
// 			const bridge_sessions = getBridgeSession(state, r);
// 			return {
// 				user_uuid: r,
// 				video_muted: bridge_sessions?.video_muted,
// 			};
// 		});
// 		return video_muted_list;
// 	},
// 	(result, store, prevResult) => {
// 		if (!result) return;
// 		if (!isRoomJoined(store.getState)) return;
// 		result.forEach((r: any) => {
// 			const prev = prevResult?.find((p: any) => p.user_uuid === r.user_uuid);
// 			console.log(r, prev);
// 			if (!prev || prev.video_muted !== r.video_muted) {
// 				APP.management.conferenceMutedVideoTrack(r.user_uuid, r.video_muted);
// 			}
// 		});
// 	},
// 	{
// 		deepEquals: true,
// 	},
// );
