import React, { useEffect, useState } from 'react';
import { MEDIA_TYPE, setAudioMuted } from '../../media';
import { getLocalTrack, getTrackState, isLocalTrackMutedByMediaType } from '../../tracks';
import { ClickButton } from '../../ui';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { ROLE, checkRoleLevel, getLocalMemberRole } from '../../members';
const ToggleAudioButton = React.memo(({ className, label, enableLable, iconStyles, }) => {
    const dispatch = useDispatch();
    const audioMuted = useSelector((state) => isLocalTrackMutedByMediaType(state, MEDIA_TYPE.AUDIO));
    const localTrack = useSelector((state) => getLocalTrack(getTrackState(state), MEDIA_TYPE.AUDIO), shallowEqual);
    const local_role = useSelector((state) => getLocalMemberRole(state));
    const [checkRole, setCheckRole] = useState(false);
    useEffect(() => {
        setCheckRole(checkRoleLevel(local_role, ROLE.PRESENTER));
    }, [local_role]);
    const initial_mic = useSelector((state) => {
        const policy = state['features/base/settings'].policy;
        return policy.initial_mic;
    });
    const [muted, setMuted] = useState(false);
    const [icon, setIcon] = useState('mic_on');
    const [color, setColor] = useState(APP.config.style.focusColor);
    const _toggleAudioMuted = () => {
        if (APP.config.option.controlDevice && initial_mic === 'off' && !checkRole) {
            APP.UI.alertMessage('현재 권한이 없습니다.');
            return;
        }
        if (!localTrack)
            return;
        dispatch(setAudioMuted(!audioMuted, false));
        setMuted(!audioMuted);
    };
    useEffect(() => {
        setMuted(audioMuted);
    }, [audioMuted]);
    useEffect(() => {
        setColor(muted ? 'red' : APP.config.style.focusColor);
        setIcon(muted ? 'mic_off' : 'mic_on');
    }, [muted]);
    return (React.createElement(ClickButton, { className: `${className} no-hover`, icon: icon, size: enableLable ? 20 : 24, label: label, color: color, styles: iconStyles, onClick: _toggleAudioMuted }));
});
export default ToggleAudioButton;
