import throttle from 'lodash/throttle';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';
import { useGlobalState } from '../../app/GlobalStateProvider';
import { CheckBox, InputElement, Table } from '../common';
import { AnswerContainer } from './AnswerContainer';
import { useLoadAnswer, useQuestionList } from './querys';
// @ts-ignore
const DetailQuestionStyled = styled.div `
	border-radius: 10px;
	display: flex;
	flex-direction: column;
	gap: 21px;
	align-items: flex-end;
	justify-content: flex-start;
	align-self: stretch;
	flex: 1;
	position: relative;

	.checkbox {
		flex-direction: column;
		align-items: flex-start;
		justify-content: flex-start;
		.checkbox-item {
			margin: 0;
		}
	}

	.fext-field {
		height: 100%;
		overflow: hidden;
	}

	.task-label {
		width: 100%;
	}

	.input_point_wrapper {
		background: #ffffff;
		border-radius: 4px;
		border-style: solid;
		border-color: var(--color-grey-w, #b6bac0);
		border-width: 1px;
		padding: 4px 10px 4px 10px;
		display: flex;
		flex-direction: row;
		gap: 6px;
		align-items: center;
		justify-content: center;
		flex-shrink: 0;
		position: relative;
		width: 100%;
		overflow: hidden;

		input {
			width: 100%;
			outline: none;
			border: none;
			padding: 0;
			text-decoration: none;
		}
	}

	${props => (props.$isMobile ? mobileStyles : desktopStyles)}
`;
const mobileStyles = css `
	.task-list-002 {
		display: flex;
		flex-direction: column;
		gap: 0px;
		align-items: flex-start;
		justify-content: flex-start;
		align-self: stretch;
		flex-shrink: 0;
		position: relative;
		width: 100%;

		/* .table-header {
			width: 100%;
			background: var(--color-silver, #f3f4f8);
			border-style: solid;
			border-color: var(--color-grey-w, #b6bac0);
			border-width: 1px 0px 0px 0px;
			padding: 10px 16px 10px 16px;
			display: flex;
			flex-direction: row;
			gap: 20px;
			align-items: flex-start;
			justify-content: flex-start;
			flex-shrink: 0;
			position: relative;

			color: var(--color-black, #101010);
			text-align: center;
			font-family: var(--b4-font-family, 'NotoSansKr-Regular', sans-serif);
			font-size: var(--b4-font-size, 14px);
			line-height: var(--b4-line-height, 28px);
			font-weight: var(--b4-font-weight, 400);
		} */

		.item {
			border-style: solid;
			border-color: var(--color-silver, #f3f4f8);
			border-width: 1px 0px 1px 0px;
			padding: 12px 0px 12px 0px;
			display: flex;
			flex-direction: column;
			gap: 6px;
			align-items: flex-start;
			justify-content: center;
			align-self: stretch;
			flex-shrink: 0;
			position: relative;

			.input_answer_wrapper {
				background: #ffffff;
				border-radius: 4px;
				border-style: solid;
				border-color: var(--color-grey-w, #b6bac0);
				border-width: 1px;
				padding: 4px 10px 4px 10px;
				display: flex;
				flex-direction: row;
				gap: 6px;
				align-items: center;
				justify-content: center;
				flex-shrink: 0;
				position: relative;
				color: var(--color-grey-l, #83868a);
				text-align: left;
				font-family: var(--b4-font-family, 'NotoSansKr-Regular', sans-serif);
				font-size: var(--b4-font-size, 14px);
				line-height: var(--b4-line-height, 28px);
				font-weight: var(--b4-font-weight, 400);
				position: relative;
			}

			.body-item {
				width: 100%;
			}

			color: var(--color-black, #101010);
			text-align: center;
			font-family: var(--b4-font-family, 'NotoSansKr-Regular', sans-serif);
			font-size: var(--b4-font-size, 12px);
			line-height: var(--b4-line-height, 28px);
			font-weight: var(--b4-font-weight, 400);
		}
	}
`;
const desktopStyles = css `
	.task-list-002 {
		display: flex;
		flex-direction: column;
		gap: 0px;
		align-items: flex-start;
		justify-content: flex-start;
		align-self: stretch;
		flex-shrink: 0;
		position: relative;
		overflow: hidden;

		.table-header {
			width: 100%;
			background: var(--color-silver, #f3f4f8);
			border-style: solid;
			border-color: var(--color-grey-w, #b6bac0);
			border-width: 1px 0px 0px 0px;
			padding: 10px 16px 10px 16px;
			display: flex;
			flex-direction: row;
			gap: 20px;
			align-items: flex-start;
			justify-content: flex-start;
			flex-shrink: 0;
			position: relative;

			color: var(--color-black, #101010);
			text-align: center;
			font-family: var(--b4-font-family, 'NotoSansKr-Regular', sans-serif);
			font-size: var(--b4-font-size, 14px);
			line-height: var(--b4-line-height, 28px);
			font-weight: var(--b4-font-weight, 400);
		}

		.scroll-content {
			margin: 0;
			width: 100%;
			overflow: auto;
		}

		.item {
			border-style: solid;
			border-color: var(--color-silver, #f3f4f8);
			border-width: 0px 0px 1px 0px;
			padding: 8px 16px 8px 16px;
			display: flex;
			flex-direction: row;
			gap: 20px;
			align-items: center;
			justify-content: flex-start;
			flex-shrink: 0;
			position: relative;

			.input_answer_wrapper {
				background: #ffffff;
				border-radius: 4px;
				border-style: solid;
				border-color: var(--color-grey-w, #b6bac0);
				border-width: 1px;
				padding: 4px 10px 4px 10px;
				display: flex;
				flex-direction: row;
				gap: 6px;
				align-items: center;
				justify-content: center;
				flex-shrink: 0;
				position: relative;
				color: var(--color-grey-l, #83868a);
				text-align: left;
				font-family: var(--b4-font-family, 'NotoSansKr-Regular', sans-serif);
				font-size: var(--b4-font-size, 14px);
				line-height: var(--b4-line-height, 28px);
				font-weight: var(--b4-font-weight, 400);
				position: relative;
			}

			color: var(--color-black, #101010);
			text-align: center;
			font-family: var(--b4-font-family, 'NotoSansKr-Regular', sans-serif);
			font-size: var(--b4-font-size, 14px);
			line-height: var(--b4-line-height, 28px);
			font-weight: var(--b4-font-weight, 400);
		}
	}
`;
function filterSelectedSubjects(data) {
    const filteredData = {
        subjects: [],
    };
    const subjectsDict = {};
    data.forEach(item => {
        const subject = item.subject_item;
        if (!subjectsDict[subject]) {
            subjectsDict[subject] = {
                subject_name: subject,
                select: subject.includes('선택'),
                questions: [],
            };
        }
        subjectsDict[subject].questions.push(item);
    });
    filteredData.subjects = Object.values(subjectsDict);
    return filteredData;
}
export const DetailQuestionTable = React.memo(({ member_id, isOpen, type, test_id, setData, subject, subject_name, setSubject, }) => {
    const { t } = useTranslation();
    const { isMobile, testData, setTestData, choiceSubject, setChoiceSubject } = useGlobalState();
    const [filterItems, setFilterItems] = useState();
    const [filterData, setFilterData] = useState([]);
    const [previewAnswer, setPreviewAnswer] = useState([]);
    const [first, setFirst] = useState(false);
    const { headTitleList, tableReadDataKeys } = useMemo(() => {
        const headTitleList = [
            { title: 'subject_item', space: 2, sort: false },
            { title: 'no', space: 1, sort: false },
            { title: 'answer', space: 4, sort: false },
        ];
        const tableReadDataKeys = [
            { title: 'subject_item', space: 2 },
            { title: 'number', space: 1 },
            { title: 'omr', space: 4 },
        ];
        return { headTitleList, tableReadDataKeys };
    }, []);
    const { data, refetch } = useQuestionList({
        test_id,
        orderby: ["IF(subject_item='공통',0,1)", 'subject_item', 'number'],
    });
    const { data: loadStatus, refetch: loadRefetch } = useLoadAnswer({
        id: test_id,
        props: { member_id },
    });
    useEffect(() => {
        member_id && loadRefetch();
    }, [member_id]);
    useEffect(() => {
        if (loadStatus) {
            // const myAnswerList: {
            // 	selected_item: string;
            // 	answer: {
            // 		question_id: string;
            // 		values: string[];
            // 	};
            // }[] = loadStatus;
            setChoiceSubject(prevChoiceSubject => ({ ...prevChoiceSubject, [test_id]: loadStatus.selected_item }));
            setPreviewAnswer(loadStatus.answers);
            return;
        }
        setPreviewAnswer([]);
    }, [loadStatus]);
    const refetchList = useMemo(() => throttle(refetch, 1000, { leading: true, trailing: false }), [refetch]);
    useEffect(() => {
        APP.eventManager.subscribe('ANSWER_REFETCH', refetchList);
        return () => {
            APP.eventManager.unsubscribe('ANSWER_REFETCH', refetchList);
        };
    }, [refetchList]);
    useEffect(() => {
        refetchList();
    }, [test_id, refetchList]);
    const updateList = data => {
        setTestData(prevTestData => {
            const updatedTestData = { ...prevTestData };
            if (updatedTestData[test_id]) {
                const existingIds = new Set(updatedTestData[test_id].map(item => item.id));
                const newData = data.filter(item => !existingIds.has(item.id));
                updatedTestData[test_id] = [...prevTestData[test_id], ...newData];
            }
            else {
                updatedTestData[test_id] = data.flat();
            }
            return updatedTestData;
        });
    };
    useEffect(() => {
        if (first)
            return;
        if (testData[test_id] && testData[test_id].length > 0 && previewAnswer.length > 0) {
            const items = testData[test_id];
            const myAnswerList = previewAnswer;
            const updatedItems = items.map(item => {
                const myAnswer = myAnswerList.find(i => i.question_id === item.id);
                if (myAnswer) {
                    return { ...item, myAnswer: myAnswer.values[0] };
                }
                return item;
            });
            setTestData(prevTestData => {
                const updatedTestData = { ...prevTestData };
                updatedTestData[test_id] = updatedItems;
                return updatedTestData;
            });
            setFirst(true);
        }
    }, [testData, previewAnswer]);
    useEffect(() => {
        if (data) {
            const { items } = data;
            const filteredResult = filterSelectedSubjects(items);
            if (type === 'OMR (기본형)') {
                let filter = [];
                filteredResult.subjects.forEach(subject => {
                    // @ts-ignore
                    if (subject.select)
                        filter = [...filter, subject.subject_name];
                });
                setFilterData(filter);
                if (filter.length <= 0)
                    setChoiceSubject(prevChoiceSubject => ({ ...prevChoiceSubject, [test_id]: 'default' }));
                else if (filter.length === 1) {
                    setChoiceSubject(prevChoiceSubject => ({
                        ...prevChoiceSubject,
                        [test_id]: filter[0],
                    }));
                }
            }
            else {
                setChoiceSubject(prevChoiceSubject => ({
                    ...prevChoiceSubject,
                    [test_id]: 'default',
                }));
            }
            setFilterItems(filteredResult);
        }
    }, [data, setChoiceSubject, setFilterData, test_id, type]);
    useEffect(() => {
        setData(testData[test_id]);
    }, [testData, setData, test_id]);
    useEffect(() => {
        let data = [];
        filterItems &&
            filterItems.subjects.forEach(subject => {
                if (subject.subject_name === choiceSubject[test_id]) {
                    data.push(subject.questions);
                }
                if (!subject.select) {
                    data.push(subject.questions);
                }
            });
        updateList(data.flat());
    }, [choiceSubject, filterItems, setTestData, test_id]);
    const onChange = (questionId, text) => {
        console.log(questionId, text);
        setTestData(prevTestData => {
            const updatedTestData = { ...prevTestData };
            if (updatedTestData[test_id]) {
                updatedTestData[test_id] = updatedTestData[test_id].map(item => item.id === questionId ? { ...item, myAnswer: text ? text.toString() : '' } : item);
            }
            console.log(updatedTestData);
            return updatedTestData;
        });
    };
    const renderItem = (item, styles) => {
        const isNumber = subject_name === 'math';
        return tableReadDataKeys.map(headItem => (React.createElement("div", { key: headItem.title, className: "body-item", style: { flex: headItem.space, ...styles?.bodyItem, overflow: 'hidden' } }, headItem.title === 'omr' ? (item.type === 'choice' ? (React.createElement(AnswerContainer, { maxCount: item.examples, defaultValue: item.myAnswer ? parseInt(item.myAnswer) : undefined, onChange: text => onChange(item.id, text) })) : (React.createElement(InputAnswer, { isNumber: isNumber, 
            // @ts-ignore
            isDisable: headItem.title === 'answer' || headItem.title === 'submitAnswer', myAnswer: item.myAnswer || '', onChange: text => onChange(item.id, text) }))) : (React.createElement("p", { className: "overText" }, item[headItem.title])))));
    };
    if (!choiceSubject[test_id] && type === 'OMR (기본형)') {
        return (React.createElement(DetailQuestionStyled, { "$isMobile": isMobile, className: "field-global-001 detail-question-wrap test-area-body" },
            React.createElement(CheckBox, { list: filterData.map(item => ({
                    id: item,
                    title: item,
                    checked: subject === item ? true : false,
                })), isRadioButton: true, handlerCheck: setSubject })));
    }
    const list = testData[test_id] || [];
    return (React.createElement(DetailQuestionStyled, { "$isMobile": isMobile, className: "field-global-001 detail-question-wrap test-area-body" },
        React.createElement(Table, { className: "task-list-002", showIndex: false, isInifinite: true, page: 0, data: list, totalCount: list.length, headTitleList: headTitleList, styles: {
                headWrapper: {
                    justifyContent: 'center',
                    alignItems: 'center',
                },
                headItem: {
                    alignItems: 'center',
                    justifyContent: 'center',
                },
            }, renderItem: renderItem, forceShowheader: false })));
});
const InputAnswer = React.memo(({ isNumber, isDisable, myAnswer, onChange, }) => {
    const { t } = useTranslation();
    const inputRef = useRef(null);
    useEffect(() => {
        if (inputRef.current) {
            inputRef.current.value = myAnswer;
        }
    }, [myAnswer]);
    return (React.createElement(InputElement, { ref: inputRef, className: "input_answer_wrapper", type: "text", isNumber: isNumber, maxNumLen: isNumber ? 5 : undefined, isDisable: isDisable, placeholder: t('assignment.inputAnswerPlaceholder'), onChange: onChange }));
});
