import { throttle } from 'lodash';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import ChatItemContainer from './ChatItemContainer';
import { removeChatList, updateLastItemId } from './action';
import { getChatIds, getLastUId, getTotalCount } from './functions';
let lastItemSize = 0;
const ChatBodyContainer = React.memo(() => {
    const dispatch = useDispatch();
    const chatItems = useSelector((state) => getChatIds(state), shallowEqual);
    const totalCount = useSelector((state) => getTotalCount(state));
    const lastId = useSelector((state) => getLastUId(state));
    const [lastItemId, setLastItemId] = useState(null);
    const [loading, setLoading] = useState(false);
    const observerPrevious = useRef(null);
    const contentRef = useRef(null);
    const scrollRef = useRef(null);
    const previousDivRef = useRef(null);
    useEffect(() => {
        return () => {
            dispatch(removeChatList());
        };
    }, []);
    const loadPrevItems = useCallback(() => {
        if (!contentRef.current)
            return;
        if (totalCount <= 0)
            return;
        if (previousDivRef.current && previousDivRef.current.scrollHeight > previousDivRef.current.clientHeight)
            return;
        setLoading(true);
        const lastUId = chatItems[0];
        setLastItemId(lastUId);
        setLoading(false);
    }, [loading, chatItems, totalCount, dispatch]);
    useEffect(() => {
        lastItemId && dispatch(updateLastItemId(lastItemId));
    }, [lastItemId]);
    const handlePreviousIntersect = useCallback((entries) => {
        entries.forEach(entry => {
            if (entry.isIntersecting && !loading) {
                loadPrevItems();
            }
        });
    }, [loading, loadPrevItems]);
    useEffect(() => {
        if (observerPrevious.current) {
            observerPrevious.current.disconnect();
        }
        observerPrevious.current = new IntersectionObserver(handlePreviousIntersect, {
            threshold: 0.5,
            root: null,
        });
        if (previousDivRef.current) {
            observerPrevious.current.observe(previousDivRef.current);
        }
        return () => {
            if (observerPrevious.current) {
                observerPrevious.current.disconnect();
            }
        };
    }, [handlePreviousIntersect]);
    const renderChat = useCallback(throttle(() => {
        if (scrollRef.current) {
            if (lastItemSize !== 0 && chatItems.length - lastItemSize >= 10) {
                scrollRef.current.scrollTop =
                    scrollRef.current.scrollHeight * ((chatItems.length - lastItemSize) / chatItems.length);
                lastItemSize = chatItems.length;
                return;
            }
            scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
            lastItemSize = chatItems.length;
        }
    }, 500), [chatItems, scrollRef.current?.scrollHeight]);
    useEffect(() => {
        renderChat();
    }, [renderChat]);
    return (React.createElement("div", { id: "chat_body_scroll", ref: scrollRef, style: { height: '100%', overflowY: 'scroll' } },
        React.createElement("div", { id: "content", ref: contentRef },
            React.createElement("div", { id: "previousDiv", ref: previousDivRef, style: { height: '10px' } }),
            chatItems.map((uuid, index) => (React.createElement(ChatItemContainer, { key: uuid, uuid: uuid }) // ChatItemContainer에 key를 uuid로 설정
            )))));
});
export default ChatBodyContainer;
