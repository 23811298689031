import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { ROLE, checkRoleLevel, getLocalMemberRole } from '../members';
import { ClickButton, OnOffSwitch } from '../ui';
const SettingNotificationContainer = React.memo(({ handlerUpdatePolicy, renderSubmitBtn }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const isShowLvl = useSelector((state) => checkRoleLevel(getLocalMemberRole(state), ROLE.PRESENTER));
    const notification_option = useSelector((state) => state['features/base/settings'].policy.notification_option);
    const enable_main_view = useMemo(() => !notification_option?.enable_main_view, [notification_option?.enable_main_view]);
    const [isShow, setIsShow] = useState(enable_main_view);
    // const [durationTime, setDurationTime] = useState(duration / 1000);
    useEffect(() => {
        setIsShow(enable_main_view);
    }, [enable_main_view]);
    // useEffect(() => {
    // 	setDurationTime(duration / 1000);
    // }, [duration]);
    const onsubmit = () => {
        handlerUpdatePolicy({
            notification_option: {
                ...notification_option,
                enable_main_view: !isShow,
                // duration: durationTime * 1000,
            },
        });
        // dispatch(
        // 	// @ts-ignore
        // 	updateRoomPolicy(
        // 		{
        // 			notification_option: {
        // 				...notification_option,
        // 				enable_main_view: !isShow,
        // 				// duration: durationTime * 1000,
        // 			},
        // 		},
        // 		false,
        // 	),
        // );
    };
    const label = useMemo(() => (isShow ? 'ON' : 'OFF'), [isShow]);
    return (React.createElement("div", { className: "screen_table", style: { gap: 20 } },
        React.createElement("p", null, "[\uC54C\uB9BC \uC124\uC815]\uC744 ON \uC124\uC815\uC2DC, \uCE74\uBA54\uB77C\uAC00 \uAEBC\uC838\uC788\uC744 \uACBD\uC6B0 \uC54C\uB9BC\uC744 \uBC1B\uC744 \uC218 \uC788\uC2B5\uB2C8\uB2E4."),
        React.createElement("div", { className: "screen_item" },
            React.createElement("div", { className: "screen_title" }, "\uCE74\uBA54\uB77C ON \uC54C\uB9BC \uC124\uC815"),
            React.createElement("div", { className: "screen_frame" }, isShowLvl ? (React.createElement(OnOffSwitch, { label: label, isShow: isShow, handlerClick: setIsShow })) : (React.createElement(ClickButton, { className: "isFocus disable", isText: true, label: label })))),
        isShowLvl && renderSubmitBtn(onsubmit)));
});
export default SettingNotificationContainer;
// function SettingNotificationContainer({ dispatch, time, renderSubmitBtn }) {
// 	const { t } = useTranslation();
// 	const [maxCount, setMaxCount] = useState(limit);
// 	const [durationTime, setDurationTime] = useState(time);
// 	useEffect(() => {
// 		setMaxCount(limit);
// 	}, [limit]);
// 	useEffect(() => {
// 		setDurationTime(time / 1000);
// 	}, [time]);
// 	const handlerClick = () => {
// 		if (maxCount < 0) {
// 			APP.UI.alertMessage('dialog.notAllowCount');
// 			return;
// 		}
// 		if (durationTime < 0) {
// 			APP.UI.alertMessage('dialog.notAllowTime');
// 			return;
// 		}
// 		dispatch(updateNotificationOption(maxCount, durationTime * 1000));
// 		dispatch(toggleDialog());
// 	};
// 	/**
// 	 * 알림 메시지 수
// 	 * @returns
// 	 */
// 	const renderLimitCount = () => {
// 		return (
// 			// <Input
// 			// 	className="grid_count_input"
// 			// 	type="number"
// 			// 	bottomLabel={t('dialog.per')}
// 			// 	value={maxCount}
// 			// 	onChange={setMaxCount}
// 			// />
// 			<OnOffSwitch label={label} isShow={isShow} handlerClick={setIsShow} />
// 		);
// 	};
// 	/**
// 	 * 알림 메시지 유지 시간
// 	 * @returns
// 	 */
// 	const renderTime = () => {
// 		return (
// 			<Input
// 				className="grid_count_input"
// 				type="number"
// 				bottomLabel={t('dialog.sec')}
// 				value={durationTime}
// 				onChange={setDurationTime}
// 			/>
// 		);
// 	};
// 	return (
// 		<>
// 			<SettingNotification
// 				t={t}
// 				guid={t('dialog.notificationGuid')}
// 				renderLimitCount={renderLimitCount}
// 				renderTime={renderTime}
// 			/>
// 			{renderSubmitBtn(handlerClick)}
// 		</>
// 	);
// }
// function _mapStateToProps(state) {
// 	const { limit, time } = state['features/base/notification'];
// 	return {
// 		limit,
// 		time,
// 	};
// }
// export default connect(_mapStateToProps)(SettingNotificationContainer);
