import React, { useState } from 'react';
import { useGlobalState } from '../../app/GlobalStateProvider';
import { Button } from '../common';
import { Colors } from '../common/Colors';
import { DSTL001_Submit_View } from './DSTL001_Submit_View';
import { SubmissionStatusTable } from './SubmissionStatusTable';
export const SubmissionContainer = React.memo(({ isTest, test_id }) => {
    const { isMobile, isTablet, isDesktop } = useGlobalState();
    const [member, setMember] = useState(undefined);
    if (!test_id)
        return null;
    return (React.createElement("div", { className: `submssion-container ${!isDesktop && 'mobile'}` },
        React.createElement("div", { className: "wrap" },
            ((isMobile || isTablet) && !member) || isDesktop ? (React.createElement("div", { className: "submission-list" },
                React.createElement(SubmissionStatusTable, { isTest: isTest, test_id: test_id, isDetail: true, handlerUpdateMember: setMember }))) : null,
            ((isMobile || isTablet) && member) || isDesktop ? (React.createElement(React.Fragment, null,
                (isMobile || isTablet) && member && (React.createElement(Button, { className: "prev", option: {
                        buttonBeforeIcon: {
                            show: true,
                            name: 'arrow',
                            size: 20,
                            color: Colors.w_grey,
                        },
                    }, text: "\uB4A4\uB85C\uAC00\uAE30", onClick: () => setMember(undefined) })),
                React.createElement("div", { className: "score-list" },
                    React.createElement(DSTL001_Submit_View, { hideTitle: false, test_id: test_id, member: member })))) : null)));
});
